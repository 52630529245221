import { Form, Modal } from 'antd';

import TaxRateSelect from '../../../../../../../components/TaxRateSelect';
import stores from '../../../../../../../stores/index.mobx';

interface Props {
	visible: boolean;
	newStatus: boolean;
	onCancel: () => void;
	onOk: (string) => void;
}

export default function VATChangeModal({
	visible,
	newStatus,
	onCancel,
	onOk,
}: Props) {
	const [form] = Form.useForm();

	const {
		taxRates: { noVat: noVatList },
	} = stores;

	const noVatRate = noVatList[0];

	async function submitForm() {
		try {
			const values = await form.validateFields();
			onOk(values.taxRateLabel || noVatRate.label);
		} catch (error) {
			return;
		}
	}

	return (
		<Modal
			centered
			visible={visible}
			title="Promena PDV statusa"
			closable={false}
			onCancel={onCancel}
			onOk={() => {
				form.submit();
			}}
		>
			<Form form={form} onFinish={submitForm}>
				{newStatus && (
					<>
						<p>
							Ulaskom u sistem PDV-a, svim postojećim artiklima je potrebno
							dodeliti novu poresku oznaku. Odaberite novu poresku oznaku:
						</p>
						<Form.Item
							name="taxRateLabel"
							extra="Napomena: odabrana poreska oznaka možda neće odgovarati svim proizvodima. "
							rules={[
								{
									required: true,
									message: 'Morate odabrati poresku oznaku',
								},
							]}
						>
							<TaxRateSelect style={{ width: '100%' }} vat={true} />
						</Form.Item>
					</>
				)}
				{!newStatus && (
					<>
						<p>
							Izlaskom iz sistema PDV-a, svim artiklima će se poreska oznaka
							promeniti na poresku oznaku{' '}
							<strong>
								{noVatRate.name} - {noVatRate.label}
							</strong>
							. Da li ste sigurni da želite da nastavite?
						</p>
					</>
				)}
			</Form>
		</Modal>
	);
}
