import { DatePicker, Input, InputNumber, Select } from 'antd';

import { CreatePage } from '../../../../components/Page';
import { RulesInput } from './Components/RulesInput';

export default CreatePage({
	module: 'settings',
	submodule: 'discounts',
	model: 'discount',
	view: {
		useEdit: true,
	},
	edit: {
		width: 900,
		title: {
			new: 'Dodavanje popusta',
			existing: (entity) => `Izmena popusta ${entity?.name || ''}`,
		},
		shouldFetch: true,
		fields: [
			{
				key: 'basic',
				label: 'Osnovne informacije',
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						rules: [{ required: true, message: 'Naziv je obavezan' }],
						component: <Input />,
						span: 18,
					},
					{
						dataIndex: 'type',
						label: 'Tip',
						key: 'type',
						rules: [{ required: true, message: 'Tip je obavezan' }],
						component: (
							<Select>
								<Select.Option value="percentage">Procenat</Select.Option>
								<Select.Option value="fixed" disabled>
									Iznos
								</Select.Option>
							</Select>
						),
						span: 12,
					},
					{
						key: 'percentage',
						label: 'Procenat',
						rules: [{ required: true, message: 'Procenat je obavezan' }],
						component: (
							<InputNumber
								addonAfter="%"
								step={0.01}
								min={0}
								max={100}
								style={{ width: '100%' }}
							/>
						),
						span: 12,
					},
				],
			},
			{
				key: 'interval',
				label: 'Period',
				fields: [
					{
						key: 'beginAt',
						dataIndex: 'beginAt',
						label: 'Od',
						component: (
							<DatePicker
								showTime
								format="LL HH:mm:ss"
								style={{ width: '100%' }}
							/>
						),
						span: 12,
					},
					{
						key: 'endAt',
						dataIndex: 'endAt',
						label: 'Do',
						component: (
							<DatePicker
								showTime
								format="LL HH:mm:ss"
								style={{ width: '100%' }}
							/>
						),
						span: 12,
					},
				],
			},
			{
				key: 'rules',
				label: 'Pravila',
				fields: [
					{
						key: 'rules',
						dataIndex: 'rules',
						component: <RulesInput />,
						span: 24,
					},
				],
			},
		],
	},
	page: {
		shouldFetch: true,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: true,
				},
				{
					title: 'Procenat',
					dataIndex: 'percentage',
					key: 'percentage',
					render: (value) => `${value}%`,
				},
				{
					title: 'Period',
					key: 'interval',
					render: (value, entity) => {
						return `${
							entity?.beginAt
								? `Od ${entity.beginAt.format('LL HH:mm:ss')} `
								: ''
						}${
							entity?.endAt ? `do ${entity.endAt.format('LL HH:mm:ss')}` : ''
						}`;
					},
				},
			],
		},
		createButtonText: 'Dodaj popust',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj popust?',
	},
});
