import { DownOutlined, PrinterOutlined } from '@ant-design/icons';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Drawer,
	Dropdown,
	Form,
	Menu,
	Row,
	Select,
	Space,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import printjs from 'print-js';
import { useCallback, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useQueryParam } from 'use-query-params';

import CashierSelect from '../../../../../../components/CashierSelect';
import { useDrawer } from '../../../../../../components/Page';
import A4Periodic from '../../../../../../components/Reports/Periodic/A4Periodic';
import { ThermalPeriodic } from '../../../../../../components/Reports/Periodic/thermalPeriodic';
import StoreSelect from '../../../../../../components/StoreSelect';
import stores from '../../../../../../stores/index.mobx';
import { PeriodicReport as PeriodicReportType } from '../../../../../../stores/Report.mobx';
import styles from './PeriodicReport.module.less';

type PrintData = {
	from: moment.Moment | null;
	to: moment.Moment | null;
	storeId: string | null;
	invoiceTypes: ('normal' | 'advance')[];
	cashiers: string[];
	getProducts: boolean;
	getDaily: boolean;
	getByPaymentMethod: boolean;
	splitProductsByPaymentMethod: boolean;
	data: PeriodicReportType;
};

const PeriodicReport = () => {
	const printDestination = useRef(null);
	const printAreaRef = useRef<HTMLDivElement>(null);

	const [initialDate] = useQueryParam('date');

	const [printData, setPrintData] = useState<PrintData>({
		storeId: null,
		from: null,
		to: null,
		invoiceTypes: [],
		data: null,
		cashiers: [],
		getProducts: false,
		getDaily: false,
		getByPaymentMethod: false,
		splitProductsByPaymentMethod: false,
	});
	const [, , close, visible] = useDrawer('periodic-report');

	const [splitEnabled, setSplitEnabled] = useState(false);

	const [form] = Form.useForm();

	const {
		devices: { thermalPrinters },
		report: { fetchPeriodicReport, isFetching },
		stores: { currentStoreId: storeId },
	} = stores;

	const printThermal = useMemo(() => {
		return window.electron && thermalPrinters.length;
	}, [thermalPrinters]);

	const menu = useMemo(() => {
		if (!printThermal) {
			return null;
		}
		return (
			<Menu
				onClick={() => {
					printDestination.current = 'a4';
					form.submit();
				}}
				items={[
					{
						key: '1',
						label: 'Štampaj na A4 štampaču',
					},
				]}
			/>
		);
	}, [printThermal]);

	const onSubmit = useCallback(async () => {
		const query = form.getFieldsValue();

		const data = await fetchPeriodicReport({
			from: query.period[0].toISOString(),
			to: query.period[1].toISOString(),
			cashierId: query.cashierId,
			storeId: query.storeId,
			invoiceType: query.invoiceType,
			getProducts: query.getProducts,
			getDaily: query.getDaily,
			getByPaymentMethod: query.getByPaymentMethod,
			splitProductsByPaymentMethod: query.splitProductsByPaymentMethod,
		});
		if (printDestination.current === 'thermal') {
			const thermalReport = new ThermalPeriodic(
				query.storeId,
				query.period[0],
				query.period[1],
				query.invoiceType,
				data,
				query.cashierId,
				query.getProducts,
				query.getDaily,
				query.getByPaymentMethod,
				query.splitProductsByPaymentMethod
			);
			thermalReport.print();
		}

		if (printDestination.current === 'a4') {
			flushSync(() => {
				setPrintData({
					from: query.period[0],
					to: query.period[1],
					storeId: query.storeId,
					invoiceTypes: query.invoiceType,
					cashiers: query.cashierId,
					getProducts: query.getProducts,
					getDaily: query.getDaily,
					getByPaymentMethod: query.getByPaymentMethod,
					splitProductsByPaymentMethod: query.splitProductsByPaymentMethod,
					data,
				});
			});
			printjs({
				printable: 'printarea',
				type: 'html',
				targetStyles: ['*'],
				font_size: '',
			});
		}
	}, [form, fetchPeriodicReport]);

	const onChange = useCallback((_, values) => {
		setSplitEnabled(values.getProducts);
	}, []);

	return (
		<Drawer
			visible={visible}
			width={500}
			title={`Periodični izveštaj`}
			onClose={close}
			// zIndex={zIndex}
			className={styles.drawer}
			footer={
				<>
					<Space className={styles.rightButtons}>
						{!!printThermal && (
							<Dropdown.Button
								disabled={isFetching}
								icon={<DownOutlined />}
								overlay={menu}
								onClick={() => {
									printDestination.current = 'thermal';
									form.submit();
								}}
							>
								<PrinterOutlined /> Štampaj na termalnom štampaču
							</Dropdown.Button>
						)}
						{!printThermal && (
							<Button
								disabled={isFetching}
								onClick={() => {
									printDestination.current = 'a4';
									form.submit();
								}}
							>
								<PrinterOutlined /> Štampaj na A4 štampaču
							</Button>
						)}
					</Space>
					<Space className={styles.leftButtons}>
						<Button
							onClick={() => {
								close();
							}}
						>
							Zatvori
						</Button>
					</Space>
				</>
			}
		>
			{visible && (
				<Form
					form={form}
					layout="vertical"
					onFinish={onSubmit}
					onValuesChange={onChange}
					preserve={false}
				>
					<Form.Item
						label="Period"
						name="period"
						rules={[{ required: true, message: 'Odaberite period' }]}
						initialValue={
							initialDate
								? [
										moment(initialDate).startOf('month'),
										moment(initialDate).endOf('month'),
								  ]
								: [moment(), moment()]
						}
					>
						<DatePicker.RangePicker
							style={{ width: '100%' }}
							format="L"
							popupClassName={styles.datePicker}
						/>
					</Form.Item>
					<Form.Item
						label="Vrsta računa"
						name="invoiceType"
						rules={[{ required: true, message: 'Odaberite vrstu računa' }]}
						initialValue={['normal', 'advance']}
					>
						<Select mode="multiple">
							<Select.Option value={'normal'}>Promet</Select.Option>
							<Select.Option value={'advance'}>Avans</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Prodajno mesto"
						name="storeId"
						rules={[{ required: true, message: 'Odaberite prodajno mesto' }]}
						initialValue={storeId}
					>
						<StoreSelect />
					</Form.Item>
					<Form.Item label="Kasiri" name="cashierId">
						<CashierSelect mode="multiple" />
					</Form.Item>
					<Form.Item
						name="getDaily"
						valuePropName="checked"
						initialValue={true}
					>
						<Checkbox value="true">Štampaj izveštaj po danima</Checkbox>
					</Form.Item>
					<Row>
						<Col span={12}>
							<Form.Item name="getProducts" valuePropName="checked">
								<Checkbox value="true">Štampaj prodate artikle</Checkbox>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="splitProductsByPaymentMethod"
								valuePropName="checked"
							>
								<Checkbox disabled={!splitEnabled} value="true">
									Razdvoji po načinu plaćanja
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item name="getByPaymentMethod" valuePropName="checked">
						<Checkbox value="true">Štampaj iznose po načinu plaćanja</Checkbox>
					</Form.Item>
				</Form>
			)}
			<div className={styles.printArea}>
				<div ref={printAreaRef} id="printarea">
					{printData.data && <A4Periodic {...printData} />}
				</div>
			</div>
		</Drawer>
	);
};

export default observer(PeriodicReport);
