import { createContext, useMemo, useRef, useState } from 'react';

export const DrawerContext = createContext(null);

export const DrawerProvider = ({ children }) => {
	const data = useRef([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState([]);

	const isOpen = useMemo(
		() => isDrawerOpen.length > 0,
		[isDrawerOpen, isDrawerOpen.length]
	);

	return (
		<DrawerContext.Provider
			value={{
				isDrawerOpen: isOpen,
				setIsDrawerOpen: (name) => {
					if (!data.current.includes(name)) {
						data.current.push(name);
						setIsDrawerOpen(data.current);
					}
				},
				setIsDrawerClosed: (name) => {
					data.current = data.current.filter((item) => item !== name);
					setIsDrawerOpen(data.current);
				},
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};
