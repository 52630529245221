import { CloudDownloadOutlined } from '@ant-design/icons';
import {
	Button,
	Carousel,
	Col,
	Divider,
	Drawer,
	Row,
	Space,
	Tag,
	Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import { MARKETPLACE_APPLICATION_SCOPE } from '../../../../../../constants/marketplace';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Marketplace.module.less';
import InstallConfirmation from '../../Modals/InstallConfirmation';
import InstallSuccessModal from '../../Modals/InstallSuccessModal';

const Application = () => {
	const [applicationId, , close, visible] = useDrawer(
		'marketplace-application'
	);
	const [installConfirmationVisible, setInstallConfirmationVisible] =
		useState(false);
	const [installSuccessVisible, setInstallSuccessVisible] = useState(false);

	const { marketplaceApplications } = stores;
	const { single } = marketplaceApplications;
	useEffect(() => {
		if (visible && applicationId) {
			marketplaceApplications.fetchSingle(applicationId);
		}
	}, [visible, applicationId]);

	return (
		<Drawer
			visible={visible}
			onClose={close}
			placement="right"
			width={800}
			title={
				<>
					<Row gutter={[16, 16]}>
						<Col flex="none">
							{single?.logoFile ? (
								<img
									src={single.logoFile.urls['64x64']}
									style={{ width: 32, height: 32 }}
								/>
							) : (
								<img
									src="/images/icons/interface.png"
									style={{ width: 32, height: 32 }}
								/>
							)}
						</Col>
						<Col flex="auto">
							<Row gutter={[8, 8]}>
								<Col flex="auto">
									{single?.name}&nbsp;
									<Typography.Text
										type="secondary"
										className={styles.regularText}
									>
										{single?.version}
									</Typography.Text>
									&nbsp;&nbsp;&nbsp;
									{single?.isInstalled && (
										<Tag className={styles.regularText} color="green">
											Instalirana
										</Tag>
									)}
								</Col>
							</Row>
							<Row gutter={[8, 8]} className={styles.regularText}>
								<Col flex="auto">
									<Typography.Text type="secondary">
										{single?.publisher?.name}
									</Typography.Text>
									<Divider type="vertical" />
									<Typography.Text type="secondary">
										<CloudDownloadOutlined />{' '}
										{numberFormat(single?.installationCount, false, 0, false)}
									</Typography.Text>
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={close}>Zatvori</Button>
					</Space>
					<Space className={styles.rightButtons}>
						{single?.isInstalled && (
							<Button
								danger
								type="primary"
								onClick={single?.uninstall}
								disabled={single?.isInstalling}
								loading={single?.isInstalling}
							>
								Ukloni
							</Button>
						)}
						{!single?.isInstalled && (
							<Button
								type="primary"
								onClick={() => setInstallConfirmationVisible(true)}
								disabled={single?.isInstalling}
								loading={single?.isInstalling}
							>
								Instaliraj
							</Button>
						)}
					</Space>
				</>
			}
		>
			<Carousel
				slidesToShow={3}
				slidesToScroll={1}
				autoplay={true}
				autoplaySpeed={3000}
				infinite={true}
				className={styles.carousel}
			>
				{single?.images?.map((screenshot) => (
					<div className={styles.carouselItem}>
						<img src={screenshot.urls['1024x1024']} alt="" />
					</div>
				))}
			</Carousel>
			{/* <div className="ant-descriptions-title">Opis</div> */}
			<p dangerouslySetInnerHTML={{ __html: single?.description }}></p>
			<p>
				<b>Aplikacija će imati pristup sledećim podacima:</b>
				<br />
				<ul>
					{single?.scopes?.map((scope) => (
						<li>{MARKETPLACE_APPLICATION_SCOPE[scope]}</li>
					))}
				</ul>
			</p>
			<p>
				<strong>Web sajt: </strong> {single?.website}
				<br />
				{single?.supportEmail && (
					<>
						<strong>E-mail za podršku: </strong> {single?.supportEmail}
						<br />
					</>
				)}
				{single?.termsOfServiceUrl && (
					<>
						<strong>Uslovi korišćenja: </strong> {single?.termsOfServiceUrl}
						<br />
					</>
				)}
				{single?.privacyPolicyUrl && (
					<>
						<strong>Politika privatnosti: </strong> {single?.privacyPolicyUrl}
						<br />
					</>
				)}
			</p>
			<InstallConfirmation
				application={single}
				visible={installConfirmationVisible}
				close={() => setInstallConfirmationVisible(false)}
				openSuccessModal={() => setInstallSuccessVisible(true)}
			/>
			<InstallSuccessModal
				application={single}
				visible={installSuccessVisible}
				close={() => setInstallSuccessVisible(false)}
			/>
		</Drawer>
	);
};

const ObservedApplication = observer(Application);

addToDrawersRegistry('marketplace-application', ObservedApplication);

export default ObservedApplication;
