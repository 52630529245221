import {
	DownloadOutlined,
	EllipsisOutlined,
	MailOutlined,
	PrinterOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import Bugsnag from '@bugsnag/js';
import {
	Button,
	Input,
	Space,
	DatePicker,
	Tooltip,
	Badge,
	Grid,
	Dropdown,
	notification,
} from 'antd';
import first from 'lodash/first';
import last from 'lodash/last';
import round from 'lodash/round';
import moment from 'moment';
import printjs from 'print-js';
import { useCallback, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { CreatePage, useDrawer } from '../../../../components/Page';
import EmailModal from '../../../../components/PaymentModal/Components/EmailModal';
import PaymentModal from '../../../../components/PaymentModal/PaymentModal';
import {
	InvoiceType,
	INVOICE_TYPE_FROM_STRING,
	INVOICE_TYPE_MAP,
	INVOICE_TYPE_TEXT,
	PaymentType,
	PAYMENT_TYPE_FROM_STRING,
	PAYMENT_TYPE_MAP,
	TransactionType,
	TransactionTypeAPI,
	TRANSACTION_TYPE_FROM_STRING,
	TRANSACTION_TYPE_MAP,
	TRANSACTION_TYPE_TEXT,
} from '../../../../constants/journal';
import { A4Journal } from '../../../../lib/a4Journal';
import numberFormat from '../../../../lib/numberFormat';
import { ThermalJournal } from '../../../../lib/thermalJournal';
import stores from '../../../../stores/index.mobx';
import { Receipt } from '../../../../stores/Receipt.mobx';
import { Store } from '../../../../stores/Store.mobx';
import { User } from '../../../../stores/User.mobx';
import AdvanceList from './Components/AdvanceList';
import ProductsList from './Components/ProductsList';
import QR from './Components/QR';
import { ReferentDocumentLink } from './Components/ReferentDocumentLink';
import TaxList from './Components/TaxList';
import styles from './List.module.less';

import './Drawers/Refund';

interface ReceiptButtonsProps {
	entity: Receipt;
}

function ReceiptButtons({ entity }: ReceiptButtonsProps) {
	const {
		stores: { currentStore: store },
		devices: { thermalPrinters },
		receipts: { sendEmail: sendEmailAction, download: downloadAction },
	} = stores;
	const [emailModalVisible, setEmailModalVisible] = useState(false);

	const [, openReceiptRefund, , , , ReceiptRefundDrawer] =
		useDrawer('receipt-refund');
	const [copyModalVisible, setCopyModalVisible] = useState(false);
	const [advancePaymentModalVisible, setAdvancePaymentModalVisible] =
		useState(false);
	const [advanceRefundModalVisible, setAdvanceRefundModalVisible] =
		useState(false);
	const [advanceCloseModalVisible, setAdvanceCloseModalVisible] =
		useState(false);
	const [
		finalizeAdvanceCloseModalVisible,
		setFinalizeAdvanceCloseModalVisible,
	] = useState(false);
	const [finalizeProformaModalVisible, setFinalizeProformaModalVisible] =
		useState(false);

	const [refundModalVisible, setRefundModalVisible] = useState(false);

	const [voidMode, setVoidMode] = useState(false);

	const screens = Grid.useBreakpoint();

	const isAdvanceOld = useMemo(() => {
		if (!entity || !entity.connectedReceipts) {
			return false;
		}

		return (
			TRANSACTION_TYPE_FROM_STRING[
				entity.connectedReceipts[0].transactionType
			] === TransactionType.REFUND &&
			INVOICE_TYPE_FROM_STRING[entity.connectedReceipts[0].invoiceType] ===
				InvoiceType.ADVANCE &&
			(entity.connectedReceipts[0].referentDocumentNumber || '')
				.toUpperCase()
				.startsWith('XXXXXXXX-XXXXXXXX-')
		);
	}, [entity]);

	const connectedReceipts = useMemo(
		() =>
			entity?.connectedReceipts
				? [
						...(isAdvanceOld
							? [
									{
										...entity.connectedReceipts[0],
										transactionType: TransactionTypeAPI.SALE,
										invoiceNumber:
											entity.connectedReceipts[0].referentDocumentNumber,
										sdcTime: entity.connectedReceipts[0].referentDocumentDT,
									},
							  ]
							: []),
						...entity.connectedReceipts.filter(
							(r) =>
								INVOICE_TYPE_FROM_STRING[r.invoiceType] !== InvoiceType.COPY &&
								!r.void
						),
				  ]
				: [],
		[entity, isAdvanceOld]
	);

	const isAdvance = useMemo(() => {
		if (!entity || connectedReceipts.length === 0) {
			return false;
		}

		if (
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.REFUND ||
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.COPY
		) {
			return false;
		}

		if (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.NORMAL &&
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.SALE
		) {
			return false;
		}

		const firstConnectedReceipt: Receipt = first(connectedReceipts);

		return (
			// firstConnectedReceipt.transactionType ===
			// 	TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
			firstConnectedReceipt.invoiceType ===
			INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
		);
	}, [entity, connectedReceipts]);

	const lastInvoice = useMemo(() => {
		return last(connectedReceipts);
	}, [connectedReceipts]);

	const hasCopyButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			[InvoiceType.ADVANCE, InvoiceType.NORMAL].includes(
				INVOICE_TYPE_FROM_STRING[entity.invoiceType]
			)
		) {
			return true;
		}

		return false;
	}, [entity, connectedReceipts]);

	const hasFinalizeButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		return (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.PROFORMA &&
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.SALE
		);
	}, [entity]);

	const hasRefundButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			(INVOICE_TYPE_FROM_STRING[entity.invoiceType] !== InvoiceType.COPY &&
				TRANSACTION_TYPE_FROM_STRING[entity.transactionType]) ===
				TransactionType.SALE ||
			isAdvance
		) {
			return true;
		}

		return false;
	}, [entity, connectedReceipts, isAdvance]);

	const hasVoidButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.ADVANCE ||
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.NORMAL
		) {
			return true;
		}

		return false;
	}, [entity]);

	const totalAdvanceItemsSum = useMemo(() => {
		if (!entity) {
			return 0;
		}
		if (!entity.advanceItems || entity.advanceItems.length === 0) {
			return entity.payment.reduce((sum, payment) => {
				return sum + payment.amount;
			}, 0);
		}
		return round(
			(entity?.advanceItems || []).reduce((acc, cur) => {
				return acc + cur.totalAmount;
			}, 0),
			2
		);
	}, [entity, entity?.advanceItems]);

	const advanceSum = useMemo(() => {
		return round(
			connectedReceipts
				.filter(
					(cr) =>
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE &&
						!cr.void &&
						!cr.voids
				)
				.reduce((acc, cur) => {
					const paymentTotal =
						TRANSACTION_TYPE_FROM_STRING[cur.transactionType] ===
						TransactionType.SALE
							? cur.paymentTotal
							: -cur.paymentTotal;
					return acc + paymentTotal - cur.paymentChange;
				}, 0),
			2
		);
	}, [connectedReceipts]);

	const [isAddAdvanceButtonEnabled, addAdvanceButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			if (
				INVOICE_TYPE_FROM_STRING[lastInvoice.invoiceType] ===
					InvoiceType.NORMAL &&
				TRANSACTION_TYPE_FROM_STRING[lastInvoice.transactionType] ===
					TransactionType.SALE
			) {
				return [
					false,
					'Nije moguće dodati avansnu uplatu jer je avans već zatvoren',
				];
			}

			if (!entity.advanceItems) {
				return [
					false,
					'Nije moguće dodati avansnu uplatu jer avans otvoren po sistemu pre izmene od 27.04.2022.',
				];
			}

			if (!entity.unknownAmountAdvance && advanceSum >= totalAdvanceItemsSum) {
				return [
					false,
					'Nije moguće dodati avansnu uplatu jer je ukupan iznos već uplaćen',
				];
			}

			return [true, null];
		}, [entity, connectedReceipts, totalAdvanceItemsSum, advanceSum]);

	const [isCloseAdvanceButtonEnabled, closeAdvanceButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			if (
				connectedReceipts.find(
					(cr) =>
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.NORMAL &&
						TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
							TransactionType.SALE
				)
			) {
				return [false, 'Avans je već zatvoren'];
			}
			return [true, null];
		}, [entity, connectedReceipts]);

	const [isFinalizeButtonEnabled, finalizeButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			const normalSaleReceipt: Receipt = connectedReceipts.find((r) => {
				return (
					INVOICE_TYPE_FROM_STRING[r.invoiceType] === InvoiceType.NORMAL &&
					TRANSACTION_TYPE_FROM_STRING[r.transactionType] ===
						TransactionType.SALE
				);
			});

			if (
				INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.PROFORMA &&
				normalSaleReceipt
			) {
				return [false, 'Nije moguće izdati konačan račun jer je već izdat.'];
			}
			const remainingItems =
				entity.connectedReceipts
					.filter(
						(cr) =>
							TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
								TransactionType.REFUND &&
							[InvoiceType.PROFORMA].includes(
								INVOICE_TYPE_FROM_STRING[cr.invoiceType]
							) &&
							!cr.void &&
							!cr.voids
					)
					.reduce(
						(acc, cur) =>
							acc +
							cur.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0),
						0
					) -
				entity.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0);

			if (round(remainingItems, 2) === 0) {
				return [false, 'Predračun je refundiran'];
			}

			return [true, null];
		}, [entity, connectedReceipts]);

	const [isRefundButtonEnabled, refundButtonDisabledReason] = useMemo(() => {
		if (!entity || connectedReceipts.length === 0) {
			return [false, null];
		}

		if (entity.void) {
			return [false, 'Račun je storniran'];
		}

		if (isAdvance) {
			if (!isCloseAdvanceButtonEnabled) {
				return [false, closeAdvanceButtonDisabledReason];
			}

			if (advanceSum == 0) {
				return [false, 'Sve avansne uplate su već refundirane'];
			}
		}

		const remainingItems =
			entity.connectedReceipts
				.filter(
					(cr) =>
						TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
							TransactionType.REFUND &&
						[
							InvoiceType.NORMAL,
							InvoiceType.PROFORMA,
							InvoiceType.TRAINING,
						].includes(INVOICE_TYPE_FROM_STRING[cr.invoiceType]) &&
						!cr.void &&
						!cr.voids
				)
				.reduce(
					(acc, cur) =>
						acc +
						cur.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0),
					0
				) - entity.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0);

		if (round(remainingItems, 2) === 0) {
			return [false, 'Ceo račun je već refundiran'];
		}

		return [true, null];
	}, [entity, connectedReceipts, isAdvance, isCloseAdvanceButtonEnabled]);

	const [isVoidButtonEnabled, voidButtonDisabledReason] = useMemo(() => {
		if (!entity || connectedReceipts.length === 0 || !hasVoidButton) {
			return [false, null];
		}

		if (entity.void) {
			return [false, 'Račun je već storniran'];
		}

		if (entity.voids) {
			return [false, 'Referentni račun je već storniran'];
		}

		// filter voiders from connected receipts
		const filteredCR = connectedReceipts.filter(
			(cr) =>
				!entity?.connectedReceipts?.find(
					(ecr) => ecr.invoiceNumber === cr.referentDocumentNumber
				)?.void
		);

		const currentIndex = filteredCR.findIndex((cr) => cr.id === entity.id);

		// if threre is receipt after this one, it can't be voided
		if (currentIndex !== -1 && currentIndex < filteredCR.length - 1) {
			return [
				false,
				'Račun nije može biti storniran jer postoji račun nakon njega',
			];
		}

		return [true, null];
	}, [entity, connectedReceipts, isAdvance, isCloseAdvanceButtonEnabled]);

	const printThermal = useCallback(() => {
		if (entity) {
			setTimeout(() => {
				const thermalJournal = new ThermalJournal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashier?.fullName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);
				thermalJournal.print();
				notification.success({
					message: `Račun ${entity.invoiceNumber} je poslat na štampu`,
					duration: 5,
				});
			});
		}
	}, [entity]);
	const printA4 = useCallback(() => {
		if (entity) {
			setTimeout(async () => {
				const a4Journal = new A4Journal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashier?.fullName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);

				const element = document.createElement('div');
				const root = createRoot(element);
				root.render(a4Journal.getComponent());

				await a4Journal.getRenderDeferred();

				document.querySelector('#a4-temporary').innerHTML = element.innerHTML;

				setTimeout(() => {
					printjs({
						printable: 'a4-temporary',
						type: 'html',
						targetStyles: ['*'],
						font_size: '',
					});
				});
				notification.success({
					message: `Račun ${entity.invoiceNumber} je poslat na štampu`,
					duration: 5,
				});

				try {
					root.unmount();
				} catch (unmountError) {
					Bugsnag.notify(unmountError);
				}
			});
		}
	}, [entity]);
	const sendEmail = useCallback(
		({ email }) => {
			if (entity) {
				setTimeout(async () => {
					const a4Journal = new A4Journal(
						{
							...entity,
							items: entity.receiptItems.map((item) => ({
								...item,
								labels: item.taxLabels,
							})),
							payment: entity.payment.map((payment) => ({
								paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
								amount:
									PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
									PaymentType.CASH
										? round(payment.amount - entity.paymentChange, 2)
										: payment.amount,
							})),
							cashier: entity.cashier?.fullName,
							invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
							transactionType:
								TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
							invoiceNumber: entity.posNumber,
							dateAndTimeOfIssue: entity.posTime,
							referentDocumentDT: entity.referentDocumentDT,
						},
						{
							...entity,
							tin: entity.secureElement.tin,
							address: entity.secureElement.address,
							district: entity.secureElement.district,
							businessName: entity.secureElement.businessName,
							locationName: entity.secureElement.locationName,
							sdcDateTime: entity.sdcTime,
						},
						entity.hasSignatureLine,
						entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
						(entity.advanceItems || []).map((ai) => ({
							product: {
								name: ai.name,
								ean: ai.gtin,
								saleUnit: {
									isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
									label: ai.unit,
								},
							},
							quantity: ai.quantity,
							finalPrice: ai.unitPrice,
							labels: ai.taxLabels,
						})),
						entity.lastAdvance,
						entity.additionalText,
						entity.unknownAmountAdvance
					);

					const element = document.createElement('div');

					const root = createRoot(element);
					root.render(a4Journal.getComponent());

					await a4Journal.getRenderDeferred();

					const html = element.innerHTML;

					setTimeout(() => {
						sendEmailAction(email, html, entity.invoiceNumber);
					});

					notification.success({
						message: `Račun ${entity.invoiceNumber} je poslat na e-mail adresu ${email}`,
						duration: 5,
					});

					try {
						root.unmount();
					} catch (unmountError) {
						Bugsnag.notify(unmountError);
					}
				});
			}
		},
		[entity]
	);
	const download = useCallback(() => {
		if (entity) {
			setTimeout(async () => {
				const a4Journal = new A4Journal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashier?.fullName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);

				const element = document.createElement('div');

				const root = createRoot(element);

				root.render(a4Journal.getComponent());

				await a4Journal.getRenderDeferred();

				const html = element.innerHTML;

				setTimeout(() => {
					downloadAction(html, entity.invoiceNumber);
				});

				notification.success({
					message: `Račun ${entity.invoiceNumber} će uskoro početi sa preuzimanjem`,
					duration: 5,
				});

				try {
					root.unmount();
				} catch (unmountError) {
					Bugsnag.notify(unmountError);
				}
			});
		}
	}, [entity]);

	const printMenuItems = useMemo(
		() => [
			...(window.electron &&
			thermalPrinters.length > 0 &&
			store?.printMethod?.thermal
				? [
						{
							key: 'printThermal',
							label: 'Štampaj na termalnom štampaču',
							icon: screens.md && <PrinterOutlined />,
						},
				  ]
				: []),
			...(store?.printMethod?.a4
				? [
						{
							key: 'printA4',
							label: 'Štampaj na A4 štampaču',
							icon: screens.md && <PrinterOutlined />,
						},
				  ]
				: []),
			...(store?.printMethod?.email
				? [
						{
							key: 'email',
							label: 'Pošalji na email',
							icon: screens.md && <MailOutlined />,
						},
				  ]
				: []),
			{
				key: 'download',
				label: 'Preuzmi u PDF formatu',
				icon: screens.md && <DownloadOutlined />,
			},
		],
		[screens]
	);

	const menuItems = useMemo(
		() => [
			...(hasVoidButton
				? [
						{
							key: 'void',
							label: 'Storniraj',
							disabled: !isVoidButtonEnabled,
							danger: true,
							title: voidButtonDisabledReason,
						},
				  ]
				: []),
			...(hasRefundButton
				? [
						{
							key: 'refund',
							label: 'Refundiraj',
							disabled: !isRefundButtonEnabled,
							title: refundButtonDisabledReason,
						},
				  ]
				: []),
			...(hasCopyButton
				? [
						{
							key: 'copy',
							label: 'Napravi kopiju',
						},
				  ]
				: []),
			...(hasFinalizeButton
				? [
						{
							key: 'finalize',
							label: 'Izdaj konačan račun',
							disabled: !isFinalizeButtonEnabled,
							title: finalizeButtonDisabledReason,
						},
				  ]
				: []),
			...(isAdvance
				? [
						{
							key: 'addAdvancePayment',
							label: 'Dodaj avansnu uplatu',
							disabled: !isAddAdvanceButtonEnabled,
							title: addAdvanceButtonDisabledReason,
						},
						{
							key: 'closeAdvance',
							label: 'Zatvori avans',
							disabled: !isCloseAdvanceButtonEnabled,
							title: closeAdvanceButtonDisabledReason,
						},
				  ]
				: []),
			...(isAdvanceOld
				? [
						{
							key: 'addAdvancePayment',
							label: 'Dodaj avansnu uplatu',
							disabled: !isAddAdvanceButtonEnabled,
							title: addAdvanceButtonDisabledReason,
						},
						{
							key: 'closeAdvance',
							label: 'Zatvori avans',
							disabled: !isCloseAdvanceButtonEnabled,
							title: closeAdvanceButtonDisabledReason,
						},
				  ]
				: []),
			...(!screens.md ? [{ type: 'divider' }, ...printMenuItems] : []),
		],
		[
			hasVoidButton,
			isVoidButtonEnabled,
			voidButtonDisabledReason,
			hasRefundButton,
			isRefundButtonEnabled,
			refundButtonDisabledReason,
			hasCopyButton,
			hasFinalizeButton,
			isFinalizeButtonEnabled,
			finalizeButtonDisabledReason,
			isAdvance,
			isAdvanceOld,
			isAddAdvanceButtonEnabled,
			addAdvanceButtonDisabledReason,
			isCloseAdvanceButtonEnabled,
			closeAdvanceButtonDisabledReason,
			printMenuItems,
			screens,
		]
	);

	if (!entity) {
		return null;
	}

	return (
		<>
			{screens.md && (
				<Space className={styles.rightButtons}>
					{hasVoidButton && (
						<Tooltip title={voidButtonDisabledReason}>
							<Button
								disabled={!isVoidButtonEnabled}
								danger
								onClick={() => {
									setVoidMode(true);
									if (
										isAdvance ||
										INVOICE_TYPE_FROM_STRING[entity?.invoiceType] ===
											InvoiceType.ADVANCE
									) {
										setAdvanceRefundModalVisible(true);
									} else {
										setRefundModalVisible(true);
									}
								}}
							>
								Storniraj
							</Button>
						</Tooltip>
					)}
					{hasRefundButton && (
						<Tooltip title={refundButtonDisabledReason}>
							<Button
								disabled={!isRefundButtonEnabled}
								onClick={() => {
									if (isAdvance) {
										// TODO: change to only include single invoice
										setAdvanceRefundModalVisible(true);
									} else {
										openReceiptRefund(entity.id);
									}
								}}
							>
								Refundiraj
							</Button>
						</Tooltip>
					)}
					{hasCopyButton && (
						<Button
							onClick={() => {
								setCopyModalVisible(true);
							}}
						>
							{screens.xs ? 'Kopija' : 'Napravi kopiju'}
						</Button>
					)}
					{hasFinalizeButton && (
						<Tooltip title={finalizeButtonDisabledReason}>
							<Button
								disabled={!isFinalizeButtonEnabled}
								onClick={() => {
									setFinalizeProformaModalVisible(true);
								}}
							>
								Izdaj konačan račun
							</Button>
						</Tooltip>
					)}
					{(isAdvance || isAdvanceOld) && (
						<Tooltip title={addAdvanceButtonDisabledReason}>
							<Button
								disabled={!isAddAdvanceButtonEnabled}
								onClick={() => {
									setAdvancePaymentModalVisible(true);
								}}
							>
								Dodaj avansnu uplatu
							</Button>
						</Tooltip>
					)}
					{isAdvance && (
						<Tooltip title={closeAdvanceButtonDisabledReason}>
							<Button
								disabled={!isCloseAdvanceButtonEnabled}
								onClick={() => {
									if (
										INVOICE_TYPE_FROM_STRING[lastInvoice.invoiceType] ===
											InvoiceType.ADVANCE &&
										TRANSACTION_TYPE_FROM_STRING[
											lastInvoice.transactionType
										] === TransactionType.REFUND &&
										advanceSum === 0
									) {
										setFinalizeAdvanceCloseModalVisible(true);
									} else {
										setAdvanceCloseModalVisible(true);
									}
								}}
							>
								Zatvori avans
							</Button>
						</Tooltip>
					)}
					{isAdvanceOld && (
						<Tooltip title={closeAdvanceButtonDisabledReason}>
							<Button
								disabled={!isCloseAdvanceButtonEnabled}
								onClick={() => {
									if (
										INVOICE_TYPE_FROM_STRING[lastInvoice.invoiceType] ===
											InvoiceType.ADVANCE &&
										TRANSACTION_TYPE_FROM_STRING[
											lastInvoice.transactionType
										] === TransactionType.REFUND &&
										advanceSum === 0
									) {
										setFinalizeAdvanceCloseModalVisible(true);
									} else {
										setAdvanceCloseModalVisible(true);
									}
								}}
							>
								Zatvori avans
							</Button>
						</Tooltip>
					)}
					<Dropdown
						trigger={['click']}
						className={styles.rightButtons}
						menu={{
							items: printMenuItems,
							onClick: (item) => {
								switch (item.key) {
									case 'printThermal':
										printThermal();
										break;
									case 'printA4':
										printA4();
										break;
									case 'email':
										setEmailModalVisible(true);
										break;
									case 'download':
										download();
										break;
								}
							},
						}}
					>
						<Button>
							<Space>
								Opcije
								<EllipsisOutlined />
							</Space>
						</Button>
					</Dropdown>
				</Space>
			)}
			{!screens.md && (
				<Dropdown
					trigger={['click']}
					className={styles.rightButtons}
					menu={{
						items: menuItems,
						onClick: (item) => {
							switch (item.key) {
								case 'void':
									setVoidMode(true);
									if (
										isAdvance ||
										INVOICE_TYPE_FROM_STRING[entity?.invoiceType] ===
											InvoiceType.ADVANCE
									) {
										setAdvanceRefundModalVisible(true);
									} else {
										setRefundModalVisible(true);
									}
									break;
								case 'refund':
									if (isAdvance) {
										// TODO: change to only include single invoice
										setAdvanceRefundModalVisible(true);
									} else {
										openReceiptRefund(entity.id);
									}
									break;
								case 'copy':
									setCopyModalVisible(true);
									break;
								case 'finalize':
									setFinalizeProformaModalVisible(true);
									break;
								case 'addAdvance':
									setAdvancePaymentModalVisible(true);
									break;
								case 'closeAdvance':
									if (
										INVOICE_TYPE_FROM_STRING[lastInvoice.invoiceType] ===
											InvoiceType.ADVANCE &&
										TRANSACTION_TYPE_FROM_STRING[
											lastInvoice.transactionType
										] === TransactionType.REFUND &&
										advanceSum === 0
									) {
										setFinalizeAdvanceCloseModalVisible(true);
									} else {
										setAdvanceCloseModalVisible(true);
									}
									break;
								case 'printThermal':
									printThermal();
									break;
								case 'printA4':
									printA4();
									break;
								case 'email':
									setEmailModalVisible(true);
									break;
								case 'download':
									download();
									break;
							}
						},
					}}
				>
					<Button>
						<Space>
							Opcije
							<EllipsisOutlined />
						</Space>
					</Button>
				</Dropdown>
			)}

			<PaymentModal
				type={entity.transactionType}
				visible={copyModalVisible}
				items={entity.receiptItems.map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				amount={entity.totalAmount}
				receipt={entity}
				prefillPayment={(store?.paymentMethodsOrder || [])
					.filter((pm) => pm >= 0)
					.map((pm) => {
						return (
							entity.payment.find(
								(payment) => payment.paymentType === PAYMENT_TYPE_MAP[pm]
							)?.amount || 0
						);
					})}
				closePayment={() => {
					setCopyModalVisible(false);
				}}
				copy
			/>
			<PaymentModal
				type={'sale'}
				visible={finalizeProformaModalVisible}
				items={entity.receiptItems.map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				amount={entity.totalAmount}
				receipt={{ ...entity, invoiceType: 'normal' }}
				prefillPayment={[
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CASH]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CHECK]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CARD]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType ===
							PAYMENT_TYPE_MAP[PaymentType.WIRE_TRANSFER]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.VOUCHER]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.MOBILE_MONEY]
					)?.amount || 0,
					entity.payment.find(
						(payment) =>
							payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.OTHER]
					)?.amount || 0,
				]}
				closePayment={() => {
					setFinalizeProformaModalVisible(false);
				}}
				finalizeProforma
			/>
			<PaymentModal
				type="sale"
				visible={advancePaymentModalVisible}
				items={(entity?.advanceItems || []).map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				amount={totalAdvanceItemsSum}
				advancePayments={advanceSum}
				receipt={entity}
				prefillPayment={[0, 0, 0, 0, 0, 0, 0]}
				closePayment={() => {
					setAdvancePaymentModalVisible(false);
				}}
				addAdvancePayment
				connectedReceipts={connectedReceipts}
				isAdvanceOld={isAdvanceOld}
			/>
			<PaymentModal
				type={'refund'}
				voidMode={voidMode}
				visible={advanceRefundModalVisible}
				items={(entity?.advanceItems && entity.advanceItems.length
					? entity.advanceItems
					: entity?.receiptItems || []
				).map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				amount={voidMode ? entity?.totalAmount : advanceSum}
				advancePayments={totalAdvanceItemsSum}
				receipt={entity}
				prefillPayment={
					voidMode
						? (store?.paymentMethodsOrder || [])
								.filter((pm) => pm >= 0)
								.map((pm) => {
									return round(
										(entity.payment.find(
											(payment) => payment.paymentType === PAYMENT_TYPE_MAP[pm]
										)?.amount || 0) -
											(pm === PaymentType.CASH
												? entity?.paymentChange || 0
												: 0),
										2
									);
								})
						: [0, 0, 0, 0, 0, 0, 0]
				}
				closePayment={() => {
					setAdvanceRefundModalVisible(false);
					setVoidMode(false);
				}}
				advanceRefund
			/>
			<PaymentModal
				type={'sale'}
				visible={advanceCloseModalVisible}
				items={entity.receiptItems.map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				advancePayments={advanceSum}
				amount={totalAdvanceItemsSum}
				receipt={entity}
				prefillPayment={[0, 0, 0, 0, 0, 0, 0]}
				closePayment={() => {
					setAdvanceCloseModalVisible(false);
				}}
				closeAdvancePayment
				connectedReceipts={connectedReceipts}
				isAdvanceOld={isAdvanceOld}
			/>
			<PaymentModal
				type={'sale'}
				visible={finalizeAdvanceCloseModalVisible}
				items={entity.receiptItems.map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				advancePayments={lastInvoice?.paymentTotal || 0}
				amount={totalAdvanceItemsSum}
				receipt={lastInvoice}
				prefillPayment={[0, 0, 0, 0, 0, 0, 0]}
				closePayment={() => {
					setFinalizeAdvanceCloseModalVisible(false);
				}}
				finalizeCloseAdvancePayment
				connectedReceipts={connectedReceipts}
			/>
			<PaymentModal
				type="refund"
				voidMode={voidMode}
				visible={refundModalVisible}
				items={entity.receiptItems.map((item) => ({
					...item,
					product: {
						id: item.productId,
						gtin: item.gtin,
						name: item.name,
						price: item.unitPrice,
						taxRateLabels: item.taxLabels,
						unit: item.unit,
					},
					finalPrice: item.unitPrice,
				}))}
				amount={entity.totalAmount}
				receipt={entity}
				prefillPayment={(store?.paymentMethodsOrder || [])
					.filter((pm) => pm >= 0)
					.map((pm) => {
						return round(
							(entity.payment.find(
								(payment) => payment.paymentType === PAYMENT_TYPE_MAP[pm]
							)?.amount || 0) -
								(pm === PaymentType.CASH ? entity?.paymentChange || 0 : 0),
							2
						);
					})}
				closePayment={() => {
					setRefundModalVisible(false);
					setVoidMode(false);
				}}
			/>
			<ReceiptRefundDrawer />
			<EmailModal
				visible={emailModalVisible}
				onCancel={() => setEmailModalVisible(false)}
				onOk={(email) => {
					setEmailModalVisible(false);
					sendEmail({ email });
				}}
			/>
		</>
	);
}

export default CreatePage({
	module: 'receipts',
	submodule: 'list',
	model: 'receipt',
	view: {
		width: 900,
		shouldFetch: true,
		title: (entity) => `Pregled računa ${entity?.invoiceNumber || ''}`,
		descriptionsProps: {
			layout: 'horizontal',
			column: 1,
			contentStyle: {
				alignSelf: 'right',
			},
		},
		fields: (entity) => [
			{
				key: 'info',
				label: (
					<div className={`${styles.centeredTitle} ${styles.title}`}>
						<span>
							{[InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
								INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
							)
								? 'Fiskalni račun'
								: 'Ovo nije fiskalni račun'}
						</span>
					</div>
				),
				column: 24,
				descriptionsProps: {
					layout: 'horizontal',
					contentStyle: {
						textAlign: 'right',
						display: 'table-cell',
					},
					labelStyle: {
						fontWeight: 'bold',
					},
				},
				fields: [
					{
						label: 'PIB',
						key: 'secureElement.tin',
						span: 24,
					},
					{
						label: 'Preduzeće',
						key: 'secureElement.businessName',
						span: 24,
					},

					{
						label: 'Mesto prodaje',
						key: 'secureElement.locationName',
						span: 24,
					},
					{
						label: 'Adresa',
						key: 'secureElement.address',
						span: 24,
					},
					{
						label: 'Opština',
						key: 'secureElement.district',
						span: 24,
					},
					{
						label: 'Kasir',
						key: 'cashier.fullName',
						span: 24,
					},
					...(entity.buyerId
						? [
								{
									label: 'ID kupca',
									key: 'buyerId',
									span: 24,
								},
						  ]
						: []),
					...(entity.buyerCostCenterId
						? [
								{
									label: 'Opciono polje kupca',
									key: 'buyerCostCenterId',
									span: 24,
								},
						  ]
						: []),
					{
						label: 'POS broj',
						key: 'posNumber',
						span: 24,
					},
					...(entity.referentDocumentNumber
						? [
								{
									label: 'Ref. broj',
									key: 'referentDocumentNumber',
									span: 24,
								},
						  ]
						: []),
					...(entity.referentDocumentDT?.isValid()
						? [
								{
									label: 'Ref. vreme',
									key: 'referentDocumentDT',
									span: 24,
									render(date) {
										return date?.format('DD.MM.YYYY HH:mm:ss');
									},
								},
						  ]
						: []),
					...(entity.posTime?.isValid()
						? [
								{
									label: 'Uplaćeno na dan',
									key: 'posTime',
									span: 24,
									render(date) {
										return date?.format('DD.MM.YYYY HH:mm:ss');
									},
								},
						  ]
						: []),
				],
			},
			{
				key: 'info',
				label: (
					<div className={`${styles.centeredTitle} ${styles.subTitle}`}>
						<span>
							{INVOICE_TYPE_TEXT[INVOICE_TYPE_FROM_STRING[entity?.invoiceType]]}{' '}
							-{' '}
							{
								TRANSACTION_TYPE_TEXT[
									TRANSACTION_TYPE_FROM_STRING[entity?.transactionType]
								]
							}
						</span>
					</div>
				),
				column: 24,
				descriptionsProps: {
					layout: 'horizontal',
					contentStyle: {
						textAlign: 'right',
						display: 'table-cell',
					},
					labelStyle: {
						fontWeight: 'bold',
					},
				},
				fields: [
					{
						span: 24,
						label: 'Artikli',
						render() {
							return '';
						},
					},
					{
						// label: 'Preduzeće',
						key: 'receiptItems',
						span: 24,
						render(items) {
							return <ProductsList record={entity} />;
						},
					},
					{
						span: 24,
						label: 'Porez',
						render() {
							return '';
						},
					},
					{
						// label: 'Preduzeće',
						key: 'taxItems',
						span: 24,
						render(items) {
							return <TaxList record={entity} />;
						},
					},
				],
			},
			...(![InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
				INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
			)
				? [
						{
							key: 'notFiscal',
							label: (
								<div
									className={`${styles.centeredTitle} ${styles.title} ${styles.large}`}
								>
									<span>Ovo nije fiskalni račun</span>
								</div>
							),
							column: 24,
							descriptionsProps: {
								layout: 'horizontal',
								contentStyle: {
									textAlign: 'right',
									display: 'table-cell',
								},
								labelStyle: {
									fontWeight: 'bold',
								},
							},
							fields: [],
						},
				  ]
				: []),
			{
				key: 'invoiceInfo',
				column: 24,
				descriptionsProps: {
					layout: 'horizontal',
					contentStyle: {
						textAlign: 'right',
						display: 'table-cell',
					},
					labelStyle: {
						fontWeight: 'bold',
					},
				},
				fields: [
					{
						label: 'PFR vreme',
						key: 'sdcTime',
						span: 24,
						render(date: any) {
							return date?.format('DD.MM.YYYY HH:mm:ss');
						},
					},
					{
						label: 'PFR broj računa',
						key: 'invoiceNumber',
						span: 24,
					},
					{
						label: 'Brojač računa',
						key: 'invoiceCounter',
						span: 24,
					},
					{
						render() {
							return (
								<div className={`${styles.centeredTitle} ${styles.title}`}>
									&nbsp;
								</div>
							);
						},
						key: 'separator',
						span: 24,
					},
					{
						key: 'verificationUrl',
						span: 24,
						render: function (verificationUrl) {
							return (
								<div className={styles.centeredTitle}>
									<QR verificationUrl={verificationUrl || ''} />
								</div>
							);
						},
					},
				],
			},
			{
				key: 'end',
				label: (
					<div className={`${styles.centeredTitle} ${styles.title}`}>
						<span>
							{[InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
								INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
							)
								? 'Kraj fiskalnog računa'
								: 'Ovo nije fiskalni račun'}
						</span>
					</div>
				),
				column: 24,
				descriptionsProps: {
					layout: 'horizontal',
					contentStyle: {
						textAlign: 'right',
						display: 'table-cell',
					},
					labelStyle: {
						fontWeight: 'bold',
					},
				},
				fields: [],
			},
			...(entity.advanceItems && entity.advanceItems.length
				? [
						{
							key: 'advanceItems',
							descriptionsProps: {
								layout: 'horizontal',
								contentStyle: {
									textAlign: 'right',
									display: 'table-cell',
								},
								labelStyle: {
									fontWeight: 'bold',
								},
							},
							column: 24,
							fields: [
								{
									span: 24,
									label: 'Avansno plaćeni artikli',
									render() {
										return '';
									},
								},
								{
									// label: 'Preduzeće',
									key: 'advanceItems',
									span: 24,
									render(items) {
										return <AdvanceList record={entity} />;
									},
								},
							],
						},
				  ]
				: []),
		],
		footer: (entity) => {
			return <ReceiptButtons entity={entity} />;
		},
	},
	edit: {},
	page: {
		shouldFetch: true,
		table: {
			props: {
				scroll: { x: 1680 },
			},
			showActions: false,
			columns: [
				{
					title: 'Broj računa',
					// fixed: 'left',
					width: 260,
					dataIndex: 'invoiceNumber',
					key: 'invoiceNumber',
					searchable: true,
					filterKey: 'invoiceNumber',
					shouldLink: true,
					filterIcon: (filtered) => (
						<SearchOutlined
							style={{ color: filtered ? '#7E89C8' : undefined }}
						/>
					),
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<Input
									placeholder="Broj računa"
									value={selectedKeys[0]}
									onPressEnter={() => confirm({ closeDropdown: true })}
									onChange={(event) => setSelectedKeys([event.target.value])}
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Datum i vreme',
					width: 160,
					// fixed: 'left',
					dataIndex: 'sdcTime',
					key: 'sdcTime',
					filterKey: 'sdcTime',
					render(date) {
						return date?.format('DD.MM.YYYY HH:mm:ss');
					},
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Ref. broj',
					dataIndex: 'referentDocumentNumber',
					key: 'referentDocumentNumber',
					width: 260,
					render: (text, record) => <ReferentDocumentLink record={record} />,
				},
				{
					title: 'Status',
					width: 170,
					key: 'status',
					render: (record) => {
						if (record.void) {
							return <Badge status="error" text="Storniran" />;
						}

						if (record.voids) {
							return <Badge status="warning" text="Stornira ref. dok." />;
						}

						if (
							[
								InvoiceType.NORMAL,
								InvoiceType.PROFORMA,
								InvoiceType.TRAINING,
							].includes(INVOICE_TYPE_FROM_STRING[record.invoiceType])
						) {
							const hasRefunds =
								record.connectedReceipts.some(
									(receipt) =>
										TRANSACTION_TYPE_FROM_STRING[receipt.transactionType] ===
											TransactionType.REFUND && !receipt.void
								) &&
								TRANSACTION_TYPE_FROM_STRING[record.transactionType] ===
									TransactionType.SALE;

							if (hasRefunds) {
								const filteredItems = record.connectedReceipts?.filter(
									(cr) =>
										TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
											TransactionType.REFUND &&
										[
											InvoiceType.NORMAL,
											InvoiceType.PROFORMA,
											InvoiceType.TRAINING,
										].includes(INVOICE_TYPE_FROM_STRING[cr.invoiceType]) &&
										!cr.void &&
										!cr.voids
								);
								// 		console.log(filteredItems);
								const refundedItems = (filteredItems || []).reduce(
									(acc, cur) =>
										acc +
										(cur.receiptItems || []).reduce(
											(iacc, icur) => iacc + icur.quantity,
											0
										),
									0
								);
								const items = (record.receiptItems || []).reduce(
									(iacc, icur) => iacc + icur.quantity,
									0
								);

								const remainingItems = refundedItems - items;

								if (round(remainingItems, 2) === 0) {
									return <Badge color="gold" text="Refundiran" />;
								} else if (refundedItems > 0) {
									return <Badge color="gold" text="Delimično refundiran" />;
								}
							}
						}

						return <Badge status="success" text="Izdat" />;
					},
				},

				{
					title: 'Tip računa',
					width: 110,
					dataIndex: 'invoiceType',
					key: 'invoiceType',
					filterKey: 'invoiceType',
					render(text) {
						return INVOICE_TYPE_TEXT[INVOICE_TYPE_FROM_STRING[text]];
					},
					filters: (store) =>
						Object.keys(INVOICE_TYPE_FROM_STRING).map((key) => ({
							text: INVOICE_TYPE_TEXT[INVOICE_TYPE_FROM_STRING[key]],
							value: key,
						})),
					filterMultiple: true,
				},
				{
					title: 'Tip transackcije',
					width: 150,
					dataIndex: 'transactionType',
					key: 'transactionType',
					filterKey: 'transactionType',
					render(text) {
						return TRANSACTION_TYPE_TEXT[TRANSACTION_TYPE_FROM_STRING[text]];
					},
					filters: (store) =>
						Object.keys(TRANSACTION_TYPE_FROM_STRING).map((key) => ({
							text: TRANSACTION_TYPE_TEXT[TRANSACTION_TYPE_FROM_STRING[key]],
							value: key,
						})),
					filterMultiple: true,
				},
				{
					title: 'Iznos',
					width: 140,
					dataIndex: 'totalAmount',
					key: 'totalAmount',
					render(text) {
						return numberFormat(text, true, 2, true);
					},
				},
				{
					title: 'Kasir',
					dataIndex: ['cashier', 'fullName'],
					key: 'cashierId',
					ellipsis: true,
					filterKey: 'cashierId',
					filters: () =>
						stores.users.available?.map((user: User) => ({
							text: user.fullName,
							value: user.id,
						})),
					filterMultiple: true,
				},
				{
					title: 'Prodajno mesto',
					dataIndex: ['store', 'name'],
					ellipsis: true,
					key: 'storeId',
					filterKey: 'storeId',
					filters: () =>
						stores.stores.available?.map((storeEntity: Store) => ({
							text: storeEntity.name,
							value: storeEntity.id,
						})),
					filterMultiple: true,
				},
			],
		},
	},
});
