import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';

function RoleSelect(props) {
	const {
		roles: { isFetching, available },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) =>
				option.props.children.toLowerCase().includes(input.toLowerCase())
			}
			loading={isFetching}
			{...props}
		>
			{(available || []).map((role) => (
				<Select.Option key={role.id} value={role.id}>
					{role.name}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(RoleSelect);
