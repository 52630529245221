import { Grid, Input, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { TableInput } from '../../../../../../components/TableInput';
import {
	TAX_EXEMPTION_CODE,
	TAX_EXEMPTION_CODE_CATEGORY,
	TAX_FEE_CATEGORY,
} from '../../../../../../constants/documentInvoice';
import stores from '../../../../../../stores/index.mobx';
import styles from './TaxExemptionInput.module.less';

type TaxExemption = {
	category: string;
	code?: string;
};

interface Props {
	value?: TaxExemption[];
	onChange?: (any) => void;
	categories?: string[];
	defaultPicker?: boolean;
}
export function TaxExemptionInput({
	value,
	onChange,
	categories = [],
	defaultPicker = false,
	...props
}: Props) {
	const {
		company: { defaultTaxExemption },
	} = stores;

	const screens = Grid.useBreakpoint();

	const [currentValue, setCurrentValue] = useState(value || []);

	useEffect(() => {
		setCurrentValue(value || []);
	}, [value]);

	const handleChange = useCallback((value) => {
		setCurrentValue(value);
		onChange(value);
	}, []);

	useEffect(() => {
		if (categories.length > 0) {
			// remove all categories that are not in the list and add new ones
			const newValue = (value || []).filter((item) =>
				categories.includes(item.category)
			);
			categories
				.filter((item) => item !== 'S')
				.forEach((category) => {
					if (!newValue.find((item) => item.category === category)) {
						const defaultValue = defaultTaxExemption?.find(
							(item) => item.category === category
						);
						newValue.push({
							category,
							code: defaultValue?.code,
							decisionNumber: defaultValue?.decisionNumber,
						});
					}
				});

			handleChange(newValue);
		}
	}, [categories]);

	return (
		<TableInput
			{...props}
			isResponsive={false}
			presetItems
			addButtonText=""
			iconPath=""
			emptyText="Nema stavki"
			onChange={handleChange}
			columns={[
				{
					title: screens.sm ? 'PDV kategorija' : 'PDV kat.',
					key: 'category',
					dataIndex: 'category',
					render: (text: string) => {
						return (
							<div className={styles.taxCategory}>
								<strong>{text}:</strong> {TAX_FEE_CATEGORY[text]}
							</div>
						);
					},
				},
				{
					title: 'Šifra osnova',
					key: 'code',
					dataIndex: 'code',
					editable: true,
					rules: !defaultPicker
						? [
								{
									required: true,
									message: 'Šifra osnova je obavezna',
								},
						  ]
						: undefined,
					component: (record: TaxExemption) => {
						return (
							<Select
								placeholder="Izaberite osnov"
								style={{ width: '100%' }}
								value={record.code}
								dropdownMatchSelectWidth={screens.lg}
								optionLabelProp="label"
							>
								<Select.Option value={''} label="">
									{' '}
								</Select.Option>
								{TAX_EXEMPTION_CODE_CATEGORY[record.category]?.map?.((code) => (
									<Select.Option key={code} value={code} label={code}>
										<strong>{code}:</strong>
										<p className={styles.description}>
											{TAX_EXEMPTION_CODE[code]}
										</p>
									</Select.Option>
								))}
							</Select>
						);
					},
				},
				{
					title: 'Broj odluke',
					dataIndex: 'decisionNumber',
					key: 'decisionNumber',
					editable: true,
					component: <Input />,
					width: 120,
				},
			]}
			value={currentValue}
		/>
	);
}
