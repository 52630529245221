import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import type { File as ImageFile } from './shared/File.mobx';
const { Store, Entity } = CreateStore({
	name: 'myMarketplaceApplication',
	paginated: false,
	persistFields: [],
});

class MyMarketplaceApplication extends Entity {
	@observable name?: string;
	@observable packageId?: string;
	@observable description?: string;
	@observable version?: string;
	@observable scopes?: string[];
	@observable installationCount = 0;
	@observable publisher?: {
		name?: string;
	};
	@observable privacyPolicyUrl?: string;
	@observable termsOfServiceUrl?: string;
	@observable websiteUrl?: string;
	@observable applicationUrl?: string;
	@observable supportEmail?: string;
	@observable internal?: boolean;
	@observable images?: ImageFile[];
	@observable logoFile?: ImageFile;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isEditable() {
		return true;
	}

	get isDeletable() {
		return true;
	}
}

class MyMarketplaceApplications extends Store<MyMarketplaceApplication> {
	constructor() {
		super(MyMarketplaceApplication);
	}

	get isCreatable() {
		return true;
	}
}

export { MyMarketplaceApplications, MyMarketplaceApplication };
