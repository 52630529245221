import { Modal, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef } from 'react';

import { LocalSaleItem, LocalSale } from '../../../stores/LocalSale.mobx';

type Props = {
	form: any;
	visible?: boolean;
	saleItem?: LocalSaleItem;
	sale: LocalSale;
	closeEdit: () => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
	},
};

function EditModal({ saleItem, visible, closeEdit }) {
	const [form] = Form.useForm();
	const focusedInput = useRef(false);

	useEffect(() => {
		if (saleItem) {
			form.setFieldsValue({
				quantity: saleItem.quantity,
				price: saleItem.price,
				discount: saleItem.discount,
			});
		}
	}, [saleItem]);

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			if (saleItem) {
				saleItem.update({
					...values,
				});
			}
		});

		closeEdit();
	}, [form, saleItem]);

	useEffect(() => {
		if (visible) {
			focusedInput.current = false;
		}
	}, [visible]);

	return (
		<Modal
			centered
			width={400}
			visible={visible}
			title="Izmena stavke računa"
			onCancel={closeEdit}
			onOk={submit}
		>
			<Form {...formItemLayout} form={form}>
				<Form.Item
					label="Količina"
					name="quantity"
					rules={[
						{
							type: 'number',
							validator: async (rule, value) => {
								if (value < 0.001 || value > 99999999999999) {
									throw new Error(
										'Količina mora biti između 0,001 i 99.999.999.999.999,00'
									);
								}
							},
							message:
								'Količina mora biti između 0,001 i 99.999.999.999.999,000',
						},
					]}
				>
					<InputNumber
						ref={(input) => {
							setTimeout(() => {
								if (input && !focusedInput.current) {
									(input as any).select();
									focusedInput.current = true;
								}
							});
						}}
						onPressEnter={() => {
							submit();
						}}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Form.Item
					label="Popust"
					name="discount"
					rules={[
						{
							type: 'number',
							message: 'Popust mora biti između 0% i 100%',
							validator: async (rule, value) => {
								if (value < 0 || value > 100) {
									throw new Error('Popust mora biti između 0% i 100%');
								}
							},
						},
					]}
				>
					<InputNumber
						onPressEnter={() => {
							submit();
						}}
						suffix="%"
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Form.Item
					label="Cena"
					name="price"
					rules={[
						{
							type: 'number',
							message: 'Cena ne može biti negativna',
							validator: async (rule, value) => {
								if (value < 0) {
									throw new Error('Cena ne može biti negativna');
								}
							},
						},
						{
							type: 'number',
							message: 'Cena ne može biti veća od 99.999.999.999.999,99',
							validator: async (rule, value) => {
								if (value > 99999999999999) {
									throw new Error(
										'Cena ne može biti veća od 99.999.999.999.999,00'
									);
								}
							},
						},
					]}
				>
					<InputNumber
						onPressEnter={() => {
							submit();
						}}
						suffix={saleItem?.product?.currencyId || 'RSD'}
						style={{ width: '100%' }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default observer(EditModal);
