import numeral from 'numeral';

numeral.register('locale', 'rs', {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal(number) {
		return number === 1 ? '.' : '.';
	},
	currency: {
		symbol: 'RSD',
	},
});
