import { Row, Col, Input } from 'antd';
import { useState, useEffect } from 'react';

interface Props {
	value?: {
		model: string;
		number: string;
	};
	onChange?: (any) => void;
	placeholder?: string;
	disabled?: boolean;
}

function ReferenceInput({ value, onChange, disabled, placeholder }: Props) {
	const [model, setModel] = useState('');
	const [number, setNumber] = useState('');

	useEffect(() => {
		setModel(value ? value.model : '');
		setNumber(value ? value.number : '');
	}, [value]);

	function triggerChange(changedValue) {
		onChange({
			model,
			number,
			...changedValue,
		});
	}

	return (
		<Row gutter={2}>
			<Col span={4}>
				<Input
					type="number"
					style={{ width: '100%' }}
					value={model}
					onChange={(event) => {
						setModel(event.target.value);
						triggerChange({ model: event.target.value });
					}}
					step={1}
					disabled={disabled}
				/>
			</Col>
			<Col span={20}>
				<Input
					style={{ width: '100%' }}
					value={number}
					onChange={(event) => {
						setNumber(event.target.value);
						triggerChange({ number: event.target.value });
					}}
					disabled={disabled}
					placeholder={placeholder}
				/>
			</Col>
		</Row>
	);
}
ReferenceInput.defaultProps = {
	value: {
		model: '',
		number: '',
	},
	onChange: () => null,
};

export default ReferenceInput;
