export const STATUS_CODES_TEXT = {
	// INFO
	PIN_CORRECT: 'PIN kod je ispravan',
	INTERNET_AVAILABLE: 'Internet je dostupan',
	INTERNET_UNAVAILABLE: 'Internet nije dostupan',
	// WARNING
	STORAGE_ALMOST_FULL: 'Prostor za skladištenje je skoro pun',
	CARD_NOT_PRESENT: 'Bezbednosni element nije prisutan',
	AUDIT_REQUIRED: 'Potrebno je iščitavanje',
	PIN_REQUIRED: 'Potreban je unos PIN koda',
	UNDEFINED_WARNING: 'Nedefinisano upozorenje',
	// ERROR
	PIN_INCORRECT: 'PIN kod je neispravan',
	CARD_LOCKED: 'Pametna kartica je zaključana',
	SECURE_ELEMENT_LOCKED: 'Bezbednosni element je zaključan',
	SECURE_ELEMENT_COMMUNICATION_FAILED:
		'Komunikacija sa bezbednosnim elementom nije uspela',
	SECURE_ELEMENT_PROTOCOL_MISMATCH:
		'Protokol bezbednosnog elementa se ne podudara',
	INVALID_TAX_LABELS: 'Neispravne poreske oznake',
	NOT_CONFIGURED: 'LPFR nije konfigurisan',
	FIELD_REQUIRED: 'Obavezno polje nije popunjeno',
	FIELD_VALUE_TOO_LONG: 'Vrednost polja je predugačka',
	FIELD_VALUE_TOO_SHORT: 'Vrednost polja je prekratka',
	INVALID_FIELD_LENGTH: 'Neispravna dužina polja',
	FIELD_OUT_OF_RANGE: 'Vrednost polja je van opsega',
	INVALID_FIELD_VALUE: 'Neispravna vrednost polja',
	INVALID_DATA_FORMAT: 'Neispravan format podataka',
	LIST_TOO_SHORT: 'Lista je prekratka',
	LIST_TOO_LONG: 'Lista je predugačka',
};

export const STATUS_CODES = {
	// INFO
	'0100': [STATUS_CODES_TEXT.PIN_CORRECT, 'secure'],
	'0210': [STATUS_CODES_TEXT.INTERNET_AVAILABLE, 'global-network'],
	'0220': [STATUS_CODES_TEXT.INTERNET_UNAVAILABLE, 'global-network'],
	// WARNING
	'1100': [STATUS_CODES_TEXT.STORAGE_ALMOST_FULL, 'disk-storage'],
	'1300': [STATUS_CODES_TEXT.CARD_NOT_PRESENT, 'sim-card'],
	'1400': [STATUS_CODES_TEXT.AUDIT_REQUIRED, 'search'],
	'1500': [STATUS_CODES_TEXT.PIN_REQUIRED, 'secure'],
	'1999': [STATUS_CODES_TEXT.UNDEFINED_WARNING, 'warning'],
	// ERROR
	'2100': [STATUS_CODES_TEXT.PIN_INCORRECT, 'secure'],
	'2110': [STATUS_CODES_TEXT.CARD_LOCKED, 'sim-card'],
	'2210': [STATUS_CODES_TEXT.SECURE_ELEMENT_LOCKED, 'sim-card'],
	'2220': [
		STATUS_CODES_TEXT.SECURE_ELEMENT_COMMUNICATION_FAILED,
		'question-mark',
	],
	'2230': [STATUS_CODES_TEXT.SECURE_ELEMENT_PROTOCOL_MISMATCH, 'question-mark'],
	'2310': [STATUS_CODES_TEXT.INVALID_TAX_LABELS, 'folder'],
	'2400': [STATUS_CODES_TEXT.NOT_CONFIGURED, 'settings'],
	'2800': [STATUS_CODES_TEXT.FIELD_REQUIRED, 'decline'],
	'2801': [STATUS_CODES_TEXT.FIELD_VALUE_TOO_LONG, 'decline'],
	'2802': [STATUS_CODES_TEXT.FIELD_VALUE_TOO_SHORT, 'decline'],
	'2803': [STATUS_CODES_TEXT.INVALID_FIELD_LENGTH, 'decline'],
	'2804': [STATUS_CODES_TEXT.FIELD_OUT_OF_RANGE, 'decline'],
	'2805': [STATUS_CODES_TEXT.INVALID_FIELD_VALUE, 'decline'],
	'2806': [STATUS_CODES_TEXT.INVALID_DATA_FORMAT, 'decline'],
	'2807': [STATUS_CODES_TEXT.LIST_TOO_SHORT, 'decline'],
	'2808': [STATUS_CODES_TEXT.LIST_TOO_LONG, 'decline'],
};

export const CUSTOM_STATUS = {
	RECIPT_OLDER_THAN_LAST:
		'Datum i vreme izdavanja računa ne može biti raniji od datuma i vremena izdavanja prethodnog računa',
	SDC_TIME_INVALID: 'Neispravan datum i vreme na LPFR-u',
	SDC_SERIAL_NUMBER_NOT_DEFINED: 'Serijski broj LPFR-a nije definisan',
	DISK_FULL: 'Disk je pun',
	SE_CERTIFICATE_EXPIRED:
		'Sertifikat bezbednosnog elementa je istekao. Potrebno je zameniti bezbednosni element',
	SE_CERTIFICATE_REVOKED:
		'Sertifikat bezbednosnog elementa je opozvan. Potrebno je zameniti bezbednosni element',
	LICENCE_INVALID: 'Licenca za korišćenje LPFR-a nije ispravna',
	LICENCE_ACTIVE: 'Licenca za korišćenje je aktivna',
	LICENCE_EXPIRED: 'Licenca za korišćenje LPFR-a je istekla',
	DUPLICATE_INVOICE_REQUEST: 'Zahtev za računom je dupliciran',
	DONGLE_DOES_NOT_EXIST: 'Dongle ne postoji',
	CARD_READER_NOT_PRESENT: 'Čitač kartica nije prisutan',
	DRIVE_NOT_FOUND: 'Eksterna jedinica memorije nije pronađena',
	COMMANDS_FILE_NOT_FOUND: 'Fajl sa komandama nije pronađen',
	CA_CERTIFICATES_NOT_INSTALLED: 'CA Sertifikati nisu instalirani',
	LAST_INVOICE_NOT_FOUND: 'Poslednji račun nije pronađen',
};

export const STATUS_PER_MRC = {
	'05-0002': {
		// TiMProcesor
		'3100': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
		'3101': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
	},
	'48-0001': {
		// HCP LPFR-W
		'5000': [CUSTOM_STATUS.DONGLE_DOES_NOT_EXIST, 'license'],
		'5100': [CUSTOM_STATUS.CARD_READER_NOT_PRESENT, 'sim-card'],
		'5101': [CUSTOM_STATUS.DRIVE_NOT_FOUND, 'disk-storage'],
		'5103': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
		'5104': [CUSTOM_STATUS.COMMANDS_FILE_NOT_FOUND, 'search'],
		'5107': [CUSTOM_STATUS.DISK_FULL, 'disk-storage'],
		'5108': [CUSTOM_STATUS.CA_CERTIFICATES_NOT_INSTALLED, 'license'],
		'5109': [CUSTOM_STATUS.LAST_INVOICE_NOT_FOUND, 'question-mark'],
		'5110': [CUSTOM_STATUS.LICENCE_ACTIVE, 'license'],
		'5111': [CUSTOM_STATUS.LICENCE_EXPIRED, 'license'],
		'5112': [CUSTOM_STATUS.SE_CERTIFICATE_EXPIRED, 'sim-card'],
		'5113': [CUSTOM_STATUS.SE_CERTIFICATE_REVOKED, 'sim-card'],
	},
	'75-0001': {
		// Master LPFR
		'3200': [CUSTOM_STATUS.SDC_SERIAL_NUMBER_NOT_DEFINED, 'bars-code'],
		'3201': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
		'3202': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
	},
	'85-0001': {
		// StoreBuddy L-PFR
		'2900': [CUSTOM_STATUS.RECIPT_OLDER_THAN_LAST, 'clock'],
		'2910': [CUSTOM_STATUS.DISK_FULL, 'disk-storage'],
		'2950': [CUSTOM_STATUS.SE_CERTIFICATE_EXPIRED, 'sim-card'],
		'2990': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
		'2991': [CUSTOM_STATUS.LICENCE_EXPIRED, 'license'],
	},
	'1B-0001': {
		// Symphony
		'4230': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
		'4242': [CUSTOM_STATUS.DUPLICATE_INVOICE_REQUEST, 'copy'],
	},
};

export const vsdcUrl =
	window.location.hostname === 'localhost' ||
	window.location.hostname.includes('sandbox')
		? 'https://vsdc.sandbox.suf.purs.gov.rs'
		: 'https://vsdc.suf.purs.gov.rs';
