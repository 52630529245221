import { Empty, Modal, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { v2Client } from '../../store/client';
import styles from './PhotosEdit.module.less';

interface Props {
	onChange?: (files: any) => void;
	value?: any;
	resource?: string;
}

function PhotosEdit({ onChange, value, resource = 'productImage' }: Props) {
	const uploadRef = useRef(null);

	const [fileList, setFileList] = useState([]);
	const [preview, setPreview] = useState({
		visible: false,
		image: '',
	});

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		onChange(newFileList);
	};

	const handlePreview = (file) => {
		setPreview({
			visible: true,
			image: file.response.urls['1024x1024'],
		});
	};

	const handleCancel = () => {
		setPreview({
			visible: false,
			image: '',
		});
	};

	useEffect(() => {
		if (value) {
			setFileList(
				value.map((file) =>
					file.uid
						? file
						: {
								uid: file.id,
								name: file.filename,
								status: 'done',
								url: file.urls['1024x1024'],
								response: file,
						  }
				)
			);
		}
	}, [value]);

	return (
		<>
			<Upload
				ref={uploadRef}
				// key={uuid()}
				listType="picture-card"
				className={`${styles.container} ${
					fileList.length === 0 ? styles.empty : ''
				}`}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}`}
				multiple
				onChange={handleChange}
				onPreview={handlePreview}
				fileList={fileList}
			>
				<Upload.Dragger
					showUploadList={false}
					headers={{
						'x-access-token':
							v2Client.defaults.headers.common['x-access-token'],
					}}
					action={`${v2Client.defaults.baseURL}/files/${resource}`}
					multiple
					onChange={handleChange}
					className={`${styles.uploadButton}`}
					openFileDialogOnClick={false}
				>
					{fileList.length === 0 && (
						<Empty
							image="/images/icons/camera.png"
							imageStyle={{
								height: 64,
							}}
							description={
								<>
									Nema fotografija, prevucite fotografije ili
									<br />
									kliknite ovde da dodate fotografije.
								</>
							}
						></Empty>
					)}
					{fileList.length > 0 && (
						<div className={styles.uploadButtonInner}>
							<img src="/images/icons/folder.png" alt="Otpremanje" />
							<div>Otpremi</div>
						</div>
					)}
				</Upload.Dragger>
			</Upload>
			<Modal
				centered
				visible={preview.visible}
				title="Fotografija"
				footer={null}
				onCancel={handleCancel}
			>
				{preview.image !== '' && (
					<img alt="example" style={{ width: '100%' }} src={preview.image} />
				)}
			</Modal>
		</>
	);
}

export default observer(PhotosEdit);
