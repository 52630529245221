import { observable, action, flow } from 'mobx';
import { v4 as uuid } from 'uuid';

import { APPLICATION_VERSION } from '../constants/application';
import { CreateStore } from './Crud.mobx';

const { Entity } = CreateStore({
	type: 'entity',
	name: 'application',
	paginated: false,
	persistFields: [
		'permissions',
		'posSettings',
		'banks',
		'clientId',
		'classifications',
	],
});

type PermissionsModule = {
	title: string;
	type?: 'module' | 'submodule';
	submodules: PermissionsModules;
	permissions: PermissionsModules;
};

type Banks = Record<number, string>;

export type Classification = {
	key: string;
	value: string;
};

type PermissionsModules = Record<string, PermissionsModule>;

type POSSettings = {
	paneWidth: number;
};

class Application extends Entity {
	@observable lastShownUpdate: string = APPLICATION_VERSION;
	@observable permissions: PermissionsModules = {};
	@observable isFetchingPermissions = false;
	@observable banks: Banks = {};
	@observable classifications: Classification[] = [];
	@observable isFetchingBanks = false;
	@observable isFetchingClassifications = false;
	@observable isInitialized = false;
	@observable posSettings: POSSettings = {
		paneWidth: 300,
	};
	@observable clientId: string = uuid();

	constructor() {
		super();
		this.init();
	}

	@action.bound
	setPaneWidth(width: number) {
		this.posSettings.paneWidth = width;
	}

	@flow.bound
	*fetchPermissions() {
		this.isFetchingPermissions = true;
		try {
			const { data } = yield this.getClient().get(`/permissions`);
			this.permissions = data;

			this.isFetchingPermissions = false;
		} catch (error) {
			console.error(error);
			this.isFetchingPermissions = false;
			throw error;
		}
	}

	@action.bound
	setIsInitialized(isInitialized: boolean) {
		this.isInitialized = isInitialized;
	}

	@flow.bound
	*fetchBanks() {
		this.isFetchingBanks = true;
		try {
			const { data } = yield this.getClient().get(`/banks`);
			this.banks = data;

			this.isFetchingBanks = false;
		} catch (error) {
			console.error(error);
			this.isFetchingBanks = false;
			throw error;
		}
	}

	@flow.bound
	*fetchClassifications() {
		this.isFetchingClassifications = true;
		try {
			const { data } = yield this.getClient().get(`/classifications`);
			this.classifications = data;

			this.isFetchingClassifications = false;
		} catch (error) {
			console.error(error);
			this.isFetchingClassifications = false;
			throw error;
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchPermissions();
			this.fetchBanks();
			this.fetchClassifications();
		}
	}
}

export { Application };
