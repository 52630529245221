import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { MarketplaceApplications } from './MarketplaceApplication.mobx';

const { Store, Entity } = CreateStore({
	name: 'marketplaceCategory',
	paginated: false,
	persistFields: [],
});

class MarketplaceCategory extends Entity {
	@observable name?: string;
	@observable description?: string;
	@observable icon?: string;
	@observable applications?: MarketplaceApplications;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any): void {
		data.applications = new MarketplaceApplications({
			resourcePrefix: `marketplace-categories/${data.id}/`,
		});
		super.replace(data);
	}
}

class MarketplaceCategories extends Store<MarketplaceCategory> {
	constructor() {
		super(MarketplaceCategory);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { MarketplaceCategories, MarketplaceCategory };
