import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import { useEffect } from 'react';

import { Product } from '../../../../../../../../stores/Product.mobx';
import { Title } from '../../../../../../../components/Title';

interface Props {
	visible?: boolean;
	entity?: Product;
	close: () => void;
	save: (entity: Product) => void;
}

export default function ContactEditDrawer({
	visible,
	entity,
	close,
	save,
}: Props) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(entity);
	}, [entity, form]);

	return (
		<Drawer
			width={842}
			visible={visible}
			title={entity ? `Izmena kontakta ${entity?.name}` : 'Dodavanje kontakta'}
			onClose={close}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						Poništi
					</Button>
					<Button key="save" type="primary" onClick={form.submit}>
						{entity ? 'Izmeni' : 'Dodaj'}
					</Button>
				</Space>
			}
		>
			<Form
				form={form}
				onFinish={(newValue) => {
					form.resetFields();
					save(newValue);
				}}
				layout="vertical"
			>
				<Title>Osnovne informacije</Title>
				<Row gutter={8}>
					<Col md={14} xs={24}>
						<Form.Item
							label="Ime i prezime / Naziv"
							name="name"
							rules={[
								{
									required: true,
									message: 'Ime i prezime / Naziv je obavezan',
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col md={10} xs={24}>
						<Form.Item label="Uloga kod partnera" name="role">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Title>Kontakt informacije</Title>
				<Row gutter={8}>
					<Col md={6} xs={24}>
						<Form.Item
							label="E-Mail"
							name="email"
							rules={[
								{
									type: 'email',
									message: 'E-Mail nije validan',
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label="Telefon" name="phone">
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label="Mobilni telefon" name="mobilePhone">
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label="Faks" name="fax">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Title>Ostalo</Title>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item label="Napomena" name="note">
							<Input.TextArea />
						</Form.Item>
					</Col>
				</Row>
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Drawer>
	);
}
