import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';

function CashierSelect(props) {
	const {
		users: { isFetching, available },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					(option.children as unknown as string)
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			dropdownMatchSelectWidth={false}
			{...props}
		>
			{available.map((user) => (
				<Select.Option key={user.id} value={user.id}>
					{user.fullName}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(CashierSelect);
