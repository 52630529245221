import { useEffect } from 'react';

import { vsdcUrl } from '../../constants/esdc';

function VSDC() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = `${vsdcUrl}/onlinepos/v1/taxcore.min.js`;
		script.async = true;

		setTimeout(() => {
			document.body.appendChild(script);
		}, 1000);
	}, []);

	return (
		<>
			<div style={{ display: 'none' }}>
				<textarea id="invoiceRequest"></textarea>
				<textarea id="invoiceResponse"></textarea>
				<button
					id="taxcore_sign_element"
					data-taxcore-vsdc-url={`${vsdcUrl}/`}
					data-taxcore-input-id="invoiceRequest"
				></button>
			</div>
		</>
	);
}

export default VSDC;
