import { Form, Grid, Input, InputNumber, Select, Table } from 'antd';
import round from 'lodash/round';
import { useEffect, useRef } from 'react';

import InvoiceSummaryView from '../../../../../../components/InvoiceSummaryView';
import { useDrawer } from '../../../../../../components/Page';
import { TableInput } from '../../../../../../components/TableInput';
import UnitSelect from '../../../../../../components/UnitSelect';
import {
	DocumentType,
	DOCUMENT_TYPE,
	TAX_FEE_CATEGORY,
} from '../../../../../../constants/documentInvoice';
import { Invoice } from '../../../../../../stores/Invoice.mobx';
import SkuInput from './Components/SkuInput';
import styles from './InvoiceItemsInput.module.less';

interface Props {
	currencyId: string;
	id?: string;
	documentType?: DocumentType;
	value?: any[];
}

export default function InvoiceItemsInput(props: Props) {
	const { currencyId, id } = props;
	const tableInputRef = useRef();
	const screens = Grid.useBreakpoint();
	const [, , , , , ProductSearchDrawer] = useDrawer('product-search');

	useEffect(() => {
		if (props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE) {
			(props.value || []).forEach((item, index) => {
				tableInputRef?.current?.handleEditObject?.(index, {
					quantity: 1,
				});
			});
		}
	}, [props.documentType, tableInputRef]);

	return (
		<>
			<TableInput
				{...props}
				ref={tableInputRef}
				isResponsive
				disallowLastRowDelete
				addButtonText="Dodaj stavku"
				emptyText="Nema stavki"
				iconPath="/images/icons/list.png"
				columns={[
					{
						title: 'Šifra',
						editable: true,
						dataIndex: 'sellerItemIdentification',
						width: 120,
						component: (record, index, handleEditObject) => (
							<SkuInput
								currencyId={currencyId}
								handleEditObject={handleEditObject}
								index={index}
							/>
						),
						colProps: {
							xs: 24,
							sm: 8,
						},
					},
					{
						title: 'Tip',
						editable: true,
						dataIndex: 'type',
						width: 120,
						component: (
							<Select
								dropdownMatchSelectWidth={false}
								style={{ width: '100%' }}
							>
								<Select.Option value="product">Fizički proizvod</Select.Option>
								<Select.Option value="digital">
									Digitalni proizvod
								</Select.Option>
								<Select.Option value="service">Usluga</Select.Option>
							</Select>
						),
						rules: [
							{
								required: true,
								message: 'Tip je obavezan',
							},
						],
					},
					{
						title: 'Naziv',
						editable: true,
						dataIndex: 'name',
						component: (
							<Input
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: 'Naziv je obavezan',
							},
						],
						colProps: {
							xs: 24,
							sm: 16,
						},
					},
					{
						title: 'Količina',
						editable: true,
						width: 190,
						dataIndex: 'quantity',

						component: (record, index, handleEditObject) => (
							<InputNumber
								step={1}
								disabled={props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE}
								addonAfter={
									<Form.Item
										noStyle
										name={[id, index, 'unitId']}
										rules={[
											{
												required: true,
												message: 'Jedinica mere je obavezna',
											},
										]}
									>
										<UnitSelect value={record.unitId} placeholder="JM" />
									</Form.Item>
								}
								style={{ width: '100%' }}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: 'Količina je obavezna',
							},
							{
								type: 'number',
								min: 0.001,
								message: 'Količina mora biti veća od 0',
							},
						],
						colProps: {
							xs: 24,
							sm: 12,
						},
					},
					{
						title: 'Cena bez PDV',
						editable: true,
						width: 180,
						dataIndex: 'unitPrice',
						component: () => (
							<InputNumber
								step={1}
								addonAfter={currencyId || 'RSD'}
								style={{ width: '100%' }}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: 'Cena je obavezna',
							},
							{
								type: 'number',
								min: 0.01,
								message: 'Cena mora biti veća od 0',
							},
						],
						colProps: {
							xs: 24,
							sm: 12,
						},
					},
					{
						title: screens.md ? 'PDV kat.' : 'PDV kategorija',
						editable: true,
						width: 80,
						dataIndex: 'taxCode',
						component: (record, index, handleEditObject) => (
							<Select
								dropdownMatchSelectWidth={false}
								optionLabelProp="label"
								onChange={(value) => {
									if (value !== 'S') {
										handleEditObject(index, {
											taxPercentage: null,
											taxCode: value,
										});
									}
								}}
							>
								<Select.Option value={null} label="">
									{' '}
								</Select.Option>
								{Object.entries(TAX_FEE_CATEGORY).map(([key, value]) => (
									<Select.Option key={key} value={key} label={key}>
										<strong className={styles.selectTaxCategoryLabel}>
											{key}:&nbsp;
										</strong>
										{value}
									</Select.Option>
								))}
							</Select>
						),
						rules: [
							{
								required: true,
								message: 'Obavezno',
							},
						],
						colProps: {
							span: 12,
						},
					},
					{
						title: 'PDV',
						editable: true,
						width: 90,
						dataIndex: 'taxPercentage',
						component: (record) => (
							<Select
								dropdownMatchSelectWidth={false}
								disabled={record.taxCode !== 'S'}
								placeholder="0%"
								optionLabelProp="label"
							>
								<Select.Option value={20} label={'20%'}>
									<strong className={styles.selectTaxCategoryLabel}>
										20%:
									</strong>{' '}
									Opšta stopa
								</Select.Option>
								<Select.Option value={10} label={'10%'}>
									<strong className={styles.selectTaxCategoryLabel}>
										10%:
									</strong>{' '}
									Posebna stopa
								</Select.Option>
							</Select>
						),
						colProps: {
							span: 12,
						},
					},
					{
						title: 'Popust',
						editable: true,
						width: 130,
						dataIndex: 'discountPercentage',
						component: (record, index, handleEditObject) => (
							<InputNumber
								addonAfter="%"
								step={1}
								style={{ width: '100%' }}
								min={0}
								max={100}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
								onChange={(value) => {
									handleEditObject(index, {
										discountPercentage: value,
										discountAmount: value
											? round(
													(value / 100) * record.unitPrice * record.quantity,
													2
											  )
											: 0,
									});
								}}
							/>
						),
						colProps: {
							span: 12,
						},
						rules: [
							{
								type: 'number',
								max: 100,
								message: 'Max je 100%',
							},
							{
								type: 'number',
								min: 0,
								message: 'Min je 0%',
							},
						],
					},
					{
						title: `Popust ${currencyId}`,
						editable: true,
						width: 160,
						dataIndex: 'discountAmount',
						component: (record, index, handleEditObject) => (
							<InputNumber
								addonAfter={currencyId || 'RSD'}
								step={1}
								min={0}
								max={record.unitPrice * record.quantity}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
								style={{ width: '100%' }}
								rules={[
									{
										type: 'number',
										max: round(record.unitPrice * record.quantity, 2),
										message: `Max je ${round(
											record.unitPrice * record.quantity,
											2
										)}`,
									},
									{
										type: 'number',
										min: 0,
										message: 'Min je 0',
									},
								]}
								onChange={(value) => {
									handleEditObject(index, {
										discountAmount: value,
										discountPercentage: value
											? round(
													(value / (record.unitPrice * record.quantity)) * 100,
													2
											  )
											: 0,
									});
								}}
							/>
						),
						colProps: {
							span: 12,
						},
					},
				]}
				shouldShowDuplicateButton
				tableProps={{
					scroll: { x: 1420 },
				}}
				newRowValue={{
					taxCode: 'S',
					unitPrice: 0,
					quantity:
						props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE ? 1 : 0,
					unitId: null,
					discountPercentage: 0,
					discountAmount: 0,
				}}
				insertNewRow
			/>
			<ProductSearchDrawer />
		</>
	);
}
