import stores from '../stores/index.mobx';
export const usePermissions = (
	module: string,
	submodule: string,
	action: string,
	entityId?: string
) => {
	const { users } = stores;
	if (typeof users.authenticatedUser?.hasPermission !== 'function') {
		return false;
	}
	const val = users.authenticatedUser?.hasPermission(
		module,
		submodule,
		action,
		entityId
	);

	return val;
};
