import { Table, Checkbox } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import stores from '../../../../../../stores/index.mobx';
import { Role, Permissions } from '../../../../../../stores/Role.mobx';
import styles from './PermissionsEdit.module.less';

const icons = {
	pos: '/images/icons/cash-register.png',
	catalog: '/images/icons/stock.png',
	documents: '/images/icons/invoice.png',
	reports: '/images/icons/business-report.png',
	receipts: '/images/icons/receipt.png',
	print: '/images/icons/printer.png',
	settings: '/images/icons/settings.png',
};

interface Props {
	readonly?: boolean;
	value?: Permissions;
	onChange?: (any) => void;
	record?: Role;
}

function PermissionsEdit({
	value = null,
	onChange,
	readonly,
	record = null,
}: Props) {
	const {
		application: { permissions: data, isFetchingPermissions: isFetching },
	} = stores;

	const [values, setValues] = useState({});
	useEffect(() => {
		setValues(value || {});
	}, [value]);
	function onCheckboxChange(paths: string[], event) {
		const valuesClone = cloneDeep(values);
		paths.forEach((path: string) => {
			set(valuesClone, path, event.target.checked);
		});
		setValues(valuesClone);
		onChange(valuesClone);
	}

	function renderPermissions(submoduleRecord, moduleRecord) {
		return (
			<Table
				loading={isFetching}
				pagination={false}
				size="small"
				columns={[
					{
						key: 'all',
						title: 'Sve',
						width: 60,
						render: () => {
							const checkedValues = Object.values(
								get(values, `${moduleRecord.key}.${submoduleRecord.key}`) || {}
							).filter(Boolean).length;
							return (
								<Checkbox
									disabled={readonly}
									checked={record?.isAdmin || checkedValues > 0}
									indeterminate={
										checkedValues !== 0 &&
										checkedValues !== submoduleRecord.permissions.size
									}
									onChange={(event) => {
										onCheckboxChange(
											Array.from(submoduleRecord.permissions.keys()).map(
												(key) =>
													`${moduleRecord.key}.${submoduleRecord.key}.${key}`
											),
											event
										);
									}}
								/>
							);
						},
					},
					...Array.from(Object.entries(submoduleRecord.permissions)).map(
						([key, value]) => ({
							key: key,
							title: value.title,
							render: () => (
								<Checkbox
									disabled={readonly}
									checked={
										record?.isAdmin ||
										get(
											values,
											`${moduleRecord.key}.${submoduleRecord.key}.${key}`
										)
									}
									onChange={(event) =>
										onCheckboxChange(
											[`${moduleRecord.key}.${submoduleRecord.key}.${key}`],
											event
										)
									}
								/>
							),
						})
					),
				]}
				dataSource={[{}]}
			/>
		);
	}

	function renderSubmodule(moduleRecord) {
		return (
			<Table
				pagination={false}
				size="small"
				showHeader={false}
				className={styles.innerTable}
				columns={[
					{
						key: 'submodule',
						width: 120,
						render: (text, record) => {
							return <>{record.title}</>;
						},
					},
					{
						key: 'permissions',
						render: (text, record) => {
							return renderPermissions(record, moduleRecord);
						},
					},
				]}
				dataSource={Array.from(Object.entries(moduleRecord.submodules)).map(
					([key, value]) => ({ ...value, key })
				)}
			></Table>
		);
	}

	function renderModule(key, value) {
		return (
			<Table
				showHeader={false}
				pagination={false}
				size="small"
				className={styles.table}
				columns={[
					{
						key: 'module',
						width: 130,
						render: (text, record) => {
							return (
								<>
									<img src={icons[record.key]} rel="icon" />
									{record.title}
								</>
							);
						},
					},

					{
						key: 'submodule',
						render: (text, record) => {
							return record.submodules
								? renderSubmodule({ ...record, key })
								: renderSubmodule({
										key: key,
										submodules: {
											[record.key]: {
												key: record.key,
												title: 'Ceo modul',
												permissions: record.permissions,
											},
										},
								  });
						},
					},
				]}
				dataSource={[{ ...value, key }]}
			></Table>
		);
	}

	return (
		<div className={styles.container}>
			{Array.from(Object.entries(data)).map(([key, value]) => {
				return renderModule(key, value);
			})}
		</div>
	);
}

export default observer(PermissionsEdit);
