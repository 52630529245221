import { Input } from 'antd';

import { CreatePage } from '../../../../components/Page';
import PermissionsEdit from './Components/PermissionsEdit';

export default CreatePage({
	module: 'settings',
	submodule: 'roles',
	model: 'role',
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: true,
				},
			],
		},
		createButtonText: 'Dodaj ulogu',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovu ulogu?',
	},
	edit: {
		width: 800,
		title: {
			new: 'Dodavanje uloge',
			existing: (entity) => `Izmena uloge ${entity?.name || ''}`,
		},
		fields: [
			{
				key: 'row1',
				label: 'Informacije',
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						rules: [{ required: true, message: 'Naziv je obavezan' }],
						component: <Input />,
						span: 24,
					},
				],
			},
			{
				key: 'row2',
				label: 'Dozvole',
				fields: [
					{
						key: 'permissions',
						component: (record) => <PermissionsEdit record={record} />,
						span: 24,
					},
				],
			},
		],
	},
	view: {
		width: 800,
		title: (record) => `Pregled uloge ${record?.name || ''}`,
		fields: [
			{
				key: 'info',
				label: 'Informacije',
				column: 24,
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						span: 24,
					},
				],
			},
			{
				key: 'permissions',
				label: 'Dozvole',
				column: 24,
				fields: [
					{
						key: 'permissions',
						span: 24,
						component: <PermissionsEdit readonly />,
					},
				],
			},
		],
	},
});
