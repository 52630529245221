import { Input } from 'antd';

import { CreatePage } from '../../../../components/Page';
import RoleSelect from '../../../../components/RoleSelect';
import stores from '../../../../stores/index.mobx';
export default CreatePage({
	module: 'settings',
	submodule: 'users',
	model: 'user',
	view: {
		useEdit: true,
	},
	edit: {
		width: 700,
		title: {
			new: 'Dodavanje korisnika',
			existing: (entity) => `Izmena korisnika ${entity?.fullName || ''}`,
		},
		shouldFetch: false,
		disablePasswordAutocomplete: true,
		fields: () => [
			{
				key: 'row1',
				fields: [
					{
						key: 'firstName',
						label: 'Ime',
						rules: [{ required: true, message: 'Ime je obavezno' }],
						component: <Input />,
						md: 12,
						xs: 24,
					},
					{
						key: 'lastName',
						label: 'Prezime',
						rules: [{ required: true, message: 'Prezime je obavezno' }],
						component: <Input />,
						md: 12,
						xs: 24,
					},
					{
						key: 'email',
						label: 'E-Mail Adresa',
						rules: [
							{ required: true, message: 'E-Mail je obavezan' },
							{ email: true, message: 'E-Mail adresa je neispravna' },
						],
						component: <Input />,
						md: 12,
						xs: 24,
					},
					{
						key: 'password',
						label: 'Lozinka',
						rules: [
							{ min: 8, message: 'Lozinka mora imati najmanje 8 karaktera' },
						],
						component: (
							<Input type="password" autoComplete="false" role="presentation" />
						),
						md: 12,
						xs: 24,
					},
				],
			},
			...(stores.users.authenticatedUser?.hasPermission(
				'settings',
				'users',
				'edit'
			)
				? [
						{
							key: 'role',
							fields: [
								{
									key: 'roleId',
									label: 'Uloga',
									rules: [{ required: true, message: 'Uloga je obavezna' }],
									component: <RoleSelect />,
									md: 12,
									xs: 24,
								},
							],
						},
				  ]
				: []),
		],
	},
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Ime',
					dataIndex: 'fullName',
					key: 'fullName',
					width: 400,
					shouldLink: true,
				},
				{
					title: 'E-Mail',
					dataIndex: 'email',
					key: 'email',
				},
			],
		},
		createButtonText: 'Dodaj korisnika',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovog korisnika?',
	},
});
