import { Spin } from 'antd';
import QRCode from 'qrcode';
import { useState, useEffect } from 'react';

export default function QR({ verificationUrl, deferred, ...props }) {
	const [qr, setQr] = useState(null);

	useEffect(() => {
		if (!verificationUrl) {
			return;
		}

		QRCode.toDataURL(verificationUrl, {
			errorCorrectionLevel: 'L',
			scale: 4,
			margin: 0,
		}).then((url) => {
			setQr(url);
		});
	}, [verificationUrl]);

	if (!verificationUrl) {
		return null;
	}

	if (!qr) {
		return <Spin spinning={true} />;
	}

	return (
		<a href={verificationUrl} target="_blank">
			<img
				src={qr}
				alt="QR kod"
				style={{ width: '5cm' }}
				{...props}
				onLoad={() => deferred?.resolve?.()}
			/>
		</a>
	);
}
