export const INVOICE_TYPE = {
	NORMAL: 0,
	PROFORMA: 1,
	COPY: 2,
	TRAINING: 3,
	ADVANCE: 4,
};

export enum InvoiceType {
	NORMAL = 0,
	PROFORMA = 1,
	COPY = 2,
	TRAINING = 3,
	ADVANCE = 4,
}

export const TRANSACTION_TYPE = {
	SALE: 0,
	REFUND: 1,
};

export enum TransactionType {
	SALE = 0,
	REFUND = 1,
}

export const PAYMENT_TYPE = {
	OTHER: 0,
	CASH: 1,
	CARD: 2,
	CHECK: 3,
	WIRE_TRANSFER: 4,
	VOUCHER: 5,
	MOBILE_MONEY: 6,
};

export enum PaymentType {
	OTHER = 0,
	CASH = 1,
	CARD = 2,
	CHECK = 3,
	WIRE_TRANSFER = 4,
	VOUCHER = 5,
	MOBILE_MONEY = 6,
}

export const TAX_FREE_LABEL =
	window.location.hostname === 'localhost' ||
	window.location.hostname.includes('sandbox')
		? 'B'
		: 'Г';
