import sortBy from 'lodash/sortBy';
import { computed, makeObservable, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'unit',
	paginated: false,
	persistFields: ['all'],
});

class Unit extends Entity {
	@observable label?: string;
	@observable name?: string;
	@observable isPieceUnitOfMeasure?: boolean;
	@observable active?: boolean;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Units extends Store<Unit> {
	constructor() {
		super(Unit);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Units, Unit };
