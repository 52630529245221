import { Table } from 'antd';
import { groupBy } from 'lodash';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { TAX_FEE_CATEGORY } from '../../constants/documentInvoice';
import numberFormat from '../../lib/numberFormat';
import {
	AdvancePayment,
	Invoice,
	TaxBreakdown,
} from '../../stores/Invoice.mobx';
import styles from './InvoiceSummaryView.module.less';

interface Props {
	record?: Partial<Invoice>;
}

function InvoiceSummaryView({ record }: Props) {
	const allowanceByTaxCategory = useMemo(() => {
		if (!record) {
			return {};
		}

		return (record.allowances || []).reduce((acc, allowance) => {
			acc[allowance.taxCategoryCode] = allowance.amount;
			return acc;
		}, {});
	}, [record]);

	const data = useMemo(() => {
		const summary = [];

		function getCategoryName(
			taxCategory: string,
			taxRate: number,
			currencyId?: string
		) {
			return `${
				taxCategory === 'S' && taxRate
					? `Stopa ${taxRate}%`
					: TAX_FEE_CATEGORY[taxCategory]
			}${currencyId ? ` (${currencyId})` : ''}`;
		}

		const taxBreakdownByCurrency = groupBy(record?.taxBreakdown, 'currencyId');
		const taxBreakdownInInvoiceCurrency =
			taxBreakdownByCurrency[record?.currencyId] || [];
		const taxBreakdownInTaxCurrency =
			taxBreakdownByCurrency[record?.taxCurrencyId] || [];

		taxBreakdownInInvoiceCurrency.forEach?.((taxBreakdown) => {
			const categoryName = getCategoryName(
				taxBreakdown.taxCategory,
				taxBreakdown.taxRate
			);
			const allowance = allowanceByTaxCategory[taxBreakdown.taxCategory];

			summary.push({
				title: `Zbir stavki - ${categoryName}`,
				amount: round(taxBreakdown.taxableAmount + (allowance?.amount || 0), 2),
				currencyId: record.currencyId,
			});
		});
		taxBreakdownInInvoiceCurrency.forEach?.((taxBreakdown) => {
			const categoryName = getCategoryName(
				taxBreakdown.taxCategory,
				taxBreakdown.taxRate
			);
			const allowance = allowanceByTaxCategory[taxBreakdown.taxCategory];
			if (allowance) {
				summary.push({
					title: `Smanjenje naknade - ${categoryName}`,
					amount: allowance.amount,
					currencyId: record.currencyId,
				});
			}
		});
		taxBreakdownInInvoiceCurrency.forEach?.((taxBreakdown) => {
			const categoryName = getCategoryName(
				taxBreakdown.taxCategory,
				taxBreakdown.taxRate
			);

			summary.push({
				title: `Ukupno osnovica - ${categoryName}`,
				amount: taxBreakdown.taxableAmount,
				currencyId: record.currencyId,
			});
		});
		taxBreakdownInInvoiceCurrency.forEach?.((taxBreakdown) => {
			const categoryName = getCategoryName(
				taxBreakdown.taxCategory,
				taxBreakdown.taxRate
			);

			if (taxBreakdown.taxCategory === 'S') {
				summary.push({
					title: `Ukupno PDV - ${categoryName}`,
					amount: taxBreakdown.taxAmount,
					currencyId: record.currencyId,
				});
			}
		});
		const totalTax = taxBreakdownInInvoiceCurrency.reduce?.(
			(acc, taxBreakdown) => {
				if (taxBreakdown.taxCategory === 'S') {
					acc += taxBreakdown.taxAmount;
				}
				return acc;
			},
			0
		);

		summary.push({
			title: `Ukupan PDV`,
			amount: totalTax,
			currencyId: record.currencyId,
			bold: true,
		});

		summary.push({
			title: `Ukupan iznos`,
			amount: record?.amount || 0,
			currencyId: record.currencyId,
			bold: true,
		});

		if ((record?.currencyId || 'RSD') !== (record?.taxCurrencyId || 'RSD')) {
			taxBreakdownInTaxCurrency.forEach?.((taxBreakdown) => {
				const categoryName = getCategoryName(
					taxBreakdown.taxCategory,
					taxBreakdown.taxRate,
					record.taxCurrencyId
				);
				const allowance = allowanceByTaxCategory[taxBreakdown.taxCategory];

				summary.push({
					title: `Zbir stavki - ${categoryName}`,
					amount: round(
						taxBreakdown.taxableAmount + (allowance?.amount || 0),
						2
					),
					currencyId: record.taxCurrencyId,
				});
			});
			taxBreakdownInTaxCurrency.forEach?.((taxBreakdown) => {
				const categoryName = getCategoryName(
					taxBreakdown.taxCategory,
					taxBreakdown.taxRate,
					record.taxCurrencyId
				);
				const allowance = allowanceByTaxCategory[taxBreakdown.taxCategory];
				if (allowance) {
					summary.push({
						title: `Smanjenje naknade - ${categoryName}`,
						amount: allowance.amount,
						currencyId: record.taxCurrencyId,
					});
				}
			});
			taxBreakdownInTaxCurrency.forEach?.((taxBreakdown) => {
				const categoryName = getCategoryName(
					taxBreakdown.taxCategory,
					taxBreakdown.taxRate,
					record.taxCurrencyId
				);

				summary.push({
					title: `Ukupno osnovica - ${categoryName}`,
					amount: taxBreakdown.taxableAmount,
					currencyId: record.taxCurrencyId,
				});
			});
			taxBreakdownInTaxCurrency.forEach?.((taxBreakdown) => {
				const categoryName = getCategoryName(
					taxBreakdown.taxCategory,
					taxBreakdown.taxRate,
					record.taxCurrencyId
				);

				if (taxBreakdown.taxCategory === 'S') {
					summary.push({
						title: `Ukupno PDV - ${categoryName}`,
						amount: taxBreakdown.taxAmount,
						currencyId: record.taxCurrencyId,
					});
				}
			});

			const totalTax = taxBreakdownInTaxCurrency.reduce?.(
				(acc, taxBreakdown) => {
					if (taxBreakdown.taxCategory === 'S') {
						acc += taxBreakdown.taxAmount;
					}
					return acc;
				},
				0
			);

			summary.push({
				title: `Ukupan PDV (${record.taxCurrencyId})`,
				amount: totalTax,
				currencyId: record.taxCurrencyId,
				bold: true,
			});
		}

		const advancePaymentsByTaxCategory = Object.values(
			(record?.advancePayments || []).reduce((acc, advancePayment) => {
				acc[`${advancePayment.taxCategory}-${advancePayment.taxRate}`] =
					acc[`${advancePayment.taxCategory}-${advancePayment.taxRate}`] || {};

				acc[
					`${advancePayment.taxCategory}-${advancePayment.taxRate}`
				].taxAmount = round(
					(acc[`${advancePayment.taxCategory}-${advancePayment.taxRate}`]
						.taxAmount || 0) + advancePayment.taxAmount,
					2
				);

				acc[
					`${advancePayment.taxCategory}-${advancePayment.taxRate}`
				].taxableAmount = round(
					(acc[`${advancePayment.taxCategory}-${advancePayment.taxRate}`]
						.taxableAmount || 0) + advancePayment.taxableAmount,
					2
				);

				acc[`${advancePayment.taxCategory}-${advancePayment.taxRate}`].taxRate =
					advancePayment.taxRate;
				acc[
					`${advancePayment.taxCategory}-${advancePayment.taxRate}`
				].taxCategory = advancePayment.taxCategory;

				return acc;
			}, {})
		);

		advancePaymentsByTaxCategory.forEach((advancePayment: AdvancePayment) => {
			const categoryName = getCategoryName(
				advancePayment.taxCategory,
				advancePayment.taxRate
			);

			summary.push({
				title: `Avansirana osnovica - ${categoryName}`,
				amount: advancePayment.taxableAmount,
			});
		}, []);

		advancePaymentsByTaxCategory.forEach((advancePayment: AdvancePayment) => {
			const categoryName = getCategoryName(
				advancePayment.taxCategory,
				advancePayment.taxRate
			);

			summary.push({
				title: `Avansni PDV - ${categoryName}`,
				amount: advancePayment.taxAmount,
			});
		}, []);

		if (advancePaymentsByTaxCategory.length > 0) {
			summary.push({
				title: `Ukupno avansirana osnovica`,
				amount: round(
					(advancePaymentsByTaxCategory as AdvancePayment[]).reduce(
						(acc, advancePayment: AdvancePayment) =>
							acc + advancePayment.taxableAmount,
						0
					),
					2
				),
			});

			summary.push({
				title: `Ukupan avansiran PDV`,
				amount: round(
					(advancePaymentsByTaxCategory as AdvancePayment[]).reduce(
						(acc, advancePayment: AdvancePayment) =>
							acc + advancePayment.taxAmount,
						0
					),
					2
				),
			});

			summary.push({
				title: `Ukupan avansiran iznos`,
				amount: round(
					(advancePaymentsByTaxCategory as AdvancePayment[]).reduce(
						(acc, advancePayment: AdvancePayment) =>
							acc + advancePayment.taxableAmount + advancePayment.taxAmount,
						0
					),
					2
				),
				bold: true,
			});
		}
		(record.reducedTotals || []).forEach((reducedTotal) => {
			const categoryName = getCategoryName(
				reducedTotal.taxCategory,
				reducedTotal.taxRate
			);

			summary.push({
				title: `Ukupno osnovica umanjena za avanse - ${categoryName}`,
				amount: reducedTotal.taxableAmount,
			});
		});
		(record.reducedTotals || []).forEach((reducedTotal) => {
			const categoryName = getCategoryName(
				reducedTotal.taxCategory,
				reducedTotal.taxRate
			);

			summary.push({
				title: `Ukupno PDV umanjen za avanse - ${categoryName}`,
				amount: reducedTotal.taxAmount,
			});
		});

		summary.push({
			title: 'Ukupno za uplatu',
			amount: record.payableAmount,
			bold: true,
		});

		return summary;
	}, [record]);

	return (
		<Table
			showHeader={false}
			className={styles.table}
			dataSource={data}
			pagination={false}
			size="small"
			bordered
			scroll={{ x: 300 }}
			style={{ width: '100%' }}
		>
			<Table.Column
				key="title"
				dataIndex="title"
				align="right"
				render={(text, r: any) => (r.bold ? <strong>{text}</strong> : text)}
			/>
			<Table.Column
				key="amount"
				dataIndex="amount"
				align="right"
				width={170}
				render={(text, r: any) =>
					r.bold ? (
						<strong>
							{numberFormat(
								text,
								true,
								2,
								true,
								r.currencyId || record?.currencyId || 'RSD'
							)}
						</strong>
					) : (
						numberFormat(
							text,
							true,
							2,
							true,
							r.currencyId || record?.currencyId || 'RSD'
						)
					)
				}
			/>
		</Table>
	);
}

export default observer(InvoiceSummaryView);
