import Bugsnag from '@bugsnag/js';
import { QRCodeCanvas } from '@cheprasov/qrcode';
import { AsciiTable3, AlignmentEnum } from 'ascii-table3';
import DitherJS from 'ditherjs';
import htmlToCanvas from 'html2canvas';
import chunk from 'lodash/chunk';
import round from 'lodash/round';
import { createRoot } from 'react-dom/client';

import { TextSize } from '../constants/journal';
import stores from '../stores/index.mobx';
import { print } from './devices/thermalPrinter';

const cache = {};

export async function waitForFontLoad(
	font: string,
	timeout = 1000,
	interval = 10
) {
	const startTime = Date.now();

	return new Promise((resolve, reject) => {
		const recursiveFn = () => {
			const currTime = Date.now();

			if (currTime - startTime >= timeout) {
				reject('font listener timeout ' + font);
			} else {
				document.fonts
					.load(font)
					.then((fonts) => {
						if (fonts.length >= 1) {
							resolve(true);
						} else {
							setTimeout(recursiveFn, interval);
						}
					})
					.catch((err) => {
						reject(err);
					});
			}
		};
		recursiveFn();
	});
}

function autoCropCanvas(canvas, ctx) {
	const bounds = {
		left: 0,
		right: canvas.width,
		top: 0,
		bottom: canvas.height,
	};
	const rows = [];
	const cols = [];
	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	for (let x = 0; x < canvas.width; x++) {
		cols[x] = cols[x] || false;
		for (let y = 0; y < canvas.height; y++) {
			rows[y] = rows[y] || false;
			const p = y * (canvas.width * 4) + x * 4;
			const [r, g, b, a] = [
				imageData.data[p],
				imageData.data[p + 1],
				imageData.data[p + 2],
				imageData.data[p + 3],
			];
			const isEmptyPixel = (r === 255 && g === 255 && b === 255) || a === 0;
			if (!isEmptyPixel) {
				cols[x] = true;
				rows[y] = true;
			}
		}
	}
	for (let i = 0; i < rows.length; i++) {
		if (rows[i]) {
			bounds.top = i ? i - 1 : i;
			break;
		}
	}
	for (let i = rows.length; i--; ) {
		if (rows[i]) {
			bounds.bottom = i < canvas.height ? i + 1 : i;
			break;
		}
	}
	for (let i = 0; i < cols.length; i++) {
		if (cols[i]) {
			bounds.left = i ? i - 1 : i;
			break;
		}
	}
	for (let i = cols.length; i--; ) {
		if (cols[i]) {
			bounds.right = i < canvas.width ? i + 1 : i;
			break;
		}
	}
	const newWidth = bounds.right - bounds.left;
	const newHeight = bounds.bottom - bounds.top;
	const cut = ctx.getImageData(bounds.left, bounds.top, newWidth, newHeight);
	canvas.width = newWidth;
	canvas.height = newHeight;
	ctx.putImageData(cut, 0, 0);
}

export class ThermalPrinter {
	component: any = null;
	deferred: null;
	rows: any[] = [];
	maxWidth: number;
	constructor() {
		this.maxWidth =
			stores.devices.thermalPrinters[0]?.configuration?.printWidth === '58'
				? 40
				: 52;
	}

	setComponent(component, deferred) {
		this.component = component;
		this.deferred = deferred;
	}

	createLeftAndRight(
		left: string,
		right: string,
		addColon = true,
		maxWidth = this.maxWidth
	): string {
		const colon = addColon ? ':' : '';
		const leftText = left ? `${left}${colon} ` : '';
		const padLength = maxWidth - leftText.length - right.length;
		if (padLength < 0) {
			return this.breakLines(`${leftText} ${right}`, maxWidth);
		}

		return `${leftText}${' '.repeat(padLength)}${right}`;
	}

	addQrCode(url, image) {
		this.rows.push({
			type: 'qr',
			url,
			image,
		});
	}

	addTitle(text: string, size: TextSize = TextSize.NORMAL, char = '=') {
		this.rows.push({
			type: 'text',
			content: AsciiTable3.align(
				AlignmentEnum.CENTER,
				` ${text} `,
				this.maxWidth,
				char
			),
			size,
		});
	}

	addCenteredText(text: string, size: TextSize = TextSize.NORMAL) {
		const lines = this.breakLinesNoPadding(
			text,
			size === TextSize.SMALL ? (this.maxWidth * 1.3) | 0 : undefined
		).split('\n');

		lines.forEach((line) => {
			this.rows.push({
				type: 'text',
				content: AsciiTable3.align(
					AlignmentEnum.CENTER,
					line,
					this.maxWidth,
					' '
				),
				size,
			});
		});
	}

	addSubtitle(text: string) {
		this.rows.push({
			type: 'text',
			content: AsciiTable3.align(
				AlignmentEnum.CENTER,
				text,
				this.maxWidth,
				'-'
			),
		});
	}

	addSeparator(character = '=') {
		this.rows.push({
			type: 'text',
			content: character.repeat(this.maxWidth),
		});
	}

	addText(text: string, size: TextSize = TextSize.NORMAL) {
		const lines = this.breakLines(
			text,
			size === TextSize.SMALL ? (this.maxWidth * 1.3) | 0 : undefined
		).split('\n');

		lines.forEach((line) => {
			this.rows.push({
				type: 'text',
				content: line,
				size,
			});
		});
	}

	addLeftRight(
		left: string,
		right: string,
		addColon = false,
		textSize: TextSize.NORMAL
	) {
		const lines = this.createLeftAndRight(left, right, addColon).split('\n');
		lines.forEach((line) => {
			this.rows.push({
				type: 'text',
				content: line,
				size: textSize,
			});
		});
	}

	addTableRow(
		columns: string[],
		widths: number[],
		alignments: AlignmentEnum[]
	) {
		const sumWidths = widths.reduce((a, b) => (b !== -1 ? a + b : a), 0);
		this.rows.push({
			type: 'text',
			content: columns.reduce((acc, cur, i) => {
				const width = widths[i] === -1 ? this.maxWidth - sumWidths : widths[i];
				const alignment = alignments[i] || AlignmentEnum.LEFT;
				return `${acc}${AsciiTable3.align(alignment, cur, width, ' ')}`;
			}, ''),
		});
	}

	breakLines(text = ' ', maxLength = this.maxWidth): string {
		return text
			.match(new RegExp(`.{1,${maxLength}}`, 'g'))
			.map((str) => str.padEnd(maxLength, ' '))
			.join('\n');
	}

	breakLinesNoPadding(text: string, maxLength = this.maxWidth): string {
		return text.match(new RegExp(`.{1,${maxLength}}`, 'g')).join('\n');
	}

	addToCache(key, value) {
		cache[
			`${stores.devices.thermalPrinters[0].configuration.printWidth}-${stores.devices.thermalPrinters[0].configuration.font}-${stores.devices.thermalPrinters[0].configuration.resolution}-${key}`
		] = value;
	}

	getFromCache(key) {
		return cache[
			`${stores.devices.thermalPrinters[0].configuration.printWidth}-${stores.devices.thermalPrinters[0].configuration.font}-${stores.devices.thermalPrinters[0].configuration.resolution}-${key}`
		];
	}

	async getData(forceFixed) {
		const finalThermalTemporary = document.getElementById('thermal-temporary');
		const thermalTemporary = document.createElement('div');
		finalThermalTemporary.appendChild(thermalTemporary);
		const dpi = stores.devices.thermalPrinters[0].configuration.resolution;
		const dpmm = dpi / 25.4;
		const maxPrintArea =
			stores.devices.thermalPrinters[0].configuration.printWidth === '58'
				? 48
				: 72;
		const qrWidth =
			stores.devices.thermalPrinters[0].configuration.printWidth === '58'
				? 48
				: 50;

		const receiptWidth = Math.round(maxPrintArea * dpmm);
		thermalTemporary.style.width = `${receiptWidth}px`;

		let cachedLogo = this.getFromCache('logo');
		if (!cachedLogo && stores.company.logoFile) {
			const element = document.createElement('img');
			// row.image is base64 string, so we need to convert it to data uri
			element.src = stores.company.logoFile.urls['256x256'];
			await new Promise((resolve) => {
				element.onload = () => {
					const canvas = document.createElement('canvas');
					canvas.width = element.width;
					canvas.height = element.height;
					const ctx = canvas.getContext('2d');
					ctx.drawImage(element, 0, 0);
					autoCropCanvas(canvas, ctx);
					element.src = canvas.toDataURL();
					resolve(true);
				};
			});

			element.className = 'print-logo';
			await new Promise((resolve) => {
				element.onload = resolve;
			});
			const dither = new DitherJS({
				step: 1,
				pallete: [
					[0, 0, 0],
					[255, 255, 255],
				],
				algorithm: 'diffusion',
			});
			thermalTemporary.appendChild(element);
			dither.dither(element);

			const newElement = thermalTemporary.querySelector('.print-logo');
			newElement.style.display = 'block';
			newElement.style.margin = '0 auto 10px auto';
			this.addToCache('logo', newElement);
			cachedLogo = newElement;
		}

		if (
			stores.devices.thermalPrinters[0].configuration.fontType === 'fixed' ||
			forceFixed
		) {
			const elements = [];
			if (
				stores.devices.thermalPrinters[0].configuration.printLogo &&
				stores.company.logoFile
			) {
				elements.push(cachedLogo);
			}
			for (const row of this.rows) {
				if (row.type === 'qr') {
					const qrCanvas = new QRCodeCanvas(row.url, {
						level: 'L',
					});
					const dataUrlWithQRCode = qrCanvas.toDataUrl();
					const element = document.createElement('img');
					// row.image is base64 string, so we need to convert it to data uri
					element.src = dataUrlWithQRCode;
					element.style.width = `${dpmm * qrWidth}px`;
					element.style.height = `${dpmm * qrWidth}px`;
					elements.push(element);
				}
				if (row.type === 'text') {
					const element = document.createElement('span');
					element.style.display = 'inline-block';
					element.style.fontFamily =
						stores.devices.thermalPrinters[0].configuration.font ||
						'Roboto Mono';

					await waitForFontLoad(`10px ${element.style.fontFamily}`, 30000);
					if (row.size === TextSize.NORMAL || typeof row.size === 'undefined') {
						element.style.fontSize = '11px';
						// element.style.letterSpacing = '-1px';
						element.style.lineHeight = '12px';
						element.innerHTML = row.content
							.replace(/ /g, '\xA0')
							.replace(/\n/g, '<br>');
					} else if (row.size === TextSize.SMALL) {
						element.style.fontSize = '8px';
						// element.style.letterSpacing = '-1px';
						element.style.lineHeight = '9px';
						element.innerHTML = row.content
							.replace(/ /g, '\xA0')
							.replace(/\n/g, '<br>');
					} else {
						element.style.fontSize = '20px';
						element.style.lineHeight = '24px';
						element.innerHTML = row.content.trim();
					}
					element.style.textAlign = 'center';

					thermalTemporary.appendChild(element);

					let cachedWidth = this.getFromCache(element.style.fontSize);

					if (!cachedWidth) {
						this.addToCache(
							element.style.fontSize,
							element.getBoundingClientRect().width
						);
						cachedWidth = element.getBoundingClientRect().width;
					}
					console.log(element.getBoundingClientRect().width);
					console.log(cachedWidth, receiptWidth);
					if (cachedWidth < receiptWidth) {
						element.style.transform = `scale(${round(
							receiptWidth / cachedWidth,
							2
						)})`;
						element.style.transformOrigin = 'top center';
						element.style.paddingBottom = `${
							parseFloat(element.style.lineHeight) *
								(receiptWidth / cachedWidth) -
							parseFloat(element.style.lineHeight)
						}px`;
						element.style.display = 'block';
						// element.style.height = `calc(${element.style.lineHeight} * ${
						// 	(element.innerHTML.match(/<br>/g) || []).length
						// }`;
					}
					thermalTemporary.removeChild(element);
					elements.push(element);
				}
			}

			const chunks = chunk(elements, 50);
			thermalTemporary.innerHTML = '';
			const response = await Promise.all(
				chunks.map(async (chunk) => {
					const element = document.createElement('div');
					element.append(...chunk);
					thermalTemporary.appendChild(element);

					// const dataUrl = await htmlToImage.toJpeg(element, {
					// 	pixelRatio: 1,
					// 	backgroundColor: '#fff',
					// });

					const dataUrl = await htmlToCanvas(element, {
						// pixelRatio: 1,
						scale: 1,
						backgroundColor: '#fff',
					}).then((canvas) => {
						return canvas.toDataURL();
					});

					return {
						type: 'image',
						content: dataUrl,
					};
				})
			);
			finalThermalTemporary.removeChild(thermalTemporary);

			// const element = document.createElement('img');
			// // row.image is base64 string, so we need to convert it to data uri
			// element.src = response[0].content;
			// thermalTemporary.appendChild(element);
			return response;
		} else {
			const fontSize =
				stores.devices.thermalPrinters[0].configuration.printWidth === '58'
					? 16
					: 18;

			thermalTemporary.style.fontSize = `${fontSize}px`;
			thermalTemporary.style.fontFeatureSettings = 'normal';
			thermalTemporary.style.fontVariant = 'initial';
			thermalTemporary.style.fontFamily = `${stores.devices.thermalPrinters[0].configuration.variableFont}`;

			await waitForFontLoad(`10px ${thermalTemporary.style.fontFamily}`, 30000);

			const receiptElement = document.createElement('div');
			receiptElement.style.backgroundColor = '#fff';
			receiptElement.style.textAlign = 'center';

			const root = createRoot(receiptElement);
			root.render(this.component);

			await this.deferred;

			thermalTemporary.innerHTML = '';

			const element = document.createElement('div');
			element.innerHTML = receiptElement.innerHTML;

			if (
				stores.devices.thermalPrinters[0].configuration.printLogo &&
				stores.company.logoFile
			) {
				element.prepend(cachedLogo);
			}
			thermalTemporary.appendChild(element);

			const dataUrl = await htmlToCanvas(element, {
				// pixelRatio: 1,
				scale: 1,
				backgroundColor: '#fff',
			}).then((canvas) => {
				return canvas.toDataURL();
			});

			try {
				root.unmount();
			} catch (unmountError) {
				Bugsnag.notify(unmountError);
			}

			finalThermalTemporary.removeChild(thermalTemporary);

			return [
				{
					type: 'image',
					content: dataUrl,
				},
			];
		}
	}

	async print(forceFixed: false) {
		// await this.getData(forceFixed);
		print({ rows: await this.getData(forceFixed) });
	}
}
