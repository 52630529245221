import moment from 'moment';

import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';

export default CreatePage({
	module: 'settings',
	submodule: 'exchangeRates',
	model: 'exchangeRate',
	page: {
		shouldFetch: false,
		table: {
			listProp: 'active',
			showActions: false,
			columns: [
				{
					title: 'Datum',
					dataIndex: 'date',
					key: 'date',
					width: 200,
					render: (text) => moment(text).format('LL'),
				},
				{
					title: 'Valuta',
					dataIndex: 'currencyId',
					key: 'currencyId',
					width: 100,
				},
				{
					title: 'Naziv valute',
					dataIndex: ['currency', 'name'],
					key: 'currency.name',
				},
				{
					title: 'Srednji kurs',
					dataIndex: 'rate',
					key: 'rate',
					width: 150,
					render: (text) => `${numberFormat(text, false, 6, false)}`,
				},
			],
		},
	},
});
