import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Input, Button, Form, Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Link, Navigate } from 'react-router-dom';

import Logo from '../../components/Logo';
import { APPLICATION_NAME } from '../../constants/application';
import stores from '../../stores/index.mobx';
import styles from './Login.module.less';

const { Password } = Input;

function Login() {
	const {
		users: { login, isFetching, token },
	} = stores;

	const [form] = Form.useForm();
	const [loginError, setLoginError] = useState(null);

	async function handleSubmit({ email, password }) {
		try {
			await login(email, password);
		} catch (e) {
			setLoginError('E-Mail ili lozinka nisu ispravni');
		}
	}

	function onValuesChange() {
		setLoginError(null);
	}

	return (
		<DocumentTitle title={`${APPLICATION_NAME} - Prijavljivanje`}>
			<>
				<div className={styles.wrapper}>
					<div className={styles.logoFace}>
						{process.env.REACT_APP_APP_NAME === 'ibuddy' ? (
							<Logo />
						) : (
							<img src="/images/logo-face.svg" />
						)}
					</div>
					<div className={styles.container}>
						{token && <Navigate to="/app" />}
						<div className={styles.content}>
							<Form
								onFinish={handleSubmit}
								className={styles.form}
								form={form}
								onValuesChange={onValuesChange}
								validateTrigger="onSubmit"
								layout="vertical"
							>
								<Form.Item
									rules={[
										{ required: true, message: 'Unesite E-Mail!' },
										{
											type: 'email',
											message: 'E-Mail Adresa je neispravna!',
										},
									]}
									name="email"
									label="E-Mail"
									validateStatus={loginError ? 'error' : undefined}
								>
									<Input
										size="large"
										prefix={<MailOutlined />}
										placeholder="E-Mail"
									/>
								</Form.Item>
								<Form.Item
									name="password"
									rules={[{ required: true, message: 'Unesite lozinku!' }]}
									validateStatus={loginError ? 'error' : undefined}
									help={loginError}
									label="Lozinka"
								>
									<Password
										size="large"
										prefix={<LockOutlined />}
										type="password"
										placeholder="Lozinka"
									/>
								</Form.Item>
								<Form.Item>
									<Button
										size="large"
										loading={isFetching}
										type="primary"
										htmlType="submit"
										block
									>
										Prijavite se
									</Button>
								</Form.Item>
								<Divider>ili</Divider>
								<Form.Item>
									<Link to="/register">
										<Button size="large" type="link" block>
											Registrujte svoje preduzeće
										</Button>
									</Link>
								</Form.Item>
							</Form>
							<div className={styles.copyright}>
								Copyright © 2023 DVSoftware. Sva prava zadržana.
							</div>
						</div>
					</div>
				</div>
			</>
		</DocumentTitle>
	);
}

export default observer(Login);
