import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
	Avatar,
	Badge,
	Button,
	Calendar as AntCalendar,
	DatePicker,
	Grid,
	List,
	Space,
	Spin,
	Tag,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useImperativeHandle, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { StringParam, useQueryParam } from 'use-query-params';

import { addToDrawersRegistry, useDrawer } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import styles from './Calendar.module.less';
import PeriodicReport from './Drawers/PeriodicReport';
import ReceiptsDay from './Drawers/ReceiptsDay';

function Calendar(undefined, ref) {
	const screens = Grid.useBreakpoint();
	const { height } = useWindowSize();

	const [currentDate, setCurrentDate] = useQueryParam('date', StringParam);
	const [, openReceiptsDay, , , , ReceiptsDayDrawer] =
		useDrawer('receipts-day');
	const [, openPeriodicReport, , , , PeriodicReportDrawer] =
		useDrawer('periodic-report');

	const {
		dailyReceipts: { byDay, fetchAll, isFetching },
	} = stores;

	useEffect(() => {
		if (!currentDate) {
			setCurrentDate(moment().toISOString());
		} else {
			fetchAll(currentDate);
		}
	}, [currentDate]);

	const days = useMemo(() => {
		const tmp = [];
		const start = moment(currentDate).startOf('month');
		while (start.month() === moment(currentDate).month()) {
			const day = start.format('YYYY-MM-DD');
			const dailyReceipt = byDay[day];

			tmp.push({
				dailyReceipt,
				day: start.format('dddd, DD. MMMM'),
				amount: dailyReceipt?.summedAmount || 0,
				date: start.clone(),
			});
			start.add(1, 'day');
		}

		return tmp;
	}, [currentDate, byDay]);

	function onChange(date) {
		setCurrentDate(date.toISOString());
	}

	function onSelect(date) {
		const day = date.format('YYYY-MM-DD');
		const dailyReceipt = byDay[day];
		if (dailyReceipt) {
			openReceiptsDay(date.toISOString());
		}
	}

	function onPanelChange(date) {
		setCurrentDate(date.toISOString());
	}

	function dateCellRender(date) {
		const day = date.format('YYYY-MM-DD');
		const dailyReceipt = byDay[day];
		if (dailyReceipt) {
			return (
				<Badge
					status={
						moment().format('YYYY-MM-DD') === day ? 'processing' : 'success'
					}
					color="green"
					text={numberFormat(dailyReceipt.summedAmount, screens.xl, 2, true)}
				/>
			);
		}
		return (
			<Badge
				status="default"
				text={
					<span className={styles.noReceipts}>
						{numberFormat(0, screens.xl, 2, true)}
					</span>
				}
			/>
		);
	}

	useImperativeHandle(ref, () => ({
		renderHeader: () => (
			<Space>
				<Button
					onClick={() => {
						openPeriodicReport();
					}}
				>
					Periodični izveštaj
				</Button>
				<Button.Group>
					<Button
						icon={<LeftOutlined />}
						onClick={() => onChange(moment(currentDate).subtract(1, 'month'))}
					/>
					<DatePicker
						className={styles.datePicker}
						onChange={onChange}
						picker="month"
						format="MM/YYYY"
						style={{ width: 110 }}
						allowClear={false}
						value={moment(currentDate)}
					/>
					<Button
						icon={<RightOutlined />}
						onClick={() => onChange(moment(currentDate).add(1, 'month'))}
					/>
				</Button.Group>
			</Space>
		),
	}));

	return (
		<>
			<Spin spinning={isFetching}>
				{screens.lg && height > 470 && (
					<AntCalendar
						className={styles.calendar}
						value={moment(currentDate)}
						headerRender={() => null}
						dateCellRender={dateCellRender}
						onSelect={onSelect}
						onPanelChange={onPanelChange}
					/>
				)}
				{(((screens.xs || screens.sm) && !screens.lg) || height <= 470) && (
					<List
						className={styles.list}
						dataSource={days}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta
									avatar={item.day}
									description={
										<Button
											block
											onClick={() => {
												if (item.dailyReceipt) {
													openReceiptsDay(item.date.toISOString());
												}
											}}
											type="link"
											className={`${styles.listButton} ${
												item.amount === 0 && styles.noReceipts
											}`}
										>
											{numberFormat(item.amount, true, 2, true)}
										</Button>
									}
								/>
								<Button
									onClick={() => {
										if (item.dailyReceipt) {
											openReceiptsDay(item.date.toISOString());
										}
									}}
									type="link"
									className={item.amount === 0 && styles.noReceipts}
								>
									<RightOutlined />
								</Button>
							</List.Item>
						)}
					/>
				)}
			</Spin>
			<ReceiptsDayDrawer />
			<PeriodicReportDrawer />
		</>
	);
}

addToDrawersRegistry('receipts-day', ReceiptsDay);
addToDrawersRegistry('periodic-report', PeriodicReport);

export default {
	Page: observer(Calendar, { forwardRef: true }),
};
