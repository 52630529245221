import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	DeleteOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Table, Tag } from 'antd';
import reverse from 'lodash/reverse';
import { observer } from 'mobx-react-lite';

import numberFormat from '../../lib/numberFormat';
import stores from '../../stores/index.mobx';
import styles from './UpdatedProducts.module.less';

function UpdatedProducts() {
	const {
		products: { updatedProducts },
	} = stores;

	return (
		<>
			<div>Ažurirani su sledeći artikli:</div>
			<div className={styles.table}>
				<Table
					size="small"
					dataSource={reverse([...updatedProducts])}
					scroll={{ y: 300, x: 800 }}
					pagination={false}
				>
					<Table.Column title="Šifra" key="sku" dataIndex="sku" width={70} />
					<Table.Column
						title="Proizvod"
						key="name"
						render={(record) => {
							return (
								<>
									{record.name}&nbsp;&nbsp;&nbsp;
									{record.variantName ? (
										<Tag color="magenta">{record.variantName}</Tag>
									) : (
										''
									)}
								</>
							);
						}}
					/>

					<Table.Column
						title="Promena"
						key="type"
						width={130}
						render={(record) => {
							if (
								record.type === 'updated' &&
								record.oldPrice < record.newPrice
							) {
								return (
									<span style={{ color: 'red ' }}>
										<ArrowUpOutlined /> Povećanje cene
									</span>
								);
							}
							if (
								record.type === 'updated' &&
								record.oldPrice > record.newPrice
							) {
								return (
									<span style={{ color: 'green ' }}>
										<ArrowDownOutlined /> Smanjenje cene
									</span>
								);
							}
							if (record.type === 'new') {
								return (
									<span style={{ color: 'grey' }}>
										<PlusOutlined /> Novi proizvod
									</span>
								);
							}
							if (record.type === 'deleted') {
								return (
									<span style={{ color: 'grey ' }}>
										<DeleteOutlined /> Obrisan proizvod
									</span>
								);
							}
						}}
					/>
					<Table.Column
						title="Stara cena"
						key="price"
						width={130}
						render={(record) => {
							return record.oldPrice !== null ? (
								<span style={{ color: 'grey', textDecoration: 'line-through' }}>
									{numberFormat(
										record.oldPrice,
										true,
										2,
										true,
										record.oldCurrencyId || 'RSD'
									)}
								</span>
							) : (
								'-'
							);
						}}
					/>
					<Table.Column
						title="Cena"
						key="price"
						width={130}
						render={(record) => {
							return record.newPrice !== null
								? numberFormat(
										record.newPrice,
										true,
										2,
										true,
										record.newCurrencyId || 'RSD'
								  )
								: '-';
						}}
					/>
				</Table>
			</div>
		</>
	);
}

export default observer(UpdatedProducts);
