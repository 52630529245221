import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Dropdown,
	Grid,
	Menu,
	message,
	Space,
	Upload,
} from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { usePermissions } from '../../../../../../hooks/permissions';
import { v2Client } from '../../../../../../store/client';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Kpo.module.less';

interface Props {
	createButtonHandler: () => void;
}

export default function CustomCreateButton({ createButtonHandler }: Props) {
	const [isUploading, setIsUploading] = useState(false);
	const canCreatePermission = usePermissions('documents', 'kpos', 'create');
	const [currentDate, setCurrentDate] = useQueryParam('date', StringParam);

	useEffect(() => {
		if (!currentDate) {
			setCurrentDate(moment().toISOString());
		}
	}, [currentDate]);

	function onChange(date) {
		setCurrentDate(date.toISOString());
	}

	const menu = useMemo(() => {
		return (
			<Menu
				onClick={(item) => {
					if (item.key === '2') {
						window.open(
							v2Client.defaults.baseURL +
								'/products/export?token=' +
								v2Client.defaults.headers.common['x-access-token']
						);
					}
				}}
				items={[
					{
						label: (
							<Upload
								disabled={isUploading}
								name="file"
								action={v2Client.defaults.baseURL + '/kpos/import/pausal'}
								headers={{
									'x-access-token':
										v2Client.defaults.headers.common['x-access-token'],
								}}
								accept=".pdf"
								showUploadList={false}
								onChange={(info) => {
									if (info.file.status !== 'uploading') {
										setIsUploading(true);
									}
									if (info.file.status === 'done') {
										message.success(`Uvoz KPO knjige je završen.`);
										setIsUploading(false);
									} else if (info.file.status === 'error') {
										message.error(
											`Uvoz KPO knjige nije uspešan, proverite da li je fajl u ispravnom formatu`
										);
										setIsUploading(false);
									}
								}}
							>
								Uvoz sa Pausal.rs
							</Upload>
						),
						key: '1',
					},
					// {
					// 	label: (
					// 		<Upload
					// 			disabled={isUploading}
					// 			name="file"
					// 			action={
					// 				v2Client.defaults.baseURL + '/documents/kpos/import/csv'
					// 			}
					// 			headers={{
					// 				'x-access-token':
					// 					v2Client.defaults.headers.common['x-access-token'],
					// 			}}
					// 			accept=".csv"
					// 			showUploadList={false}
					// 			onChange={(info) => {
					// 				if (info.file.status !== 'uploading') {
					// 					setIsUploading(true);
					// 				}
					// 				if (info.file.status === 'done') {
					// 					message.success(`Uvoz KPO knjige je završen.`);
					// 					setIsUploading(false);
					// 				} else if (info.file.status === 'error') {
					// 					message.error(
					// 						`Uvoz KPO knjige nije uspešan, proverite da li je fajl u ispravnom formatu`
					// 					);
					// 					setIsUploading(false);
					// 				}
					// 			}}
					// 		>
					// 			Uvoz iz CSV-a
					// 		</Upload>
					// 	),
					// 	key: '2',
					// },
				]}
			/>
		);
	}, []);

	const screens = Grid.useBreakpoint();

	// return canCreatePermission ? (
	// 	<Dropdown.Button
	// 		loading={isUploading}
	// 		disabled={isUploading}
	// 		icon={<DownOutlined />}
	// 		overlay={menu}
	// 		type="primary"
	// 		onClick={createButtonHandler}
	// 	>
	// 		{screens.xs ? 'Dodaj' : 'Dodaj artikal'}
	// 	</Dropdown.Button>
	// ) : null;

	return (
		<Space>
			{/* <Button
				onClick={() => {
					//
				}}
			>
				Preuzmi
			</Button> */}
			<Button.Group>
				<Button
					icon={<LeftOutlined />}
					onClick={() => onChange(moment(currentDate).subtract(1, 'year'))}
				/>
				<DatePicker
					className={styles.datePicker}
					onChange={onChange}
					picker="year"
					format="YYYY"
					style={{ width: 80 }}
					allowClear={false}
					value={moment(currentDate)}
				/>
				<Button
					icon={<RightOutlined />}
					onClick={() => onChange(moment(currentDate).add(1, 'year'))}
				/>
			</Button.Group>
			{canCreatePermission && (
				<Dropdown.Button
					loading={isUploading}
					disabled={isUploading}
					icon={<DownOutlined />}
					overlay={menu}
					type="primary"
					onClick={createButtonHandler}
				>
					{screens.xs ? 'Dodaj' : 'Dodaj stavku'}
				</Dropdown.Button>
			)}
		</Space>
	);
}
