import { DatePicker, Form, Modal, Spin } from 'antd';
import type { Moment } from 'moment';
import { useState } from 'react';

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: (startDate: Moment) => Promise<void>;
}

export default function FlatRateTaxChangeModal({
	visible,
	onCancel,
	onOk,
}: Props) {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	async function submitForm() {
		try {
			const values = await form.validateFields();
			setIsLoading(true);
			await onOk(values.startDate);
		} catch (error) {
			return;
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal
			centered
			visible={visible}
			title="Kreiranje KPO knjige"
			closable={false}
			onCancel={onCancel}
			onOk={() => {
				form.submit();
			}}
			confirmLoading={isLoading}
		>
			<Spin spinning={isLoading}>
				<Form form={form} onFinish={submitForm} layout="vertical">
					<>
						<p>
							Da li želite da kreirate KPO knjigu od do sada kreiranih
							dokumenata (fiskalnih računa i faktura)? Ova akcija može potrajati
							nekoliko minuta.
						</p>
						<Form.Item
							name="startDate"
							label="Datum početka paušalnog oporezivanja"
							rules={[
								{
									required: true,
									message:
										'Morate odabrati datum početka paušalnog oporezivanja',
								},
							]}
						>
							<DatePicker format={'L'} style={{ width: '100%' }} />
						</Form.Item>
					</>
				</Form>
			</Spin>
		</Modal>
	);
}
