import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { Category } from '../../../../../stores/Category.mobx';
import { Product } from '../../../../../stores/Product.mobx';
import styles from '../../POS.module.less';
import CategoryView from './CategoryView';

interface Props {
	height?: number;
	width?: number;
	selectProduct?: (product: Product) => void;
	openSearchDrawer?: () => void;
}

function CategoryPicker({
	height,
	width,
	selectProduct,
	openSearchDrawer,
}: Props) {
	const [categoryStack, setCategoryStack] = useState<Category[]>([null]);

	const push = useCallback(
		(category: Category) => {
			setCategoryStack((stack) => [...stack, category]);
		},
		[categoryStack, setCategoryStack]
	);
	const pop = useCallback(() => {
		setCategoryStack((stack) => stack.slice(0, stack.length - 1));
	}, [categoryStack, setCategoryStack]);

	return (
		<div className={styles.categoryPicker}>
			{categoryStack.map((category, index) => (
				<CategoryView
					key={category}
					category={category}
					height={height | 0}
					width={width | 0}
					// zIndex={index}
					push={push}
					pop={pop}
					selectProduct={selectProduct}
					openSearchDrawer={openSearchDrawer}
				/>
			))}
		</div>
	);
}

export default observer(CategoryPicker);
