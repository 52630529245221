import { Select, Row, Col, Input } from 'antd';
import { useState, useEffect } from 'react';

interface Props {
	value?: string;
	onChange?: (string) => void;
	readOnly?: boolean;
	disabled?: boolean;
}

const buyerTypes = [
	{
		label: 'Lica koja obavljaju delatnost',
		options: [
			{
				value: 10,
				label: 'PIB kupca',
			},
			{
				value: 11,
				label: 'JMBG',
			},
			{
				value: 12,
				label: 'PIB i JBKJS kupca',
			},
		],
	},
	{
		label: 'Fizička lica',
		options: [
			{
				value: 20,
				label: 'Broj lične karte',
			},
			{
				value: 21,
				label: 'Broj izbegličke legitimacije',
			},
			{
				value: 22,
				label: 'EBS',
			},
			{
				value: 23,
				label: 'Broj pasoša',
			},
		],
	},
	{
		label: 'Strana lica',
		options: [
			{
				value: 30,
				label: 'Broj pasoša',
			},
			{
				value: 31,
				label: 'Broj diplomatske legitimacije/LK)',
			},
			{
				value: 32,
				label: 'Broj lične karte MKD',
			},
			{
				value: 33,
				label: 'Broj lične karte MNE',
			},
			{
				value: 34,
				label: 'Broj lične karte ALB',
			},
			{
				value: 35,
				label: 'Broj lične karte BIH',
			},
		],
	},
	{
		label: 'Strana pravna lica',
		options: [
			{
				value: 40,
				label: 'Poreski ID iz strane države (TIN)',
			},
		],
	},
];

function BuyerInput({
	value,
	onChange,
	readOnly = false,
	disabled = false,
}: Props) {
	const [initialType, ...initialId] = value ? value.split(':') : ['', ''];

	const [type, setType] = useState(initialType);
	const [id, setId] = useState(initialId.join(':'));

	useEffect(() => {
		const [t, ...i] = value ? value.split(':') : ['', ''];
		setType(t);
		setId(i.join(':'));
	}, [value]);

	useEffect(() => {
		const newValue = type ? `${type}:${id}` : ':';
		if (newValue === value) {
			return;
		}
		if (newValue === ':') {
			onChange(null);
		} else {
			onChange(newValue);
		}
	}, [type, id]);

	return (
		<Row gutter={2}>
			<Col span={10}>
				<Select
					dropdownMatchSelectWidth={false}
					value={type ? Number(type) : undefined}
					onChange={(value) => {
						setType(value);
					}}
					disabled={readOnly || disabled}
				>
					<Select.Option value="">&nbsp;</Select.Option>
					{buyerTypes.map((type) => (
						<Select.OptGroup label={type.label} key={type.label}>
							{type.options.map((option) => (
								<Select.Option value={option.value} key={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select.OptGroup>
					))}
				</Select>
			</Col>
			<Col span={14}>
				<Input
					style={{ width: '100%' }}
					value={id}
					onChange={(event) => {
						setId(event.target.value);
					}}
					disabled={readOnly || disabled}
				/>
			</Col>
		</Row>
	);
}
export default BuyerInput;
