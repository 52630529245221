import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Drawer, Empty, Input, Table, Tag } from 'antd';
import clamp from 'lodash/clamp';
import { observer } from 'mobx-react-lite';
import { useState, useRef, useEffect } from 'react';
import { useKey, useWindowSize } from 'react-use';
import { useVT } from 'virtualizedtableforantd4';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import styles from './Search.module.less';
const Search = () => {
	const inputRef = useRef(null);
	const [inputText, setInputText] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [visualIndicatorPos, setVisualIndicatorPos] = useState(0);
	const [data, setData] = useState([]);
	const { height } = useWindowSize();

	const [queryParam, , close, visible, emit] = useDrawer('product-search');

	const {
		products: { search },
	} = stores;

	const tableHeight = (height - 49 - 30 - 40) | 0;
	const maxVisualIndicatorPos = (tableHeight / 36 - 1) | 0;

	const [vt, , vtRef] = useVT(
		() => ({ scroll: { y: tableHeight } }),
		[tableHeight]
	);

	useKey(
		'ArrowUp',
		() => {
			if (!visible) return;

			const newSelectedIndex =
				selectedIndex > 0 ? selectedIndex - 1 : data.length - 1;
			const newVisualIndicatorPos = clamp(
				newSelectedIndex === data.length - 1
					? maxVisualIndicatorPos
					: visualIndicatorPos - 1,
				0,
				maxVisualIndicatorPos
			);

			if (visualIndicatorPos === 0 && data.length > maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(newSelectedIndex);
			}

			setSelectedIndex(newSelectedIndex);
			setVisualIndicatorPos(newVisualIndicatorPos);
		},
		undefined,
		[selectedIndex, data.length, visualIndicatorPos, visible]
	);

	useKey(
		'ArrowDown',
		() => {
			if (!visible) return;

			const newSelectedIndex =
				selectedIndex < data.length - 1 ? selectedIndex + 1 : 0;

			const newVisualIndicatorPos = clamp(
				newSelectedIndex === 0 ? 0 : visualIndicatorPos + 1,
				0,
				maxVisualIndicatorPos
			);

			if (newVisualIndicatorPos === maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(newSelectedIndex - maxVisualIndicatorPos);
			}
			if (newVisualIndicatorPos === 0 && data.length > maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(0);
			}

			setSelectedIndex(newSelectedIndex);
			setVisualIndicatorPos(newVisualIndicatorPos);
		},
		undefined,
		[selectedIndex, data.length, visualIndicatorPos, visible]
	);

	useKey(
		'Enter',
		() => {
			if (!visible) return;
			emit('select', data[selectedIndex], queryParam);
			close();
		},
		undefined,
		[visible, selectedIndex, data]
	);

	useEffect(() => {
		if (!visible) {
			setSelectedIndex(0);
			setVisualIndicatorPos(0);
		} else {
			setTimeout(() => {
				inputRef?.current?.input?.focus();
				inputRef?.current?.input?.select();
			}, 250);
		}
	}, [visible]);

	const columns = [
		{
			title: '',
			width: '3px',
			render: (text, record, index) =>
				index === selectedIndex ? (
					<div className={styles.selectedProduct} />
				) : (
					<div />
				),
		},
		{
			title: 'Foto',
			dataIndex: 'coverImage',
			key: 'coverImage',
			width: 50,
			render: (image) => {
				return image ? (
					<>
						<img
							src={image.urls['64x64']}
							alt="Slika"
							className={styles.image}
						/>
					</>
				) : null;
			},
		},
		{
			title: 'Šifra',
			dataIndex: 'sku',
			width: 80,
		},
		{
			title: 'Šif. proizv.',
			dataIndex: 'manufacturerSku',
			width: 120,
			responsive: ['md'],
		},
		{
			title: 'Naziv',
			render: (record) => {
				return (
					<>
						{record.name}&nbsp;&nbsp;&nbsp;
						{record.variantName ? (
							<Tag color="magenta">{record.variantName}</Tag>
						) : (
							''
						)}
					</>
				);
			},
		},
		{
			title: 'Cena',
			key: 'price',
			dataIndex: 'price',
			width: 120,
			render: (text) => `${numberFormat(text, true)}`,
		},
		{
			title: 'Bar kod',
			key: 'ean',
			dataIndex: 'ean',
			width: 160,
			responsive: ['md'],
		},
	];

	function handleInputKeyDown(event) {
		if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
			event.preventDefault();
		}
	}

	return (
		<Drawer
			// destroyOnClose
			visible={visible}
			title="Pretraga proizvoda"
			width={1200}
			className="noPadding"
			onClose={close}
			// zIndex={zIndex}
		>
			<Input
				bordered={false}
				autoFocus
				suffix={<SearchOutlined />}
				ref={inputRef}
				value={inputText}
				placeholder="Unesite naziv, šifru ili bar kod"
				onChange={({ target: { value } }) => {
					setInputText(value);
					const searchResults = search(value).reduce(
						(acc, { item, matches }: any) => {
							// TODO only show variant matches
							if (item.hasVariants) {
								const variantMatches = matches.filter((match) =>
									match.key.startsWith('variants')
								);
								if (variantMatches.length === 0) {
									acc.push(
										...item.variants.map((variant) => ({
											...variant,
											name: item.name,
											coverImage: item.coverImage,
											categories: item.categories,
											product: item,
											variant,
											priceWithoutTaxInCurrency:
												variant.priceWithoutTaxInCurrency.bind(variant),
										}))
									);
								} else {
									item.variants.forEach((variant) => {
										const variantMatch = variantMatches.find(
											(match) =>
												`${variant[match.key.split('.')?.[1]]}` === match.value
										);
										if (variantMatch) {
											acc.push({
												...variant,
												name: item.name,
												coverImage: item.coverImage,
												categories: item.categories,
												product: item,
												variant,
												priceWithoutTaxInCurrency:
													variant.priceWithoutTaxInCurrency.bind(variant),
											});
										}
									});
								}
							} else {
								acc.push({
									...item,
									product: item,
									variant: null,
									priceWithoutTaxInCurrency:
										item.priceWithoutTaxInCurrency.bind(item),
								});
							}

							return acc;
						},
						[]
					);

					setData(searchResults);
					setSelectedIndex(0);
				}}
				onKeyDown={handleInputKeyDown}
			/>
			<ConfigProvider
				renderEmpty={() =>
					inputText ? (
						<Empty
							image={
								<img
									src="/images/icons/search-no-results.png"
									alt="Nema rezultata"
								/>
							}
							description="Nema rezultata. Unesite drugi termin za pretragu."
						/>
					) : (
						<Empty
							image={<img src="/images/icons/search.png" alt="Pretraga" />}
							description="Unesite termin za pretragu"
						/>
					)
				}
			>
				<Table
					size="small"
					columns={columns}
					dataSource={data}
					pagination={false}
					rowKey="id"
					scroll={{ y: tableHeight, x: 500 }}
					components={vt}
					onRow={(record) => ({
						onClick: () => {
							emit('select', record, queryParam);
							close();
						},
					})}
				/>
			</ConfigProvider>
		</Drawer>
	);
};

const ObservedSearch = observer(Search);

addToDrawersRegistry('products-search', ObservedSearch);

export default ObservedSearch;
