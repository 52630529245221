import { Input } from 'antd';

import CategorySelect from '../../../../components/CategorySelect';
import { CreatePage } from '../../../../components/Page';

export default CreatePage({
	module: 'catalog',
	submodule: 'categories',
	model: 'category',
	view: {
		useEdit: true,
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje kategorije',
			existing: (entity) => `Izmena kategorije ${entity?.name || ''}`,
		},
		shouldFetch: false,
		beforeSave: (values) => {
			return {
				...values,
				categoryId:
					values.categoryId === 'root' ? undefined : values.categoryId,
			};
		},
		fields: [
			{
				key: 'row1',
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						rules: [{ required: true, message: 'Naziv je obavezan' }],
						component: <Input />,
						span: 24,
					},
				],
			},
			{
				key: 'row2',
				fields: [
					{
						key: 'description',
						label: 'Opis',
						rules: [],
						component: <Input.TextArea />,
						span: 24,
					},
				],
			},
			{
				key: 'row3',
				fields: [
					{
						key: 'categoryId',
						label: 'Otac',
						rules: [],
						component: <CategorySelect showRoot defaultValue={'root'} />,
						span: 24,
					},
				],
			},
		],
	},
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: true,
				},
				{
					title: 'Opis',
					dataIndex: 'description',
					key: 'description',
					responsive: ['sm'],
				},
			],
			listProp: 'tree',
		},
		createButtonText: 'Dodaj kategoriju',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovu kategoriju?',
	},
});
