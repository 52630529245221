import axios, { AxiosInstance } from 'axios';

import config from '../config.json';

export const defaultClient: AxiosInstance = axios.create({
	baseURL: config.api,
	responseType: 'json',
});

export const v2Client: AxiosInstance = axios.create({
	baseURL: config.apiV2,
	responseType: 'json',
});

export const ecrServiceClient: AxiosInstance = axios.create({
	baseURL: config.ecrservice,
	responseType: 'json',
});

export default {};
