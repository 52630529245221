import { Table } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';

import {
	TransactionType,
	TRANSACTION_TYPE_MAP,
	PAYMENT_TYPE_TEXT,
	PAYMENT_TYPE_FROM_STRING,
	PaymentType,
	PAYMENT_TYPE_MAP,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import styles from '../../List.module.less';

function ProductsList({ record }) {
	function calculateTotalTax() {
		return round(
			record.taxItems.reduce((acc, curr) => acc + Number(curr.amount), 0),
			2
		);
	}

	function calculateAdvanceTax(totalTax, advancePayments, totalAmount) {
		return round((totalTax * advancePayments) / totalAmount, 2);
	}

	const totalTax = calculateTotalTax();
	const advanceTax = calculateAdvanceTax(
		totalTax,
		record.advanceData?.advancePayments || 0,
		record.totalAmount
	);

	if (!record) {
		return null;
	}

	const cashPayment = record.payment.find(
		(payment) => payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CASH]
	);

	return (
		<Table
			className={styles.table}
			dataSource={[...record.receiptItems]}
			pagination={false}
			size="small"
			bordered
			scroll={{ x: 600 }}
			summary={() => (
				<>
					<Table.Summary.Row>
						<Table.Summary.Cell index={0} colSpan={4} align="right">
							<strong>
								{record.transactionType ===
								TRANSACTION_TYPE_MAP[TransactionType.SALE]
									? 'Ukupan iznos računa'
									: 'Ukupan iznos refundacije'}
								:
							</strong>
						</Table.Summary.Cell>
						<Table.Summary.Cell index={1} align="right">
							{numberFormat(record?.totalAmount, true, 2, true)}
						</Table.Summary.Cell>
					</Table.Summary.Row>
					{!!record.advanceData?.advancePayments && (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={4} align="right">
									<strong>Uplaćeno avansom</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} align="right">
									{numberFormat(
										record.advanceData?.advancePayments,
										true,
										2,
										true
									)}
								</Table.Summary.Cell>
							</Table.Summary.Row>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={4} align="right">
									<strong>PDV na avans</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} align="right">
									{numberFormat(advanceTax, true, 2, true)}
								</Table.Summary.Cell>
							</Table.Summary.Row>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={4} align="right">
									<strong>Preostalo za plaćanje</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} align="right">
									{numberFormat(
										record?.totalAmount - record?.advanceData?.advancePayments,
										true,
										2,
										true
									)}
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					)}
					{record.payment.map((item, index) => (
						<Table.Summary.Row key={index}>
							<Table.Summary.Cell index={0} colSpan={4} align="right">
								<strong>
									{
										PAYMENT_TYPE_TEXT[
											PAYMENT_TYPE_FROM_STRING[item.paymentType]
										]
									}
									:
								</strong>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={1} align="right">
								{numberFormat(
									item.amount -
										(PAYMENT_TYPE_FROM_STRING[item.paymentType] ===
										PaymentType.CASH
											? record?.paymentChange || 0
											: 0),
									true,
									2,
									true
								)}
							</Table.Summary.Cell>
						</Table.Summary.Row>
					))}
					{record.transactionType ===
						TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
						(cashPayment?.amount || 0) > 0 && (
							<>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0} colSpan={4} align="right">
										<strong>Plaćeno u gotovini:</strong>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={1} align="right">
										{numberFormat(cashPayment.amount, true, 2, true)}
									</Table.Summary.Cell>
								</Table.Summary.Row>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0} colSpan={4} align="right">
										<strong>Povraćaj:</strong>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={1} align="right">
										{numberFormat(record?.paymentChange, true, 2, true)}
									</Table.Summary.Cell>
								</Table.Summary.Row>
							</>
						)}
				</>
			)}
		>
			<Table.Column key="name" dataIndex="name" title="Naziv" />
			<Table.Column
				key="taxLabels"
				title="Oznaka"
				render={(record) => record.taxLabels?.join(', ')}
				width={70}
				align="center"
			/>
			<Table.Column
				key="unitPrice"
				dataIndex="unitPrice"
				title="Cena"
				width={150}
				align="right"
				render={(text) => numberFormat(text, true, 2, true)}
			/>
			<Table.Column
				key="quantity"
				title="Količina"
				width={100}
				align="right"
				render={(record) =>
					`${numberFormat(record.quantity, false, 3, false)} ${
						record.unit || ''
					}`
				}
			/>
			<Table.Column
				key="totalAmount"
				dataIndex="totalAmount"
				title="Ukupno"
				width={150}
				align="right"
				render={(text) =>
					numberFormat(
						record.transactionType ===
							TRANSACTION_TYPE_MAP[TransactionType.SALE]
							? text
							: -text,
						true,
						2,
						true
					)
				}
			/>
		</Table>
	);
}

export default observer(ProductsList);
