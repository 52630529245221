import { WarningOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useRef } from 'react';

import { TableInput } from '../../../../../../components/TableInput';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import GlovoCategorySelect from './Components/Glovo/CategorySelect';
import MisterDCategorySelect from './Components/MisterD/CategorySelect';
import WoltCategorySelect from './Components/Wolt/CategorySelect';
import styles from './SaleChannelsInput.module.less';

type Props = {
	id?: string;
	isVariant?: boolean;
	productForm?: FormInstance;
};

function SaleChannelsInput({ id, isVariant, productForm, ...props }: Props) {
	const tableInputRef = useRef(null);
	const parentForm = Form.useFormInstance();

	const currencyIdWatch = Form.useWatch('currencyId', parentForm);

	const formFields = useCallback(
		(record, form, setFields) => {
			return [
				{
					key: 'basicInfo',
					label: 'Osnovne informacije',
					fields: [
						{
							key: 'saleChannelId',
							label: 'Kanal prodaje',
							component: (
								<Select
									options={stores.saleChannels.all.map((channel) => ({
										label: channel.name,
										value: channel.id,
									}))}
									disabled={form.getFieldValue('id')}
								/>
							),
							sm: 12,
							xs: 24,
							rules: [{ required: true, message: 'Izaberite kanal prodaje' }],
						},
						{
							key: 'storeId',
							label: 'Prodajno mesto',
							initialValue: stores.stores.currentStoreId,
							component: (
								<Select
									options={stores.stores.all.map((store) => ({
										label: store.name,
										value: store.id,
									}))}
									disabled={form.getFieldValue('id')}
								/>
							),
							sm: 12,
							xs: 24,
							rules: [{ required: true, message: 'Izaberite prodajno mesto' }],
						},
						{
							key: 'name',
							label: 'Naziv',
							component: <Input />,
							span: 24,
							rules: [{ required: true, message: 'Unesite naziv' }],
						},
						{
							key: 'description',
							label: 'Opis',
							component: <Input.TextArea />,
							span: 24,
						},
						{
							key: 'saleQuantity',
							label: 'Prodajna količina',
							component: <InputNumber style={{ width: '100%' }} />,
							md: 12,
							xs: 24,
							rules: [{ required: true, message: 'Unesite prodajnu količinu' }],
						},
						{
							key: 'salePrice',
							label: 'Prodajna cena',
							component: <InputNumber style={{ width: '100%' }} />,
							md: 12,
							xs: 24,
							rules: currencyIdWatch &&
								currencyIdWatch !== 'RSD' && [
									{ required: true, message: 'Unesite prodajnu cenu' },
								],
						},
					],
				},
				...(stores.marketplaceApplications.installedByApplicationId?.[
					stores.saleChannels.byId[form.getFieldValue('saleChannelId')]
						?.applicationId
				]?.packageId === 'rs.storebuddy.wolt'
					? [
							{
								key: 'woltInfo',
								label: 'Wolt informacije',
								fields: [
									{
										key: ['metadata', 'category'],
										label: 'Kategorija',
										component: <WoltCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
				...(stores.marketplaceApplications.installedByApplicationId?.[
					stores.saleChannels.byId[form.getFieldValue('saleChannelId')]
						?.applicationId
				]?.packageId === 'rs.storebuddy.glovo'
					? [
							{
								key: 'glovoInfo',
								label: 'Glovo informacije',
								fields: [
									{
										key: ['metadata', 'category'],
										label: 'Kategorija',
										component: <GlovoCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
				...(stores.marketplaceApplications.installedByApplicationId?.[
					stores.saleChannels.byId[form.getFieldValue('saleChannelId')]
						?.applicationId
				]?.packageId === 'rs.storebuddy.misterd'
					? [
							{
								key: 'misterdInfo',
								label: 'Mister D informacije',
								fields: [
									{
										key: ['metadata', 'category'],
										label: 'Kategorija',
										component: <MisterDCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
			];
		},
		[currencyIdWatch]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Kanal prodaje',
				key: 'saleChannelId',
				editable: false,
				render: (value, record) => (
					<>
						{stores.saleChannels.byId[record.saleChannelId]?.name ||
							'Nepoznat kanal prodaje'}
						{!record.externalId &&
							stores.marketplaceApplications.installedByApplicationId?.[
								stores.saleChannels.byId[record.saleChannelId]?.applicationId
							]?.packageId === 'rs.storebuddy.misterd' && (
								<Tooltip
									title={
										<>
											Artikal nije povezan sa platformom Mister D. Preuzmite
											fajl sa izmenama iz podešavanja aplikacije i pošaljite ga
											na e-mail adresu{' '}
											<a href="mailto:kontakt@misterd.rs">kontakt@misterd.rs</a>
											. Kada dobijete potvrdu da su artikli ubačeni, pokrenite
											povezivanje artikala iz podešavanja aplikacije.
										</>
									}
									className={styles.warning}
								>
									<WarningOutlined />
								</Tooltip>
							)}
					</>
				),
			},
			{
				title: 'Prodajno mesto',
				key: 'storeId',
				editable: false,
				render: (value, record) =>
					stores.stores.byId[record.storeId]?.name ||
					'Nepoznato prodajno mesto',
			},
			{
				title: 'Cena',
				key: 'salePrice',
				dataIndex: 'salePrice',
				editable: false,
				render: (value, record) =>
					record.salePrice
						? numberFormat(record.salePrice, true, 2, true, 'RSD')
						: 'Prati cenu artikla',
			},
		],
		[parentForm]
	);

	const nameWatch = Form.useWatch('name', isVariant ? productForm : parentForm);
	const variantNameWatch = Form.useWatch('variantName', parentForm);
	const subtitleWatch = Form.useWatch(
		'subtitle',
		isVariant ? productForm : parentForm
	);

	const newRowValue = useMemo(
		() => ({
			name: isVariant ? `${nameWatch} ${variantNameWatch}` : nameWatch,
			description: subtitleWatch,
			salePrice: null,
			saleQuantity: 1,
		}),
		[isVariant, nameWatch, variantNameWatch, subtitleWatch]
	);

	return (
		<TableInput
			ref={tableInputRef}
			isResponsive={false}
			addButtonText="Dodaj kanal prodaje"
			emptyText="Nema dodatnih kanala prodaje"
			iconPath="/images/icons/sale-channels.png"
			newRowValue={newRowValue}
			columns={columns}
			editInDrawer
			editDrawerTitle={(item) =>
				item?.id ? `Izmena kanala prodaje` : 'Dodavanje kanala prodaje'
			}
			formFields={formFields}
			id={id}
			editDrawerWidth={500}
			{...props}
		/>
	);
}

export default observer(SaleChannelsInput);
