import { Button, Form, Input, Modal } from 'antd';
import { useCallback, useEffect, useRef } from 'react';

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: (item: any, quantity: number) => void;
	item: any;
}

function EnterQuantityModal({ visible, onClose, onSubmit, item }: Props) {
	const [form] = Form.useForm();

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			onSubmit(item, Number(values.quantity));
			onClose();
		});
	}, [form, item]);
	const focusedInput = useRef(false);

	useEffect(() => {
		if (visible) {
			form.resetFields();
			focusedInput.current = false;
		}
	}, [visible]);

	return (
		<Modal
			centered
			visible={visible}
			title="Unesite količinu"
			onCancel={onClose}
			width={500}
			destroyOnClose
			footer={
				<Button type="primary" onClick={submit}>
					Potvrdi
				</Button>
			}
		>
			<Form
				form={form}
				preserve={false}
				validateTrigger="onFinish"
				layout="vertical"
			>
				<Form.Item
					style={{ marginBottom: 0 }}
					name="quantity"
					label="Količina"
					initialValue={1}
					rules={[
						{
							required: true,
							message: 'Unesite količinu',
						},
						{
							type: 'number',
							validator: async (rule, value) => {
								if (value < 0.001 || value > 99999999999999) {
									throw new Error(
										'Količina mora biti između 0,001 i 99.999.999.999.999,00'
									);
								}
							},
							message:
								'Količina mora biti između 0,001 i 99.999.999.999.999,000',
						},
					]}
				>
					<Input
						type={'number'}
						onPressEnter={(event) => {
							submit();
							event.preventDefault();
						}}
						ref={(input) => {
							setTimeout(() => {
								if (input && !focusedInput.current && visible) {
									(input as any).input.select();
									focusedInput.current = true;
								}
							});
						}}
					></Input>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default EnterQuantityModal;
