import React from 'react';

interface Props {
	children: React.ReactNode;
}

export function Title({ children }: Props) {
	return (
		<div className="ant-descriptions-header">
			<div className="ant-descriptions-title">{children}</div>
		</div>
	);
}
