import {
	LogoutOutlined,
	SettingOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Card, Space } from 'antd';
import Avatar from 'boring-avatars';
import complementaryColors from 'complementary-colors';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

import { rgbToHex } from '../../lib/colors';
import SelectStoreDropdown from '../../modules/Application/POS/Components/SelectStoreDropdown';
import stores from '../../stores/index.mobx';
import { LoadingReference } from '../../stores/transformers/Reference';
import { useDrawer } from '../Page';
import './style.less';

const color = new complementaryColors(
	process.env.REACT_APP_APP_NAME === 'ibuddy' ? '#f49c11' : '#7E89C8'
);
const colors = color.splitComplementary().map((c) => rgbToHex(c.r, c.g, c.b));
function UserMenu() {
	const {
		users: { authenticatedUser, logout },
	} = stores;

	const location = useLocation();

	const [, open, , , , EditUserDrawer] = useDrawer('edit/settings/users');

	if (!authenticatedUser || authenticatedUser instanceof LoadingReference) {
		return null;
	}

	const { initials, fullName, email } = authenticatedUser;

	const userMenu = (
		<Menu className="erp-userMenu-dropdown">
			<Card bordered={false}>
				<Card.Meta
					avatar={
						<div className="avatarWrapper big">
							<Avatar
								size={48}
								name={fullName}
								title={true}
								variant="marble"
								colors={colors}
							></Avatar>
							<span>{initials}</span>
						</div>
					}
					title={fullName}
					description={email}
				/>
			</Card>
			<Menu.Divider />
			<Space className="store-dropdown">
				<>Prodajno mesto:</>
				<SelectStoreDropdown />
			</Space>
			<Menu.Divider />
			<Menu.Item
				key="profile"
				onClick={() => {
					open(authenticatedUser.id);
				}}
			>
				<UserOutlined /> Profil
			</Menu.Item>
			<Menu.Item key="settings">
				<Link to="/app/settings">
					<SettingOutlined /> Podešavanja
				</Link>
			</Menu.Item>
			<Menu.Item key="logout" onClick={logout}>
				<LogoutOutlined /> Odjavi se
			</Menu.Item>
		</Menu>
	);

	return (
		<span className="erp-userMenu">
			<Dropdown overlay={userMenu} trigger={['click']} placement="bottomRight">
				<Link to="/app/user/profile" className="clickArea">
					<div className="avatarWrapper">
						<Avatar
							size={32}
							name={fullName}
							title={true}
							variant="marble"
							colors={colors}
						></Avatar>
						<span>{initials}</span>
					</div>
				</Link>
			</Dropdown>
			{location.pathname !== '/app/settings/organization/users' && (
				<EditUserDrawer />
			)}
		</span>
	);
}

export default observer(UserMenu);
