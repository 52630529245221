import { ArrowRightOutlined } from '@ant-design/icons';
import Bugsnag from '@bugsnag/browser';
import {
	Alert,
	Button,
	Form,
	Input,
	message,
	Modal,
	notification,
	Progress,
	Select,
	Skeleton,
	Space,
	Spin,
	Typography,
} from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import papaparse from 'papaparse';
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { Title } from '../../../../../../../../components/Title';
import config from '../../../../../../../../config.json';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';
import { MisterdItem } from './types';

type Venue = {
	id: number;
	name: string;
	address: string;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsMisterd({ storeId }, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const [username, setUsername] = useState('');

	const [areItemsLoading, setAreItemsLoading] = useState(false);
	const [items, setItems] = useState<MisterdItem[]>([]);

	const [areChangesLoading, setAreChangesLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isConnectProductsVisible, setIsConnectProductsVisible] =
		useState(false);

	const [areVenuesLoading, setAreVenuesLoading] = useState(false);
	const [venues, setVenues] = useState<Venue[] | null>(null);
	const [form] = Form.useForm();

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setUsername(response.data.credentials?.username);
					setIsLoggedIn(!!response.data.settings?.loggedIn);
					form.setFieldValue('venueId', response.data.settings?.venueId);
					// setMenuId(response.data.settings?.menuId);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, storeId, form]);

	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	useEffect(() => {
		if (isLoggedIn) {
			setAreVenuesLoading(true);
			v2Client
				.get(`/marketplace-applications/${id}/functions/get-venues`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setVenues(response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setAreVenuesLoading(false);
				});
		}
	}, [isLoggedIn, storeId]);

	const showConnectProductsDrawer = useCallback(async (alwaysOpen = true) => {
		setAreItemsLoading(true);
		try {
			const itemsResponse = await v2Client.get(
				`/marketplace-applications/${id}/functions/get-items`
			);

			// setItems(sortBy(itemsResponse.data, 'name.0.value'));
			setItems(sortBy(itemsResponse.data, 'name'));
			if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
				setIsConnectProductsVisible(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAreItemsLoading(false);
		}
	}, []);

	const handleConnectProductsButtonClick = useCallback(() => {
		showConnectProductsDrawer(true);
	}, [showConnectProductsDrawer]);

	const handleDownloadChangesButtonClick = useCallback(() => {
		setAreChangesLoading(true);
		v2Client
			.get(`/marketplace-applications/${id}/functions/get-changes`)
			.then((response) => {
				const { data } = response;
				if (!data.length) {
					return notification.info({
						message: 'Nema promena',
						description: 'Nema dodatih ili izmenjenih artikala',
					});
				}
				const csv = papaparse.unparse(
					data.map((item) => {
						const changes = [];
						if (!item.externalId) {
							changes.push('Novi proizvod');
						} else {
							if (item.previousName) {
								changes.push('Naziv');
							}
							if (typeof item.groupName !== 'undefined') {
								changes.push('Kategorija');
							}
							if (item.description) {
								changes.push('Opis');
							}
							if (item.price) {
								changes.push('Cena');
							}
							if (item.imageId) {
								changes.push('Fotografija');
							}
						}

						return {
							Naziv: item.name,
							'Stari naziv': item.previousName,
							Kategorija: item.groupName,
							Opis: item.description,
							Cena: item.price,
							Fotografija: item.imageId
								? `${v2Client.defaults.baseURL}/files/${item.imageId}?size=800x450`
								: '',
							Izmene: changes.join(', '),
						};
					})
				);
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				// download csv
				const link = document.createElement('a');
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute(
					'download',
					`misterd-promene-${moment().toISOString()}.csv`
				);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.log(error);
				notification.error({
					message: 'Greška',
					description: 'Došlo je do greške prilikom preuzimanja promena',
				});
				Bugsnag.notify(error);
			})
			.finally(() => {
				setAreChangesLoading(false);
			});
	}, []);

	const handleDeleteButtonClick = useCallback(() => {
		setIsDeleteLoading(true);
		v2Client
			.post(`/marketplace-applications/${id}/functions/delete-changes`, {})
			.then((response) => {
				notification.success({
					message: 'Brisanje je uspešno',
					description: 'Fajl sa promenama je obrisan',
				});
			})
			.catch((error) => {
				console.log(error);
				notification.error({
					message: 'Brisanje nije uspelo',
					description:
						'Došlo je do greške prilikom brisanja fajla sa promenama',
				});
				Bugsnag.notify(error);
			})
			.finally(() => {
				setIsDeleteLoading(false);
			});
	}, []);

	const handleSubmit = useCallback(async () => {
		const values = await form.validateFields();
		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {
						venueId: values.venueId,
					},
					credentials: {
						username: values.username,
						password: values.password,
					},
				}
			);

			setIsLoggedIn(!!response.data.settings?.loggedIn);

			notification.success({
				message: 'Podešavanja su uspešno sačuvana',
			});
		} catch (error) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVALID_CREDENTIALS
			) {
				return message.error({
					content: 'Korisničko ime ili lozinka nisu ispravni',
				});
			}
			message.error({
				content: 'Došlo je do greške prilikom čuvanja podešavanja',
			});
		} finally {
			setLoading(false);
		}
	}, [form, id, venues]);

	const closeConnectProductsDrawer = useCallback(() => {
		setIsConnectProductsVisible(false);
	}, [setIsConnectProductsVisible]);

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name,
			description: item.product?.description || item.description,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
			active: item.active,
			metadata: {
				...(item.metadata || {}),
				groupId: item.metadata?.groupId || item.groupId,
			},
			imageUrl: item.imageUrl,
			price: item.displayPrice,
			product: item.product,
		}),
		[]
	);

	return (
		<Spin spinning={loading || areVenuesLoading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>Kredencijali</Title>
				<DisablePasswordAutocomplete />
				{!isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<>
										Koristite kredencijale za pristup Mister D vlasničkom
										portalu
									</>
								}
							/>
						</Form.Item>

						<Form.Item
							name="username"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Obavezan je unos e-maila imena',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="password"
							label="Lozinka"
							rules={[
								{
									required: true,
									message: 'Obavezan je unos lozinke',
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</>
				)}
				{isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<>
										Prijavljeni ste kao korisnik <strong>{username}</strong>.{' '}
										<Typography.Link onClick={() => setIsLoggedIn(false)}>
											Kliknite ovde
										</Typography.Link>{' '}
										da promenite korisnika.
									</>
								}
							/>
						</Form.Item>
						<Title>Objekat</Title>
						{areVenuesLoading && <Skeleton />}
						{!areVenuesLoading && (
							<Form.Item name="venueId">
								<Select placeholder="Izaberite objekat">
									{venues?.map((venue) => (
										<Select.Option key={venue.id} value={venue.id}>
											{venue.name}
											<br />
											<small>{venue.address}</small>
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						)}
						<Title>Administracija</Title>
						<Form.Item label="Povezivanje artikala">
							<Typography.Text type="secondary">
								Ukoliko već imate artikle na platformi Mister D, možete ih
								povezati sa artiklima na vašem prodajnom mestu.
							</Typography.Text>
							<br />
							<br />
							<Button
								loading={areItemsLoading}
								onClick={handleConnectProductsButtonClick}
							>
								Poveži artikle
							</Button>
						</Form.Item>
						<Form.Item label="Preuzimanje izmena">
							<Typography.Text type="secondary">
								Platforma Mister D trenutno ne omogućava automatsko ažuriranje
								artikala, već je potrebno ručno poslati fajl sa izmenama na
								e-mail adresu{' '}
								<a href="mailto:kontakt@misterd.rs">kontakt@misterd.rs</a>.
								Nakon što preuzmete fajl sa izmenama, kliknite na dugme{' '}
								<strong>Obriși izmene</strong>, kako bi se beležile samo nove
								izmene.
							</Typography.Text>
							<br />
							<br />
							<Space>
								<Button
									loading={areChangesLoading}
									disabled={areChangesLoading}
									onClick={handleDownloadChangesButtonClick}
								>
									Preuzmi izmene
								</Button>
								<Button
									danger
									loading={isDeleteLoading}
									disabled={isDeleteLoading}
									onClick={handleDeleteButtonClick}
								>
									Obriši izmene
								</Button>
							</Space>
						</Form.Item>
					</>
				)}

				<input type="submit" style={{ display: 'none' }} />
			</Form>
			<ConnectProductsDrawer
				visible={isConnectProductsVisible}
				items={items}
				onClose={closeConnectProductsDrawer}
				formatItem={formatItem}
				categoryProperty="groupId"
				enabledProperty="active"
				platformName="Mister D"
			/>
		</Spin>
	);
}

export default observer(ApplicationSettingsMisterd, { forwardRef: true });
