import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import Line from '@ant-design/plots/es/components/line';
import {
	Button,
	Card,
	Col,
	Divider,
	Empty,
	Grid,
	message,
	Row,
	Spin,
	Statistic,
	Table,
	Tag,
	Typography,
} from 'antd';
import orderBy from 'lodash/orderBy';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useImperativeHandle, useMemo } from 'react';
import { useMedia, useUpdateEffect } from 'react-use';

import { useDrawer } from '../../../components/Page';
import {
	PAYMENT_TYPE_FROM_STRING,
	PAYMENT_TYPE_TEXT,
	TRANSACTION_TYPE_FROM_STRING,
	TRANSACTION_TYPE_TEXT,
} from '../../../constants/journal';
import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';
import styles from './Home.module.less';

function Home(undefined, ref) {
	const isDarkMode = useMedia('(prefers-color-scheme: dark)');
	const screens = Grid.useBreakpoint();
	const [, openReceipt, , , , ReceiptViewDrawer] =
		useDrawer('view/receipts/list');
	const [, openProduct, , , , ProductEditDrawer] = useDrawer(
		'edit/catalog/products'
	);
	const [, openReceiptsDay, , , , ReceiptsDayDrawer] =
		useDrawer('receipts-day');

	const {
		dailyReceipts: { byDay, fetchAllChart, fetchAll, isFetching, chartData },
		products: { getById },
	} = stores;

	const singleDay = useMemo(() => {
		return !isFetching ? byDay?.[moment().format('YYYY-MM-DD')] : null;
	}, [byDay, isFetching]);

	useEffect(() => {
		fetchAllChart(moment().toISOString()).then(() => {
			fetchAll(moment().toISOString());
		});
	}, []);

	useUpdateEffect(() => {
		if (singleDay) {
			singleDay?.fetchDetails?.();
		}
	}, [singleDay]);

	useImperativeHandle(ref, () => ({
		renderHeader: () => null,
	}));
	const sales = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.sales || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const salesSum = useMemo(
		() => sales.reduce((sum, [, value]) => sum + value, 0),
		[sales]
	);

	const refunds = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.refunds || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const refundsSum = useMemo(
		() => refunds.reduce((sum, [, value]) => sum + value, 0),
		[refunds]
	);

	const totals = useMemo(
		() =>
			Object.keys(PAYMENT_TYPE_FROM_STRING).filter(
				(key) =>
					singleDay?.details?.stats?.sales?.[key] ||
					0 > 0 ||
					singleDay?.details?.stats?.refunds?.[key] ||
					0 > 0
			),
		[singleDay?.details]
	);

	const totalSum = useMemo(() => salesSum - refundsSum, [salesSum, refundsSum]);

	const lastTenReceipts = useMemo(() => {
		return orderBy(
			singleDay?.details?.receipts || [],
			(receipt) => moment(receipt.sdcTime).unix(),
			'desc'
		).slice(0, 10);
	}, [singleDay?.details?.receipts]);

	const topItems = useMemo(() => {
		return orderBy(singleDay?.receiptItems || [], 'quantity', 'desc').slice(
			0,
			10
		);
	}, [singleDay?.receiptItems]);

	// TODO: add forecasting https://www.npmjs.com/package/holtwinters
	return (
		<>
			<Spin spinning={isFetching}>
				<div className={styles.wrapper}>
					<Row gutter={[8, 8]}>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title="Prodaje"
										value={numberFormat(salesSum, false, 2, true)}
										valueStyle={{ color: '#52c41a' }}
										style={{ marginBottom: sales.length ? 8 : 0 }}
										prefix={
											<Typography.Text type="success">
												<ArrowUpOutlined />
											</Typography.Text>
										}
									/>
									{sales.map(([key, amount]) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text type="secondary">
													{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider orientation="right" plain dashed>
													{numberFormat(amount, false, 2, true)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title="Refundacije"
										value={numberFormat(refundsSum, false, 2, true)}
										valueStyle={{ color: '#ff4d4f' }}
										style={{ marginBottom: refunds.length ? 8 : 0 }}
										prefix={
											<Typography.Text type="danger">
												<ArrowDownOutlined />
											</Typography.Text>
										}
									/>
									{refunds.map(([key, amount]) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text type="secondary">
													{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider orientation="right" plain dashed>
													{numberFormat(amount, false, 2, true)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title="Ukupno"
										value={numberFormat(totalSum, false, 2, true)}
										style={{ marginBottom: totals.length ? 8 : 0 }}
									/>
									{totals.map((key) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text type="secondary">
													{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider orientation="right" plain dashed>
													{numberFormat(
														(singleDay?.details?.stats?.sales?.[key] || 0) -
															(singleDay?.details?.stats?.refunds?.[key] || 0),
														false,
														2,
														true
													)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
					</Row>
					<Row gutter={8} className={styles.chart}>
						<Col span={24}>
							<Card title="Promet">
								<Line
									// smooth
									animation={false}
									theme={{
										styleSheet: {
											fontFamily: 'Montserrat',
										},
										defaultColor: '#7e89c8',
										components: isDarkMode
											? {
													tooltip: {
														marker: {
															stroke: '#131516',
														},
														crosshairs: {
															line: {
																style: {
																	stroke: '#303030',
																},
															},
														},
													},
													axis: {
														common: {
															tickLine: {
																style: {
																	stroke: '#303030',
																},
															},
															line: {
																style: {
																	stroke: '#303030',
																},
															},
														},
													},
													slider: {
														common: {
															textStyle: {
																fill: '#fff',
																opacity: 0.45,
															},
															backgroundStyle: {
																fill: '#7e89c8',
																opacity: 0.05,
															},
														},
													},
											  }
											: {
													slider: {
														common: {
															backgroundStyle: {
																fill: '#7e89c8',
																opacity: 0.05,
															},
														},
													},
											  },
									}}
									className={'home-chart'}
									color={['#F38CA0', '#FFBA93', '#7e89c8']}
									lineStyle={({ type }) => {
										if (type === 'Tekući mesec') {
											return {
												stroke: isDarkMode
													? 'l(90) 0:#7e89c8 1:#2f386e'
													: 'l(90) 0:#7e89c8 1:#cbd0e9',
												lineWidth: 2,
											};
										}
										if (type === 'Prethodni mesec') {
											return {
												stroke: 'l(90) 0:#FFBA93 1:#FFD2B8',
												lineWidth: 2,
												lineDash: [4, 4],
												strokeOpacity: isDarkMode ? 0.2 : 0.5,
											};
										}

										return {
											stroke: 'l(90) 0:#F38CA0 1:#F9B4C2',
											lineWidth: 2,
											lineDash: [4, 4],
											strokeOpacity: isDarkMode ? 0.2 : 0.5,
										};
									}}
									point={{
										size: 3,
										shape: 'point',
										style: ({ type }) => {
											if (type === 'Tekući mesec') {
												return {
													fill: '#7e89c8',
													stroke: isDarkMode ? '#131516' : '#ffffff',
													lineWidth: 2,
												};
											}
											if (type === 'Prethodni mesec') {
												return {
													fill: '#FFBA93',
													fillOpacity: isDarkMode ? 0.2 : 0.5,
													stroke: isDarkMode ? '#131516' : '#ffffff',
													lineWidth: 2,
												};
											}

											return {
												fill: '#F38CA0',
												fillOpacity: isDarkMode ? 0.2 : 0.5,
												stroke: isDarkMode ? '#131516' : '#ffffff',
												lineWidth: 2,
											};
										},
										state: {
											active: {
												animate: { duration: 200, easing: 'easeLinear' },
												style: {
													// size: 4,
													lineWidth: 2,
													stroke: isDarkMode ? '#131516' : '#ffffff',
												},
											},
										},
									}}
									padding={[
										32,
										32,
										(screens.sm && !screens.md) || screens.xs ? 64 : 24,
										128,
									]}
									height={200}
									xField="day"
									// smooth
									seriesField="type"
									yField="summedAmount"
									data={chartData.map((r) => ({
										...r,
										day: moment(r.day)
											.set('month', moment().month())
											.set('year', moment().year())
											.format('YYYY-MM-DD'),
									}))}
									xAxis={{
										alias: 'Datum',
										// type: 'time',
										// min: moment().utc().startOf('month').toDate(),
										// max: moment().utc().endOf('month').toDate(),
										label: {
											formatter: (value) => moment(value).format('DD. MMM'),
										},
									}}
									yAxis={{
										grid: {
											line: {
												style: {
													stroke: isDarkMode ? '#303030' : '#f0f0f0',
												},
											},
										},
										label: {
											formatter: (value) => numberFormat(value, true, 2, true),
										},
									}}
									slider={
										(screens.sm && !screens.md) || screens.xs
											? { start: 0.75, end: 1 }
											: false
									}
									tooltip={{
										formatter: ({ summedAmount, type }) => ({
											name: type,
											value: numberFormat(summedAmount, true, 2, true),
										}),
										title: (value) => moment(value).format('DD. MMMM'),
									}}
								/>
							</Card>
						</Col>
					</Row>
					<Row gutter={[8, 8]} className={styles.chart}>
						<Col xl={12} xs={24}>
							<Card
								className={`${styles.card} ${
									lastTenReceipts.length > 0 && styles.tableCard
								} ${styles.receiptsCard}`}
								title="Poslednji današnji računi"
								extra={
									<>
										<Button
											type="primary"
											onClick={() => {
												openReceiptsDay(moment().toISOString());
											}}
											disabled={!lastTenReceipts.length}
										>
											Svi današnji računi
										</Button>
										<Button
											type="primary"
											className={styles.actionButton}
											block
											onClick={() => {
												openReceiptsDay(moment().toISOString());
											}}
											disabled={!lastTenReceipts.length}
										>
											Svi
										</Button>
									</>
								}
							>
								{lastTenReceipts.length > 0 && (
									<Table
										size="small"
										dataSource={lastTenReceipts}
										scroll={{ x: 800 }}
										columns={[
											{
												title: 'Broj računa',
												key: 'invoiceNumber',
												render: (record) => (
													<Button
														type="link"
														className={styles.columnLink}
														onClick={() => openReceipt(record.id)}
													>
														{record.invoiceNumber}
													</Button>
												),
											},
											{
												title: 'Vreme',
												key: 'sdcTime',
												render(record) {
													return moment(record.sdcTime).format('HH:mm:ss');
												},
												width: 90,
											},
											{
												title: 'Tip transakcije',
												key: 'transactionType',
												dataIndex: 'transactionType',
												render(text) {
													return TRANSACTION_TYPE_TEXT[
														TRANSACTION_TYPE_FROM_STRING[text]
													];
												},
												width: 140,
											},
											{
												title: 'Način plaćanja',
												key: 'paymentType',
												width: 200,
												responsive: ['xxl'],
												render(record) {
													return record.payment.map((payment) => (
														<Tag
															title={numberFormat(
																payment.amount,
																false,
																2,
																true
															)}
														>
															{
																PAYMENT_TYPE_TEXT[
																	PAYMENT_TYPE_FROM_STRING[payment.paymentType]
																]
															}
														</Tag>
													));
												},
											},
											{
												title: 'Iznos',
												dataIndex: 'totalAmount',
												key: 'totalAmount',
												render(text) {
													return numberFormat(text, true, 2, true);
												},
												width: 150,
											},
										]}
										pagination={false}
									/>
								)}
								{lastTenReceipts.length === 0 && (
									<Empty
										image={<img src="/images/icons/bill.png" alt="" />}
										description="Danas nema izdatih računa"
									/>
								)}
							</Card>
						</Col>
						<Col xl={12} xs={24}>
							<Card
								className={`${styles.card} ${
									topItems.length > 0 && styles.tableCard
								}  ${styles.receiptsCard}`}
								title="Današnji najprodavaniji artikli"
								extra={
									<>
										<Button
											type="primary"
											onClick={() => {
												openReceiptsDay(`${moment().toISOString()},items`);
											}}
											disabled={!topItems.length}
										>
											Svi prodati artikli
										</Button>
										<Button
											type="primary"
											className={styles.actionButton}
											block
											onClick={() => {
												openReceiptsDay(`${moment().toISOString()},items`);
											}}
											disabled={!topItems.length}
										>
											Svi
										</Button>
									</>
								}
							>
								{topItems.length > 0 && (
									<Table
										scroll={{ x: 800 }}
										size="small"
										dataSource={topItems}
										columns={[
											{
												title: 'Šifra',
												key: 'sku',
												render: (record) => (
													<Button
														type="link"
														className={styles.columnLink}
														onClick={() => {
															const product = getById(record.id);
															const parent = product.parentId
																? getById(product.parentId)
																: null;
															if (!product || product.deletedAt) {
																return message.error(
																	`Artikal ${record.name} je obrisan`
																);
															}
															if (parent && parent.deletedAt) {
																return message.error(
																	`Artikal ${record.name} je obrisan`
																);
															}
															openProduct(
																product.parentId ? product.parentId : record.id
															);
														}}
													>
														{record.sku}
													</Button>
												),
												width: 100,
											},
											{
												title: 'Naziv',
												key: 'name',
												render: (record) => (
													<Button
														type="link"
														className={styles.columnLink}
														onClick={() => {
															const product = getById(record.id);
															const parent = product.parentId
																? getById(product.parentId)
																: null;
															if (!product || product.deletedAt) {
																return message.error(
																	`Artikal ${record.name} je obrisan`
																);
															}
															if (parent && parent.deletedAt) {
																return message.error(
																	`Artikal ${record.name} je obrisan`
																);
															}
															openProduct(
																product.parentId ? product.parentId : record.id
															);
														}}
													>
														{record.name}
													</Button>
												),
											},
											{
												title: 'Količina',
												key: 'quantity',
												render(record) {
													return `${numberFormat(
														record.quantity,
														false,
														3,
														false
													)}${` ${record.unit || ''}`}`;
												},
												width: 100,
											},
											{
												title: 'Cena',
												key: 'unitPrice',
												dataIndex: 'unitPrice',
												render(text) {
													return numberFormat(text, true, 2, true);
												},
												width: 150,
											},
											{
												title: 'Vrednost',
												key: 'totalAmount',
												render(record) {
													return numberFormat(
														record.unitPrice * record.quantity,
														true,
														2,
														true
													);
												},
												width: 150,
											},
										]}
										pagination={false}
									/>
								)}
								{topItems.length === 0 && (
									<Empty
										image={<img src="/images/icons/stock.png" alt="" />}
										description="Danas nema prodatih artikala"
									/>
								)}
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
			<ReceiptViewDrawer />
			<ProductEditDrawer />
			<ReceiptsDayDrawer />
		</>
	);
}

export default {
	Page: observer(Home, { forwardRef: true }),
};
