import { Modal, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import stores from '../../../../../../stores/index.mobx';
import CassaScontoInput from '../CassaScontoInput';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

interface Props {
	visible: boolean;
	close: () => void;
}

function CassaScontoEditModal({ visible, close }: Props) {
	const {
		invoices: { single },
	} = stores;

	const [form] = Form.useForm();

	const submit = useCallback(() => {
		form.validateFields().then(async (values) => {
			await single.update(values);
			close();
		});
	}, [form, single, close]);

	return (
		<Modal
			centered
			width={600}
			title="Izmena iznosa za plaćanje u roku"
			visible={visible}
			destroyOnClose
			onOk={() => {
				submit();
			}}
			onCancel={() => {
				close();
			}}
		>
			{visible && (
				<>
					<Form
						{...formItemLayout}
						form={form}
						layout="vertical"
						preserve={false}
						initialValues={single}
						validateTrigger="onSubmit"
					>
						<Form.Item
							label="Iznosi za plaćanje u roku"
							required
							name={'cassaSconto'}
						>
							<CassaScontoInput />
						</Form.Item>
					</Form>
				</>
			)}
		</Modal>
	);
}

export default observer(CassaScontoEditModal);
