import { computed, makeObservable, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'warehouse',
	paginated: false,
	persistFields: ['all'],
});

class Warehouse extends Entity {
	@observable name?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Warehouses extends Store<Warehouse> {
	constructor() {
		super(Warehouse);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Warehouses, Warehouse };
