import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';

import { CreatePage, useDrawer } from '../../../../../../components/Page';
import styles from '../../Marketplace.module.less';
import ActionSettings from './Actions/Settings';

function SettingsDrawerWrapper() {
	const [, , , , , SettingsDrawer] = useDrawer(
		'marketplace-application-settings'
	);

	return <SettingsDrawer />;
}

export default CreatePage({
	module: 'settings',
	submodule: 'installed-applications',
	model: 'marketplaceApplications',
	view: {
		useEdit: true,
	},
	edit: {
		width: 800,
		title: {},
		fields: [],
	},
	page: {
		shouldFetch: false,
		table: {
			actions: [
				(record) => <ActionSettings record={record} />,
				(record) => (
					<Popconfirm
						placement="topRight"
						title="Da li ste sigurni da želite da deinstalirate ovu aplikaciju?"
						onConfirm={() => {
							record?.uninstall();
						}}
						okText="Da"
						cancelText="Ne"
					>
						<Button type="link" size="small" danger icon={<DeleteOutlined />} />
					</Popconfirm>
				),
			],
			columns: [
				{
					title: '',
					dataIndex: 'logoFile',
					key: 'logoFile',
					width: 50,
					render: (image) => {
						return image ? (
							<>
								<img
									src={image.urls['64x64']}
									alt="Logo"
									className={styles.image}
								/>
							</>
						) : (
							<img
								src={'/images/icons/interface.png'}
								alt="Logo"
								className={styles.image}
							/>
						);
					},
				},
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: false,
				},
				{
					title: 'Verzija',
					dataIndex: 'version',
					key: 'version',
					shouldLink: false,
					width: 100,
				},
				{
					title: 'Autor',
					dataIndex: ['publisher', 'name'],
					key: 'publisher',
					shouldLink: false,
					width: 400,
					ellipsis: true,
				},
			],
			listProp: 'installedApplications',
		},
		additionalContent: [<SettingsDrawerWrapper />],
	},
});
