import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { TAX_FEE_CATEGORY } from '../../../constants/documentInvoice';
import numberFormat from '../../../lib/numberFormat';
import { InvoiceItem } from '../../../stores/Invoice.mobx';
import styles from './InvoiceItemsView.module.less';

interface Props {
	value?: InvoiceItem[];
}

function InvoiceItemsView({ value }: Props) {
	if (!value) {
		return null;
	}

	return (
		<Table
			className={styles.table}
			dataSource={value}
			pagination={false}
			size="small"
			bordered
			scroll={{ x: 1100 }}
		>
			<Table.Column
				key="index"
				title="#"
				render={(value, record, index) => {
					return index + 1;
				}}
				width={60}
				align="right"
			/>
			<Table.Column
				key="name"
				dataIndex="name"
				title="Naziv"
				render={(text, record: InvoiceItem) => {
					return (
						<>
							<div>{text}</div>
							{record.description && record.description !== text && (
								<small>
									<Typography.Text type="secondary">
										{record.description}
									</Typography.Text>
								</small>
							)}
						</>
					);
				}}
			/>
			<Table.Column
				key="sellerItemIdentification"
				dataIndex="sellerItemIdentification"
				title="Šifra"
				width={120}
			/>
			<Table.Column
				key="quantity"
				title="Količina"
				width={100}
				align="right"
				render={(record) =>
					`${numberFormat(record.quantity, false, 3, false)} ${
						record.unit?.label || ''
					}`
				}
			/>
			<Table.Column
				key="unitPrice"
				dataIndex="unitPrice"
				title="Jedinična cena"
				width={150}
				align="right"
				render={(text, record: InvoiceItem) =>
					numberFormat(text, true, 2, true, record.currencyId)
				}
			/>
			<Table.Column
				key="discountPercentage"
				title="Popust"
				width={80}
				align="right"
				render={(record: InvoiceItem) =>
					record.allowances
						?.map(
							(allowance) =>
								`${numberFormat(allowance.percentage, false, 2, true)}%`
						)
						.join(', ') || '0%'
				}
			/>
			<Table.Column
				key="discountAmount"
				title="Iznos popusta"
				width={150}
				align="right"
				render={(record: InvoiceItem) =>
					numberFormat(
						record.allowances?.reduce(
							(acc, allowance) => acc + (allowance.amount || 0),
							0
						) || 0,
						true,
						2,
						true,
						record.currencyId
					)
				}
				responsive={['lg']}
			/>

			<Table.Column
				key="taxPercentage"
				dataIndex="taxPercentage"
				title="PDV"
				width={90}
				align="right"
				render={(text, record: InvoiceItem) => (
					<>
						{numberFormat(text, false, 2, true)}%
						{record.taxCode !== 'S' && (
							<>
								{' '}
								<Tooltip title={TAX_FEE_CATEGORY[record.taxCode]}>
									<InfoCircleOutlined />
								</Tooltip>
							</>
						)}
					</>
				)}
			/>

			<Table.Column
				key="totalAmount"
				dataIndex="totalAmount"
				title="Ukupno"
				width={170}
				align="right"
				render={(text, record: InvoiceItem) =>
					numberFormat(text, true, 2, true, record.currencyId)
				}
				fixed="right"
			/>
		</Table>
	);
}

export default observer(InvoiceItemsView);
