import { DollarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import HasPermission from '../../../../../../components/HasPermission';
import { Invoice } from '../../../../../../stores/Invoice.mobx';
import PayModal from '../PayModal';

interface Props {
	record: Invoice;
}

function PayButton({ record }: Props) {
	const [isModalVisible, setIsModalVisible] = useState(false);
	return (
		<HasPermission module="documents" submodule="invoices" action="pay">
			<Button
				type="link"
				key="payButton"
				size="small"
				icon={<DollarOutlined />}
				onClick={() => {
					setIsModalVisible(true);
				}}
			/>
			<PayModal
				close={() => setIsModalVisible(false)}
				visible={isModalVisible}
				id={record.id}
			/>
		</HasPermission>
	);
}

export default observer(PayButton);
