import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Logo.module.less';

type Props = {
	small?: boolean;
	color?: 'light' | 'dark';
	version?: string;
	showIcon?: boolean;
};

export default function Logo({
	small = false,
	color = 'light',
	version = null,
	showIcon = true,
}: Props) {
	return (
		<span
			className={`${styles.wrapper} ${small ? styles.small : ''} ${
				color === 'dark' ? `${styles.dark} logo-dark` : ''
			}`}
		>
			<Link to="/" className={`sider-logo`}>
				{process.env.REACT_APP_APP_NAME === 'ibuddy' ? (
					<>
						<img
							className={styles.iBuddyLogo}
							src={`/images/ibuddy/logo-${color}.png`}
							alt="logo"
						/>
						<img
							className={styles.iBuddyLogoSmall}
							src="/images/ibuddy/logo-icon.png"
							alt="logo"
						/>
					</>
				) : (
					<>
						{showIcon && (
							<img
								className={styles.logo}
								src="/images/newlogo.png"
								alt="Logo"
							/>
						)}
						<span className={styles.title}>StoreBuddy</span>
					</>
				)}
				{version && <span className={styles.version}>{version}</span>}
			</Link>
		</span>
	);
}
