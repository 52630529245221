export const ERROR_NOT_FOUND_TIN = 40401501;
export const ERROR_CONFLICT_DUPLICATE_TIN = 40901001;
export const ERROR_CONFLICT_DUPLICATE_EMAIL = 40902001;
export const ERROR_BAD_REQUEST_SKU_ALREADY_USED = 40003001;
export const ERROR_CONFLICT_DUPLICATE_RECEIPT = 40900001;
export const ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE = 40903001;
export const ERROR_CONFLICT_DUPLICATE_PARTNER_CODE = 40903002;
export const ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL = 40005002;
export const ERROR_BAD_REQUEST_PARTNER_MISSING_CODE = 40005001;
export const ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR = 40004005;
export const ERROR_BAD_REQUEST_INVALID_CREDENTIALS = 40090002;

export const SDC_VALIDATION_ERRORS_MAP = {
	'2310': 'Neispravna poreska oznaka',
	'2801': 'Vrednost polja je predugačka',
	'2802': 'Vrednost polja je prekratka',
	'2803': 'Dužina vrednosti polja nije ispravna',
	'2804': 'Vrednost polja je van očekivanog opsega',
	'2805': 'Polje sadrži neispravnu vrednost',
	'2806': 'Format datuma nije ispravan',
	'2807': 'Lista mora sadržati najmanje jedan element',
	'2808': 'Lista sadrži više elemenata nego što je dozvoljeno',
};

export const SDC_FIELD_NAMES_MAP = {
	dateAndTimeOfIssue: 'Uplaćeno na dan',
	invoiceType: 'Vrsta računa',
	transactionType: 'Vrsta transakcije',
	payment: 'Plaćanje',
	'payment.amount': 'iznos plaćanja',
	'payment.paymentType': 'način plaćanja',
	cashier: 'Kasir',
	buyerId: 'ID kupca',
	buyerCostCenterId: 'Opciono polje kupca',
	invoiceNumber: 'Broj računa',
	referentDocumentNumber: 'Referentni broj računa',
	referentDocumentDT: 'Datum referentnog računa',
	items: 'Stavke računa',
	'items.gtin': 'GTIN',
	'items.name': 'naziv',
	'items.quantity': 'količina',
	'items.labels': 'poreske oznake',
	'items.unitPrice': 'cena',
	'items.totalAmount': 'ukupan iznos',
	options: 'Opcije',
	'options.omitTextualRepresentation': 'isključivanje tekstualnog prikaza',
	'options.omitQRCodeGen': 'isključivanje generisanja QR koda',
};
