import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {
	Card,
	Col,
	Divider,
	Drawer,
	message,
	Row,
	Spin,
	Statistic,
	Table,
	Tabs,
	Tag,
	Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useSize, useUpdateEffect } from 'react-use';

import { useDrawer } from '../../../../../../components/Page';
import {
	PAYMENT_TYPE_FROM_STRING,
	PAYMENT_TYPE_TEXT,
	TRANSACTION_TYPE_FROM_STRING,
	TRANSACTION_TYPE_TEXT,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import styles from './ReceiptsDay.module.less';
const ReceiptsDay = () => {
	const [dayParams, open, close, visible] = useDrawer('receipts-day');
	const [, openReceipt, , , , ReceiptViewDrawer] =
		useDrawer('view/receipts/list');
	const [, openProduct, , , , ProductEditDrawer] = useDrawer(
		'edit/catalog/products'
	);
	const {
		dailyReceipts: { byDay, isFetching },
		products: { getById },
	} = stores;

	const [date, tab = 'receipts'] = useMemo(() => {
		return dayParams ? dayParams.split(',') : [];
	}, [dayParams]);

	const singleDay = useMemo(() => {
		if (!date) {
			return;
		}
		const formattedDate = moment(date).format('YYYY-MM-DD');

		return !isFetching ? byDay?.[formattedDate] : null;
	}, [byDay, date, isFetching]);

	useEffect(() => {
		const e = new Event('resize');
		window.dispatchEvent(e);
	}, [singleDay?.details?.receipts, singleDay?.receiptItems]);

	useUpdateEffect(() => {
		if (date && !isFetching) {
			singleDay?.fetchDetails?.();
		}
	}, [date, isFetching, singleDay]);

	const sales = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.sales || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const refunds = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.refunds || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const totals = useMemo(
		() =>
			Object.keys(PAYMENT_TYPE_FROM_STRING).filter(
				(key) =>
					singleDay?.details?.stats?.sales?.[key] ||
					0 > 0 ||
					singleDay?.details?.stats?.refunds?.[key] ||
					0 > 0
			),
		[singleDay?.details]
	);

	const salesSum = useMemo(
		() => sales.reduce((sum, [, value]) => sum + value, 0),
		[sales]
	);

	const refundsSum = useMemo(
		() => refunds.reduce((sum, [, value]) => sum + value, 0),
		[refunds]
	);

	const totalSum = useMemo(() => salesSum - refundsSum, [salesSum, refundsSum]);

	const [details, { height: detailsHeight }] = useSize(() => (
		<Row gutter={[8, 8]} style={{ backgroundColor: 'white' }}>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title="Prodaje"
							value={numberFormat(salesSum, false, 2, true)}
							valueStyle={{ color: '#52c41a' }}
							style={{ marginBottom: sales.length ? 8 : 0 }}
							prefix={
								<Typography.Text type="success">
									<ArrowUpOutlined />
								</Typography.Text>
							}
						/>
						{sales.map(([key, amount]) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text type="secondary">
										{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider orientation="right" plain dashed>
										{numberFormat(amount, false, 2, true)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title="Refundacije"
							value={numberFormat(refundsSum, false, 2, true)}
							valueStyle={{ color: '#ff4d4f' }}
							style={{ marginBottom: refunds.length ? 8 : 0 }}
							prefix={
								<Typography.Text type="danger">
									<ArrowDownOutlined />
								</Typography.Text>
							}
						/>
						{refunds.map(([key, amount]) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text type="secondary">
										{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider orientation="right" plain dashed>
										{numberFormat(amount, false, 2, true)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title="Ukupno"
							value={numberFormat(totalSum, false, 2, true)}
							style={{ marginBottom: totals.length ? 8 : 0 }}
						/>
						{totals.map((key) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text type="secondary">
										{PAYMENT_TYPE_TEXT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider orientation="right" plain dashed>
										{numberFormat(
											(singleDay?.details?.stats?.sales?.[key] || 0) -
												(singleDay?.details?.stats?.refunds?.[key] || 0),
											false,
											2,
											true
										)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
		</Row>
	));

	return (
		<Drawer
			visible={visible}
			width={900}
			title={`Pregled dana ${moment(date).format('DD.MM.YYYY')}`}
			onClose={close}
			// zIndex={zIndex}
			className={styles.drawer}
		>
			<Spin spinning={singleDay?.isFetchingDetails}>
				<>
					{details}
					<Tabs
						className={styles.tabs}
						activeKey={tab}
						onChange={(activeKey) => open(`${date},${activeKey}`)}
					>
						<Tabs.TabPane tab="Po računima" key="receipts">
							<Table
								size="small"
								dataSource={singleDay?.details?.receipts || []}
								columns={[
									{
										title: 'Broj računa',
										key: 'invoiceNumber',
										render: (record) => (
											<Typography.Link
												className={styles.columnLink}
												onClick={() => openReceipt(record.id)}
											>
												{record.invoiceNumber}
											</Typography.Link>
										),
									},
									{
										title: 'Vreme',
										key: 'sdcTime',
										render(record) {
											return moment(record.sdcTime).format('HH:mm:ss');
										},
										width: 90,
									},
									{
										title: 'Tip transakcije',
										key: 'transactionType',
										dataIndex: 'transactionType',
										render(text) {
											return TRANSACTION_TYPE_TEXT[
												TRANSACTION_TYPE_FROM_STRING[text]
											];
										},
										width: 120,
									},
									{
										title: 'Način plaćanja',
										key: 'paymentType',
										width: 200,
										render(record) {
											return record.payment.map((payment) => (
												<Tag
													title={numberFormat(payment.amount, false, 2, true)}
												>
													{
														PAYMENT_TYPE_TEXT[
															PAYMENT_TYPE_FROM_STRING[payment.paymentType]
														]
													}
												</Tag>
											));
										},
									},
									{
										title: 'Iznos',
										dataIndex: 'totalAmount',
										key: 'totalAmount',
										render(text) {
											return numberFormat(text, true, 2, true);
										},
										width: 150,
									},
								]}
								pagination={false}
								bordered
								sticky
								scroll={{ x: 630 }}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Po artiklima" key="items">
							<Table
								size="small"
								dataSource={(singleDay?.receiptItems || []) as any[]}
								columns={[
									{
										title: 'Šifra',
										key: 'sku',
										render: (record) => (
											<Typography.Link
												className={styles.columnLink}
												onClick={() => {
													const product = getById(record.id);
													if (!product || product.deletedAt) {
														return message.error(
															`Artikal ${record.name} je obrisan`
														);
													}
													const parent = product.parentId
														? getById(product.parentId)
														: null;
													if (parent && parent.deletedAt) {
														return message.error(
															`Artikal ${record.name} je obrisan`
														);
													}
													openProduct(
														product.parentId ? product.parentId : record.id
													);
												}}
											>
												{record.sku}
											</Typography.Link>
										),
										width: 100,
									},
									{
										title: 'Naziv',
										key: 'name',
										render: (record) => (
											<Typography.Link
												className={styles.columnLink}
												onClick={() => {
													const product = getById(record.id);
													if (!product || product.deletedAt) {
														return message.error(
															`Artikal ${record.name} je obrisan`
														);
													}
													const parent = product.parentId
														? getById(product.parentId)
														: null;
													if (parent && parent.deletedAt) {
														return message.error(
															`Artikal ${record.name} je obrisan`
														);
													}
													openProduct(
														product.parentId ? product.parentId : record.id
													);
												}}
											>
												{record.name}
											</Typography.Link>
										),
									},
									{
										title: 'Količina',
										key: 'quantity',
										render(record) {
											return `${numberFormat(
												record.quantity,
												false,
												3,
												false
											)}${` ${record.unit || ''}`}`;
										},
										width: 100,
									},
									{
										title: 'Cena',
										key: 'unitPrice',
										dataIndex: 'unitPrice',
										render(text) {
											return numberFormat(text, true, 2, true);
										},
										width: 150,
									},
									{
										title: 'Vrednost',
										key: 'totalAmount',
										render(record) {
											return numberFormat(
												record.unitPrice * record.quantity,
												true,
												2,
												true
											);
										},
										width: 150,
									},
								]}
								pagination={false}
								bordered
								sticky
								scroll={{ x: 800 }}
							/>
						</Tabs.TabPane>
					</Tabs>
				</>
			</Spin>
			<ReceiptViewDrawer />
			<ProductEditDrawer />
		</Drawer>
	);
};

export default observer(ReceiptsDay);
