export default function DisablePasswordAutocomplete() {
	return (
		<>
			<input
				type="text"
				name="email"
				style={{
					width: 0,
					height: 0,
					position: 'absolute',
					left: -9999,
					top: -9999,
				}}
			></input>
			<input
				type="text"
				name="username"
				style={{
					width: 0,
					height: 0,
					position: 'absolute',
					left: -9999,
					top: -9999,
				}}
			></input>
			<input
				type="password"
				style={{
					width: 0,
					height: 0,
					position: 'absolute',
					left: -9999,
					top: -9999,
				}}
			></input>
		</>
	);
}
