import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { MarketplaceApplication } from '../../../../../../stores/MarketplaceApplication.mobx';
import '../../Drawers/ApplicationSettings';
interface Props {
	application: MarketplaceApplication;
	visible?: boolean;
	close: () => void;
}

const InstallSuccessModal = ({ application, visible, close }: Props) => {
	const [, openApplicationSettings, , , , ApplicationSettings] = useDrawer(
		'marketplace-application-settings'
	);
	return (
		<>
			<Modal
				visible={visible}
				title={`Instaliranje aplikacije ${application?.name}`}
				okText="Otvori podešavanja"
				cancelText="Zatvori"
				onOk={() => {
					close();
					openApplicationSettings(application.id);
				}}
				onCancel={close}
			>
				<p>
					Aplikacija {application?.name} je uspešno instalirana
					<br />
				</p>
			</Modal>
			<ApplicationSettings />
		</>
	);
};

export default observer(InstallSuccessModal);
