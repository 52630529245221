import { Mod97_10 } from '@konfirm/iso7064';
import { Col, Row } from 'antd';
import { AsciiTable3, AlignmentEnum } from 'ascii-table3';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';

import { InvoiceType, TransactionType } from '../../constants/invoice';
import {
	INVOICE_TYPE_MAP,
	PaymentType,
	PAYMENT_TYPE_MAP,
	TRANSACTION_TYPE_MAP,
} from '../../constants/journal';
import numberFormat from '../../lib/numberFormat';
import { normalize } from '../../lib/referenceNoMod97';
import QR from '../../modules/Application/Receipts/List/Components/QR';
import mobxStores from '../../stores/index.mobx';
import styles from './A4Receipt.module.less';

export default function A4Receipt({
	invoiceRequest,
	invoiceResponse,
	locale,
	includeSignature,
	advancePayments,
	advanceItems,
	lastAdvanceSale,
	deferred,
	additionalText,
	unknownAmountAdvance,
}) {
	const { company, application, stores } = mobxStores;
	const { banks } = application;
	const { bankAccounts } = company;
	const { currentStore } = stores;

	function isFiscalInvoice(): boolean {
		return [InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
			invoiceRequest.invoiceType
		);
	}

	function getTitle(text: string, char = '=') {
		return AsciiTable3.align(AlignmentEnum.CENTER, ` ${text} `, 40, char);
	}

	function formatDate(date: string, dateOnly = false): string {
		if (dateOnly) {
			return moment(date).format('DD.MM.YYYY');
		}

		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}

	function getInvoiceType() {
		return `${INVOICE_TYPE_MAP[invoiceRequest.invoiceType]}${upperFirst(
			TRANSACTION_TYPE_MAP[invoiceRequest.transactionType]
		)}`;
	}

	function calculateTotalTax() {
		return round(
			invoiceResponse.taxItems.reduce(
				(acc, curr) => acc + Number(curr.amount),
				0
			),
			2
		);
	}

	function calculateTotalDiscount() {
		return round(
			invoiceRequest.items.reduce(
				(acc, curr) => acc + Number(curr.discount * curr.quantity),
				0
			),
			2
		);
	}

	function calculateAdvanceTax(totalTax, advancePayments, totalAmount) {
		return round((totalTax * advancePayments) / totalAmount, 2);
	}

	const totalTax = calculateTotalTax();
	const advanceTax = calculateAdvanceTax(
		totalTax,
		advancePayments || 0,
		invoiceResponse.totalAmount
	);

	const totalDiscount = calculateTotalDiscount();

	const cashPayment = invoiceRequest.payment.find(
		(payment) => payment.paymentType === PaymentType.CASH
	);

	return (
		<div className={`${styles.page} page`}>
			{isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					{getTitle(locale['fiscalInvoice'])}
				</h3>
			)}
			{!isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					{getTitle(locale['notFiscalReceipt'])}
				</h3>
			)}
			<Row gutter={16}>
				<Col span={16}>
					<strong>{locale['tin']}:</strong> {invoiceResponse.tin}
					<br />
					<strong>{locale['company']}:</strong> {invoiceResponse.businessName}
					<br />
					<strong>{locale['store']}:</strong> {invoiceResponse.locationName}
					<br />
					<strong>{locale['address']}:</strong> {invoiceResponse.address}
					<br />
					<strong>{locale['district']}:</strong> {invoiceResponse.district}
					<br />
					<strong>{locale['cashier']}:</strong> {invoiceRequest.cashier || ''}
					<br />
					{invoiceRequest.buyerId && (
						<>
							<strong>{locale['buyer']}:</strong> {invoiceRequest.buyerId}
							<br />
						</>
					)}
					{invoiceRequest.buyerCostCenterId && (
						<>
							<strong>{locale['buyerCostCenter']}:</strong>{' '}
							{invoiceRequest.buyerCostCenterId}
							<br />
						</>
					)}
					{invoiceRequest.invoiceNumber && (
						<>
							<strong>{locale['posNumber']}:</strong>{' '}
							{invoiceRequest.invoiceNumber}
							<br />
						</>
					)}
					{invoiceRequest.dateAndTimeOfIssue && (
						<>
							<strong>{locale['posTime']}:</strong>{' '}
							{formatDate(invoiceRequest.dateAndTimeOfIssue)}
							<br />
						</>
					)}
					{invoiceRequest.referentDocumentNumber && (
						<>
							<strong>{locale['refNo']}:</strong>{' '}
							{invoiceRequest.referentDocumentNumber}
							<br />
						</>
					)}
					{invoiceRequest.referentDocumentDT && (
						<>
							<strong>{locale['refDT']}:</strong>{' '}
							{formatDate(invoiceRequest.referentDocumentDT)}
							<br />
						</>
					)}
				</Col>
				<Col span={8} className={`${`${styles.tdRight} tdRight`} tdRight`}>
					<QR
						verificationUrl={invoiceResponse.verificationUrl}
						className={`${styles.qr} qr`}
						style={{}}
						deferred={deferred}
					/>
				</Col>
			</Row>
			<h3>{locale[getInvoiceType()]}</h3>

			<p>{locale['items']}</p>

			<table className={`${styles.items} items`}>
				<thead>
					<tr>
						<th width={50} className={`${styles.tdLeft} tdLeft`}>
							#
						</th>
						<th className={`${styles.tdLeft} tdLeft`}>{locale['itemName']}</th>
						<th width={90} className={`${styles.tdLeft} tdLeft`}>
							{locale['gtin']}
						</th>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['label']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['price']}
						</th>
						<th width={50} className={`${styles.tdRight} tdRight`}>
							{locale['quantity']}
						</th>
						<th width={50} className={`${styles.tdLeft} tdLeft`}>
							{locale['unit']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['itemTotal']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceRequest.items.map((item, index) => (
						<tr key={index}>
							<td>{index + 1}</td>
							<td>{item.name}</td>
							<td>{item.gtin}</td>
							<td>{item.labels.join(', ')}</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(item.unitPrice, false, 2, true)}
							</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(item.quantity, false, 3, false)}
							</td>
							<td>{item.unit ? item.unit : ''}</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(
									invoiceRequest.transactionType === TransactionType.SALE
										? item.totalAmount
										: -item.totalAmount,
									false,
									2,
									true
								)}
							</td>
						</tr>
					))}
					<tr>
						<td colSpan={7} className={`${styles.tdRight} tdRight`}>
							<strong>
								{invoiceRequest.transactionType == TransactionType.SALE
									? locale['totalPurchase']
									: locale['totalRefund']}
							</strong>
						</td>
						<td className={`${styles.tdRight} tdRight`}>
							{numberFormat(invoiceResponse.totalAmount, false, 2, true)}
						</td>
					</tr>
				</tbody>
			</table>

			<table className={`${styles.payment} payment`}>
				<tbody>
					{typeof advancePayments !== 'undefined' && (
						<>
							<tr>
								<td width={150} className={`${styles.tdRight} tdRight`}>
									<strong>{locale.advancePayments}</strong>
								</td>
								<td width={100} className={`${styles.tdRight} tdRight`}>
									{numberFormat(advancePayments, false, 2, true)}
								</td>
							</tr>
							<tr>
								<td width={150} className={`${styles.tdRight} tdRight`}>
									<strong>{locale.advanceTaxes}</strong>
								</td>
								<td width={100} className={`${styles.tdRight} tdRight`}>
									{numberFormat(advanceTax, false, 2, true)}
								</td>
							</tr>
						</>
					)}
					{invoiceRequest.payment.map((payment) => (
						<tr key={payment.paymentType}>
							<td width={150} className={`${styles.tdRight} tdRight`}>
								<strong>{locale[PAYMENT_TYPE_MAP[payment.paymentType]]}</strong>
							</td>
							<td width={100} className={`${styles.tdRight} tdRight`}>
								{numberFormat(payment.amount, false, 2, true)}
							</td>
						</tr>
					))}
					{typeof advancePayments !== 'undefined' && (
						<tr>
							<td width={150} className={`${styles.tdRight} tdRight`}>
								<strong>{locale.remaining}</strong>
							</td>
							<td width={100} className={`${styles.tdRight} tdRight`}>
								{numberFormat(0, false, 2, true)}
							</td>
						</tr>
					)}
					{invoiceRequest.transactionType === TransactionType.SALE &&
						(cashPayment?.amount || 0) > 0 && (
							<>
								<tr>
									<td width={150} className={`${styles.tdRight} tdRight`}>
										<strong>{locale['paidCash']}</strong>
									</td>
									<td width={100} className={`${styles.tdRight} tdRight`}>
										{numberFormat(
											Number(cashPayment.amount) +
												Number(invoiceRequest.paymentChange),
											false,
											2,
											true
										)}
									</td>
								</tr>
								<tr>
									<td width={150} className={`${styles.tdRight} tdRight`}>
										<strong>{locale['change']}</strong>
									</td>
									<td width={100} className={`${styles.tdRight} tdRight`}>
										{numberFormat(invoiceRequest.paymentChange, false, 2, true)}
									</td>
								</tr>
							</>
						)}
				</tbody>
			</table>

			{!isFiscalInvoice() && (
				<h2
					className={`${styles.title} ${styles.notFiscalReceipt} title notFiscalReceipt`}
				>
					{getTitle(locale['notFiscalInvoice'], ' ')}
				</h2>
			)}
			<br />
			<table className={`${styles.taxItems} taxItems`}>
				<thead>
					<tr>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['label']}
						</th>
						<th width={70} className={`${styles.tdLeft} tdLeft`}>
							{locale['taxName']}
						</th>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['rate']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['tax']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceResponse.taxItems.map((taxItem, index) => (
						<tr key={index}>
							<td>{taxItem.label}</td>
							<td>{taxItem.categoryName}</td>
							<td>{numberFormat(taxItem.rate, false, 2, true)}%</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(taxItem.amount, false, 2, true)}
							</td>
						</tr>
					))}
					<tr>
						<td className={`${styles.tdRight} tdRight`} colSpan={3}>
							<strong>{locale['totalTax']}</strong>
						</td>
						<td className={`${styles.tdRight} tdRight`}>
							{numberFormat(
								invoiceResponse.taxItems.reduce(
									(acc, curr) => acc + Number(curr.amount),
									0
								),
								false,
								2,
								true
							)}
						</td>
					</tr>
				</tbody>
			</table>

			<p>
				<strong>{locale['sdcTime']}:</strong>{' '}
				{formatDate(invoiceResponse.sdcDateTime)}
				<br />
				<strong>{locale['sdcInvoiceNo']}:</strong>{' '}
				{invoiceResponse.invoiceNumber}
				<br />
				<strong>{locale['invoiceCounter']}:</strong>{' '}
				{invoiceResponse.invoiceCounter}
				<br />
			</p>

			{includeSignature && (
				<p>
					<strong>
						{locale['signature']}: ________________________________
					</strong>
				</p>
			)}

			{isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					{getTitle(locale['endInvoice'])}
				</h3>
			)}
			{!isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					{getTitle(locale['notFiscalReceipt'])}
				</h3>
			)}

			{lastAdvanceSale && (
				<p>
					<strong>{locale['lastAdvance']}:</strong>{' '}
					{lastAdvanceSale.invoiceNumber}{' '}
					{formatDate(lastAdvanceSale.sdcTime, true)}
					<br />
				</p>
			)}

			{advanceItems && advanceItems.length > 0 && (
				<div>
					<p>{locale['advanceItems']}</p>

					<table className={`${styles.items} items`}>
						<thead>
							<tr>
								<th width={50} className={`${styles.tdLeft} tdLeft`}>
									#
								</th>
								<th className={`${styles.tdLeft} tdLeft`}>
									{locale['itemName']}
								</th>
								<th width={90} className={`${styles.tdLeft} tdLeft`}>
									{locale['gtin']}
								</th>
								<th width={40} className={`${styles.tdLeft} tdLeft`}>
									{locale['label']}
								</th>
								{!unknownAmountAdvance && (
									<th width={100} className={`${styles.tdRight} tdRight`}>
										{locale['price']}
									</th>
								)}
								<th width={50} className={`${styles.tdRight} tdRight`}>
									{locale['quantity']}
								</th>
								<th width={50} className={`${styles.tdLeft} tdLeft`}>
									{locale['unit']}
								</th>
								{!unknownAmountAdvance && (
									<th width={100} className={`${styles.tdRight} tdRight`}>
										{locale['itemTotal']}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{advanceItems.map((item, index) => (
								<tr key={index}>
									<td>{index + 1}</td>
									<td>{`${item.product.name}${
										item.variant?.variantName
											? ` ${item.variant?.variantName}`
											: ''
									}`}</td>
									<td>
										{(item.product.ean || '').trim() !== ''
											? item.product.ean
											: ''}
									</td>
									<td>
										{(item.labels || item.product.taxRateLabels).join(', ')}
									</td>
									{!unknownAmountAdvance && (
										<td className={`${styles.tdRight} tdRight`}>
											{numberFormat(item.finalPrice, false, 2, true)}
										</td>
									)}
									<td className={`${styles.tdRight} tdRight`}>
										{numberFormat(item.quantity, false, 3, false)}
									</td>
									<td>
										{(() => {
											const showUnit = item.variant
												? item.variant.saleUnit?.isPieceUnitOfMeasure
												: item.product?.saleUnit?.isPieceUnitOfMeasure;
											if (showUnit) {
												return item.variant
													? item.variant.saleUnit?.label
													: item.product?.saleUnit?.label || '';
											}
											return '';
										})()}
									</td>
									{!unknownAmountAdvance && (
										<td className={`${styles.tdRight} tdRight`}>
											{numberFormat(
												item.finalPrice * item.quantity,
												false,
												2,
												true
											)}
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}

			{currentStore?.printBankAccounts &&
				bankAccounts.length &&
				invoiceRequest.transactionType === TransactionType.SALE &&
				invoiceRequest.payment.find(
					(payment) => payment.paymentType === PaymentType.WIRE_TRANSFER
				) && (
					<p className={`${styles.small} small`}>
						{locale['paymentInstructions']}:<br />
						<br />
						<strong>
							{bankAccounts.length > 1
								? locale['bankAccounts']
								: locale['bankAccount']}
							:
						</strong>
						<br />
						{bankAccounts.map((bankAccount, index) => (
							<span key={index}>
								{banks[bankAccount.bankId]} {bankAccount.formattedNumber}
								<br />
							</span>
						))}
						<br />
						<strong>{locale['model']}:</strong> 97
						<br />
						<strong>{locale['referenceNo']}:</strong>{' '}
						{Mod97_10.checksum(normalize(invoiceResponse.invoiceNumber))}-
						{invoiceResponse.invoiceNumber}
					</p>
				)}

			{totalDiscount > 0 && currentStore?.printDiscounts && (
				<p className={`${styles.small} ${styles.alignLeft} small`}>
					{locale['discounts']}:<br />
					<br />
					<table className={`${styles.items} items`}>
						<thead>
							<tr>
								<th className={`${styles.tdLeft} tdLeft`}>
									{locale['itemName']}
								</th>
								<th width={100} className={`${styles.tdRight} tdRight`}>
									{locale['discount']}
								</th>
							</tr>
						</thead>
						<tbody>
							{invoiceRequest.items
								.filter((item) => item.discount > 0)
								.map((item) => (
									<tr>
										<td className={`${styles.tdLeft} tdLeft`}>{item.name}</td>
										<td flex="auto" className={`${styles.tdRight} tdRight`}>
											{numberFormat(
												item.discount * item.quantity,
												false,
												2,
												true
											)}
										</td>
									</tr>
								))}
							<tr>
								<td className={`${styles.tdLeft} tdLeft`}>
									{locale['itemTotal']}
								</td>
								<td flex="auto" className={`${styles.tdRight} tdRight`}>
									{numberFormat(totalDiscount, false, 2, true)}
								</td>
							</tr>
						</tbody>
					</table>
				</p>
			)}

			{additionalText && (
				<>
					<br />
					<p className={`${styles.small} small`}>{additionalText}</p>
				</>
			)}
		</div>
	);
}
