import { ProLayout } from '@ant-design/pro-layout/es';
import { Capacitor } from '@capacitor/core';
import { Grid, notification } from 'antd';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React, { memo, useMemo, useRef, useCallback } from 'react';
import { Route, Switch, Link, Routes, Navigate } from 'react-router-dom';
import '../../lib/numeral/locales/rs';
import { useKey } from 'react-use';
import semverLt from 'semver/functions/lt';

import Header from '../../components/Header';
import Logo from '../../components/Logo';
import MobileHeader from '../../components/MobileHeader';
import SDCStatusModal from '../../components/SDCStatusModal';
import SocketStatus from '../../components/SocketStatus';
import { MINIMUM_CLIENT_VERSION } from '../../constants/application';
import { useFeature } from '../../hooks/feature';
import { usePermissions } from '../../hooks/permissions';
import { GlobalNavigator } from '../../lib/GlobalNavigator';
import stores from '../../stores/index.mobx';
import styles from './App.module.less';
// import ApplicationDocumentsCalculations from './Documents/Calculations';
import ApplicationIncomingInvoices from './Documents/IncomingInvoices';
import ApplicationDocumentsKpo from './Documents/Kpo';
import ApplicationOutgoingInvoices from './Documents/OutgoingInvoices';
import Home from './Home';
import ApplicationPartners from './Partners';
import ApplicationPOS from './POS';
// import PrintDeclarations from './Print/Declarations';
// import PrintPrices from './Print/Prices';
import ApplicationProductAvailability from './Products/Availability';
import ApplicationProductCategories from './Products/Categories';
import ApplicationProductProducts from './Products/Products';
import ApplicationReceiptsCalendar from './Receipts/Calendar';
import ApplicationReceiptsList from './Receipts/List';
import ApplicationSettings from './Settings';
import ApplicationSettingsAbout from './Settings/About';
import ApplicationSettingsDevices from './Settings/Devices';
import ApplicationSettingsDiscounts from './Settings/Discounts';
import ApplicationSettingsExchangeRates from './Settings/ExchangeRates';
import ApplicationSettingsIntegrationsApiKeys from './Settings/Integrations/ApiKeys';
// import ApplicationSettingsIntegrationsWebhooks from './Settings/Integrations/Webhooks';
// import ApplicationSettingsLabels from './Settings/Labels';
import ApplicationSettingsMarketplace from './Settings/Marketplace';
import ApplicationSettingsMarketplaceInstalled from './Settings/Marketplace/Pages/Installed';
import ApplicationSettingsMarketplaceMy from './Settings/Marketplace/Pages/My';
import ApplicationSettingsOrganizationCompany from './Settings/Organization/Company';
import ApplicationSettingsOrganizationStores from './Settings/Organization/Stores';
import ApplicationSettingsOrganizationWarehouses from './Settings/Organization/Warehouses';
import ApplicationSettingsRoles from './Settings/Roles';
import ApplicationSettingsSDC from './Settings/SDC';
import ApplicationSettingsTaxRates from './Settings/TaxRates';
import ApplicationSettingsUnits from './Settings/Units';
import ApplicationUsers from './Settings/Users';

SafeArea.getSafeAreaInsets().then(({ insets }) => {
	document.documentElement.style.setProperty('--inset-top', insets.top + 'px');
});

numeral.locale('rs');

if (window.electron) {
	if (
		semverLt(window.electron.clientVersion || '0.0.0', MINIMUM_CLIENT_VERSION)
	) {
		notification.error({
			message: 'Verzija aplikacije',
			style: {
				width: 600,
			},
			description: (
				<>
					Verzija aplikacije koju imate instaliranu je zastarela. <br />
					Molimo vas da ažurirate aplikaciju. Novu verziju aplikacije možete
					preuzeti sa{' '}
					{process.env.REACT_APP_APP_NAME === 'ibuddy' ? (
						<a href="https://ibuddy.rs/" target="_blank">
							https://ibuddy.rs
						</a>
					) : (
						<a
							href="https://dvsoftware.rs/proizvodi/storebuddy-pos/"
							target="_blank"
						>
							https://dvsoftware.rs/proizvodi/storebuddy-pos
						</a>
					)}
					<br />
					<br />
					<strong>Pažnja: </strong> korišćenjem starije verzije aplikacije može
					doći do nepredviđenih problema u radu.
				</>
			),
			duration: 0,
		});
	}
}

const RouteRender = memo(({ route, headerRef, mobileHeaderRef }) => {
	// if (route.routes?.length && route.path === routeProps.location.pathname) {
	// 	return <Navigate to={route.routes[0].path} />;
	// }
	if (route.page) {
		return (
			<>
				<route.page.Page
					ref={(ref) => {
						headerRef?.current?.renderHeaderExtra?.(
							ref?.renderHeader?.() || null
						);
						mobileHeaderRef?.current?.renderHeaderExtra?.(
							ref?.renderHeader?.() || null
						);
					}}
				/>
			</>
		);
	}

	return route.component ? (
		<>
			<route.component
				// {...routeProps}
				routes={route.routes}
				onRouteChange={(path, ref) => {
					headerRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
					mobileHeaderRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
				}}
				ref={(ref) => {
					headerRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
					mobileHeaderRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
				}}
			/>
		</>
	) : null;
});

RouteRender.whyDidYouRender = true;

const RoutesRender = memo(({ routes, headerRef, mobileHeaderRef }) => (
	<Routes>
		{routes.map((route) => (
			<Route
				path={`${(route.routePath || route.path).replace('/app', '')}${
					!route.exact ? '/*' : ''
				}`}
				key={route.path}
				element={
					<RouteRender
						route={route}
						headerRef={headerRef}
						mobileHeaderRef={mobileHeaderRef}
					/>
				}
			/>
		))}
	</Routes>
));

function Application() {
	const {
		marketplaceCategories: { isFetching, available },
		company: { flatRateTax },
	} = stores;

	const screens = Grid.useBreakpoint();

	const marketplaceRoutes = useMemo(() => {
		const defaultRoutes = [
			// {
			// 	path: '/app/settings/applications/marketplace/featured',
			// 	name: 'Preporučeno',
			// 	exact: true,
			// 	hideInMenu: screens.md,
			// 	isInSubmenu: !screens.md,
			// 	headerTitle: 'Podešavanja / Aplikacije / Prodavnica / Preporučeno',
			// 	icon: (
			// 		<img
			// 			src="/images/icons/marketplace/star.png"
			// 			alt="Preporučeno"
			// 			className="anticon"
			// 		/>
			// 	),
			// 	headerIcon: (
			// 		<img
			// 			src="/images/icons/marketplace/star.png"
			// 			alt="Preporučeno"
			// 			className="anticon"
			// 		/>
			// 	),
			// },
		];

		if (isFetching) {
			return defaultRoutes;
		}

		return [
			...defaultRoutes,
			...available.map(({ id, name, icon }) => ({
				name,
				path: `/app/settings/applications/marketplace/category/${id}`,
				routePath: `/app/settings/applications/marketplace/category/:id`,
				exact: true,
				hideInMenu: screens.md,
				isInSubmenu: !screens.md,

				headerTitle: `Podešavanja / Aplikacije / Prodavnica / ${name}`,
				icon: (
					<img
						src={`/images/icons/marketplace/${icon}.png`}
						alt={name}
						className="anticon"
					/>
				),
				headerIcon: (
					<img
						src={`/images/icons/marketplace/${icon}.png`}
						alt={name}
						className="anticon"
					/>
				),
				page: ApplicationSettingsMarketplace,
			})),
		];
	}, [isFetching, available, screens]);

	const [
		hasUsersFeature,
		hasIntegrationsFeature,
		hasStockFeature,
		hasDocoumentsFeature,
		hasReportsFeature,
	] = useFeature([
		'users',
		'integrations',
		'stock',
		'documents',
		'store_management',
		'reports',
	]);

	useKey(
		(e) => {
			return (
				(e.metaKey || e.ctrlKey) &&
				e.shiftKey &&
				e.key === 'd' &&
				window.electron
			);
		},
		() => {
			window.electron.openDevTools();
		},
		undefined,
		[]
	);

	// POS
	const hasPosAccessPermission = usePermissions('pos', 'pos', 'access');
	// Documents
	const hasDocumentsInvoicesPermission = usePermissions(
		'documents',
		'invoices',
		'view'
	);
	const hasDocumentsCalculationsPermission = usePermissions(
		'documents',
		'calculations',
		'view'
	);
	const hasDocumentsCorrectionsPermission = usePermissions(
		'documents',
		'corrections',
		'view'
	);
	const hasDocumentsLevelingPermission = usePermissions(
		'documents',
		'leveling',
		'list'
	);
	const hasDocumentsCashBillsPermission = usePermissions(
		'documents',
		'cashbills',
		'list'
	);
	const hasDocumentsInventoryPermission = usePermissions(
		'documents',
		'inventory',
		'list'
	);
	const hasDocumentsWriteoffsPermission = usePermissions(
		'documents',
		'writeoffs',
		'list'
	);

	const hasIntegrationsPermission = usePermissions(
		'settings',
		'integrations',
		'access'
	);

	const hasSettingsRolesViewPermission = usePermissions(
		'settings',
		'roles',
		'view'
	);
	const hasSettingsUsersViewPermission = usePermissions(
		'settings',
		'users',
		'view'
	);

	const hasSaleChannels = stores.saleChannels.available.length > 0;

	const hasDocumentsPermissions =
		hasDocumentsInvoicesPermission ||
		hasDocumentsCalculationsPermission ||
		hasDocumentsCorrectionsPermission ||
		hasDocumentsLevelingPermission ||
		hasDocumentsCashBillsPermission ||
		hasDocumentsInventoryPermission ||
		hasDocumentsWriteoffsPermission;

	const routes = useMemo(
		() => [
			{
				path: '/app/home',
				name: 'Početna',
				icon: (
					<img
						src="/images/icons/shops.png"
						alt="Početna"
						className="anticon"
					/>
				),
				page: Home,
				// component: ApplicationPOS,
				// hideInMenu: !hasPosAccessPermission,
			},
			{
				path: '/app/pos',
				exact: true,
				name: 'Kasa',
				icon: (
					<img
						src="/images/icons/cash-register.png"
						alt="Kasa"
						className="anticon"
					/>
				),
				component: ApplicationPOS,
				hideInMenu: !hasPosAccessPermission,
			},
			{
				name: 'Katalog',
				path: '/app/catalog',
				exact: true,
				icon: (
					<img
						src="/images/icons/catalog.png"
						alt="Katalog"
						className="anticon"
					/>
				),
				component: ApplicationProductCategories,
				routes: [
					{
						icon: (
							<img
								src="/images/icons/subfolder.png"
								alt="Kategorije"
								className="anticon"
							/>
						),
						path: '/app/catalog/categories',
						routePath: '/app/catalog/categories/:page?',
						name: 'Kategorije',
						page: ApplicationProductCategories,
						isInSubmenu: true,
					},
					{
						icon: (
							<img
								src="/images/icons/stock.png"
								alt="Artikli"
								className="anticon"
							/>
						),
						path: '/app/catalog/products',
						name: 'Artikli',
						page: ApplicationProductProducts,
						isInSubmenu: true,
					},
					{
						icon: (
							<img
								src="/images/icons/availability.png"
								alt="Artikli"
								className="anticon"
							/>
						),
						path: '/app/catalog/availability',
						name: 'Dostupnost',
						page: ApplicationProductAvailability,
						isInSubmenu: true,
						hideInMenu: !hasSaleChannels,
					},
					// {
					// 	path: '/app/catalog/orders',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/order.png"
					// 			alt="Trebovanje"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Trebovanje',
					// 	isInSubmenu: true,
					// },
				],
			},
			{
				name: 'Dokumenti',
				path: '/app/documents',
				exact: true,
				icon: (
					<img
						src="/images/icons/documents.png"
						alt="Dokumenti"
						className="anticon"
					/>
				),
				hideInMenu: !hasDocumentsPermissions || !hasDocoumentsFeature,
				routes: [
					...(flatRateTax
						? [
								{
									path: '/app/documents/kpo',
									icon: (
										<img
											src="/images/icons/finance.png"
											alt="KPO knjiga"
											className="anticon"
										/>
									),
									exact: true,
									name: 'KPO knjiga',
									page: ApplicationDocumentsKpo,
									// hideInMenu: !hasDocumentsInvoicesPermission,
									isInSubmenu: true,
								},
						  ]
						: []),
					{
						path: '/app/documents/invoices/incoming',
						icon: (
							<img
								src="/images/icons/invoice-in.png"
								alt="Ulazni dokumenti"
								className="anticon"
							/>
						),
						exact: true,
						name: 'Ulazni dokumenti',
						page: ApplicationIncomingInvoices,
						hideInMenu: !hasDocumentsInvoicesPermission,
						isInSubmenu: true,
					},
					{
						path: '/app/documents/invoices/outgoing',
						icon: (
							<img
								src="/images/icons/invoice-out.png"
								alt="Izlazni dokumenti"
								className="anticon"
							/>
						),
						exact: true,
						name: 'Izlazni dokumenti',
						page: ApplicationOutgoingInvoices,
						hideInMenu: !hasDocumentsInvoicesPermission,
						isInSubmenu: true,
					},
					// {
					// 	path: '/app/documents/calculations',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/calculator.png"
					// 			alt="Kalkulacije"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Kalkulacije',
					// 	page: ApplicationDocumentsCalculations,
					// 	hideInMenu: !hasDocumentsCalculationsPermission,
					// 	isInSubmenu: true,
					// },
					// {
					// 	path: '/app/documents/corrections',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/cash-back.png"
					// 			alt="Nalozi za ispravku"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Nalozi za ispravku',
					// 	hideInMenu: !hasDocumentsCorrectionsPermission,
					// 	isInSubmenu: true,
					// },
					// {
					// 	path: '/app/documents/leveling',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/price-change.png"
					// 			alt="Nivelacije"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Nivelacije',
					// 	hideInMenu: !hasDocumentsLevelingPermission,
					// 	isInSubmenu: true,
					// },
					// {
					// 	path: '/app/documents/cashbills',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/cash.png"
					// 			alt="Gotovinski računi"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Gotovinski računi',
					// 	hideInMenu: !hasDocumentsCashBillsPermission,
					// 	isInSubmenu: true,
					// },
					// {
					// 	path: '/app/documents/inventory',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/inventory.png"
					// 			alt="Popisi"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Popisi',
					// 	hideInMenu: !hasDocumentsInventoryPermission,
					// 	isInSubmenu: true,
					// },
					// {
					// 	path: '/app/documents/writeoffs',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/garbage.png"
					// 			alt="Otpisi"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	exact: true,
					// 	name: 'Otpisi',
					// 	hideInMenu: !hasDocumentsWriteoffsPermission,
					// 	isInSubmenu: true,
					// },
				],
			},
			// {
			// 	path: '/app/reports',
			// 	name: 'Izveštaji',
			// 	exact: true,
			// 	hideInMenu: !hasReportsFeature,
			// 	icon: (
			// 		<img
			// 			src="/images/icons/business-report.png"
			// 			alt="Izveštaji"
			// 			className="anticon"
			// 		/>
			// 	),
			// 	routes: [
			// 		{
			// 			path: '/app/reports/kep',
			// 			icon: (
			// 				<img src="/images/icons/book.png" alt="KEP" className="anticon" />
			// 			),
			// 			exact: true,
			// 			name: 'KEP',
			// 			isInSubmenu: true,
			// 		},
			// 		{
			// 			path: '/app/reports/stock',
			// 			icon: (
			// 				<img
			// 					src="/images/icons/check-list.png"
			// 					alt="Lager"
			// 					className="anticon"
			// 				/>
			// 			),
			// 			exact: true,
			// 			name: 'Lager',
			// 			isInSubmenu: true,
			// 		},
			// 	],
			// },
			{
				path: '/app/receipts',
				exact: true,
				name: 'Računi',
				icon: (
					<img
						src="/images/icons/receipt.png"
						alt="Računi"
						className="anticon"
					/>
				),
				routes: [
					{
						path: '/app/receipts/list',
						icon: (
							<img
								src="/images/icons/bill.png"
								alt="Izdati računi"
								className="anticon"
							/>
						),
						exact: true,
						name: 'Izdati računi',
						isInSubmenu: true,
						page: ApplicationReceiptsList,
					},
					{
						path: '/app/receipts/calendar',
						icon: (
							<img
								src="/images/icons/salary.png"
								alt="Po danima"
								className="anticon"
							/>
						),
						exact: true,
						name: 'Po danima',
						isInSubmenu: true,
						page: ApplicationReceiptsCalendar,
					},
				],
			},
			// {
			// 	path: '/app/print',
			// 	exact: true,
			// 	name: 'Štampa',
			// 	icon: (
			// 		<img
			// 			src="/images/icons/printer.png"
			// 			alt="Štampa"
			// 			className="anticon"
			// 		/>
			// 	),
			// 	routes: [
			// 		{
			// 			path: '/app/print/prices',
			// 			exact: true,
			// 			name: 'Cene',
			// 			icon: (
			// 				<img
			// 					src="/images/icons/printer.png"
			// 					alt="Cene"
			// 					className="anticon"
			// 				/>
			// 			),
			// 			component: PrintPrices,
			// 			isInSubmenu: true,
			// 		},
			// 		{
			// 			path: '/app/print/declarations',
			// 			exact: true,
			// 			name: 'Deklaracije',
			// 			icon: (
			// 				<img
			// 					src="/images/icons/printer.png"
			// 					alt="Deklaracije"
			// 					className="anticon"
			// 				/>
			// 			),
			// 			component: PrintDeclarations,
			// 			isInSubmenu: true,
			// 		},
			// 	],
			// },
			{
				path: '/app/settings',
				exact: false,
				hideInMenu: screens.md,
				name: 'Podešavanja',
				icon: (
					<img
						src="/images/icons/settings.png"
						alt="Podešavanja"
						className="anticon"
					/>
				),
				routes: [
					{
						path: '/app/settings/about',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'O aplikaciji',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/info.png"
								alt="O aplikaciji"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/info.png"
								alt="O aplikaciji"
								className="anticon"
							/>
						),
						component: ApplicationSettingsAbout,
					},

					{
						path: '/app/settings/organization/company',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Preduzeće',
						headerTitle: 'Podešavanja / Preduzeće',
						icon: (
							<img
								src="/images/icons/office-building.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/office-building.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						component: ApplicationSettingsOrganizationCompany,
					},
					{
						path: '/app/settings/organization/roles',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Uloge korisnika',
						headerTitle: 'Podešavanja / Uloge korisnika',
						icon: (
							<img
								src="/images/icons/id-card.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						hideInMenu: !hasUsersFeature || !hasSettingsRolesViewPermission,
						headerIcon: (
							<img
								src="/images/icons/id-card.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						page: ApplicationSettingsRoles,
					},
					{
						path: '/app/settings/organization/users',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Korisnici',
						headerTitle: 'Podešavanja / Korisnici',
						hideInMenu: !hasUsersFeature || !hasSettingsUsersViewPermission,
						icon: (
							<img
								src="/images/icons/man.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/man.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						page: ApplicationUsers,
					},
					{
						path: '/app/settings/organization/warehouses',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Magacini',
						headerTitle: 'Podešavanja / Magacini',
						hideInMenu: !hasStockFeature,
						icon: (
							<img
								src="/images/icons/warehouse.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/warehouse.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						page: ApplicationSettingsOrganizationWarehouses,
					},
					{
						path: '/app/settings/organization/stores',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Prodajna mesta',
						headerTitle: 'Podešavanja / Prodajna mesta',
						icon: (
							<img
								src="/images/icons/shops.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/shops.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						page: ApplicationSettingsOrganizationStores,
					},
					{
						path: '/app/settings/organization/partners',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Partneri',
						headerTitle: 'Podešavanja / Partneri',
						icon: (
							<img
								src="/images/icons/handshake.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/handshake.png"
								alt="Podešavanja"
								className="anticon"
							/>
						),
						page: ApplicationPartners,
					},
					{
						path: '/app/settings/units',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Jedinice mere',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/measuring-tape.png"
								alt="Jedinice mere"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/measuring-tape.png"
								alt="Jedinice mere"
								className="anticon"
							/>
						),
						page: ApplicationSettingsUnits,
					},
					{
						path: '/app/settings/discounts',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Popusti',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/discount.png"
								alt="Popusti"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/discount.png"
								alt="Popusti"
								className="anticon"
							/>
						),
						page: ApplicationSettingsDiscounts,
					},
					// {
					// 	path: '/app/settings/labels',
					// 	exact: true,
					// 	name: 'Dizajn etiketa',
					// 	headerTitle: 'Podešavanja',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/label.png"
					// 			alt="Dizajn etiketa"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	headerIcon: (
					// 		<img
					// 			src="/images/icons/label.png"
					// 			alt="Dizajn etiketa"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	page: ApplicationSettingsLabels,
					// },
					{
						path: '/app/settings/sdc',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Procesor fiskalnih računa',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/pos.png"
								alt="L-PFR"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/pos.png"
								alt="L-PFR"
								className="anticon"
							/>
						),
						component: ApplicationSettingsSDC,
					},
					...(window.electron
						? [
								{
									path: '/app/settings/devices',
									exact: true,
									isInSubmenu: !screens.md,
									name: 'Uređaji',
									headerTitle: 'Podešavanja',
									icon: (
										<img
											src="/images/icons/ethernet.png"
											alt="Uređaji"
											className="anticon"
										/>
									),
									headerIcon: (
										<img
											src="/images/icons/ethernet.png"
											alt="Uređaji"
											className="anticon"
										/>
									),
									page: ApplicationSettingsDevices,
									// hideInMenu: !window.electron,
								},
						  ]
						: []),

					// {
					// 	path: '/app/settings/integrations',
					// 	exact: true,
					// 	isInSubmenu: !screens.md,
					// 	name: 'Integracije',
					// 	headerTitle: 'Podešavanja',
					// 	hideInMenu: !hasIntegrationsFeature || !hasIntegrationsPermission,
					// 	icon: (
					// 		<img
					// 			src="/images/icons/plugin.png"
					// 			alt="Integracije"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	headerIcon: (
					// 		<img
					// 			src="/images/icons/plugin.png"
					// 			alt="Integracije"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	page: ApplicationSettingsIntegrationsApiKeys,
					// 	routes: [

					// 		// {
					// 		// 	path: '/app/settings/integrations/webhooks',
					// 		// 	exact: true,
					// 		// 	name: 'Webhookovi',
					// 		// 	headerTitle: 'Podešavanja',
					// 		// 	isInSubmenu: true,
					// 		// 	icon: (
					// 		// 		<img
					// 		// 			src="/images/icons/webhook.png"
					// 		// 			alt="Webhookovi"
					// 		// 			className="anticon"
					// 		// 		/>
					// 		// 	),
					// 		// 	headerIcon: (
					// 		// 		<img
					// 		// 			src="/images/icons/webhook.png"
					// 		// 			alt="Webhookovi"
					// 		// 			className="anticon"
					// 		// 		/>
					// 		// 	),
					// 		// 	page: ApplicationSettingsIntegrationsWebhooks,
					// 		// },
					// 	],
					// },

					{
						path: '/app/settings/tax-rates',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Poreske stope',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/tax.png"
								alt="Poreske stope"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/tax.png"
								alt="Poreske stope"
								className="anticon"
							/>
						),
						page: ApplicationSettingsTaxRates,
					},
					{
						path: '/app/settings/exchange-rates',
						exact: true,
						isInSubmenu: !screens.md,
						name: 'Kursna lista',
						headerTitle: 'Podešavanja',
						icon: (
							<img
								src="/images/icons/currency-exchange.png"
								alt="Kursna lista"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/currency-exchange.png"
								alt="Kursna lista"
								className="anticon"
							/>
						),
						page: ApplicationSettingsExchangeRates,
					},
					// {
					// 	path: '/app/settings/shortcuts',
					// 	exact: true,
					// 	name: 'Prečice',
					// 	headerTitle: 'Podešavanja',
					// 	icon: (
					// 		<img
					// 			src="/images/icons/keyboard-key.png"
					// 			alt="Prečice"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	headerIcon: (
					// 		<img
					// 			src="/images/icons/keyboard-key.png"
					// 			alt="Prečice"
					// 			className="anticon"
					// 		/>
					// 	),
					// 	component: ApplicationSettingsUnits,
					// },

					{
						path: '/app/settings/applications',
						exact: true,
						name: 'Aplikacije',
						headerTitle: 'Podešavanja',
						isInSubmenu: !screens.md,
						hideInMenu: !hasIntegrationsFeature || !hasIntegrationsPermission,
						icon: (
							<img
								src="/images/icons/choice.png"
								alt="Aplikacije"
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/choice.png"
								alt="Aplikacije"
								className="anticon"
							/>
						),
						// page: ApplicationSettingsIntegrationsApiKeys,
						routes: [
							{
								path: '/app/settings/applications/marketplace',
								routePath: '/app/settings/applications/marketplace/*',
								name: 'Prodavnica',
								headerTitle: 'Podešavanja / Aplikacije / Prodavnica',
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/store.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/store.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								routes: marketplaceRoutes,
								page: ApplicationSettingsMarketplace,
							},
							{
								path: '/app/settings/applications/installed',
								exact: true,
								name: 'Instalirane',
								headerTitle: 'Podešavanja / Aplikacije / Instalirane',
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/download.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/download.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								page: ApplicationSettingsMarketplaceInstalled,
							},

							{
								path: '/app/settings/applications/developer',
								exact: true,
								name: 'Za programere',
								headerTitle: 'Podešavanja',
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/programmer.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/programmer.png"
										alt="Prodavnica"
										className="anticon"
									/>
								),
								routes: [
									{
										path: '/app/settings/applications/developer/my-applications',
										exact: true,
										name: 'Moje aplikacije',
										headerTitle:
											'Podešavanja / Aplikacije / Za programere / Moje aplikacije',
										isInSubmenu: true,
										icon: (
											<img
												src="/images/icons/program.png"
												alt="Prodavnica"
												className="anticon"
											/>
										),
										headerIcon: (
											<img
												src="/images/icons/program.png"
												alt="Prodavnica"
												className="anticon"
											/>
										),
										page: ApplicationSettingsMarketplaceMy,
									},
									{
										path: '/app/settings/integrations/api-keys',
										exact: true,
										name: 'API ključevi',
										headerTitle:
											'Podešavanja / Aplikacije / Za programere / API ključevi',
										isInSubmenu: true,
										icon: (
											<img
												src="/images/icons/api.png"
												alt="API ključevi"
												className="anticon"
											/>
										),
										headerIcon: (
											<img
												src="/images/icons/api.png"
												alt="API ključevi"
												className="anticon"
											/>
										),
										page: ApplicationSettingsIntegrationsApiKeys,
									},
								],
							},
						],
					},
				],
				component: ApplicationSettings,
			},
		],
		[
			hasPosAccessPermission,
			hasDocumentsInvoicesPermission,
			hasDocumentsCalculationsPermission,
			hasDocumentsCorrectionsPermission,
			hasDocumentsLevelingPermission,
			hasDocumentsCashBillsPermission,
			hasDocumentsInventoryPermission,
			hasDocumentsWriteoffsPermission,
			hasIntegrationsPermission,
			hasUsersFeature,
			hasIntegrationsFeature,
			hasStockFeature,
			hasDocoumentsFeature,
			hasReportsFeature,
			screens,
			marketplaceRoutes,
			flatRateTax,
			hasSaleChannels,
		]
	);

	const reducedRoutes = useMemo(() => {
		return routes.reduce((prev, curr) => {
			prev.push(curr);
			function getChildren(route) {
				if (route.routes && !route.path.startsWith('/app/settings')) {
					route.routes.forEach((child) => {
						prev.push(child);
						getChildren(child);
					});
				}
			}
			getChildren(curr);
			return prev;
		}, []);
	}, [routes]);

	const pageRoutes = useMemo(
		() =>
			reducedRoutes.filter((route) => {
				return !!route.page;
			}),
		[reducedRoutes]
	);

	const headerRef = useRef();
	const mobileHeaderRef = useRef();

	const memoizedLogo = useMemo(() => <Logo small />, []);

	const menuItemRender = useCallback(
		(item, defaultDom) => (
			<Link to={item.path}>
				{item.isInSubmenu && item.icon}
				{defaultDom}
			</Link>
		),
		[]
	);

	const menuProps = useMemo(
		() => ({
			triggerSubMenuAction:
				'ontouchstart' in window || navigator.msMaxTouchPoints
					? 'click'
					: 'hover',
		}),
		[]
	);

	const subMenuItemRender = useCallback(
		(item, defaultDom) => (
			<>
				{item.isInSubmenu && item.icon}
				{defaultDom}
			</>
		),
		[]
	);

	const menuDataRender = useCallback(() => routes, [routes]);

	const headerContentRender = useCallback(
		(p) => {
			return <Header {...p} ref={headerRef} />;
		},
		[headerRef]
	);

	const headerRender = useCallback(
		(p, defaultDom) => {
			return (
				<>
					{defaultDom}
					<MobileHeader ref={mobileHeaderRef} />
				</>
			);
		},
		[mobileHeaderRef]
	);

	const menuFooterRender = useCallback(() => <SocketStatus />, []);

	return (
		<>
			<ProLayout
				className={`${styles.layout} ${
					(window.electron &&
						(window.electron.platform === 'darwin' ||
							!window.electron.platform)) ||
					window.__TAURI__
						? styles.electron
						: ''
				} ${Capacitor.isNativePlatform() ? styles.capacitor : ''}`}
				title=""
				defaultCollapsed={false}
				logo={memoizedLogo}
				siderWidth={screens.md ? 200 : 320}
				menuItemRender={menuItemRender}
				menuProps={menuProps}
				subMenuItemRender={subMenuItemRender}
				menuDataRender={menuDataRender}
				fixedHeader
				fixSiderbar
				disableContentMargin
				headerContentRender={headerContentRender}
				headerRender={headerRender}
				menuFooterRender={menuFooterRender}
			>
				<RoutesRender
					routes={reducedRoutes}
					headerRef={headerRef}
					mobileHeaderRef={mobileHeaderRef}
				/>
			</ProLayout>
			<SDCStatusModal />
			<GlobalNavigator />
		</>
	);
}

export default observer(Application);
