import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';

import stores from '../stores/index.mobx';
import { withRouter } from './withRouter';

type Props = {
	location: string;
};

function withAuth(
	WrappedComponent,
	protectedRoute,
	allowAuthenticated = false
) {
	function Wrapper(props: Props) {
		const {
			users: { token },
		} = stores;

		if (token && !protectedRoute && !allowAuthenticated) {
			return (
				<Navigate
					to={{
						pathname: '/app/home',
						state: { from: props.location },
					}}
				/>
			);
		}

		if (!token && protectedRoute) {
			return (
				<Navigate
					to={{
						pathname: '/',
						state: { from: props.location },
					}}
				/>
			);
		}

		return <WrappedComponent {...props} />;
	}

	return withRouter(observer(Wrapper));
}

export default withAuth;
