import { Row, Col } from 'antd';
import React from 'react';

interface Props {
	value?: {
		model: string;
		number: string;
	};
}

function ReferenceView({ value }: Props) {
	return (
		<Row gutter={2} style={{ width: '100%' }}>
			{value?.model && value?.model !== '' && (
				<Col flex="none">{value?.model}</Col>
			)}
			<Col flex="auto">{value?.number}</Col>
		</Row>
	);
}
ReferenceView.defaultProps = {
	value: {
		model: '',
		number: '',
	},
};

export default ReferenceView;
