import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Drawer,
	Form,
	Input,
	Row,
	Space,
	Spin,
	Layout,
	Table,
	Popconfirm,
	DescriptionsProps,
	Typography,
	Skeleton,
	message,
	Grid,
	ConfigProvider,
	Empty,
	Alert,
	Modal,
	TableProps,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import CRC32 from 'crc-32/crc32c';
import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import pluralize from 'pluralize';
import {
	cloneElement,
	ReactElement,
	ReactNode,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
	isValidElement,
	useCallback,
	useRef,
	Fragment,
	FC,
	RefObject,
	memo,
} from 'react';
import { useBus, useListener } from 'react-bus';
import nl2br from 'react-nl2br';
import { useDebounce, useWindowSize } from 'react-use';
import {
	useQueryParam,
	StringParam,
	NumberParam,
	withDefault,
	useQueryParams,
	ArrayParam,
} from 'use-query-params';

import { DrawerContext } from '../../context/DrawerContext';
import { usePermissions } from '../../hooks/permissions';
import { getScrollbarWidth } from '../../lib/scrollbar';
import stores from '../../stores/index.mobx';
import { LoadingReference } from '../../stores/transformers/Reference';
import DisablePasswordAutocomplete from '../DisablePasswordAutocomplete';
import { Title } from '../Title';
import styles from './Page.module.less';

const { Content } = Layout;

interface FilterItem {
	text: ReactNode;
	value: string;
}

interface TableColumn {
	title: string;
	dataIndex?: string | string[];
	key?: string;
	searchable?: boolean;
	searchKey?: string;
	width?: number;
	render?:
		| ((text?: string, record?: any, index?: number) => ReactNode)
		| ((record?: any, index?: number) => ReactNode);
	filters?: FilterItem[] | ((stores) => FilterItem[]);
	filterMultiple?: boolean;
	onFilter?: (value: string, record: any) => boolean;
	sorter?: ((a: any, b: any) => number) | boolean;
	defaultSortOrder?: 'ascend' | 'descend';
	defaultFilteredValue?: string[];
	filterDropdown?: (any) => ReactNode;
	shouldLink?: boolean;
}

interface FormField {
	label?: string;
	key?: string | string[];
	rules?: any[]; // TODO use antd rules
	component: ReactNode;
	span?: number;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
	initialValue?: unknown;
	valuePropName?: string;
	rerenderOnChange?: boolean;
	hidden?: boolean;
}

export interface FormRow {
	key: string;
	label?: string;
	fields: FormField[];
}

interface ViewField {
	label?: ReactNode;
	key?: string;
	dataIndex?: string;
	component?: ReactElement;
	span?: number;
	block?: boolean;
	render?: (text?: string, record?: any) => ReactNode;
}

interface ViewRow {
	key: string;
	label?: ReactNode;
	column: DescriptionsProps['column'];
	fields: ViewField[];
	descriptionsProps?: DescriptionsProps;
}

interface Options {
	module: string;
	submodule: string;
	model: string;
	drawers?: Record<string, any>;
	page: {
		shouldFetch?: boolean;
		empty?: {
			image?: string;
			text?: string;
		};
		table: {
			columns: TableColumn[] | (() => TableColumn[]);
			actions?: ((record?: any) => ReactNode)[];
			listProp?: string;
			props?: any; // TODO don't use any
			showActions?: boolean;
			showDisabledActions?: boolean;
		};
		deletePrompt?: string;
		searchable?: boolean;
		searchPlaceholder?: string;
		searchFilter?: (values: any[]) => any[];
		createButtonText?: string;
		additionalButtons?: ReactNode;
		customCreateButton?: (createHandler: () => void) => ReactNode;
		additionalQueryParams?: Record<string, any>;
		additionalContent?: ReactNode;
	};
	edit?: {
		width?: number;
		title?: {
			new?: string;
			existing?: string | ((entity: any) => ReactNode);
		};
		disabled?: boolean | ((entity: any) => boolean);
		disabledReason?: string | ((entity: any) => string);
		shouldFetch?: boolean;
		beforeSave?: (fields) => any;
		afterSave?: (fields, form: FormInstance) => any;
		beforeSetFormFields?: (fields) => any;
		fields?:
			| FormRow[]
			| ((
					record?: any,
					form?: any,
					setFields?: (fields: any) => any
			  ) => FormRow[])
			| ReactNode;
		onError?: (
			error,
			entity,
			form: FormInstance,
			controlerRef?: RefObject<any>
		) => any;
		disablePasswordAutocomplete?: boolean;
		buttons?: FC<{
			entity: any;
			save: (closeDrawer: boolean) => any;
			close: () => void;
			isCreating: boolean;
			isLoading: boolean;
			disabled: boolean;
			canEditPermission: boolean;
		}>;
		controllerComponent?: any;
	};
	view?: {
		useEdit?: boolean;
		width?: number;
		title?: string | ((entity: any) => ReactNode);
		shouldFetch?: boolean;
		fields?: ViewRow[] | ((record?: any, form?: any) => ViewRow[]) | ReactNode;
		footer?: (entity, onEdit, onClose, onDelete) => ReactNode;
		descriptionsProps?: DescriptionsProps;
	};
}

const drawers = {};

export function addToDrawersRegistry(name, component) {
	drawers[name] = component;
}

export function CreatePage(options: Options) {
	const { module, model, submodule, drawers: pageDrawers = {} } = options;
	const modelPlural = pluralize(model);

	type RenderPageProps = {
		searchLoading: boolean;
		emptyImage?: string;
		emptyText?: string;
		pagination?: TableProps<any>['pagination'];
		handleTableChange?: TableProps<any>['onChange'];
		columns?: TableColumn[];
		isFetching?: boolean;
		data: any[];
		tableProps?: TableProps<any>;
		pageSize?: number;
	};

	const RenderPage = memo(
		({
			searchLoading,
			emptyImage,
			emptyText,
			pagination,
			handleTableChange,
			columns,
			isFetching,
			data,
			tableProps,
			pageSize,
		}: RenderPageProps) => {
			const emptyData = useMemo(() => {
				return Array(pageSize).fill({});
			}, [pageSize]);

			const ViewDrawer = drawers[`view/${module}/${submodule}`];
			const EditDrawer = drawers[`edit/${module}/${submodule}`];
			const defaultExpandable = useMemo(() => {
				return {};
			}, []);

			return (
				<>
					<ConfigProvider
						renderEmpty={() =>
							searchLoading ? (
								<Empty
									image={<img src="/images/icons/search.png" alt="" />}
									description="Pretraga je u toku..."
								/>
							) : (
								<Empty
									image={
										<img
											src={`/images/icons/${emptyImage || 'sad'}.png`}
											alt=""
										/>
									}
									description={emptyText || 'Nema podataka'}
								/>
							)
						}
					>
						<Content>
							<Table
								rowKey="id"
								size="small"
								pagination={pagination}
								onChange={handleTableChange}
								columns={columns}
								dataSource={isFetching || searchLoading ? emptyData : data}
								expandable={defaultExpandable}
								{...tableProps}
							/>
						</Content>
					</ConfigProvider>
					<ViewDrawer />
					<EditDrawer />
				</>
			);
		}
	);

	function Page(props, ref) {
		const {
			view,
			page: {
				shouldFetch = true,
				empty,
				table: {
					columns,
					actions,
					listProp = 'list',
					props: tableProps,
					showActions = true,
					showDisabledActions = false,
				},
				deletePrompt = 'Da li ste sigurni da želite da obrišete ovu stavku?',
				searchable = false,
				searchPlaceholder = 'Pretraži',
				searchFilter = (values) => values,
				createButtonText = 'Dodaj',
				additionalButtons,
				customCreateButton,
				additionalQueryParams,
				additionalContent,
			},
		} = options;

		const { height } = useWindowSize();

		const hasMobileHeader = !!document.querySelector(
			'#root.has-mobile-header .screen-xs, #root.has-mobile-header .screen-sm'
		);
		const screens = Grid.useBreakpoint();
		const titleBarHeight =
			window.electron &&
			(!window.electron.platform || window.electron.platform === 'darwin') &&
			(screens.sm || screens.xs) &&
			!screens.lg
				? 28
				: 0;
		const contentHeight =
			height -
			49 - // header
			39 - // table header
			60 - // pagination
			(tableProps?.summary ? 39 : 0) - // table summary
			(hasMobileHeader ? 46 : 0) - // mobile header
			titleBarHeight - // extended titlebar
			getScrollbarWidth(); // scrollbar

		const currentPageSize = Math.floor(contentHeight / 39);
		const [pageSize, setPageSize] = useState(currentPageSize);

		const { isDrawerOpen } = useContext(DrawerContext);

		useDebounce(
			() => {
				if (!isDrawerOpen) {
					setPageSize(currentPageSize);
				}
			},
			1000,
			[currentPageSize, isDrawerOpen]
		);

		const [pageQueryParam, setPageQueryParam] = useQueryParam(
			'page',
			withDefault(NumberParam, 1)
		);

		const [filterQueryParams, setFilterQueryParams] = useQueryParams(
			(typeof columns === 'function' ? columns() : columns)
				.filter((column) => Boolean(column.filters || column.filterDropdown))
				.map((column) => column.key)
				.reduce((prev, curr) => {
					prev[curr] = ArrayParam;
					return prev;
				}, {})
		);
		const [sorterQueryParam, setSorterQueryParam] = useQueryParam(
			'sort',
			StringParam
		);

		const [, setCreateQueryParam] = useQueryParam(
			`create/${module}/${submodule}`,
			StringParam
		);
		const [, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}`,
			StringParam
		);
		const [, setViewQueryParam] = useQueryParam(
			`view/${module}/${submodule}`,
			StringParam
		);

		const [reloadQueryParam, setReloadQueryParam] = useQueryParam(
			'reload',
			StringParam
		);

		const modelStore = stores[modelPlural];

		const { fetchAll, isFetching, pagination, isCreatable, search } =
			modelStore;

		const [searchQueryParam, setSearchQueryParam] = useQueryParam(
			'search',
			StringParam
		);

		const [searchResults, setSearchResults] = useState(undefined);
		const [searchLoading, setSearchLoading] = useState(false);
		const searchTimeout = useRef(null);

		useEffect(() => {
			if (searchQueryParam && searchQueryParam !== '') {
				setSearchLoading(true);
				setSearchResults([]);

				clearTimeout(searchTimeout.current);
				searchTimeout.current = setTimeout(async () => {
					const results = search(searchQueryParam).map(({ item }) => item);
					setSearchResults(searchFilter(results));
					setSearchLoading(false);
				}, 500);
			} else {
				setSearchResults(undefined);
			}
		}, [searchQueryParam]);

		const data = useMemo(() => {
			if (typeof searchResults !== 'undefined') {
				return searchResults;
			}

			return modelStore[listProp];
		}, [
			modelStore[listProp],
			modelStore[listProp].length,
			isFetching,
			searchResults,
		]);

		const canCreatePermission = usePermissions(module, submodule, 'create');
		const canEditPermission = usePermissions(module, submodule, 'edit');
		const canDeletePermission = usePermissions(module, submodule, 'delete');

		const memoizedColumns = useMemo(() => {
			return [
				...(typeof columns === 'function' ? columns() : columns).map(
					(column) => ({
						...column,
						filters:
							typeof column.filters === 'function'
								? column.filters(stores)
								: column.filters,
						// ...(column.searchable
						// 	? {
						// 			...this.getColumnSearchProps(
						// 				column.key,
						// 				column.searchKey || column.key
						// 			),
						// 	  }
						// 	: {}),
						render(text, record, index) {
							if (
								text instanceof LoadingReference ||
								isFetching ||
								searchLoading
							) {
								return (
									<Skeleton
										className={styles.skeleton}
										active
										title={{
											width: `${100 - (Math.abs(CRC32.str(`${index}`)) % 50)}%`,
										}}
										paragraph={false}
									/>
								);
							}
							if (column.shouldLink && view) {
								return (
									<Typography.Link
										className={styles.columnLink}
										onClick={() => {
											if (view?.useEdit) {
												return setEditQueryParam(record.id);
											}
											return setViewQueryParam(record.id);
										}}
									>
										{column.render ? column.render(text, record) : text}
									</Typography.Link>
								);
							}
							return column.render
								? column.render(text, record, index)
								: get(
										record,
										typeof column.dataIndex === 'string'
											? column.dataIndex
											: column.dataIndex.join('.')
								  ) || text;
						},
					})
				),
				...(showActions
					? [
							{
								title: 'Akcije',
								key: 'actions',
								align: 'right',
								fixed: 'right',
								render: (record) => (
									<Button.Group className={styles.actions}>
										{(actions || []).map((action) => action(record))}
										{(showDisabledActions ||
											(record.isEditable && canEditPermission)) && (
											<Button
												type="link"
												size="small"
												icon={<EditOutlined />}
												onClick={() => {
													setEditQueryParam(record.id);
												}}
												disabled={!record.isEditable || !canEditPermission}
											/>
										)}
										{(showDisabledActions ||
											(record.isDeletable && canDeletePermission)) && (
											<Button
												type="link"
												danger
												size="small"
												icon={<DeleteOutlined />}
												disabled={!record.isDeletable || !canDeletePermission}
												onClick={() => {
													Modal.confirm({
														title: deletePrompt,
														content: 'Ova akcija ne može biti poništena.',
														onOk() {
															record.destroy();
														},
														okText: 'Obriši',
														cancelText: 'Poništi',
													});
												}}
											/>
										)}
									</Button.Group>
								),
								width: 70 + (actions?.length || 0) * 35,
							},
					  ]
					: []),
			];
		}, [
			columns,
			isFetching,
			searchLoading,
			showActions,
			actions,
			showDisabledActions,
			deletePrompt,
			canEditPermission,
			canDeletePermission,
		]);

		useEffect(() => {
			if (shouldFetch) {
				handleTableChange(
					{
						current: pageQueryParam || 1,
						pageSize,
					},
					filterQueryParams,
					{}
				);
			}
		}, [pageSize]);

		useImperativeHandle(ref, () => ({
			renderHeader: () =>
				(additionalButtons ||
					searchable ||
					(canCreatePermission && isCreatable)) && (
					<Space>
						{additionalButtons}
						{searchable && (
							<Input
								prefix={<SearchOutlined />}
								onChange={(e) => setSearchQueryParam(e.target.value)}
								placeholder={searchPlaceholder}
								value={searchQueryParam}
							/>
						)}
						{canCreatePermission &&
							isCreatable &&
							(customCreateButton ? (
								customCreateButton(() => setCreateQueryParam('true'))
							) : (
								<Button
									type="primary"
									onClick={() => {
										setCreateQueryParam('true');
									}}
								>
									{screens.xs ? 'Dodaj' : createButtonText}
								</Button>
							))}
					</Space>
				),
		}));

		const handleTableChange = useCallback(
			(tablePagination, filters, sorter) => {
				setPageQueryParam(tablePagination.current);

				let finalFilters = {};
				let finalSorters = '';
				if (filters) {
					const filtered = Object.entries(filters)
						.filter(([, value]) => value !== null)
						.reduce(function (acc, curr) {
							acc[curr[0]] = curr[1];
							return acc;
						}, {});
					finalFilters = filtered;
					setFilterQueryParams(filtered);
				}
				if (sorter && sorter.field) {
					finalSorters = `${sorter.field}:${
						sorter.order === 'descend' ? 'desc' : 'asc'
					}`;
					setSorterQueryParam(finalSorters);
				}

				if (shouldFetch) {
					if (!pagination?.supported) {
						fetchAll();
					} else {
						fetchAll(
							tablePagination.pageSize,
							(tablePagination.current - 1) * tablePagination.pageSize,
							{ ...finalFilters, ...additionalQueryParams },
							finalSorters
						);
					}
				}
			},
			[shouldFetch, pagination, additionalQueryParams, fetchAll]
		);

		useEffect(() => {
			if (reloadQueryParam) {
				handleTableChange(
					{
						current: pageQueryParam || 1,
						pageSize,
					},
					filterQueryParams,
					{}
				);
			}
			setReloadQueryParam(undefined);
		}, [reloadQueryParam, filterQueryParams, pageQueryParam, pageSize]);

		const memoizedPagination = useMemo(() => {
			return (
				!isFetching &&
				!searchLoading && {
					pageSize: pageSize,
					total: pagination?.count,
					current: pageQueryParam,
					showSizeChanger: false,
				}
			);
		}, [isFetching, searchLoading, pagination, pageSize, pageQueryParam]);

		const emptyText = useMemo(() => {
			return empty?.text || 'Nema podataka';
		}, [empty]);

		const emptyImage = useMemo(() => {
			return empty?.image;
		}, [empty]);

		return (
			<>
				<RenderPage
					searchLoading={searchLoading}
					emptyImage={emptyImage}
					emptyText={emptyText}
					pagination={memoizedPagination}
					handleTableChange={handleTableChange}
					columns={memoizedColumns}
					isFetching={isFetching}
					data={data}
					tableProps={tableProps}
					pageSize={pageSize}
				/>
				{additionalContent}
			</>
		);
	}

	function EditDrawer() {
		if (!options.edit) {
			return null;
		}
		const {
			edit: {
				width = 500,
				title = {},
				shouldFetch = true,
				fields,
				disabled = false,
				disabledReason,
				buttons,
				beforeSave = (data) => data,
				beforeSetFormFields = (data) => data,
				afterSave = (data, form) => data,
				onError = null,
				disablePasswordAutocomplete = false,
				controllerComponent: ControllerComponent,
			},
		} = options;

		// HOOKS

		const controllerComponentRef = useRef();

		// Query params
		const [createQueryParam, setCreateQueryParam] = useQueryParam(
			`create/${module}/${submodule}`,
			StringParam
		);
		const [editQueryParam, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}`,
			StringParam
		);

		const canEditPermission = usePermissions(
			module,
			submodule,
			'edit',
			editQueryParam
		);

		const [, , , , editEmitter] = useDrawer(`edit/${module}/${submodule}`);
		const [, , , , createEmitter] = useDrawer(`create/${module}/${submodule}`);

		const modelStore = stores[modelPlural];

		const [form] = Form.useForm();
		const {
			create,
			fetchSingle,
			isCreating,
			single,
			getOrFetchSingle,
			unloadSingle,
		} = modelStore;

		const [entityId, setEntityId] = useState(null);
		const [visible, setVisible] = useState(false);

		const [realVisible, currentEntityId] = useMemo(() => {
			const id = editQueryParam;

			const createModel = typeof createQueryParam !== 'undefined';
			const visible = Boolean(createModel || id);

			return [visible, visible ? id : null];
		}, [createQueryParam, editQueryParam]);

		useEffect(() => {
			if (realVisible) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}, [realVisible]);

		const { setIsDrawerClosed } = useContext(DrawerContext);

		useEffect(() => {
			if (!visible) {
				setIsDrawerClosed(`view-${model}-${submodule}`);
			}
		}, [visible]);

		useEffect(() => {
			if (currentEntityId) {
				setEntityId(currentEntityId);
			} else {
				setTimeout(() => {
					setEntityId(null);
					unloadSingle();
				}, 200);
			}
		}, [currentEntityId]);

		useEffect(() => {
			if (visible && entityId) {
				if (shouldFetch) {
					fetchSingle(entityId);
				} else {
					getOrFetchSingle(entityId);
				}
			}
		}, [entityId, visible]);

		const usedTitle = entityId
			? title.existing || 'Izmena'
			: title.new || 'Dodavanje';

		const entity = useMemo(() => {
			return entityId ? single : null;
		}, [entityId, single]);

		const [formFields, setFormFields] = useState(null);

		const rerenderOnChangeFields = useMemo(() => {
			const derivedFormFields =
				typeof fields === 'function'
					? (fields as any)(entity, form, setFields)
					: fields || [];
			return Array.isArray(derivedFormFields)
				? derivedFormFields
						.reduce((prev, curr) => {
							return [...prev, ...curr.fields];
						}, [])
						.filter((field) => field.rerenderOnChange)
						.map((field) => field.key)
				: [];
		}, [fields, entity, form, formFields]);

		const setFields = (changedFields = null) => {
			if (isValidElement(fields)) {
				setFormFields(fields);
				return;
			}
			if (
				!changedFields ||
				rerenderOnChangeFields.includes(changedFields?.[0]?.name?.[0])
			) {
				const derivedFormFields =
					typeof fields === 'function'
						? (fields as any)(entity, form, setFields)
						: fields || [];
				setFormFields(derivedFormFields);
			}
		};

		useEffect(() => {
			if (entity) {
				const transformed = beforeSetFormFields({
					...Object.getOwnPropertyNames(entity).reduce((prev, curr) => {
						prev[curr] = entity[curr];
						return prev;
					}, {}),
				});
				form.setFieldsValue(transformed);
			} else {
				form.resetFields();
			}
			setFields();
		}, [visible, entity]);

		const isLoading = useMemo(
			() =>
				visible &&
				(isCreating || entity?.isFetching || entity?.isUpdating || false),
			[
				isCreating,
				entity,
				entityId,
				visible,
				entity?.isFetching,
				entity?.isUpdating,
			]
		);

		const createTitle = useMemo(() => {
			return typeof usedTitle === 'function' ? usedTitle(entity) : usedTitle;
		}, [entity]);

		const close = () => {
			setCreateQueryParam(undefined);
			setEditQueryParam(undefined);
		};

		const save = useCallback(
			async (closeDrawer = true) => {
				try {
					const values = await form.validateFields();
					// TODO handle errors
					try {
						if (entity) {
							const response = await entity.update(beforeSave(values));
							afterSave(response, form);
							editEmitter('entity-update', response);
							if (closeDrawer) {
								close();
							}
							return response;
						} else {
							const response = await create(beforeSave(values));
							afterSave(response, form);
							createEmitter('entity-create', response);
							if (closeDrawer) {
								close();
							}
							return response;
						}
					} catch (e) {
						if (onError) {
							try {
								return onError(e, entity, form, controllerComponentRef);
							} catch (e) {
								//
							}
						}
						if (entity) {
							message.error('Došlo je do greške prilikom izmene.');
						} else {
							message.error('Došlo je do greške prilikom dodavanja.');
						}
					}
				} catch (e) {
					//
				}
			},
			[entity, form]
		);
		const focused = useRef(false);
		const Buttons = buttons;
		return (
			<Drawer
				visible={visible}
				width={width}
				title={createTitle}
				destroyOnClose
				footerStyle={{ textAlign: 'right' }}
				onClose={close}
				footer={
					!buttons ? (
						<Space>
							<Button key="cancel" onClick={close}>
								Zatvori
							</Button>
							<Button
								key="save"
								type="primary"
								loading={isCreating || isLoading}
								onClick={save}
								disabled={
									!canEditPermission ||
									isLoading ||
									(typeof disabled === 'function' ? disabled(entity) : disabled)
								}
							>
								Sačuvaj
							</Button>
						</Space>
					) : (
						<Buttons
							entity={entity}
							save={save}
							close={close}
							isCreating={isCreating}
							isLoading={isLoading}
							canEditPermission={canEditPermission}
							disabled={
								typeof disabled === 'function' ? disabled(entity) : disabled
							}
						/>
					)
				}
			>
				<Spin spinning={isLoading}>
					{visible && (
						<Form
							layout="vertical"
							form={form}
							onFinish={save}
							preserve={false}
							scrollToFirstError
							onFieldsChange={setFields}
							disabled={
								!canEditPermission ||
								(typeof disabled === 'function' ? disabled(entity) : disabled)
							}
						>
							{(typeof disabled === 'function' ? disabled(entity) : disabled) &&
								disabledReason && (
									<Form.Item>
										<Alert
											type="warning"
											message={
												typeof disabledReason === 'function'
													? disabledReason(entity)
													: disabledReason
											}
										/>
									</Form.Item>
								)}

							{disablePasswordAutocomplete && <DisablePasswordAutocomplete />}
							{isValidElement(formFields)
								? cloneElement(formFields, {
										form,
								  })
								: (formFields || []).map((row, rowIndex) => (
										<Fragment key={row.key}>
											{row.label && (
												<div className="ant-descriptions-header">
													<div className="ant-descriptions-title">
														{row.label}
													</div>
												</div>
											)}
											<Row gutter={8}>
												{row.fields.map((field, fieldIndex) => (
													<Col
														key={field.key}
														span={field.span}
														xs={field.xs}
														sm={field.sm}
														md={field.md}
														lg={field.lg}
														xl={field.xl}
														xxl={field.xxl}
														style={field.hidden && { display: 'none' }}
													>
														<Form.Item {...field} name={field.key}>
															{cloneElement(
																typeof field.component === 'function'
																	? field.component(entity)
																	: field.component,
																{
																	ref: (ref) => {
																		setTimeout(() => {
																			if (
																				ref &&
																				ref.focus &&
																				rowIndex === 0 &&
																				fieldIndex === 0 &&
																				!focused.current
																			) {
																				ref.focus();
																				focused.current = true;
																			}
																		}, 100);
																	},
																}
															)}
														</Form.Item>
													</Col>
												))}
											</Row>
										</Fragment>
								  ))}
							<input type="submit" style={{ display: 'none' }} />
						</Form>
					)}
				</Spin>
				{ControllerComponent && (
					<ControllerComponent ref={controllerComponentRef} />
				)}
			</Drawer>
		);
	}
	function ViewDrawer() {
		if (!options.view) {
			return null;
		}

		const {
			page: { deletePrompt },
			view: {
				footer,
				width = 500,
				title = 'Pregled',
				shouldFetch = true,
				fields = [],
				descriptionsProps,
			},
		} = options;

		const [viewQueryParam, setViewQueryParam] = useQueryParam(
			`view/${module}/${submodule}`,
			StringParam
		);
		const [, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}`,
			StringParam
		);

		const canEditPermission = usePermissions(module, submodule, 'edit');
		const canDeletePermission = usePermissions(module, submodule, 'delete');

		const modelStore = stores[modelPlural];

		const { fetchSingle, getOrFetchSingle, single } = modelStore;

		const [visible, setVisible] = useState(false);

		const [realVisible, entityId] = useMemo(() => {
			const id = viewQueryParam;
			const visible = Boolean(id);

			return [visible, id];
		}, [viewQueryParam]);

		useEffect(() => {
			if (realVisible) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}, [realVisible]);

		const { setIsDrawerClosed } = useContext(DrawerContext);

		useEffect(() => {
			if (!visible) {
				setIsDrawerClosed(`view-${model}-${submodule}`);
			}
		}, [visible]);

		useEffect(() => {
			if (visible && shouldFetch && entityId) {
				fetchSingle(entityId);
			} else if (visible && !shouldFetch && entityId) {
				getOrFetchSingle(entityId);
			}
		}, [entityId, visible]);

		const isLoading = false;

		const viewTitle = useMemo(() => {
			if (!visible) {
				return null;
			}
			return typeof title === 'function' ? title(single) : title;
		}, [entityId, visible, single]);

		const close = () => {
			setViewQueryParam(undefined);
		};
		const edit = () => {
			setEditQueryParam(viewQueryParam);
			close();
		};

		const onDeleteClick = useCallback(async () => {
			await single.destroy();
			close();
		}, [single]);
		return (
			<Drawer
				title={viewTitle}
				visible={visible}
				width={width}
				destroyOnClose
				footerStyle={{ textAlign: 'right' }}
				onClose={close}
				footer={
					footer ? (
						footer(single, edit, close, onDeleteClick)
					) : (
						<>
							<Space className={styles.leftButton}>
								{single?.isEditable && canEditPermission && (
									<Button key="edit" onClick={edit}>
										<EditOutlined /> Izmeni
									</Button>
								)}
								{single?.isDeletable && canDeletePermission && (
									<Popconfirm
										placement="topRight"
										title={deletePrompt}
										onConfirm={onDeleteClick}
										okText="Da"
										cancelText="Ne"
									>
										<Button key="delete" danger>
											<DeleteOutlined /> Obriši
										</Button>
									</Popconfirm>
								)}
							</Space>
							<Button key="close" type="primary" onClick={close}>
								Zatvori
							</Button>
						</>
					)
				}
			>
				<Spin spinning={isLoading}>
					{single &&
						(isValidElement(fields)
							? cloneElement(fields, {
									record: single,
							  })
							: (typeof fields === 'function'
									? (fields as any)(single)
									: fields
							  ).map((row) => (
									<>
										<Title>{row.label}</Title>
										<Row
											key={row.key}
											className={styles.descriptions}
											gutter={[8, 8]}
										>
											{row.fields.map((field) => {
												const dprops =
													row.descriptionsProps || descriptionsProps;
												const text = field.render
													? field.render(
															get(single, field.dataIndex || field.key),
															single
													  )
													: get(single, field.dataIndex || field.key);
												const viewComponent = field.component
													? cloneElement(field.component, {
															value: text,
															record: single,
													  })
													: null;

												return (
													<Col
														span={field.span && field.span * (24 / row.column)}
														xs={field.xs && field.xs * (24 / row.column)}
														sm={field.sm && field.sm * (24 / row.column)}
														md={field.md && field.md * (24 / row.column)}
														lg={field.lg && field.lg * (24 / row.column)}
														xl={field.xl && field.xl * (24 / row.column)}
														xxl={field.xxl && field.xxl * (24 / row.column)}
													>
														<Row gutter={[4, 0]}>
															<Col
																span={
																	dprops?.layout !== 'horizontal'
																		? 24
																		: undefined
																}
															>
																<Typography.Text strong>
																	{field.label}
																	{field.label ? ':' : ''}
																</Typography.Text>
															</Col>
															<Col
																flex="auto"
																style={dprops?.contentStyle}
																span={
																	dprops?.layout !== 'horizontal'
																		? 24
																		: undefined
																}
															>
																{viewComponent || nl2br(text) || (
																	<Typography.Text disabled>
																		Nije dostupno
																	</Typography.Text>
																)}
															</Col>
														</Row>
													</Col>
												);
											})}
										</Row>
									</>
							  )))}
				</Spin>
			</Drawer>
		);
	}

	const WrappedEditDrawer = observer(EditDrawer);
	const WrappedViewDrawer = observer(ViewDrawer);

	drawers[`create/${module}/${submodule}`] = WrappedEditDrawer;
	drawers[`edit/${module}/${submodule}`] = WrappedEditDrawer;
	drawers[`view/${module}/${submodule}`] = WrappedViewDrawer;

	Object.entries(pageDrawers).forEach(([key, Drawer]) => {
		drawers[key] = Drawer;
	});

	const ObservedPage = observer(Page, { forwardRef: true });
	ObservedPage.whyDidYouRender = true;
	return {
		Page: ObservedPage,
	};
}

export function useDrawer(
	drawerName,
	listener?: (event: string, data: any, queryParam: any) => void,
	trackVisibility = true
): [
	queryParam: string,
	open: (param?: string | number) => void,
	close: () => void,
	visible: boolean,
	emitter: (event: string, data: unknown, queryParam?: unknown) => void,
	drawerComponent: any
] {
	const [drawerQueryParam, setDrawerQueryParam] = useQueryParam(
		drawerName,
		StringParam
	);
	const bus = useBus();
	const { setIsDrawerClosed, setIsDrawerOpen } = useContext(DrawerContext);

	const openDrawer = useCallback(
		(param?: string | number) => {
			setDrawerQueryParam(`${param}`);
		},
		[setDrawerQueryParam]
	);

	const closeDrawer = useCallback(() => {
		setDrawerQueryParam(undefined);
		setIsDrawerClosed(drawerName);
	}, [setDrawerQueryParam]);

	const emitMessage = useCallback(
		(event: string, data: unknown, queryParam?: unknown) => {
			bus.emit(drawerName, [event, data, queryParam]);
		},
		[bus, drawerName]
	);

	useListener(drawerName, (event: [string, unknown]) => {
		if (listener) {
			listener(...event);
		}
	});

	const visible = useMemo(() => {
		return Boolean(drawerQueryParam);
	}, [drawerQueryParam]);

	useEffect(() => {
		if (!visible) {
			setIsDrawerClosed(drawerName);
		} else {
			setIsDrawerOpen(drawerName);
		}
	}, [visible]);

	return [
		trackVisibility ? drawerQueryParam : undefined,
		openDrawer,
		closeDrawer,
		trackVisibility ? visible : false,
		emitMessage,
		drawers[drawerName],
	];
}
