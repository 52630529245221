import { Button, Drawer, Input, message, Space, Table } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import stores from '../../../../stores/index.mobx';
import styles from './AdvanceSpecification.module.less';
interface Props {
	visible: boolean;
	labels: string[];
	onCancel: () => void;
	onSave: (data: any) => void;
	sum: number;
}

const AdvanceSpecification = ({
	visible,
	onCancel,
	onSave,
	labels,
	sum,
}: Props) => {
	const [data, setData] = useState([]);
	const { taxRates } = stores;
	useEffect(() => {
		setData(
			labels.map((label) => {
				return {
					label,
					amount: 0,
				};
			})
		);
	}, [labels, labels?.length]);

	return (
		<Drawer
			destroyOnClose
			visible={visible}
			title={`Specifikacija avansa`}
			width={500}
			bodyStyle={{ padding: 0, marginTop: -1, marginBottom: -1 }}
			// onClose={close}
			// zIndex={zIndex}
			onClose={onCancel}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={onCancel}>Odustani</Button>
					</Space>
					<Space className={styles.rightButtons}>
						<Button
							type="primary"
							onClick={() => {
								if (
									sum !==
									round(
										data.reduce((acc, curr) => acc + Number(curr.amount), 0),
										2
									)
								) {
									return message.error(
										'Suma svih iznosa mora biti jednaka uplaćenom avansu!'
									);
								}

								onSave(data);
							}}
						>
							Potvrdi
						</Button>
					</Space>
				</>
			}
			className="noPadding"
		>
			<Table
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				sticky
				rowKey={(record) => record.label}
				columns={[
					{
						title: 'Poreska oznaka',
						dataIndex: 'label',
						key: 'label',
						width: 150,
						render: (label) => `${label} - ${taxRates.byLabel(label)?.name}`,
					},
					{
						title: 'Iznos',
						key: 'amount',
						render: (_, record, index) => (
							<Input
								onClick={(e) => e.currentTarget.select()}
								type="number"
								style={{ textAlign: 'right' }}
								value={record.amount}
								onChange={(e) => {
									const value = e.target.value;
									setData((data) => {
										const newData = [...data];
										newData[index].amount = value;
										return newData;
									});
								}}
							/>
						),
						align: 'right',
					},
				]}
			></Table>
		</Drawer>
	);
};

const ObservedAdvanceSpecification = observer(AdvanceSpecification);

export default ObservedAdvanceSpecification;
