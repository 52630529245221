import { Button, Form, Input, Modal, Result } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import { STATUS_CODES, STATUS_PER_MRC } from '../../constants/esdc';
import stores from '../../stores/index.mobx';
import styles from './SDCStatusModal.module.less';

function SDCStatusModal() {
	const {
		sdc: {
			esdcStatus,
			currentESDCError,
			resolveDeferred,
			rejectDeferred,
			pinVerify,
			setESDCError,
		},
	} = stores;

	const [pin, setPin] = useState('');

	const [statusText, statusIcon] = useMemo(() => {
		if (!currentESDCError) {
			return [];
		}

		const status = STATUS_CODES[`${currentESDCError}`];
		if (!status) {
			const MRC = esdcStatus?.deviceSerialNumber?.substring?.(0, 7);
			if (!MRC) {
				return [`Nepoznata greška (${currentESDCError})`, 'question-mark'];
			}
			const mscStatus = STATUS_PER_MRC[MRC][`${currentESDCError}`];
			if (!mscStatus) {
				return [`Nepoznata greška (${currentESDCError})`, 'question-mark'];
			}
			return mscStatus;
		}
		return status;
	}, [currentESDCError]);

	useEffect(() => {
		setPin('');
	}, [currentESDCError]);

	return (
		<Modal
			centered
			visible={!!currentESDCError}
			width={600}
			footer={null}
			// zIndex={1501}
			closable={false}
			destroyOnClose={true}
			title={
				['1500', '2100'].includes(currentESDCError)
					? null
					: 'Greška lokalnog procesora fiskalnih računa'
			}
		>
			{!!currentESDCError && (
				<Result
					className={styles.result}
					icon={<img src={`/images/icons/esdc/${statusIcon}.png`} />}
					title={statusText}
					subTitle={
						['1500', '2100'].includes(currentESDCError) ? (
							<>
								<Form.Item>
									<Input
										className={styles.pin}
										type="password"
										maxLength={4}
										inputMode="numeric"
										autoComplete="false"
										ref={(input) => {
											input && setTimeout(() => input.focus());
										}}
										value={pin}
										onChange={(e) => setPin(e.target.value)}
										onPressEnter={() => pinVerify(pin)}
									/>
								</Form.Item>
								<Form.Item>
									<strong>Pažnja:</strong> Unosom pogrešnog PIN koda 5 puta
									uzastopno, blokiraćete bezbednosni element i on više neće moći
									da se koristi!
								</Form.Item>
							</>
						) : null
					}
					extra={[
						<Button
							key="cancel"
							onClick={() => {
								rejectDeferred('user canceled');
								setESDCError(null);
							}}
						>
							Odustani
						</Button>,
						!['1500', '2100'].includes(currentESDCError) ? (
							<Button
								key="retry"
								type="primary"
								onClick={() => resolveDeferred()}
							>
								Pokušaj ponovo
							</Button>
						) : (
							<Button key="ok" type="primary" onClick={() => pinVerify(pin)}>
								Pošalji
							</Button>
						),
					]}
				/>
			)}
		</Modal>
	);
}

export default observer(SDCStatusModal);
