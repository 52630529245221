import {
	CheckOutlined,
	CloseOutlined,
	DownloadOutlined,
	FileImageOutlined,
	FileOutlined,
	FilePdfOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Badge,
	Button,
	DatePicker,
	Dropdown,
	Input,
	InputNumber,
	Menu,
	Select,
	Space,
	Tooltip,
	Grid,
	Alert,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Fragment } from 'react';
import nl2br from 'react-nl2br';

import CurrencySelect from '../../../../components/CurrencySelect';
import InvoiceItemsView from '../../../../components/InvoiceCommon/InvoiceItemsView';
import PartnerView from '../../../../components/InvoiceCommon/PartnerView';
import InvoiceSummaryView from '../../../../components/InvoiceSummaryView';
import { CreatePage } from '../../../../components/Page';
import pageStyles from '../../../../components/Page/Page.module.less';
import PartnerSelect from '../../../../components/Selects/PartnerSelect';
import config from '../../../../config.json';
import {
	DOCUMENT_TYPE_MAP,
	INVOICE_STATUS,
	INVOICE_STATUS_MAP,
} from '../../../../constants/documentInvoice';
import numberFormat from '../../../../lib/numberFormat';
import { v2Client } from '../../../../store/client';
import stores from '../../../../stores/index.mobx';
import { Invoice } from '../../../../stores/Invoice.mobx';
import { Partner } from '../../../../stores/Partner.mobx';
import AcceptRejectModal from './Components/AcceptRejectModal';
import CassaScontoInput from './Components/CassaScontoInput';
import CassaScontoView from './Components/CassaScontoView';
import PayButton from './Components/PayButton';
import ReferenceInput from './Components/ReferenceInput';
import ReferenceView from './Components/ReferenceView';

interface Props {
	record: Invoice;
	close: () => void;
}

const InvoiceButtons = observer(({ record, close }: Props) => {
	const [acceptModalVisible, setAcceptModalVisible] = useState(false);
	const [rejectModalVisible, setRejectModalVisible] = useState(false);

	const screens = Grid.useBreakpoint();

	const menu = useMemo(() => {
		if (!record) {
			return [];
		}

		const menuItems = [
			{
				type: 'item',
				label: 'Preuzmi XML',
				key: 'xml',
				icon: <FileTextOutlined />,
				disabled: !record.xmlFile,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.xmlFile.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			},
		];

		if ((record.attachments?.length || 0) > 0) {
			menuItems.push({
				label: 'Prilozi',
				key: 'attachments',
				type: 'divider',
				onClick: null,
				disabled: null,
				icon: null,
			});

			record.attachments.forEach((attachment) => {
				let attachmentIcon = <FileOutlined />;
				if (attachment.mimetype === 'application/xml') {
					attachmentIcon = <FileTextOutlined />;
				} else if (attachment.mimetype === 'application/pdf') {
					attachmentIcon = <FilePdfOutlined />;
				} else if (attachment.mimetype.startsWith('image/')) {
					attachmentIcon = <FileImageOutlined />;
				}

				menuItems.push({
					type: 'item',
					label: `Preuzmi prilog ${
						attachment.originalFilename || attachment.filename
					}`,
					key: attachment.id,
					icon: attachmentIcon,
					disabled: false,
					onClick: () => {
						window.open(
							`${config.apiV2}/files/${attachment.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
						);
					},
				});
			});
		}

		if (
			screens.xs &&
			!screens.sm &&
			stores.marketplaceApplications.isInstalled('rs.storebuddy.efaktura')
		) {
			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN ||
				record.status === INVOICE_STATUS.REJECTED
			) {
				menuItems.push({
					type: 'divider',
					label: '',
					key: 'divider',
					icon: null,
					disabled: false,
					onClick: null,
				});
			}

			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN
			) {
				menuItems.push({
					type: 'item',
					label: `Odbij`,
					key: 'reject',
					icon: <CloseOutlined />,
					disabled: record.isUpdating,
					danger: true,
					onClick: () => {
						setRejectModalVisible(true);
					},
				});
			}

			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN ||
				record.status === INVOICE_STATUS.REJECTED
			) {
				menuItems.push({
					type: 'item',
					label: `Prihvati`,
					key: 'approve',
					icon: <CheckOutlined />,
					disabled: record.isUpdating,
					onClick: () => {
						setAcceptModalVisible(true);
					},
				});
			}
		}

		return (
			<Menu
				onClick={({ key }) => {
					menuItems.find((item) => item.key === key)?.onClick();
				}}
				items={menuItems}
			/>
		);
	}, [
		record,
		record?.status,
		record?.isUpdating,
		stores?.marketplaceApplications,
		rejectModalVisible,
		acceptModalVisible,
		screens,
	]);

	if (!record) {
		return null;
	}

	return (
		<>
			<Space className={pageStyles.leftButton}>
				{record.file && (
					<Dropdown.Button
						overlay={menu}
						target="_blank"
						href={`${config.apiV2}/files/${record.file.id}?token=${v2Client.defaults.headers.common['x-access-token']}`}
					>
						<DownloadOutlined /> Preuzmi PDF
					</Dropdown.Button>
				)}
				{screens.sm &&
					stores.marketplaceApplications.isInstalled(
						'rs.storebuddy.efaktura'
					) &&
					(record.status === INVOICE_STATUS.NEW ||
						record.status === INVOICE_STATUS.SEEN) && (
						<Button
							loading={record.isUpdating}
							disabled={record.isUpdating}
							danger
							icon={<CloseOutlined />}
							onClick={() => {
								setRejectModalVisible(true);
							}}
						>
							Odbij
						</Button>
					)}
				{screens.sm &&
					stores.marketplaceApplications.isInstalled(
						'rs.storebuddy.efaktura'
					) &&
					(record.status === INVOICE_STATUS.NEW ||
						record.status === INVOICE_STATUS.SEEN ||
						record.status === INVOICE_STATUS.REJECTED) && (
						<Button
							loading={record.isUpdating}
							disabled={record.isUpdating}
							icon={<CheckOutlined />}
							onClick={() => setAcceptModalVisible(true)}
						>
							Prihvati
						</Button>
					)}
			</Space>
			<Button key="close" type="primary" onClick={close}>
				Zatvori
			</Button>

			<AcceptRejectModal
				visible={acceptModalVisible}
				title="Prihvati dokument"
				okText="Prihvati"
				cancelText="Poništi"
				onOk={({ comment }) => {
					record.accept({ comment });
				}}
				close={() => setAcceptModalVisible(false)}
				record={record}
			/>
			<AcceptRejectModal
				visible={rejectModalVisible}
				title="Odbij dokument"
				okText="Odbij"
				cancelText="Poništi"
				onOk={({ comment }) => {
					record.reject({ comment });
				}}
				isCommentRequired
				close={() => setRejectModalVisible(false)}
				record={record}
			/>
		</>
	);
});

export default CreatePage({
	module: 'documents',
	submodule: 'incoming-invoices',
	model: 'invoice',
	view: {
		width: 1200,
		shouldFetch: true,
		footer: (single, edit, close) => (
			<InvoiceButtons record={single} close={close} />
		),
		title: (entity) => `Pregled dokumenta ${entity?.number || ''}`,
		// fields: <ViewInvoice />,
		fields: (record) => [
			{
				key: 'info',
				label: 'Informacije',
				column: 12,
				fields: [
					{
						key: 'documentType',
						label: 'Tip dokumenta',
						md: 3,
						xs: 12,
						render: (text) => (text ? DOCUMENT_TYPE_MAP[text] : 'Nepoznat'),
					},
					{
						key: 'partner',
						label: 'Prodavac',
						md: 9,
						xs: 12,
						component: <PartnerView />,
					},
					{
						key: 'number',
						label: 'Broj dokumenta',
						md: 3,
						sm: 6,
						xs: 12,
					},
					{
						key: 'reference',
						label: 'Poziv na broj',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text, record) =>
							record.reference?.number && record.reference?.number !== '' ? (
								<ReferenceView value={record.reference} />
							) : null,
					},
					{
						key: 'date',
						label: 'Datum prometa',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text) => moment(text).format('LL'),
					},
					{
						key: 'dueDate',
						label: 'Datum valute',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text) => (text ? moment(text).format('LL') : null),
					},
				],
			},
			{
				key: 'payment',
				label: 'Plaćanje',
				column: 12,
				fields: [
					{
						key: 'payableAmount',
						label: 'Iznos',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text) => numberFormat(text, true),
					},
					{
						key: 'amountWithCassaSconto',
						label: 'Iznos za plaćanje u roku',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text, record) =>
							numberFormat(text, true, 2, true, record.currencyId),
					},
					{
						key: 'paidAmount',
						label: 'Plaćeno',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text, record) =>
							numberFormat(text, true, 2, true, record.currencyId),
					},
					{
						label: 'Preostalo',
						md: 3,
						sm: 6,
						xs: 12,
						render: (text, record) =>
							numberFormat(
								record.amountWithCassaSconto - record.paidAmount,
								true,
								2,
								true,
								record.currencyId
							),
					},
					...(record?.paymentMeans?.length > 0 ||
					record?.partner?.bankAccounts?.length > 0
						? [
								{
									key: 'paymentMethod',
									label: 'Računi za uplatu',
									span: 12,
									render: (text, record) => (
										<>
											{record.paymentMeans?.length > 0
												? record.paymentMeans
														.filter((pm) => pm.startsWith('30'))
														.map((pm, i) => (
															<div>
																{pm
																	.split(':')[1]
																	.replace(
																		/^(\d{3})(\d.*)(\d{2})$/,
																		'$1-$2-$3'
																	)}
															</div>
														))
												: record.partner?.bankAccounts?.map((ba, i) => (
														<div>{ba.formattedNumber}</div>
												  ))}
										</>
									),
								},
						  ]
						: []),
					{
						key: 'cassaSconto',
						label: (
							<>
								Iznosi za plaćanje u roku{' '}
								<Tooltip title="Ukoliko je prodavac naveo posebne uslove za plaćanje u određenom roku, možete ih navesti ovde.">
									<InfoCircleOutlined />
								</Tooltip>
							</>
						),
						span: 12,
						component: <CassaScontoView />,
					},
				],
			},
			...(record.invoiceItems?.length > 0
				? [
						{
							key: 'items',
							label: 'Stavke fakture',
							column: 12,

							fields: [
								{
									key: 'invoiceItems',
									span: 12,
									component: <InvoiceItemsView />,
								},
								{
									key: 'taxBreakdown',
									span: 12,
									component: <InvoiceSummaryView record={record} />,
								},
							],
						},
				  ]
				: []),
			...(record.note
				? [
						{
							key: 'notes',
							label: 'Napomena',
							column: 12,
							fields: [
								{
									key: 'note',
									span: 24,
									render: (text) => nl2br(text),
								},
							],
						},
				  ]
				: []),
		],
	},
	edit: {
		width: 800,
		title: {
			new: 'Dodavanje ulaznog dokumenta',
			existing: (entity) => `Izmena ulaznog dokumenta ${entity?.number || ''}`,
		},
		shouldFetch: true,
		beforeSave: (entity) => {
			entity.storeId = stores.stores.currentStoreId;
			return entity;
		},
		disabled: (entity) => {
			return entity?.externalId;
		},
		disabledReason: (entity) => {
			return 'Dokument je uvezen iz drugog sistema i ne može se menjati';
		},
		fields: (record, form, setFields) => [
			...(stores.marketplaceApplications.isInstalled('rs.storebuddy.efaktura')
				? [
						{
							key: 'notice',
							column: 12,
							fields: [
								{
									key: 'notice',
									span: 24,
									component: (
										<Alert
											description="Ukoliko ste korisnik sistema e-Faktura, koristite automatski ili ručni uvoz dokumenata. Ručno dodavanje dokumenta koristite ukoliko ste dobili fakturu od prodavca koji nije korisnik sistema e-Faktura."
											type="info"
											showIcon
										/>
									),
								},
							],
						},
				  ]
				: []),
			{
				key: 'row1',
				label: 'Osnovne informacije',
				fields: [
					{
						key: 'documentType',
						label: 'Tip dokumenta',
						rules: [
							{
								required: true,
								message: 'Tip dokumenta je obavezan',
							},
						],
						component: (
							<Select>
								{Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => (
									<Select.Option value={key}>{value}</Select.Option>
								))}
							</Select>
						),
						xs: 24,
						sm: 6,
					},
					{
						key: 'partnerId',
						label: 'Prodavac',
						rules: [
							{
								required: true,
								message: 'Prodavac je obavezan',
							},
						],
						component: <PartnerSelect />,
						xs: 24,
						sm: 18,
					},
					{
						key: 'date',
						label: 'Datum prometa',
						xs: 24,
						sm: 8,
						rules: [
							{
								required: true,
								message: 'Datum prometa fakture je obavezan',
							},
						],
						component: <DatePicker style={{ width: '100%' }} format="L" />,
					},
					{
						key: 'issueDate',
						label: 'Datum izdavanja',
						xs: 24,
						sm: 8,
						rules: [
							{
								required: true,
								message: 'Datum izdavanja fakture je obavezan',
							},
						],
						component: <DatePicker style={{ width: '100%' }} format="L" />,
					},
					{
						key: 'dueDate',
						label: 'Datum valute',
						xs: 24,
						sm: 8,
						rules: [
							{
								required: true,
								message: 'Datum valute je obavezan',
							},
						],
						component: <DatePicker style={{ width: '100%' }} format="L" />,
					},
				],
			},
			{
				key: 'documentNumber',
				fields: [
					{
						key: 'number',
						label: 'Broj dokumenta',
						xs: 24,
						sm: 12,
						rules: [
							{
								required: true,
								message: 'Broj dokumenta je obavezan',
							},
						],
						component: <Input />,
					},
					{
						key: 'reference',
						label: 'Poziv na broj',
						xs: 24,
						sm: 12,
						rules: [],
						component: <ReferenceInput />,
					},
				],
			},
			{
				key: 'row4',
				fields: [
					{
						key: 'currencyId',
						label: 'Valuta',
						hidden: true,
						component: <CurrencySelect />,
						rerenderOnChange: true,
					},
					{
						key: 'amount',
						label: 'Iznos',
						span: 12,
						rules: [
							{
								required: true,
								message: 'Iznos je obavezan',
							},
						],
						component: (
							<InputNumber
								style={{ width: '100%' }}
								addonAfter={
									<CurrencySelect
										style={{ width: 75 }}
										value={form.getFieldValue('currencyId') || 'RSD'}
										optionLabelProp="value"
										onChange={(value) => {
											form.setFieldsValue({ currencyId: value });
											setFields?.([{ name: ['currencyId'] }]);
										}}
									/>
								}
								lang="sr"
								step={0.01}
							/>
						),
					},
					{
						key: 'taxAmount',
						label: 'Iznos poreza',
						span: 12,
						rules: [
							{
								required: true,
								message: 'Iznos poreza je obavezan',
							},
						],
						component: (
							<InputNumber
								style={{ width: '100%' }}
								addonAfter={
									<CurrencySelect
										style={{ width: 75 }}
										value={form.getFieldValue('currencyId') || 'RSD'}
										optionLabelProp="value"
										onChange={(value) => {
											form.setFieldsValue({ currencyId: value });
											setFields?.([{ name: ['currencyId'] }]);
										}}
									/>
								}
								lang="sr"
								step={0.01}
							/>
						),
					},
					// {
					// 	key: 'type',
					// 	label: 'Tip',
					// 	span: 12,
					// 	component: (
					// 		<Select
					// 			mode="multiple"
					// 			style={{ width: '100%' }}
					// 			placeholder="Izaberite tipove"
					// 		>
					// 			<Select.Option value="goods">Ulaz robe</Select.Option>
					// 			<Select.Option value="assets">Osnovna sredstva</Select.Option>
					// 			<Select.Option value="supplies">Potrošna roba</Select.Option>
					// 			<Select.Option value="services">Usluge</Select.Option>
					// 		</Select>
					// 	),
					// },
				],
			},

			{
				key: 'row5',
				fields: [
					{
						key: 'cassaSconto',
						label: 'Iznosi za plaćanje u roku',
						rules: [],
						component: <CassaScontoInput />,
						span: 24,
						initialValue: [],
					},
				],
			},
		],
	},
	page: {
		additionalQueryParams: {
			direction: 'incoming',
		},
		shouldFetch: true,
		table: {
			props: {
				scroll: { x: 1900 },
			},
			actions: [(record) => <PayButton record={record} />],
			showDisabledActions: true,
			columns: () => [
				{
					title: 'Broj dokumenta',
					dataIndex: 'number',
					key: 'number',
					width: 220,
					shouldLink: true,
					ellipsis: true,
					filterKey: 'number',
					render: (text, record) => (
						<>
							{text}
							{record.externalId && (
								<img
									src="/images/icons/efaktura-logo.png"
									style={{ float: 'right', marginTop: '4px' }}
									title="e-Faktura"
								/>
							)}
						</>
					),
					filterIcon: (filtered) => (
						<SearchOutlined
							style={{ color: filtered ? '#7E89C8' : undefined }}
						/>
					),
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<Input
									placeholder="Broj dokumenta"
									value={selectedKeys[0]}
									onPressEnter={() => confirm({ closeDropdown: true })}
									onChange={(event) => setSelectedKeys([event.target.value])}
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Tip dokumenta',
					dataIndex: 'documentType',
					key: 'documentType',
					ellipsis: true,
					width: 150,
					render: (text) => {
						return DOCUMENT_TYPE_MAP[text] || 'Nepoznat';
					},
					filters: Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => ({
						text: value,
						value: key,
					})),
					filterMultiple: true,
					filterKey: 'documentType',
				},
				// {
				// 	title: 'Poziv na broj',
				// 	key: 'reference',
				// 	ellipsis: true,
				// 	render: (record) =>
				// 		record.reference &&
				// 		`${record.reference.model ? `(${record.reference.model})` : ''} ${
				// 			record.reference.number
				// 		}`,
				// },
				{
					title: 'Status',
					dataIndex: 'status',
					key: 'status',
					filterKey: 'status',
					width: 120,
					filters: Object.entries(INVOICE_STATUS_MAP).map(([key, value]) => ({
						text: value,
						value: key,
					})),
					filterMultiple: true,
					render: (text) => {
						return INVOICE_STATUS_MAP[text] || 'Nepoznato';
					},
				},
				{
					title: 'Prodavac',
					dataIndex: ['partner', 'name'],
					key: 'partnerId',
					filterKey: 'partnerId',
					filters: () =>
						stores.partners.list?.map((partner: Partner) => ({
							text: partner.name,
							value: partner.id,
						})),
					filterMultiple: true,
					filterSearch: true,
					ellipsis: true,
				},
				{
					title: 'Iznos',
					width: 160,
					key: 'amountWithCassaSconto',
					dataIndex: 'amountWithCassaSconto',
					render: (text, record) =>
						numberFormat(text, true, 2, true, record.currencyId),
				},
				{
					title: 'Preostalo',
					width: 160,
					key: 'remainingAmount',
					dataIndex: 'remainingAmount',
					render: (text, record) =>
						numberFormat(
							record.remainingAmount,
							true,
							2,
							true,
							record.currencyId
						),
				},
				{
					title: 'Datum prometa',
					width: 170,
					key: 'date',
					dataIndex: 'date',
					render: (text) => moment(text).format('LL'),
					filterKey: 'date',
					sorter: true,
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Datum valute',
					width: 170,
					key: 'dueDate',
					dataIndex: 'dueDateWithCassaSconto',
					render: (text, record) => {
						if (!text) {
							return '';
						}
						const badgeColor =
							record.cassaSconto && record.cassaSconto.length
								? 'yellow'
								: 'green';
						return (
							<Tooltip
								title={
									record.cassaSconto && record.cassaSconto.length
										? [
												...record.cassaSconto,
												{
													dueDate: record.dueDate,
													amount: record.payableAmount,
												},
										  ].map((cassa) => (
												<Fragment key={cassa.dueDate}>
													{moment(cassa.dueDate).format('LL')}:{' '}
													{numberFormat(cassa.amount, true)}
													<br />
												</Fragment>
										  ))
										: undefined
								}
							>
								<Badge
									color={
										moment().endOf('day').isAfter(moment(text).endOf('day'))
											? 'red'
											: badgeColor
									}
									text={moment(text).format('LL')}
								/>
							</Tooltip>
						);
					},
					sorter: true,
					filterKey: 'dueDate',
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Plaćeno',
					width: 110,
					key: 'paid',
					filters: [
						{
							text: <Badge color="green" text="Plaćeno" />,
							value: 'yes',
						},
						{
							text: <Badge color="orange" text="Delimično" />,
							value: 'partial',
						},
						{
							text: <Badge color="red" text="Nije plaćeno" />,
							value: 'no',
						},
					],
					defaultFilteredValue: ['no', 'partial'],
					filterKey: 'paid',
					filterMultiple: true,
					render: (record) => {
						if (!record.paid && record.partiallyPaid) {
							return <Badge color="orange" text="Delimično" />;
						}

						return record.paid ? (
							<Badge color="green" text="Plaćeno" />
						) : (
							<Badge color="red" text="Nije plaćeno" />
						);
					},
				},
			],
		},
		createButtonText: 'Dodaj ulazni dokument',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj dokument?',
	},
});
