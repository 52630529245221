import { makeObservable, observable, computed } from 'mobx';
import moment from 'moment';
import { Moment } from 'moment';

import { CreateStore } from './Crud.mobx';
import { MomentTransformer } from './transformers/Moment';

const { Store, Entity } = CreateStore({
	name: 'taxRate',
	paginated: false,
	persistFields: ['all'],
});

class TaxRate extends Entity {
	@observable name?: string;
	@observable rate?: number;
	@observable groupId?: number;
	@observable label?: string;
	@MomentTransformer
	validFrom?: Moment | void;
	@observable noVat?: boolean;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@computed
	get validUntil() {
		const allRates = this.getParent().available;
		const sortedRates = allRates.slice();
		sortedRates.sort((a, b) => a.validFrom.valueOf() - b.validFrom.valueOf());
		const nextRate = sortedRates.find(
			(rate) =>
				this.validFrom &&
				this.validFrom.isBefore(rate.validFrom) &&
				rate.label === this.label
		);
		return nextRate?.validFrom;
	}

	get isDeletable(): boolean {
		return false;
	}

	get isEditable(): boolean {
		return false;
	}

	@computed
	get isActive(): boolean {
		return (
			this.validFrom &&
			this.validFrom.isBefore(moment()) &&
			(!this.validUntil || this.validUntil.isAfter(moment()))
		);
	}

	@computed
	get isNoVat() {
		return this.noVat;
	}
}

class TaxRates extends Store<TaxRate> {
	constructor() {
		super(TaxRate);
	}

	@computed
	get active() {
		return this.available.filter((item) => item.isActive);
	}

	@computed({ keepAlive: true })
	get noVat() {
		return this.active.filter((item) => item.isNoVat);
	}

	@computed({ keepAlive: true })
	get vat() {
		return this.active.filter((item) => !item.isNoVat);
	}

	byLabel(label: string) {
		return this.available.find((item) => item.label === label);
	}

	get isCreatable() {
		return false;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { TaxRates, TaxRate };
