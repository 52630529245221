import { Alert, Checkbox, Input, Select } from 'antd';
import gs1 from 'gs1-key';

import { CreatePage } from '../../../../../components/Page';
import WarehouseSelect from '../../../../../components/WarehouseSelect';
import { PAYMENT_TYPE_MAP } from '../../../../../constants/journal';
import stores from '../../../../../stores/index.mobx';
import PaymentMethodsOrder from './Components/PaymentMethodsOrder';

const { company } = stores;

const hasFeature = company?.hasFeature;

export default CreatePage({
	module: 'settings',
	submodule: 'stores',
	model: 'store',
	view: {
		useEdit: true,
	},
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: true,
				},
				{
					title: 'Adresa',
					dataIndex: 'fullAddress',
					ellipsis: true,
					key: 'fullAddress',
				},
				{
					title: 'Telefon',
					dataIndex: 'phone',
					key: 'phone',
				},
				...(hasFeature?.('stock')
					? [
							{
								title: 'Magacin',
								dataIndex: ['warehouse', 'name'],
								key: 'warehouse',
							},
					  ]
					: []),
			],
		},
		createButtonText: 'Dodaj prodajno mesto',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovo prodajno mesto?',
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje prodajnog mesta',
			existing: (entity) => `Izmena prodajnog mesta ${entity?.name || ''}`,
		},
		shouldFetch: true,
		beforeSave: (entity) => {
			const paymentMethodsOrder = entity.paymentMethodsOrder;
			Object.keys(PAYMENT_TYPE_MAP).forEach((paymentType) => {
				if (!paymentMethodsOrder.includes(Number(paymentType))) {
					paymentMethodsOrder.push(Number(paymentType));
				}
			});
			return {
				...entity,
			};
		},
		fields: () => [
			{
				key: 'basic',
				label: 'Osnovni podaci',
				fields: [
					{
						key: 'info',
						component: (
							<Alert
								type="info"
								message="
								Naziv i adresa prodajnog mesta se koriste za identifikaciju
								prodajnog mesta u aplikaciji. Na fiskalnim računima će biti
								odštampani podaci koji su upisani na bezbednosnom elementu."
							/>
						),
						span: 24,
					},
					{
						key: 'name',
						label: 'Naziv',
						rules: [
							{
								required: true,
								message: 'Naziv prodajnog mesta je obavezan',
							},
						],
						component: <Input />,
						span: 24,
					},
					{
						key: 'address',
						label: 'Adresa',
						component: <Input />,
						span: 24,
					},
					{
						key: 'zip',
						label: 'Poštanski broj',
						component: <Input />,
						xs: 24,
						sm: 12,
					},
					{
						key: 'city',
						label: 'Grad',
						component: <Input />,
						xs: 24,
						sm: 12,
					},
					{
						key: 'gln',
						label: 'GLN',
						component: <Input />,
						span: 24,
						rules: [
							{
								validator: (_, value) => {
									if (!value || value.length === 0) {
										return Promise.resolve();
									}

									if (!gs1.validate(value)) {
										return Promise.reject();
									}
									return Promise.resolve();
								},
								message: 'GLN nije ispravan',
							},
						],
					},
					{
						key: 'phone',
						label: 'Telefon',
						component: <Input />,
						span: 24,
					},
					...(hasFeature?.('stock')
						? [
								{
									key: 'warehouseId',
									label: 'Magacin',
									component: <WarehouseSelect />,
								},
						  ]
						: []),
				],
			},
			{
				key: 'print',
				label: 'Načini izdavanja računa',
				fields: [
					{
						key: ['printMethod', 'thermal'],
						valuePropName: 'checked',
						initialValue: true,
						component: <Checkbox>Termalni štampač</Checkbox>,
						style: { marginBottom: '0px' },
					},
					{
						key: ['printMethod', 'a4'],
						initialValue: true,
						valuePropName: 'checked',
						component: <Checkbox>A4 štampač</Checkbox>,
						style: { marginBottom: '0px' },
					},
					{
						key: ['printMethod', 'email'],
						initialValue: true,
						valuePropName: 'checked',
						component: <Checkbox>E-Mail</Checkbox>,
					},
				],
			},
			{
				key: 'paymentMethods',
				label: 'Načini plaćanja',
				fields: [
					{
						label: 'Redosled načina plaćanja',
						key: 'paymentMethodsOrder',
						initialValue: [1, 3, 2, -1, 4, 5, 6, 0],
						component: <PaymentMethodsOrder />,
					},
				],
			},
			{
				key: 'fiscalReceipt',
				label: 'Fiskalni račun',
				fields: [
					{
						key: 'printBankAccounts',
						valuePropName: 'checked',
						span: 24,
						component: (
							<Checkbox style={{ width: '100%' }}>
								Štampaj brojeve tekućeg računa preduzeća ukoliko je vrsta
								plaćanja prenos na račun
							</Checkbox>
						),
					},
					{
						key: 'printDiscounts',
						valuePropName: 'checked',
						span: 24,
						component: (
							<Checkbox style={{ width: '100%' }}>
								Štampaj ostvarene popuste
							</Checkbox>
						),
					},
					{
						key: 'language',
						label: 'Jezik',
						initialValue: 'sr-Cyrl-RS',
						component: (
							<Select>
								<Select.Option value="sr-Cyrl-RS">
									Српски (ћирилица)
								</Select.Option>
								<Select.Option value="sr-Latn-RS">
									Srpski (latinica)
								</Select.Option>
								<Select.Option value="en-US">English</Select.Option>
							</Select>
						),
					},
					{
						key: 'defaultUnknownAmountAdvance',
						valuePropName: 'checked',
						component: (
							<Checkbox>
								Podrazumevano izdavanje avansa bez poznatog konačnog iznosa
							</Checkbox>
						),
					},
				],
			},
			{
				key: 'settings',
				label: 'Podešavanja',
				fields: [
					{
						label: 'Način prikaza proizvoda na kasi',
						key: 'productPickerType',
						initialValue: 'list',
						component: (
							<Select>
								<Select.Option value="list">Lista</Select.Option>
								<Select.Option value="grid">Mreža</Select.Option>
							</Select>
						),
					},
					{
						key: 'isSoundEnabled',
						valuePropName: 'checked',
						component: (
							<Checkbox>
								Reprodukuj zvučne signale prilikom dodavanja i brisanja artikala
								sa računa, kao i prilikom greške pri dodavanju artikla na račun
							</Checkbox>
						),
					},
				],
			},
		],
	},
});
