import React, {
	Fragment,
	ReactNode,
	useImperativeHandle,
	useState,
	forwardRef,
	useEffect,
} from 'react';

import { APPLICATION_NAME } from '../../constants/application';
import UserMenu from '../UserMenu';
import styles from './Header.module.less';

type Props = {
	breadcrumb: {
		[key: string]: {
			name: string;
			icon?: ReactNode;
			hideHeader?: boolean;
		};
	};
	location: {
		pathname: string;
	};
};

export function parseProps(props) {
	const {
		breadcrumb,
		location: { pathname },
	} = props;
	const titleParts = breadcrumb[pathname]?.headerTitle?.split(' / ');
	const headerTitle = titleParts?.shift();
	const title = titleParts?.join(' / ') || breadcrumb[pathname]?.name || '';
	const icon = breadcrumb[pathname] ? breadcrumb[pathname].icon : '';
	const headerIcon = breadcrumb[pathname]
		? breadcrumb[pathname].headerIcon
		: '';

	return { title, icon, headerIcon, headerTitle };
}

function Header(props: Props, ref) {
	const [headerExtra, setHeaderExtra] = useState(null);
	useImperativeHandle(ref, () => ({
		renderHeaderExtra: (extra) => setHeaderExtra(extra),
	}));

	const { title, icon, headerIcon, headerTitle } = parseProps(props);
	const renderIcon = headerIcon || icon;
	const renderTitle = headerTitle || title;
	const renderSubTitle = headerTitle ? title : '';

	useEffect(() => {
		setTimeout(() => {
			document.title = `${APPLICATION_NAME} - ${renderTitle}${
				renderSubTitle ? ` - ${renderSubTitle}` : ''
			}`;
		});
	}, [props.breadcrumb]);

	return (
		<Fragment>
			<div className={styles.header}>
				{renderIcon && <div className={styles.icon}>{renderIcon}</div>}
				<div className={styles.title}>{renderTitle}</div>
				{renderSubTitle && (
					<div className={styles.subtitle}>&nbsp;/&nbsp;{title}</div>
				)}
				<div className={`${styles.extraHeader} extra-header`}>
					{headerExtra}
				</div>
			</div>

			<UserMenu />
		</Fragment>
	);
}

export default forwardRef(Header);
