import {
	Button,
	Checkbox,
	Drawer,
	Form,
	Input,
	Radio,
	Select,
	Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import stores from '../../../../../../stores/index.mobx';
const Configure = () => {
	const [deviceId, , close, visible] = useDrawer('configure-device');

	const {
		devices: { getById },
	} = stores;

	const [form] = Form.useForm();

	const device = useMemo(() => getById(deviceId), [deviceId]);
	const [formValues, setFormValues] = useState<any>({});

	useEffect(() => {
		if (!device) {
			return;
		}
		form.setFieldsValue(device.configuration);
		setFormValues(device.configuration);
	}, [device]);

	async function save() {
		const fields = await form.validateFields();
		device.saveConfiguration({
			...fields,
			resolution: Number(fields.resolution),
			delayConsecutive: Number(fields.delayConsecutive || 0),
		});
		close();
	}

	const isEscPos = useMemo(
		() => !device?.device?.protocol || device?.device?.protocol === 'escpos',
		[device]
	);

	return (
		<Drawer
			destroyOnClose
			visible={visible}
			title={`Konfiguracija uređaja ${device?.name}`}
			width={500}
			onClose={close}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						Poništi
					</Button>
					<Button key="save" type="primary" onClick={save}>
						Sačuvaj
					</Button>
				</Space>
			}
		>
			<Form
				layout="vertical"
				form={form}
				onValuesChange={(values) => setFormValues({ ...formValues, ...values })}
			>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Osnovna podešavanja</div>
				</div>
				<Form.Item name="printLogo" valuePropName="checked">
					<Checkbox>Štampaj logo preduzeća pre fiskalnog računa</Checkbox>
				</Form.Item>
				{isEscPos && (
					<Form.Item
						name="cut"
						valuePropName="checked"
						extra={<>Štampač mora podržavati funkciju sečenja papira</>}
					>
						<Checkbox>Preseci papir nakon fiskalnog računa</Checkbox>
					</Form.Item>
				)}
				{isEscPos && (
					<Form.Item
						name="kick"
						valuePropName="checked"
						extra={
							<>Štampač mora podržavati funkciju otvaranja fioke za novac</>
						}
					>
						<Checkbox>Otvori fioku za novac nakon štampanja računa</Checkbox>
					</Form.Item>
				)}
				<Form.Item label="Širina papira" name="printWidth">
					<Radio.Group>
						<Radio value={'58'}>58mm</Radio>
						<Radio value={'80'}>80mm</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label="Dodatni tekst nakon fiskalnog računa"
					name="additionalText"
				>
					<TextArea />
				</Form.Item>
				<Form.Item label="Tip fonta" name="fontType">
					<Radio.Group>
						<Radio value={'fixed'}>Fiksna širina</Radio>
						<Radio value={'variable'}>Varijabilna širina</Radio>
					</Radio.Group>
				</Form.Item>
				{formValues.fontType === 'fixed' && (
					<Form.Item
						label="Font"
						name="font"
						rules={[{ required: true, message: 'Morate izabrati font' }]}
					>
						<Select>
							<Select.Option value={'Roboto Mono'}>Roboto Mono</Select.Option>
							<Select.Option value={'Source Code Pro'}>
								Source Code Pro
							</Select.Option>
							{/* <Select.Option value={'Ubuntu Mono'}>Ubuntu Mono</Select.Option> */}
							<Select.Option value={'IBM Plex Mono'}>
								IBM Plex Mono
							</Select.Option>
							<Select.Option value={'PT Mono'}>PT Mono</Select.Option>
							<Select.Option value={'Cousine'}>Cousine</Select.Option>
							<Select.Option value={'Fira Mono'}>Fira Mono</Select.Option>
							<Select.Option value={'Anonymous Pro'}>
								Anonymous Pro
							</Select.Option>
							<Select.Option value={'Overpass Mono'}>
								Overpass Mono
							</Select.Option>
							<Select.Option value={'JetBrains Mono'}>
								JetBrains Mono
							</Select.Option>
							<Select.Option value={'Noto Sans Mono'}>
								Noto Sans Mono
							</Select.Option>
						</Select>
					</Form.Item>
				)}
				{formValues.fontType === 'variable' && (
					<Form.Item
						label="Font"
						name="variableFont"
						rules={[{ required: true, message: 'Morate izabrati font' }]}
					>
						<Select>
							<Select.Option value={'Roboto'}>Roboto</Select.Option>
							<Select.Option value={'Roboto Condensed'}>
								Roboto Condensed
							</Select.Option>
							<Select.Option value={'Roboto Slab'}>Roboto Slab</Select.Option>
							<Select.Option value={'Open Sans'}>Open Sans</Select.Option>
							<Select.Option value={'Montserrat'}>Montserrat</Select.Option>
							<Select.Option value={'Source Sans Pro'}>
								Source Sans Pro
							</Select.Option>
							<Select.Option value={'Noto Sans'}>Noto Sans</Select.Option>
							<Select.Option value={'Ubuntu'}>Ubuntu</Select.Option>
							<Select.Option value={'IBM Plex Sans'}>
								IBM Plex Sans
							</Select.Option>
							<Select.Option value={'PT Sans'}>PT Sans</Select.Option>
							<Select.Option value={'PT Serif'}>PT Serif</Select.Option>
						</Select>
					</Form.Item>
				)}
				<Form.Item label="Pregled fonta">
					<span
						style={{
							fontFamily:
								formValues.fontType === 'fixed'
									? formValues.font
									: formValues.variableFont,
						}}
					>
						============ ФИСКАЛНИ РАЧУН ============
						<br />
						------------ПРОМЕТ - ПРОДАЈА------------
						<br />
						Готовина:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;123.456.789,00
						<br />
						<span style={{ fontSize: 23 }}>ОВО НИЈЕ ФИСКАЛНИ РАЧУН</span>
					</span>
				</Form.Item>

				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Napredna podešavanja</div>
				</div>
				<Form.Item
					label="Rezolucija štampača"
					name="resolution"
					rules={[{ required: true, message: 'Rezolucija je obavezna' }]}
				>
					<Input type="number" suffix="DPI" />
				</Form.Item>
				<Form.Item
					label="Odlaganje štampanja sledećeg računa"
					name="delayConsecutive"
					extra="Broj sekundi koji će aplikacija sačekati pre štampanja sledećeg računa."
				>
					<Input type="number" suffix="sekundi" />
				</Form.Item>
				{isEscPos && (
					<Form.Item
						label="Komanda za štampanje"
						name="imageMode"
						extra="Komanda koja se koristi za štampanje računa. Neki štampači ne podržavaju oba režima."
					>
						<Select>
							<Select.Option value={'image'}>Image</Select.Option>
							<Select.Option value={'raster'}>Raster</Select.Option>
						</Select>
					</Form.Item>
				)}
			</Form>
		</Drawer>
	);
};

const ObservedConfigure = observer(Configure);
addToDrawersRegistry('configure-device', ObservedConfigure);
