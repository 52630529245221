import { PlusOutlined } from '@ant-design/icons';
import { TreeSelect, Button, ConfigProvider, Empty } from 'antd';
import { observer } from 'mobx-react-lite';

import { Category } from '../../stores/Category.mobx';
import stores from '../../stores/index.mobx';
import { useDrawer } from '../Page';

interface Props extends Omit<React.ComponentProps<typeof TreeSelect>, 'value'> {
	showRoot?: boolean;
	treeCheckable?: boolean;
	value?: Category[] | void;
	showCreateButton?: boolean;
}

function CategorySelect({
	onChange,
	value,
	showRoot,
	treeCheckable,
	showCreateButton = false,
	...rest
}: Props) {
	const {
		categories: { isFetching, tree },
	} = stores;

	const [, openCreateCategory, , , , CreateCategoryDrawer] = useDrawer(
		'create/catalog/categories',
		(event, entity: any) => {
			if (event === 'entity-create') {
				onChange([...(value || []), entity.id], [], null);
			}
		}
	);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/subfolder.png" />}
						imageStyle={{ height: 64 }}
						description="Nema kategorija"
					>
						<Button
							type="link"
							onClick={() => openCreateCategory()}
							icon={<PlusOutlined />}
						>
							Dodaj novu kategoriju
						</Button>
					</Empty>
				)}
			>
				<TreeSelect
					loading={isFetching}
					treeLine
					treeDefaultExpandAll
					treeCheckable={treeCheckable}
					dropdownMatchSelectWidth={false}
					treeData={
						showRoot
							? [
									{
										title: 'Koren',
										value: 'root',
										children: tree,
									},
							  ]
							: tree
					}
					onChange={onChange}
					value={value}
					dropdownRender={
						showCreateButton && tree.length > 0
							? (menu) => (
									<>
										{menu}
										<Button
											type="link"
											onClick={() => openCreateCategory()}
											icon={<PlusOutlined />}
										>
											Dodaj novu kategoriju
										</Button>
									</>
							  )
							: null
					}
					{...rest}
				/>
			</ConfigProvider>
			{showCreateButton && <CreateCategoryDrawer />}
		</>
	);
}

export default observer(CategorySelect);
