export const MARKETPLACE_APPLICATION_SCOPE = {
	company: 'Preduzeće',
	invoices: 'Fakture',
	users: 'Korisnici',
	products: 'Artikli',
	receipts: 'Fiskalni računi',
	categories: 'Kategorije',
	units: 'Jedinice mere',
	partners: 'Partneri',
};
