import numeral from 'numeral';

export default function numberFormat(
	value,
	showCurrency = false,
	precision = 2,
	pad = true,
	currency = 'RSD'
) {
	return `${numeral(value).format(
		pad ? `0,0.${'0'.repeat(precision)}` : `0,0.[${'0'.repeat(precision)}]`
	)}${showCurrency ? ` ${currency}` : ''}`;
}
