import { InputNumber, DatePicker, Input, Checkbox } from 'antd';
import round from 'lodash/round';
import moment from 'moment';

import { TableInput } from '../../../../../../components/TableInput';
import { AdvanceInvoice } from '../../../../../../stores/Invoice.mobx';
import { InvoiceSelect } from '../InvoiceSelect';

interface Props {
	partnerId: string;
}

function recalculate(record, index, handleEditObject) {
	const {
		amountS10,
		amountS20,
		amountAE,
		amountO,
		amountE,
		amountR,
		amountZ,
		amountSS,
		amountOE,
		amountN,
	} = record;

	const vatS10 = round(amountS10 * 0.1, 2);
	const vatS20 = round(amountS20 * 0.2, 2);

	const amountTotal = round(
		amountS10 +
			amountS20 +
			amountAE +
			amountO +
			amountE +
			amountR +
			amountZ +
			amountSS +
			amountOE +
			amountN +
			vatS10 +
			vatS20,
		2
	);
	handleEditObject(index, { amountTotal, vatS10, vatS20 });
}

function AdvanceInvoicesInput({ partnerId, ...props }: Props) {
	return (
		<TableInput
			tableProps={{ scroll: { x: 1550 } }}
			addButtonText="Dodaj avansni račun"
			emptyText="Nema avansnih računa"
			iconPath="/images/icons/invoice-advance.png"
			newRowValue={{
				inSystem: true,
				advanceInvoiceNumber: undefined,
				advanceInvoiceId: undefined,
				amountS10: 0,
				amountS20: 0,
				vatS10: 0,
				vatS20: 0,
				amountAE: 0,
				amountO: 0,
				amountE: 0,
				amountR: 0,
				amountZ: 0,
				amountSS: 0,
				amountOE: 0,
				amountN: 0,
				amountTotal: 0,
			}}
			columns={[
				{
					title: 'U sistemu',
					dataIndex: 'inSystem',
					width: 95,
					editable: true,
					valuePropName: 'checked',
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<Checkbox
							onChange={(event) => {
								handleEditObject(index, {
									inSystem: event.target.checked,
									advanceInvoiceNumber: undefined,
									advanceInvoiceId: undefined,
									date: undefined,
									amountS10: 0,
									amountS20: 0,
									vatS10: 0,
									vatS20: 0,
									amountAE: 0,
									amountO: 0,
									amountE: 0,
									amountR: 0,
									amountZ: 0,
									amountSS: 0,
									amountOE: 0,
									amountN: 0,
									amountTotal: 0,
								});
							}}
						/>
					),
					style: { textAlign: 'center' },
				},
				{
					title: 'Avansni račun',
					dataIndex: 'advanceInvoiceNumber',
					width: 200,
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => {
						return record.inSystem ? (
							<InvoiceSelect
								partnerId={partnerId}
								direction="outgoing"
								types={['advanceInvoice']}
								hideNotInSystem
								onSelect={(value) => {
									if (!value) {
										handleEditObject(index, {
											date: undefined,
											advanceInvoiceNumber: undefined,
											advanceInvoiceId: undefined,
											amountS10: 0,
											amountS20: 0,
											vatS10: 0,
											vatS20: 0,
											amountAE: 0,
											amountO: 0,
											amountE: 0,
											amountR: 0,
											amountZ: 0,
											amountSS: 0,
											amountOE: 0,
											amountN: 0,
											amountTotal: 0,
										});

										return;
									}
									const amounts = value.taxBreakdown.reduce((acc, curr) => {
										if (curr.taxCategory === 'S') {
											acc[`amountS${curr.taxRate}`] = curr.taxableAmount;
											acc[`vatS${curr.taxRate}`] = curr.taxAmount;
										} else {
											acc[`amount${curr.taxCategory}`] = curr.taxableAmount;
										}
										return acc;
									}, {} as Record<string, number>);

									const total = value.taxBreakdown.reduce(
										(acc, curr) => acc + curr.taxableAmount + curr.taxAmount,
										0
									);
									handleEditObject(index, {
										...amounts,
										advanceInvoiceNumber: value.number,
										advanceInvoiceId: value.id,
										amountTotal: total,
										date: moment(value.dueDate),
									});
								}}
							/>
						) : (
							<Input style={{ width: '100%' }} />
						);
					},
					rules: [
						{
							required: true,
							message: 'Avansni račun je obavezan',
						},
					],
				},
				{
					title: 'Datum',
					dataIndex: 'date',
					editable: true,
					width: 150,
					component: (record: AdvanceInvoice) => (
						<DatePicker
							disabled={record.inSystem}
							format="L"
							style={{ width: '100%' }}
						/>
					),
					rules: [
						{
							required: true,
							message: 'Datum je obavezan',
						},
					],
				},
				{
					title: 'S 10%',
					dataIndex: 'amountS10',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountS10: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'PDV 10%',
					dataIndex: 'vatS10',
					editable: true,
					component: (record: AdvanceInvoice) => (
						<InputNumber style={{ width: '100%' }} disabled />
					),
				},
				{
					title: 'S 20%',
					dataIndex: 'amountS20',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountS20: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'PDV 20%',
					dataIndex: 'vatS20',
					editable: true,
					component: (record: AdvanceInvoice) => (
						<InputNumber style={{ width: '100%' }} disabled />
					),
				},
				{
					title: 'AE',
					dataIndex: 'amountAE',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountAE: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'O',
					dataIndex: 'amountO',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountO: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'E',
					dataIndex: 'amountE',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountE: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'R',
					dataIndex: 'amountR',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountR: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'Z',
					dataIndex: 'amountZ',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountZ: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'SS',
					dataIndex: 'amountSS',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountSS: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'OE',
					dataIndex: 'amountOE',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountOE: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'N',
					dataIndex: 'amountN',
					editable: true,
					component: (record: AdvanceInvoice, index, handleEditObject) => (
						<InputNumber
							style={{ width: '100%' }}
							onChange={(value) => {
								handleEditObject(index, {
									amountN: value,
								});
								recalculate(record, index, handleEditObject);
							}}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
						/>
					),
				},
				{
					title: 'Ukupno',
					dataIndex: 'amountTotal',
					editable: true,
					component: (record: AdvanceInvoice) => (
						<InputNumber disabled style={{ width: '100%' }} />
					),
				},
			]}
			{...props}
		/>
	);
}

export default AdvanceInvoicesInput;
