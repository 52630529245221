import { computed, observable } from 'mobx';
import type { Moment } from 'moment';

import { CreateStore } from './Crud.mobx';
import { Store } from './Store.mobx';
import { ReferenceTransformer } from './transformers/Reference';

const { Store: BaseStore, Entity } = CreateStore({
	name: 'webhook',
	paginated: false,
	// persistFields: ['all'],
});

export type WebhookEventType =
	| 'receipt.created'
	| 'product.created'
	| 'product.updated'
	| 'product.deleted';

class Webhook extends Entity {
	@observable url?: string;
	@observable eventTypes?: WebhookEventType[] = [];
	@observable secret?: string;
	@observable headers?: Record<string, string>;
	@observable enabled?: boolean = true;

	@ReferenceTransformer('store') stores?: Store[] = [];

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Webhooks extends BaseStore<Webhook> {
	constructor() {
		super(Webhook);
	}
}

export { Webhooks, Webhook };
