import { message } from 'antd';
import round from 'lodash/round';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import type { Moment } from 'moment';
import { v4 as uuid } from 'uuid';

import { InvoiceTypeAPI } from '../constants/journal';
import { CreateStore } from './Crud.mobx';
import { Discounts } from './Discount.mobx';
import stores from './index.mobx';
import { Product } from './Product.mobx';
import { MomentTransformer } from './transformers/Moment';
import { ReferenceTransformer } from './transformers/Reference';

export type LocalSalePayment = {
	paymentType:
		| 'card'
		| 'check'
		| 'cash'
		| 'mobilemoney'
		| 'wiretransfer'
		| 'other';
	amount: number;
};

const { Store, Entity } = CreateStore({
	name: 'localSale',
	paginated: false,
	persistFields: ['all', 'active', 'saleCount'],
	clientVersion: 'local',
	persistDelay: 0,
});

class LocalSaleItem extends Entity {
	@observable key?: string;
	@observable productId?: string;
	@ReferenceTransformer('product', 'productId') product?: Product;
	@observable variantId?: string;
	@ReferenceTransformer('product', 'variantId') variant?: Product;
	@observable quantity = 0;
	@observable price = 0;
	@observable discount = 0;

	constructor(data, parent) {
		super(parent);
		this.init({ ...data, key: data.key || data.productId });
	}

	*update(data) {
		this.replace(data);
	}

	finalPriceOnDate(date: Moment) {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		if (this.variant?.['isResolving'] || this.product?.['isResolving']) {
			return 0;
		}

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabels =
				(this.variant
					? this.variant?.taxRateLabels
					: this.product?.taxRateLabels) || [];
			price = taxRateLabels.reduce((price, taxRateLabel) => {
				const vatRate = taxRates.byLabel(taxRateLabel).rate;
				return round(price / (1 + vatRate / 100), 2);
			}, this.price);
		}

		if (!currencyId || currencyId === 'RSD') {
			return this.discount
				? round(price * (1 - this.discount / 100), 2)
				: price;
		}

		const data = exchangeRates.getByDate(date);
		const exchangeRate = data?.[0]?.[currencyId]?.rate || 0;
		return this.discount
			? round(price * exchangeRate * (1 - this.discount / 100), 2)
			: round(price * exchangeRate, 2);
	}

	@computed
	get priceWithoutDiscount() {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabels =
				(this.variant
					? this.variant?.taxRateLabels
					: this.product?.taxRateLabels) || [];
			price = taxRateLabels.reduce((price, taxRateLabel) => {
				const vatRate = taxRates.byLabel(taxRateLabel).rate;
				return round(price / (1 + vatRate / 100), 2);
			}, this.price);
		}

		if (!currencyId || currencyId === 'RSD') {
			return price;
		}

		const lastRates = exchangeRates.lastRates;
		const exchangeRate = lastRates[currencyId].rate;
		return round(price * exchangeRate, 2);
	}

	@computed
	get finalPrice() {
		return this.finalPriceOnDate(this.getParent().date || moment());
	}
}

class LocalSale extends Entity {
	@observable items: Record<string, LocalSaleItem> = {};
	@observable activeProductId: string;
	@observable uniqueId: string;
	@MomentTransformer date?: Moment;
	@observable vatExempt = false;
	@observable payment: LocalSalePayment[] = [];
	@observable invoiceType: InvoiceTypeAPI;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@computed
	get itemsAsArray() {
		return Object.values(this.items);
	}

	@computed
	get activeSaleItem() {
		return this.items[this.activeProductId];
	}

	@computed
	get total() {
		return round(
			this.itemsAsArray.reduce(
				(total, item) =>
					total + round((item.quantity || 0) * item.finalPrice, 2),
				0
			),
			2
		);
	}

	@computed
	get hasForeignCurrency() {
		return this.itemsAsArray.some((item) => {
			const currencyId = item.variant
				? item.variant?.currencyId
				: item.product?.currencyId;
			return currencyId !== 'RSD' && currencyId;
		});
	}

	@action.bound
	setPayment(payment: LocalSalePayment[]) {
		this.payment = payment;
	}

	@action.bound
	setInvoiceType(invoiceType: InvoiceTypeAPI) {
		this.invoiceType = invoiceType;
	}

	@action.bound
	setDate(date: Moment) {
		this.date = date;
	}

	@action.bound
	setTaxFree(vatExempt: boolean) {
		this.vatExempt = vatExempt;
	}

	@action.bound
	addItem(product: Product, quantity = 0, overridePrice = false) {
		let discount = 0;

		(stores.discounts as Discounts).activeDiscounts.forEach(
			(activeDiscount) => {
				if (activeDiscount.rules) {
					activeDiscount.rules.forEach((rule) => {
						if (
							rule.type === 'all' ||
							(rule.type === 'products' && rule?.value.includes(product.id)) ||
							(rule.type === 'categories' &&
								rule.value?.find((categoryId) =>
									(product.parent ? product.parent : product).categories.find(
										(category) => category.id === categoryId
									)
								))
						) {
							discount = Math.max(discount, activeDiscount.percentage);
						}
					});
				}
			}
		);

		if (quantity === 0) {
			if (product.quantityFromScale && stores.devices.scales.length > 0) {
				return message.error(
					'Sa vage je očitana težina 0. Proverite da li je proizvod postavljen na vagu.'
				);
			}

			return message.error('Količina ne može biti 0');
		}

		if (quantity === null) {
			if (product.quantityFromScale && stores.devices.scales.length > 0) {
				return message.error(
					'Neuspešno očitavanje težine sa vage. Proverite da li je vaga ispravno povezana i konfigurisana.'
				);
			}

			return message.error('Količina ne može biti 0');
		}

		const id = `${product.id}${product.multiplePerReceipt ? `:${uuid()}` : ''}`;

		if (product.parentId) {
			// variant
			const parent = product.parent;

			if (this.items[id]) {
				const current = this.items[id];
				current.replace({
					quantity: round(current.quantity + quantity, 3),
					discount: this.items[id].discount,
				});
			} else {
				const productPrice = product.price || 0;
				this.items[id] = new LocalSaleItem(
					{
						id: product.id,
						key: id,
						price: overridePrice || productPrice,
						productId: parent.id,
						variantId: product.id,
						quantity,
						discount,
					},
					this
				);
			}
		} else {
			if (this.items[id]) {
				const current = this.items[id];
				current.replace({
					quantity: round(current.quantity + quantity, 3),
					discount: this.items[id].discount,
				});
			} else {
				const productPrice = product.price || 0;
				this.items[id] = new LocalSaleItem(
					{
						id: product.id,
						key: id,
						price: overridePrice || productPrice,
						productId: product.id,
						quantity,
						discount,
					},
					this
				);
			}
		}

		this.activeProductId = id;
		return true;
	}

	@action.bound
	removeItem(id: string) {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.id === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.selectPreviousItem();
		} else {
			this.selectNextItem();
		}

		if (this.items[id]) {
			delete this.items[id];
		}
	}

	@action.bound selectPreviousItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === 0) {
			this.activeProductId =
				this.itemsAsArray[this.itemsAsArray.length - 1].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex - 1].key;
		}
	}

	@action.bound selectNextItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.activeProductId = this.itemsAsArray[0].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex + 1].key;
		}
	}

	@action.bound updateQuantity(id: string, quantity: number) {
		if (this.items[id]) {
			const product = this.items[id];
			product.replace({
				quantity,
			});
		}
		this.activeProductId = id;
	}

	replace(data) {
		data.items = Object.fromEntries(
			Object.entries(data.items).map(([key, item]) => [
				key,
				new LocalSaleItem(item, this),
			])
		);
		super.replace(data);
	}
}

class LocalSales extends Store<LocalSale> {
	@observable active?: string;
	@observable saleCount = 0;

	constructor() {
		super(LocalSale);
	}

	@action.bound
	createSale() {
		this.saleCount += 1;
		const sale = new LocalSale(
			{
				items: {},
				id: `${this.saleCount}`,
				uniqueId: uuid(),
				activeProductId: null,
			},
			this
		);
		this.all.push(sale);
		this.active = `${this.saleCount}`;
		return sale;
	}

	@action.bound
	setActive(id: string) {
		this.active = id;
	}

	@action.bound
	removeSale(sale: LocalSale) {
		const activeSaleIndex = this.all.findIndex(
			(s) => s.id === `${this.active}`
		);
		this.all = this.all.filter((s) => s.id !== sale.id);
		let previousSale;
		if (activeSaleIndex > 0) {
			previousSale = this.all[activeSaleIndex - 1];
		} else if (activeSaleIndex < this.all.length - 1) {
			previousSale = this.all[activeSaleIndex + 1];
		}

		if (!previousSale || previousSale.items.size > 0) {
			return this.createSale();
		}

		this.active = previousSale.id;
	}

	@computed
	get byUniqueId() {
		return this.available.reduce((acc, sale) => {
			acc[sale.uniqueId] = sale;
			return acc;
		}, {});
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (!this.all.length) {
				this.createSale();
			}

			for (const sale of this.all) {
				if (!sale.date) {
					sale.date = null;
				}
				if (sale.vatExempt) {
					sale.vatExempt = false;
				}
			}
		}
	}
}

export { LocalSales, LocalSale, LocalSaleItem };
