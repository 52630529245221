import { InputNumber, DatePicker } from 'antd';

import { TableInput } from '../../../../../../components/TableInput';

type CassaSconto = {
	dueDate?: string;
	amount?: string;
	id?: string;
};

type Props = {
	value?: Array<CassaSconto>;
	onChange?: (any) => void;
};

function CassaScontoInput(props: Props) {
	return (
		<TableInput
			addButtonText="Dodaj iznos za plaćanje u roku"
			emptyText="Nema iznosa za plaćanje u roku"
			iconPath="/images/icons/flash-sale.png"
			columns={[
				{
					title: 'Valuta plaćanja',
					key: 'dueDate',
					dataIndex: 'dueDate',
					width: 200,
					editable: true,
					component: <DatePicker format="L" style={{ width: '100%' }} />,
					rules: [
						{
							required: true,
							message: 'Valuta plaćanja je obavezna',
						},
					],
				},
				{
					title: 'Iznos',
					key: 'amount',
					dataIndex: 'amount',
					editable: true,
					component: <InputNumber style={{ width: '100%' }} />,
					rules: [
						{
							required: true,
							message: 'Iznos je obavezan',
						},
					],
				},
			]}
			{...props}
		/>
	);
}

export default CassaScontoInput;
