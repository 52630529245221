export enum TextSize {
	SMALL = 0,
	NORMAL = 1,
	DOUBLE = 2,
}

export enum InvoiceType {
	NORMAL = 0,
	PROFORMA = 1,
	COPY = 2,
	TRAINING = 3,
	ADVANCE = 4,
}

export enum InvoiceTypeAPI {
	NORMAL = 'normal',
	ADVANCE = 'advance',
	PROFORMA = 'proforma',
	TRAINING = 'training',
	COPY = 'copy',
}

export enum TransactionType {
	SALE = 0,
	REFUND = 1,
}

export enum PaymentType {
	OTHER = 0,
	CASH = 1,
	CARD = 2,
	CHECK = 3,
	WIRE_TRANSFER = 4,
	VOUCHER = 5,
	MOBILE_MONEY = 6,
}

export enum PaymentTypeAPI {
	OTHER = 'other',
	CASH = 'cash',
	CARD = 'card',
	CHECK = 'check',
	WIRE_TRANSFER = 'wiretransfer',
	VOUCHER = 'voucher',
	MOBILE_MONEY = 'mobilemoney',
}
export enum TransactionTypeAPI {
	SALE = 'sale',
	REFUND = 'refund',
}

export const INVOICE_TYPE_MAP = {
	[InvoiceType.NORMAL]: 'normal',
	[InvoiceType.PROFORMA]: 'proforma',
	[InvoiceType.COPY]: 'copy',
	[InvoiceType.TRAINING]: 'training',
	[InvoiceType.ADVANCE]: 'advance',
};

export const INVOICE_TYPE_FROM_STRING = {
	normal: InvoiceType.NORMAL,
	proforma: InvoiceType.PROFORMA,
	copy: InvoiceType.COPY,
	training: InvoiceType.TRAINING,
	advance: InvoiceType.ADVANCE,
};

export const INVOICE_TYPE_TEXT = {
	[InvoiceType.NORMAL]: 'Promet',
	[InvoiceType.PROFORMA]: 'Predračun',
	[InvoiceType.COPY]: 'Kopija',
	[InvoiceType.TRAINING]: 'Obuka',
	[InvoiceType.ADVANCE]: 'Avans',
};

export const TRANSACTION_TYPE_MAP = {
	[TransactionType.SALE]: 'sale',
	[TransactionType.REFUND]: 'refund',
};

export const TRANSACTION_TYPE_FROM_STRING = {
	sale: TransactionType.SALE,
	refund: TransactionType.REFUND,
};

export const TRANSACTION_TYPE_TEXT = {
	[TransactionType.SALE]: 'Prodaja',
	[TransactionType.REFUND]: 'Refundacija',
};

export const PAYMENT_TYPE_MAP = {
	[PaymentType.OTHER]: 'other',
	[PaymentType.CASH]: 'cash',
	[PaymentType.CARD]: 'card',
	[PaymentType.CHECK]: 'check',
	[PaymentType.WIRE_TRANSFER]: 'wiretransfer',
	[PaymentType.VOUCHER]: 'voucher',
	[PaymentType.MOBILE_MONEY]: 'mobilemoney',
};

export const PAYMENT_TYPE_FROM_STRING = {
	cash: PaymentType.CASH,
	card: PaymentType.CARD,
	check: PaymentType.CHECK,
	wiretransfer: PaymentType.WIRE_TRANSFER,
	mobilemoney: PaymentType.MOBILE_MONEY,
	voucher: PaymentType.VOUCHER,
	other: PaymentType.OTHER,
};

export const PAYMENT_TYPE_TEXT = {
	[PaymentType.OTHER]: 'Drugo bezgotovinsko plaćanje',
	[PaymentType.CASH]: 'Gotovina',
	[PaymentType.CARD]: 'Kartica',
	[PaymentType.CHECK]: 'Ček',
	[PaymentType.WIRE_TRANSFER]: 'Prenos na račun',
	[PaymentType.VOUCHER]: 'Vaučer',
	[PaymentType.MOBILE_MONEY]: 'Instant plaćanje',
};

export const ADVANCE_TYPE = {
	['Ђ']: 10,
	['Е']: 11,
	['Г']: 12,
	['А']: 13,
	['Ж']: 10,
	['A']: 11,
	['B']: 12,
	['C']: 13,
	['N']: 13,
};
