export type DocumentType =
	| 'invoice'
	| 'creditNote'
	| 'debitNote'
	| 'advanceInvoice';

export const DOCUMENT_TYPE = {
	INVOICE: 'invoice',
	CREDIT_NOTE: 'creditNote',
	DEBIT_NOTE: 'debit_note',
	ADVANCE_INVOICE: 'advanceInvoice',
};

export const DOCUMENT_TAX_INVOICE_PERIOD = {
	ISSUE_DATE: 'issueDate',
	PAYMENT_DATE: 'dueDate',
	DELIVERY_DATE: 'deliveryDate',
};

export const DOCUMENT_TAX_INVOICE_PERIOD_MAP = {
	[DOCUMENT_TAX_INVOICE_PERIOD.ISSUE_DATE]: 'Datum slanja fakture',
	[DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE]:
		'PDV se obračunava na dan plaćanja',
	[DOCUMENT_TAX_INVOICE_PERIOD.DELIVERY_DATE]: 'Datum prometa',
};

export const DOCUMENT_CREDIT_DEBIT_NOTE_TYPE = {
	SINGLE_INVOICE: 'singleInvoice',
	PERIOD: 'period',
};

export const DOCUMENT_CREDIT_DEBIT_NOTE_TYPE_MAP = {
	[DOCUMENT_CREDIT_DEBIT_NOTE_TYPE.SINGLE_INVOICE]: 'Pojedinačnu fakturu',
	[DOCUMENT_CREDIT_DEBIT_NOTE_TYPE.PERIOD]: 'Fakture u periodu',
};

export const DOCUMENT_TYPE_MAP = {
	[DOCUMENT_TYPE.INVOICE]: 'Faktura',
	[DOCUMENT_TYPE.CREDIT_NOTE]: 'Knjižno odobrenje',
	[DOCUMENT_TYPE.DEBIT_NOTE]: 'Knjižno zaduženje',
	[DOCUMENT_TYPE.ADVANCE_INVOICE]: 'Avansni račun',
};

export const EFAKTURA_ERROR_CODE = {
	UBLUploadRequestIdDuplicate: 'Ovaj dokument je već poslat',
	EInvoiceNumberDublicate: 'Broj dokumenta je već u upotrebi',
	IssueDateCannotBeDifferentFromTodays:
		'Datum dokumenta mora biti današnji datum',
};

export const TAX_FEE_CATEGORY = {
	S: 'Standardna stopa',
	AE: 'Obrnuto obračunavanje PDV',
	O: 'Nije predmet oporezivanja PDV',
	E: 'Poresko oslobođenje bez prava na odbitak prethodnog poreza',
	R: 'Izuzimanje od PDV',
	Z: 'Poresko oslobođenje sa pravom na odbitak prethodnog poreza',
	SS: 'Posebni postupci oporezivanja',
	OE: 'Nije predmet oporezivanja PDV 2',
	N: 'Anuliranje',
};

export const TAXCORE_INVOICE_TAX_CATEGORY_MAPPING =
	window.location.hostname === 'localhost' ||
	window.location.hostname.includes('sandbox')
		? {
				A: 'S',
				Ж: 'S',
				B: 'O',
				N: 'SS',
		  }
		: {
				Ђ: 'S',
				Е: 'S',
				Г: 'O',
				А: 'SS',
		  };

export const TAX_LABEL_INVOICE_MAPPING = {
	Ђ: 20,
	Е: 10,
	Ж: 20,
	A: 10,
};

export const INVOICE_STATUS = {
	NEW: 'new',
	DRAFT: 'draft',
	SEEN: 'seen',
	SENT: 'sent',
	PAID: 'paid',
	MISTAKE: 'mistake',
	OVERDUE: 'overdue',
	ARCHIVED: 'archived',
	SENDING: 'sending',
	DELETED: 'deleted',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	CANCELLED: 'cancelled',
	STORNO: 'storno',
	UNKNOWN: 'unknown',
};

export const INVOICE_STATUS_MAP = {
	[INVOICE_STATUS.NEW]: 'Novi',
	[INVOICE_STATUS.SEEN]: 'Viđen',
	[INVOICE_STATUS.DRAFT]: 'Nacrt',
	[INVOICE_STATUS.SENT]: 'Poslat',
	[INVOICE_STATUS.PAID]: 'Plaćen',
	[INVOICE_STATUS.MISTAKE]: 'Greška',
	[INVOICE_STATUS.OVERDUE]: 'Dospeo',
	[INVOICE_STATUS.ARCHIVED]: 'Arhiviran',
	[INVOICE_STATUS.SENDING]: 'Slanje',
	[INVOICE_STATUS.DELETED]: 'Obrisan',
	[INVOICE_STATUS.APPROVED]: 'Prihvaćen',
	[INVOICE_STATUS.REJECTED]: 'Odbijen',
	[INVOICE_STATUS.CANCELLED]: 'Otkazan',
	[INVOICE_STATUS.STORNO]: 'Storniran',
	[INVOICE_STATUS.UNKNOWN]: 'Nepoznato',
};

export const TAX_EXEMPTION_CODE = {
	// AE
	'PDV-RS-10-2-1':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara ili usluga, obveznik PDV, za promet sekundarnih sirovina i usluga koje su neposredno povezane sa tim dobrima, izvršen od strane drugog obveznika PDV',
	'PDV-RS-10-2-2':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara, obveznik PDV, za promet građevinskih objekata i ekonomski deljivih celina u okviru tih objekata, uključujući i vlasničke udele na tim dobrima, izvršen od strane drugog obveznika PDV, u slučaju kada je ugovorom na osnovu kojeg se vrši promet tih dobara predviđeno da će se na taj promet obračunati PDV u skladu sa ovim zakonom',
	'PDV-RS-10-2-3':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara i usluga iz oblasti građevinarstva, obveznik PDV, odnosno lice iz člana 9. stav 1. ovog zakona, za promet izvršen od strane obveznika PDV, ako je vrednost tog prometa veća od 500.000 dinara, bez PDV',
	'PDV-RS-10-2-4':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac električne energije i prirodnog gasa koji se isporučuju preko prenosne, transportne i distributivne mreže, obveznik PDV koji je ova dobra nabavio radi dalje prodaje, za promet električne energije i prirodnog gasa izvršen od strane drugog obveznika PDV',
	'PDV-RS-10-2-5-1':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara ili usluga, obveznik PDV, za promet izvršen od strane drugog obveznika PDV, i to kod prometa hipotekovane nepokretnosti kod realizacije hipoteke u skladu sa zakonom kojim se uređuje hipoteka',
	'PDV-RS-10-2-5-2':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara ili usluga, obveznik PDV, za promet izvršen od strane drugog obveznika PDV, i to kod prometa predmeta založnog prava kod realizacije ugovora o zalozi u skladu sa zakonom kojim se uređuje založno pravo na pokretnim stvarima',
	'PDV-RS-10-2-5-3':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik je primalac dobara ili usluga, obveznik PDV, za promet izvršen od strane drugog obveznika PDV, i to kod prometa dobara ili usluga nad kojima se sprovodi izvršenje u izvršnom postupku u skladu sa zakonom',
	'PDV-RS-36b-6':
		'Zakon o Porezu na dodatu vrednost: Poreski dužnik za promet investicionog zlata iz stava 5. ovog člana je obveznik PDV kojem je taj promet izvršen',
	// O
	'PDV-RS-11-1-1':
		'Zakon o Porezu na dodatu vrednost: Mesto prometa dobara je inostranstvo - mesto u kojem se dobro nalazi u trenutku slanja ili prevoza do primaoca ili, po njegovom nalogu, do trećeg lica, ako dobro šalje ili prevozi isporučilac, primalac ili treće lice, po njegovom nalogu',
	'PDV-RS-11-1-2':
		'Zakon o Porezu na dodatu vrednost: Mesto prometa dobara je inostranstvo - mesto ugradnje ili montaže dobra, ako se ono ugrađuje ili montira od strane isporučioca ili, po njegovom nalogu, od strane trećeg lica',
	'PDV-RS-11-1-3':
		'Zakon o Porezu na dodatu vrednost: Mesto prometa dobara je inostranstvo - mesto u kojem se dobro nalazi u trenutku isporuke, ako se dobro isporučuje bez otpreme, odnosno prevoza',
	'PDV-RS-11-1-4':
		'Zakon o Porezu na dodatu vrednost: Mesto prometa dobara je inostranstvo - mesto u kojem primalac električne energije, prirodnog gasa i energije za grejanje, odnosno hlađenje, čija se isporuka vrši preko prenosne, transportne i distributivne mreže, a koji je ova dobra nabavio radi dalje prodaje, ima sedište ili stalnu poslovnu jedinicu kojima se dobra isporučuju',
	'PDV-RS-11-1-5':
		'Zakon o Porezu na dodatu vrednost: Mesto prometa dobara je inostranstvo - mesto prijema vode, električne energije, prirodnog gasa i energije za grejanje, odnosno hlađenje, za krajnju potrošnju',
	'PDV-RS-11-3':
		'Zakon o Porezu na dodatu vrednost: Izuzetno od stava 1. tačka 3) ovog člana, ako se promet dobara vrši na brodu, odnosno u letilici ili vozu u toku prevoza putnika, mestom prometa smatra se mesto polaska broda, letilice ili voza, koje se nalazi u inostranstvu',
	'PDV-RS-12-4':
		'Zakon o Porezu na dodatu vrednost: Ako se promet usluga vrši poreskom obvezniku, mestom prometa usluga smatra se mesto u inostranstvu u kojem primalac usluga ima sedište ili stalnu poslovnu jedinicu ako se promet usluga vrši stalnoj poslovnoj jedinici koja se ne nalazi u mestu u kojem primalac usluga ima sedište, odnosno mesto u inostranstvu u kojem primalac usluga ima prebivalište ili boravište',
	'PDV-RS-12-6-1':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga u vezi sa nepokretnostima, uključujući i usluge posredovanja kod prometa nepokretnosti, smatra se mesto u inostranstvu u kojem se nalazi nepokretnost',
	'PDV-RS-12-6-2':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga prevoza lica, smatra se mesto gde se obavlja prevoz, a ako se prevoz obavlja i u Republici i u inostranstvu, odredbe ovog zakona primenjuju se samo na deo prevoza izvršen u Republici - deo usluge prevoza koji se vrši u inostranstvu',
	'PDV-RS-12-6-3':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga prevoza dobara koja se pruža licu koje nije poreski obveznik, smatra se mesto gde se obavlja prevoz, a ako se prevoz obavlja i u Republici i u inostranstvu, odredbe ovog zakona primenjuju se samo na deo prevoza izvršen u Republici - deo usluge prevoza koji se vrši u inostranstvu',
	'PDV-RS-12-6-4-1':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga smatra se mesto gde su usluge stvarno pružene, ako se radi o uslugama koje se odnose na prisustvovanje kulturnim, umetničkim, sportskim, naučnim, obrazovnim, zabavnim ili sličnim događajima (sajmovi, izložbe i dr.), uključujući i pomoćne usluge u vezi sa prisustvovanjem tim događajima, čije je mesto prometa u inostranstvu',
	'PDV-RS-12-6-4-2':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga smatra se mesto gde su usluge stvarno pružene, ako se radi o uslugama organizatora događaja iz podtačke (1) ove tačke, pruženih licu koje nije poreski obveznik, čije je mesto prometa u inostranstvu',
	'PDV-RS-12-6-4-3':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga smatra se mesto gde su usluge stvarno pružene, ako se radi o pomoćnim uslugama u vezi sa prevozom, kao što su utovar, istovar, pretovar i slično, pruženih licu koje nije poreski obveznik, čije je mesto prometa u inostranstvu',
	'PDV-RS-12-6-4-4':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga smatra se mesto gde su usluge stvarno pružene, ako se radi o uslugama procene pokretnih stvari, odnosno radova na pokretnim stvarima pruženih licu koje nije poreski obveznik, čije je mesto prometa u inostranstvu',
	'PDV-RS-12-6-4-5':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga smatra se mesto gde su usluge stvarno pružene, ako se radi o uslugama predaje jela i pića za konzumaciju na licu mesta, čije je mesto prometa u inostranstvu',
	'PDV-RS-12-6-5':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga iznajmljivanja prevoznih sredstava na kraći vremenski period, smatra se mesto u inostranstvu u kojem se prevozno sredstvo stvarno stavlja na korišćenje primaocu usluge',
	'PDV-RS-12-6-6':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga iznajmljivanja prevoznih sredstava, osim iz tačke 5) ovog stava, pruženih licu koje nije poreski obveznik, smatra se mesto u inostranstvu u kojem to lice ima sedište, prebivalište ili boravište',
	'PDV-RS-12-6-7-1':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama prenosa, ustupanja i davanja na korišćenje autorskih i srodnih prava, prava na patente, licence, zaštitne znakove i druga prava intelektualne svojine',
	'PDV-RS-12-6-7-2':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama oglašavanja',
	'PDV-RS-12-6-7-3':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama savetnika, inženjera, advokata, revizora i sličnih usluga, kao i prevodilaca za usluge prevođenja, uključujući i prevođenje u pisanom obliku',
	'PDV-RS-12-6-7-4':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama obrade podataka i ustupanja, odnosno davanja informacija, uključujući i informacije o poslovnim postupcima i iskustvu',
	'PDV-RS-12-6-7-5':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama preuzimanja obaveze da se u potpunosti ili delimično odustane od obavljanja neke delatnosti ili od korišćenja nekog prava iz ove tačke',
	'PDV-RS-12-6-7-6':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama bankarskog i finansijskog poslovanja i poslovanja u oblasti osiguranja, uključujući reosiguranje, osim iznajmljivanja sefova',
	'PDV-RS-12-6-7-7':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama stavljanja na raspolaganje osoblja',
	'PDV-RS-12-6-7-8':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama iznajmljivanja pokretnih stvari, osim prevoznih sredstava',
	'PDV-RS-12-6-7-9':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama omogućavanja pristupa mreži prirodnog gasa, mreži za prenos električne energije i mreži za grejanje, odnosno hlađenje, transporta i distribucije putem tih mreža, kao i drugih usluga koje su neposredno povezane sa tim uslugama',
	'PDV-RS-12-6-7-10':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama telekomunikacija',
	'PDV-RS-12-6-7-11':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga koje se pružaju licu koje nije poreski obveznik, smatra se mesto sedišta, prebivališta ili boravišta primaoca usluga, koje se nalazi u inostranstvu, ako se radi o uslugama radijskog i televizijskog emitovanja',
	'PDV-RS-12-6-7-12':
		'Zakon o Porezu na dodatu vrednost: Mestom prometa usluga posredovanja kod prometa dobara ili usluga koja se pruža licu koje nije poreski obveznik, smatra se mesto u kojem je izvršen promet dobara ili usluga koji je predmet posredovanja, koje je u inostranstvu',
	'PDV-RS-12-9':
		'Zakon o Porezu na dodatu vrednost: Izuzetno od stava 6. tačka 4) podtačka (5) ovog člana, ako se usluge predaje jela i pića za konzumaciju na licu mesta faktički pružaju na brodu, odnosno u letilici ili vozu u toku prevoza putnika, mestom prometa smatra se mesto polaska broda, letilice ili voza, koje je u inostranstvu.',
	'PDV-RS-61':
		'Zakon o porezu na dodatu vrednost: Promet dobara sa teritorije Republike van APKM na teritoriju APKM',
	// E
	'PDV-RS-25-1-1':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja i posredovanja u poslovanju zakonskim sredstvima plaćanja, osim papirnog i kovanog novca koji se ne koristi kao zakonsko sredstvo plaćanja ili ima numizmatičku vrednost;',
	'PDV-RS-25-1-1a':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod prenosa virtuelnih valuta i zamene virtuelnih valuta za novčana sredstva u skladu sa zakonom kojim se uređuje digitalna imovina',
	'PDV-RS-25-1-2':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja i posredovanja u poslovanju akcijama, udelima u društvima i udruženjima, obveznicama i drugim hartijama od vrednosti, osim poslovanja koje se odnosi na čuvanje i upravljanje hartijama od vrednosti',
	'PDV-RS-25-1-3':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod kreditnih poslova, uključujući posredovanje, kao i novčanih pozajmica',
	'PDV-RS-25-1-4':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod preuzimanja obaveza, garancija i drugih sredstava obezbeđenja, uključujući posredovanje',
	'PDV-RS-25-1-5':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja i posredovanja u poslovanju depozitima, tekućim i žiro računima, nalozima za plaćanje, kao i platnim prometom i doznakama',
	'PDV-RS-25-1-6':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja i posredovanja u poslovanju novčanim potraživanjima, čekovima, menicama i drugim sličnim hartijama od vrednosti, osim naplate potraživanja za druga lica',
	'PDV-RS-25-1-7':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja društava za upravljanje investicionim fondovima u skladu sa propisima kojima se uređuju investicioni fondovi',
	'PDV-RS-25-1-8':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza u prometu novca i kapitala, i to kod poslovanja društava za upravljanje dobrovoljnim penzijskim fondovima u skladu sa propisima kojima se uređuju dobrovoljni penzijski fondovi i penzijski planovi',
	'PDV-RS-25-2-1':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga osiguranja i reosiguranja, uključujući prateće usluge posrednika i agenta (zastupnika) u osiguranju',
	'PDV-RS-25-2-2':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet zemljišta, kao i na davanje u zakup tog zemljišta',
	'PDV-RS-25-2-3':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet objekata, osim prvog prenosa prava raspolaganja na novoizgrađenim građevinskim objektima ili ekonomski deljivim celinama u okviru tih objekata i prvog prenosa vlasničkog udela na novoizgrađenim građevinskim objektima ili ekonomski deljivim celinama u okviru tih objekata, kao i prometa objekata i ekonomski deljivih celina u okviru tih objekata, uključujući i vlasničke udele na tim dobrima, u slučaju kada je ugovorom na osnovu kojeg se vrši promet tih dobara, zaključenim između obveznika PDV, predviđeno da će se na taj promet obračunati PDV, pod uslovom da sticalac obračunati PDV može u potpunosti odbiti kao prethodni porez',
	'PDV-RS-25-2-3a':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet dobara i usluga za koje pri nabavci obveznik nije imao pravo na odbitak prethodnog poreza',
	'PDV-RS-25-2-3b':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet dobara za koja je u prethodnoj fazi prometa postojala obaveza plaćanja poreza u skladu sa zakonom kojim se uređuju porezi na imovinu',
	'PDV-RS-25-2-5':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet udela, hartija od vrednosti, poštanskih vrednosnica, taksenih i drugih važećih vrednosnica po njihovoj utisnutoj vrednosti u Republici, osim vlasničkih udela iz člana 4. ovog zakona',
	'PDV-RS-25-2-6':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet poštanskih usluga od strane javnog preduzeća, kao i sa njima povezanih isporuka dobara',
	'PDV-RS-25-2-7':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga koje pružaju zdravstvene ustanove u skladu sa propisima koji regulišu zdravstvenu zaštitu, uključujući i smeštaj, negu i ishranu bolesnika u tim ustanovama, osim apoteka i apotekarskih ustanova',
	'PDV-RS-25-2-8':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga koje pružaju lekari, stomatolozi ili druga lica u skladu sa propisima koji regulišu zdravstvenu zaštitu',
	'PDV-RS-25-2-9':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga i isporuke zubne protetike u okviru delatnosti zubnog tehničara, kao i isporuka zubne protetike od strane stomatologa',
	'PDV-RS-25-2-10':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet ljudskih organa, tkiva, telesnih tečnosti i ćelija, krvi i majčinog mleka',
	'PDV-RS-25-2-11':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga socijalnog staranja i zaštite, dečje zaštite i zaštite mladih, usluga ustanova socijalne zaštite, kao i sa njima neposredno povezanog prometa dobara i usluga od strane lica registrovanih za obavljanje tih delatnosti',
	'PDV-RS-25-2-12':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga smeštaja i ishrane učenika i studenata u školskim i studentskim domovima ili sličnim ustanovama, kao i sa njima neposredno povezan promet dobara i usluga',
	'PDV-RS-25-2-13':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga obrazovanja (predškolsko, osnovno, srednje, više i visoko) i profesionalne prekvalifikacije, kao i sa njima neposredno povezanog prometa dobara i usluga od strane lica registrovanih za obavljanje tih delatnosti, ako se ove delatnosti obavljaju u skladu sa propisima koji uređuju tu oblast',
	'PDV-RS-25-2-14':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga iz oblasti kulture i sa njima neposredno povezanog prometa dobara i usluga, od strane lica čija delatnost nije usmerena ka ostvarivanju dobiti, a koja su registrovana za tu delatnost',
	'PDV-RS-25-2-15':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga iz oblasti nauke i sa njima neposredno povezanog prometa dobara i usluga, od strane lica čija delatnost nije usmerena ka ostvarivanju dobiti, a koja su registrovana za tu delatnost',
	'PDV-RS-25-2-16':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga verskog karaktera od strane registrovanih crkava i verskih zajednica i sa njima neposredno povezanog prometa dobara i usluga',
	'PDV-RS-25-2-17':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga javnog radiodifuznog servisa, osim usluga komercijalnog karaktera',
	'PDV-RS-25-2-18':
		'Zakon o Porezu na dodatu vrednost: Poresko oslobođenje bez prava na odbitak prethodnog poreza za promet usluga priređivanja igara na sreću',
	// R
	'PDV-RS-6-1-1':
		'Zakon o porezu na dodatu vrednost: Smatra se da promet dobara i usluga nije izvršen kod prenosa celokupne ili dela imovine, sa ili bez naknade, ili kao ulog, ako je sticalac poreski obveznik ili tim prenosom postane poreski obveznik i ako produži da obavlja istu delatnost',
	'PDV-RS-6a':
		'Zakon o porezu na dodatu vrednost: Smatra se da, u smislu ovog zakona, promet dobara i usluga koji vrši davalac koncesije koncesionaru, odnosno koncesionar davaocu koncesije u okviru realizacije ugovora o javno-privatnom partnerstvu sa elementima koncesije, zaključenog u skladu sa zakonom kojim se uređuju javno-privatno partnerstvo i koncesije, nije izvršen, ako su davalac koncesije i koncesionar obveznici PDV koji bi, u slučaju kada bi se taj promet smatrao izvršenim, imali u potpunosti pravo na odbitak prethodnog poreza u skladu sa ovim zakonom',
	// Z
	'PDV-RS-24-1-1':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnok poreza za prevozne i ostale usluge, koje su povezane sa uvozom dobara, ako je vrednost tih usluga sadržana u osnovici iz člana 19. stav 2. ovog zakona',
	'PDV-RS-24-1-2':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara koja obveznik ili treće lice, po njegovom nalogu, šalje ili otprema u inostranstvo',
	'PDV-RS-24-1-3':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara koja inostrani primalac ili treće lice, po njegovom nalogu, šalje ili otprema u inostranstvo',
	'PDV-RS-24-1-5':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za unos dobara u slobodnu zonu, prevozne i druge usluge korisnicima slobodnih zona koje su neposredno povezane sa tim unosom i promet dobara i usluga u slobodnoj zoni, za koje bi obveznik - korisnik slobodne zone imao pravo na odbitak prethodnog poreza kada bi ta dobra ili usluge nabavljao za potrebe obavljanja delatnosti van slobodne zone',
	'PDV-RS-24-1-5a':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara koja se unose u slobodnu zonu, prevozne i druge usluge koje su neposredno povezane sa tim unosom i promet dobara u slobodnoj zoni, koji se vrši stranom licu koje ima zaključen ugovor sa obveznikom PDV - korisnikom slobodne zone da ta dobra ugradi u dobra namenjena otpremanju u inostranstvo',
	'PDV-RS-24-1-6':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara koja su u postupku carinskog skladištenja',
	'PDV-RS-24-1-6a':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za otpremanje dobara u slobodne carinske prodavnice otvorene na vazduhoplovnim pristaništima otvorenim za međunarodni saobraćaj na kojima je organizovana pasoška i carinska kontrola radi prodaje putnicima u skladu sa carinskim propisima, kao i na isporuku dobara iz slobodnih carinskih prodavnica',
	'PDV-RS-24-1-7':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za usluge radova na pokretnim dobrima nabavljenim od strane inostranog primaoca usluge u Republici, ili koja su uvezena radi oplemenjivanja, opravke ili ugradnje, a koja posle oplemenjivanja, opravke ili ugradnje, isporučilac usluge, inostrani primalac ili treće lice, po njihovom nalogu, prevozi ili otprema u inostranstvo',
	'PDV-RS-24-1-7a':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara koja su u postupku aktivnog oplemenjivanja za koja bi obveznik - sticalac imao pravo na odbitak prethodnog poreza kada bi ta dobra nabavljao sa obračunatim PDV',
	'PDV-RS-24-1-8':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za prevozne i ostale usluge koje su u vezi sa izvozom, tranzitom ili privremenim uvozom dobara, osim usluga koje su oslobođene od PDV bez prava na poreski odbitak u skladu sa ovim zakonom',
	'PDV-RS-24-1-9':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za usluge međunarodnog prevoza lica u vazdušnom saobraćaju, s tim što za nerezidentno vazduhoplovno preduzeće poresko oslobođenje važi samo u slučaju uzajamnosti',
	'PDV-RS-24-1-10':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za isporuke letilica, servisiranje, popravke, održavanje, čarterisanje i iznajmljivanje letilica, koje se pretežno koriste uz naknadu u međunarodnom vazdušnom saobraćaju, kao i isporuke, iznajmljivanje, popravke i održavanje dobara namenjenih opremanju tih letilica',
	'PDV-RS-24-1-11':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga namenjenih neposrednim potrebama letilica iz tačke 10) ovog stava',
	'PDV-RS-24-1-12':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za usluge međunarodnog prevoza lica brodovima u rečnom saobraćaju, s tim što za nerezidentno preduzeće koje vrši međunarodni prevoz lica brodovima u rečnom saobraćaju, poresko oslobođenje važi samo u slučaju uzajamnosti',
	'PDV-RS-24-1-13':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za isporuke brodova, servisiranje, popravke, održavanje i iznajmljivanje brodova, koji se pretežno koriste uz naknadu u međunarodnom rečnom saobraćaju, kao i isporuke, iznajmljivanje, popravke i održavanje dobara namenjenih opremanju tih brodova',
	'PDV-RS-24-1-14':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga namenjenih neposrednim potrebama brodova iz tačke 13) ovog stava',
	'PDV-RS-24-1-15':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za isporuke zlata Narodnoj banci Srbije',
	'PDV-RS-24-1-16-1':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za dobra i usluge namenjene za službene potrebe diplomatskih i konzularnih predstavništava',
	'PDV-RS-24-1-16-2':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za dobra i usluge namenjene za službene potrebe međunarodnih organizacija, ako je to predviđeno međunarodnim ugovorom',
	'PDV-RS-24-1-16-3':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za dobra i usluge namenjene za lične potrebe stranog osoblja diplomatskih i konzularnih predstavništava, uključujući i članove njihovih porodica',
	'PDV-RS-24-1-16-4':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za dobra i usluge namenjene za lične potrebe stranog osoblja međunarodnih organizacija, uključujući članove njihovih porodica, ako je to predviđeno međunarodnim ugovorom',
	'PDV-RS-24-1-16a':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga koji se vrši u skladu sa ugovorima o donaciji zaključenim sa državnom zajednicom Srbija i Crna Gora, odnosno Republikom, ako je tim ugovorom predviđeno da se iz dobijenih novčanih sredstava neće plaćati troškovi poreza, u delu koji se finansira dobijenim novčanim sredstvima osim ako ratifikovanim međunarodnim ugovorom nije drukčije predviđeno',
	'PDV-RS-24-1-16b':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga koji se vrši u skladu sa ugovorima o kreditu, odnosno zajmu, zaključenim između državne zajednice Srbija i Crna Gora, odnosno Republike i međunarodne finansijske organizacije, odnosno druge države, kao i između treće strane i međunarodne finansijske organizacije, odnosno druge države u kojem se Republika Srbija pojavljuje kao garant, odnosno kontragarant, u delu koji se finansira dobijenim novčanim sredstvima, ako je tim ugovorima predviđeno da se iz dobijenih novčanih sredstava neće plaćati troškovi poreza',
	'PDV-RS-24-1-16v':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga koji se vrši na osnovu međunarodnih ugovora, ako je tim ugovorima predviđeno poresko oslobođenje, osim međunarodnih ugovora iz tač. 16a) i 16b) ovog stava',
	'PDV-RS-24-1-16g':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za promet dobara i usluga koji se vrši u okviru realizacije infrastrukturnih projekata izgradnje autoputeva za koje je posebnim zakonom utvrđen javni interes',
	'PDV-RS-24-1-17':
		'Zakon o porezu na dodatu vrednost: Poresko oslobođenje sa pravom na odbitak prethodnog poreza za usluge posredovanja koje se odnose na promet dobara i usluga iz tač. 1) - 16) ovog stava',
	// SS
	'PDV-RS-33':
		'Zakon o porezu na dodatu vrednost: Promet dobara i usluga koji vrši mali obveznik',
	'PDV-RS-35-7':
		'Zakon o porezu na dodatu vrednost: Turistička agencija za turističke usluge iz stava 1. ovog člana ne može da iskazuje PDV u računima ili drugim dokumentima i nema pravo na odbitak prethodnog poreza na osnovu prethodnih turističkih usluga koje su joj iskazane u računu',
	'PDV-RS-36-5':
		'Zakon o porezu na dodatu vrednost: Kod prometa dobara iz stava 1. ovog člana na koji se primenjuje oporezivanje razlike, obveznik ne može iskazivati PDV u računima ili drugim dokumentima',
	'PDV-RS-36b-4':
		'Zakon o porezu na dodatu vrednost: PDV se ne plaća na:\n1) promet i uvoz investicionog zlata, uključujući i investiciono zlato čija je vrednost navedena u potvrdama o alociranom ili nealociranom zlatu, zlato kojim se trguje preko računa za trgovanje zlatom, uključujući i zajmove i zamene zlata(svop poslovi) koji podrazumevaju pravo vlasništva ili potraživanja u vezi sa zlatom, kao i aktivnosti u vezi sa investicionim zlatom na osnovu fjučers i forvard ugovora čiji je rezultat prenos prava raspolaganja ili prava potraživanja u vezi sa investicionim zlatom;\n2) promet usluga posrednika koji u ime i za račun nalogodavca vrši promet investicionog zlata.',
	// OE
	'PDV-RS-17-4-2':
		'Zakon o porezu na dodatu vrednost: Osnovica ne sadrži iznose koje obveznik naplaćuje u ime i za račun drugog, ako te iznose prenosi licu u čije ime i za čiji račun je izvršio naplatu i ako su ti iznosi posebno evidentirani',
	'PDV-RS-17-4-3':
		'Zakon o porezu na dodatu vrednost: Osnovica ne sadrži iznose koje obveznik potražuje za izdatke koje je platio u ime i za račun primaoca dobara ili usluga, ako su ti iznosi posebno evidentirani',
	'PDV-RS-9':
		'Zakon o porezu na dodatu vrednost: U cilju obavljanja poslova za koje lice nije poreski obveznik iz člana 9. stav 1. ZPDV',
	'PDV-RS-7a-4':
		'Zakon o porezu na dodatu vrednost: Višenamenski vrednosni vaučer',
	'PDV-RS-4-4-2NP':
		'Zakon o porezu na dodatu vrednost: Promet dobara bez naknade koji nije predmet oporezivanja',
	'PDV-RS-5-4-2NP':
		'Zakon o porezu na dodatu vrednost: Promet usluga bez naknade koji nije predmet oporezivanja',
	'PDV-RS-3NP-ostalo':
		'Zakon o porezu na dodatu vrednost: Ostala potraživanja koja nisu predmet oporezivanja PDV',
	// N
	'PDV-RS-4':
		'Zakon o porezu na dodatu vrednost: Promet dobara bez naknade, nezavisno od toga da li je predmet oporezivanja PDV ',
	'PDV-RS-5':
		'Zakon o porezu na dodatu vrednost: Promet usluga bez naknade, nezavisno od toga da li je predmet oporezivanja PDV',
	'PDV-RS-6-1-1 (BN)':
		'Zakon o porezu na dodatu vrednost: Prenos imovine ili dela imovine u skladu sa članom 6. stav 1. tačka 1) ZPDV, bez naknade',
	'PDV-RS-3-DZ':
		'Zakon o porezu na dodatu vrednost: Umanjenje iznosa za plaćanje zbog primene drugog zakona',
};

export const TAX_EXEMPTION_CODE_CATEGORY = {
	AE: [
		'PDV-RS-10-2-1',
		'PDV-RS-10-2-2',
		'PDV-RS-10-2-3',
		'PDV-RS-10-2-4',
		'PDV-RS-10-2-5-1',
		'PDV-RS-10-2-5-2',
		'PDV-RS-10-2-5-3',
	],
	O: [
		'PDV-RS-11-1-1',
		'PDV-RS-11-1-2',
		'PDV-RS-11-1-3',
		'PDV-RS-11-1-4',
		'PDV-RS-11-1-5',
		'PDV-RS-11-3',
		'PDV-RS-12-4',
		'PDV-RS-12-6-1',
		'PDV-RS-12-6-2',
		'PDV-RS-12-6-3',
		'PDV-RS-12-6-4-1',
		'PDV-RS-12-6-4-2',
		'PDV-RS-12-6-4-3',
		'PDV-RS-12-6-4-4',
		'PDV-RS-12-6-4-5',
		'PDV-RS-12-6-5',
		'PDV-RS-12-6-6',
		'PDV-RS-12-6-7-1',
		'PDV-RS-12-6-7-2',
		'PDV-RS-12-6-7-3',
		'PDV-RS-12-6-7-4',
		'PDV-RS-12-6-7-5',
		'PDV-RS-12-6-7-6',
		'PDV-RS-12-6-7-7',
		'PDV-RS-12-6-7-8',
		'PDV-RS-12-6-7-9',
		'PDV-RS-12-6-7-10',
		'PDV-RS-12-6-7-11',
		'PDV-RS-12-6-7-12',
		'PDV-RS-12-9',
		'PDV-RS-61',
	],
	E: [
		'PDV-RS-25-1-1',
		'PDV-RS-25-1-1a',
		'PDV-RS-25-1-2',
		'PDV-RS-25-1-3',
		'PDV-RS-25-1-4',
		'PDV-RS-25-1-5',
		'PDV-RS-25-1-6',
		'PDV-RS-25-1-7',
		'PDV-RS-25-1-8',
		'PDV-RS-25-2-1',
		'PDV-RS-25-2-2',
		'PDV-RS-25-2-3',
		'PDV-RS-25-2-3a',
		'PDV-RS-25-2-3b',
		'PDV-RS-25-2-5',
		'PDV-RS-25-2-6',
		'PDV-RS-25-2-7',
		'PDV-RS-25-2-8',
		'PDV-RS-25-2-9',
		'PDV-RS-25-2-10',
		'PDV-RS-25-2-11',
		'PDV-RS-25-2-12',
		'PDV-RS-25-2-13',
		'PDV-RS-25-2-14',
		'PDV-RS-25-2-15',
		'PDV-RS-25-2-16',
		'PDV-RS-25-2-17',
		'PDV-RS-25-2-18',
	],
	R: ['PDV-RS-6-1-1', 'PDV-RS-6a'],
	Z: [
		'PDV-RS-24-1-1',
		'PDV-RS-24-1-2',
		'PDV-RS-24-1-3',
		'PDV-RS-24-1-5',
		'PDV-RS-24-1-5a',
		'PDV-RS-24-1-6',
		'PDV-RS-24-1-6a',
		'PDV-RS-24-1-7',
		'PDV-RS-24-1-7a',
		'PDV-RS-24-1-8',
		'PDV-RS-24-1-9',
		'PDV-RS-24-1-10',
		'PDV-RS-24-1-11',
		'PDV-RS-24-1-12',
		'PDV-RS-24-1-13',
		'PDV-RS-24-1-14',
		'PDV-RS-24-1-15',
		'PDV-RS-24-1-16-1',
		'PDV-RS-24-1-16-2',
		'PDV-RS-24-1-16-3',
		'PDV-RS-24-1-16-4',
		'PDV-RS-24-1-16a',
		'PDV-RS-24-1-16b',
		'PDV-RS-24-1-16v',
		'PDV-RS-24-1-16g',
		'PDV-RS-24-1-17',
	],
	SS: ['PDV-RS-33', 'PDV-RS-35-7', 'PDV-RS-36-5', 'PDV-RS-36b-4'],
	OE: [
		'PDV-RS-17-4-2',
		'PDV-RS-17-4-3',
		'PDV-RS-9',
		'PDV-RS-7a-4',
		'PDV-RS-4-4-2NP',
		'PDV-RS-5-4-2NP',
		'PDV-RS-3NP-ostalo',
	],
	N: ['PDV-RS-4', 'PDV-RS-5', 'PDV-RS-6-1-1 (BN)', 'PDV-RS-3-DZ'],
};

export const BUDGET_USER_TYPE = {
	0: 'Nosioci budžeta',
	1: 'Direktni korisnici budžetskih sredstava',
	2: 'Indirektni korisnici budžetskih sredstava',
	4: 'Organizacione jedinice direktnog korisnika budžetskih sredstava',
	5: 'Namena sredstava direktnih korisnika budžetskih sredstava',
	6: 'Drugi korisnici javnih sredstava',
	7: 'Ostali korisnici javnih sredstava',
	8: 'Pravna lica i drugi subjekti koji ne pripadaju javnom sektoru',
	9: 'Organizacije za obavezno socijalno osiguranje (OOSO)',
	10: 'Korisnici sredstava Republičkog fonda za zdravstveno osiguranje',
	11: 'Organizacione jedinice u sastavu organizacija za obavezno socijalno osiguranje (OJ OOSO)',
};
