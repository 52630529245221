import { CreatePage } from '../../../components/Page';
import {
	ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE,
	ERROR_CONFLICT_DUPLICATE_PARTNER_CODE,
} from '../../../constants/errors';
import { PartnerForm } from './Components/PartnerForm';

export default CreatePage({
	module: 'settings',
	submodule: 'partners',
	model: 'partner',
	view: {
		useEdit: true,
	},
	edit: {
		width: 700,
		title: {
			new: 'Dodavanje partnera',
			existing: (entity) => `Izmena partnera ${entity?.name || ''}`,
		},
		shouldFetch: true,
		onError: (error, entity, form) => {
			if (
				error.response?.data?.errorCode ===
				ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE
			) {
				form.setFields([
					{
						name: 'internalCode',
						errors: ['Uneta šifra već postoji'],
					},
				]);
			}
			if (
				error.response?.data?.errorCode ===
				ERROR_CONFLICT_DUPLICATE_PARTNER_CODE
			) {
				form.setFields([
					{
						name: 'code',
						errors: ['Uneta šifra već postoji'],
					},
				]);
			}
		},
		beforeSave: (values) => {
			if (
				values.internalCode &&
				values.locations.find((l) => l.internalCode === values.internalCode)
			) {
				throw {
					response: {
						data: { errorCode: ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE },
					},
				};
			}
			return {
				...values,
				sellerIdentifier: values.jbkjs ? `JBKJS:${values.jbkjs}` : null,
				jbkjs: undefined,
				locations: (values.locations || []).map((location) => ({
					id: location.id,
					name: location.name,
					address: location.address,
					city: location.city,
					country: location.country,
					internalCode: location.internalCode,
					internalRoutingNumber: location.internalRoutingNumber,
					gln: location.gln,
				})),
			};
		},
		fields: <PartnerForm />,
	},
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: true,
					ellipsis: true,
				},
				// {
				// 	title: 'E-Mail',
				// 	dataIndex: 'email',
				// 	key: 'email',
				// 	ellipsis: true,
				// 	responsive: ['xl'],
				// },
				// {
				// 	title: 'Web Sajt',
				// 	dataIndex: 'website',
				// 	key: 'website',
				// 	ellipsis: true,
				// 	responsive: ['xl'],
				// },
			],
		},
		createButtonText: 'Dodaj partnera',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovog partnera?',
	},
});
