import { Button, Col, Drawer, Form, Input, Row, Space, Spin } from 'antd';
import gs1 from 'gs1-key';
import { useCallback } from 'react';
import { useEffect } from 'react';

import { Title } from '../../../../../../../components/Title';
import stores from '../../../../../../../stores/index.mobx';
import { PartnerLocation } from '../../../../../../../stores/Partner.mobx';

interface Props {
	visible?: boolean;
	entity?: PartnerLocation;
	close: () => void;
	save: (entity: PartnerLocation, index?: number | void) => void;
	locations: PartnerLocation[];
	index?: number | void;
}

export default function LocationEditDrawer({
	visible,
	entity,
	close,
	save,
	locations,
	index,
}: Props) {
	const [form] = Form.useForm();
	const address = Form.useWatch('address', form);
	const city = Form.useWatch('city', form);
	const country = Form.useWatch('country', form);

	const {
		partners: { isCheckingInternalCode, checkInternalCode },
	} = stores;

	const onSubmit = useCallback(
		async (values) => {
			try {
				const found = locations.find(
					(l) =>
						values.internalCode &&
						((values.id && l.id !== values.id) || !values.id) &&
						l.internalCode === values.internalCode
				);

				if (found) {
					throw new Error('Uneta šifra već postoji');
				}

				if (values.internalCode) {
					await checkInternalCode(values.internalCode, values.id);
				}
				save(values, index);
				form.resetFields();
			} catch (e) {
				form.setFields([
					{
						name: 'internalCode',
						errors: ['Uneta šifra već postoji'],
					},
				]);
			}
		},
		[form, locations, index]
	);

	useEffect(() => {
		form.setFieldsValue(entity);
	}, [entity, form]);

	return (
		<Drawer
			width={700}
			visible={visible}
			title={entity ? `Izmena lokacije ${entity?.name}` : 'Dodavanje lokacije'}
			onClose={close}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						Poništi
					</Button>
					<Button key="save" type="primary" onClick={form.submit}>
						{entity ? 'Izmeni' : 'Dodaj'}
					</Button>
				</Space>
			}
		>
			<Spin spinning={isCheckingInternalCode}>
				<Form
					form={form}
					onFinish={(newValue) => {
						onSubmit(newValue);
					}}
					layout="vertical"
				>
					<Title>Osnovne informacije</Title>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item name="id" hidden>
								<Input />
							</Form.Item>
							<Form.Item
								label="Naziv"
								name="name"
								rules={[{ required: true, message: 'Naziv je obavezan' }]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								label={
									<>
										Adresa&nbsp;&nbsp;
										{address && city && (
											<Button
												type="link"
												size="small"
												href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
													`${address || ''} ${city || ''} ${country || ''}`
												)}`}
												target="_blank"
											>
												Prikaži na mapi
											</Button>
										)}
									</>
								}
								name="address"
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item label="Poštanski broj" name="zip">
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item label="Grad" name="city">
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item label="Država" name="country">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Title>Identifikacija</Title>
					<Row gutter={8}>
						<Col xs={24} sm={8}>
							<Form.Item label="Šifra objekta" name="internalCode">
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item
								label="Interni broj za rutiranje"
								name="internalRoutingNumber"
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item
								label="GLN"
								name="gln"
								rules={[
									{
										validator: (_, value) => {
											if (!value || value.length === 0) {
												return Promise.resolve();
											}

											if (!gs1.validate(value)) {
												return Promise.reject();
											}
											return Promise.resolve();
										},
										message: 'GLN nije ispravan',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<input type="submit" style={{ display: 'none' }} />
				</Form>
			</Spin>
		</Drawer>
	);
}
