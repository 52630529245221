import { Mod97_10 } from '@konfirm/iso7064';
import { Col, Row } from 'antd';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import React, { Fragment } from 'react';

import { InvoiceType, TransactionType } from '../../constants/invoice';
import {
	INVOICE_TYPE_MAP,
	PaymentType,
	PAYMENT_TYPE_MAP,
	TRANSACTION_TYPE_MAP,
} from '../../constants/journal';
import numberFormat from '../../lib/numberFormat';
import { normalize } from '../../lib/referenceNoMod97';
import QR from '../../modules/Application/Receipts/List/Components/QR';
import mobxStores from '../../stores/index.mobx';
import styles from './VariableFontReceipt.module.less';

export default function VariableFontReceipt({
	invoiceRequest,
	invoiceResponse,
	locale,
	includeSignature,
	advancePayments,
	advanceItems,
	lastAdvanceSale,
	deferred,
	additionalText,
	unknownAmountAdvance,
}) {
	const { company, application, stores, devices } = mobxStores;
	const { banks } = application;
	const { bankAccounts } = company;
	const { currentStore } = stores;

	function isFiscalInvoice(): boolean {
		return [InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
			invoiceRequest.invoiceType
		);
	}

	function formatDate(date: string, dateOnly = false): string {
		if (dateOnly) {
			return moment(date).format('DD.MM.YYYY');
		}

		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}

	function getInvoiceType() {
		return `${INVOICE_TYPE_MAP[invoiceRequest.invoiceType]}${upperFirst(
			TRANSACTION_TYPE_MAP[invoiceRequest.transactionType]
		)}`;
	}

	function calculateTotalTax() {
		return round(
			invoiceResponse.taxItems.reduce(
				(acc, curr) => acc + Number(curr.amount),
				0
			),
			2
		);
	}

	function calculateTotalDiscount() {
		return round(
			invoiceRequest.items.reduce(
				(acc, curr) => acc + Number(curr.discount * curr.quantity),
				0
			),
			2
		);
	}

	function calculateAdvanceTax(totalTax, advancePayments, totalAmount) {
		return round((totalTax * advancePayments) / totalAmount, 2);
	}

	const totalTax = calculateTotalTax();
	const advanceTax = calculateAdvanceTax(
		totalTax,
		advancePayments || 0,
		invoiceResponse.totalAmount
	);

	const totalDiscount = calculateTotalDiscount();

	const cashPayment = invoiceRequest.payment.find(
		(payment) => payment.paymentType === PaymentType.CASH
	);

	return (
		<div className={`${styles.page} page`}>
			{isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span>{locale['fiscalInvoice']}</span>
				</h3>
			)}
			{!isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span>{locale['notFiscalReceipt']}</span>
				</h3>
			)}
			<Row>
				<Col flex="auto">{invoiceResponse.tin}</Col>
			</Row>
			<Row>
				<Col flex="auto">{invoiceResponse.businessName}</Col>
			</Row>
			<Row>
				<Col flex="auto">{invoiceResponse.locationName}</Col>
			</Row>
			<Row>
				<Col flex="auto">{invoiceResponse.address}</Col>
			</Row>
			<Row>
				<Col flex="auto">{invoiceResponse.district}</Col>
			</Row>
			<hr />
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['cashier']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{invoiceRequest.cashier || ''}
				</Col>
			</Row>
			{invoiceRequest.buyerId && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['buyer']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.buyerId}
					</Col>
				</Row>
			)}
			{invoiceRequest.buyerCostCenterId && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['buyerCostCenter']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.buyerCostCenterId}
					</Col>
				</Row>
			)}
			{invoiceRequest.invoiceNumber && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['posNumber']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.invoiceNumber}
					</Col>
				</Row>
			)}
			{invoiceRequest.dateAndTimeOfIssue && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['posTime']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{formatDate(invoiceRequest.dateAndTimeOfIssue)}
					</Col>
				</Row>
			)}
			{invoiceRequest.referentDocumentNumber && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['refNo']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.referentDocumentNumber}
					</Col>
				</Row>
			)}
			{invoiceRequest.referentDocumentDT && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['refDT']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{formatDate(invoiceRequest.referentDocumentDT)}
					</Col>
				</Row>
			)}

			<h3 className={styles.subTitle}>
				<span>{locale[getInvoiceType()]}</span>
			</h3>

			<p>
				<strong>{locale['items']}</strong>
			</p>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<table className={`${styles.items} items`}>
				<thead>
					<tr>
						<th className={`${styles.tdLeft} tdLeft`}>{locale['itemName']}</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['price']}
						</th>
						<th width={50} className={`${styles.tdRight} tdRight`}>
							{locale['quantity']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['itemTotal']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceRequest.items.map((item, index) => (
						<Fragment key={index}>
							<tr>
								<td className={`${styles.tdLeft} tdLeft`} colSpan={4}>{`${
									item.name
								} (${item.labels.join(', ')})${
									item.unit && item.isPieceUnitOfMeasure
										? ` / ${item.unit}`
										: ''
								}`}</td>
							</tr>
							<tr>
								<td></td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(item.unitPrice, false, 2, true)}
								</td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(item.quantity, false, 3, false)}
								</td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(
										invoiceRequest.transactionType === TransactionType.SALE
											? item.totalAmount
											: -item.totalAmount,
										false,
										2,
										true
									)}
								</td>
							</tr>
						</Fragment>
					))}
				</tbody>
			</table>
			<hr />

			<Row>
				<Col className={styles.alignLeft}>
					<strong>
						{invoiceRequest.transactionType == TransactionType.SALE
							? locale['totalPurchase']
							: locale['totalRefund']}
						:
					</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{numberFormat(invoiceResponse.totalAmount, false, 2, true)}
				</Col>
			</Row>
			{typeof advancePayments !== 'undefined' && (
				<>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['advancePayments']}:</strong>
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							{numberFormat(advancePayments, false, 2, true)}
						</Col>
					</Row>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['advanceTaxes']}:</strong>
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							{numberFormat(advanceTax, false, 2, true)}
						</Col>
					</Row>
				</>
			)}

			{invoiceRequest.payment.map((payment) => (
				<Row key={payment.paymentType}>
					<Col className={styles.alignLeft}>
						<strong>{locale[PAYMENT_TYPE_MAP[payment.paymentType]]}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{numberFormat(payment.amount, false, 2, true)}
					</Col>
				</Row>
			))}
			{typeof advancePayments !== 'undefined' && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['remaining']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{numberFormat(0, false, 2, true)}
					</Col>
				</Row>
			)}
			{invoiceRequest.transactionType === TransactionType.SALE &&
				(cashPayment?.amount || 0) > 0 && (
					<>
						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['paidCash']}:</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								{numberFormat(
									Number(cashPayment.amount) +
										Number(invoiceRequest.paymentChange),
									false,
									2,
									true
								)}
							</Col>
						</Row>

						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['change']}:</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								{numberFormat(invoiceRequest.paymentChange, false, 2, true)}
							</Col>
						</Row>
					</>
				)}

			<h3 className={`${styles.title} title`}>&nbsp;</h3>
			{!isFiscalInvoice() && (
				<>
					<h2 className={`${styles.notFiscalReceipt} notFiscalReceipt`}>
						<span>{locale['notFiscalInvoice']}</span>
					</h2>
					<h3 className={`${styles.title} title`}>&nbsp;</h3>
				</>
			)}

			<table className={`${styles.taxItems} taxItems`}>
				<thead>
					<tr>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['label']}
						</th>
						<th width={70} className={`${styles.tdLeft} tdLeft`}>
							{locale['taxName']}
						</th>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['rate']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['tax']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceResponse.taxItems.map((taxItem, index) => (
						<tr key={index}>
							<td className={`${styles.tdLeft} tdLeft`}>{taxItem.label}</td>
							<td className={`${styles.tdLeft} tdLeft`}>
								{taxItem.categoryName}
							</td>
							<td className={`${styles.tdLeft} tdLeft`}>
								{numberFormat(taxItem.rate, false, 2, true)}%
							</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(taxItem.amount, false, 2, true)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<hr />
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['totalTax']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{numberFormat(
						invoiceResponse.taxItems.reduce(
							(acc, curr) => acc + Number(curr.amount),
							0
						),
						false,
						2,
						true
					)}
				</Col>
			</Row>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['sdcTime']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{formatDate(invoiceResponse.sdcDateTime)}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['sdcInvoiceNo']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{invoiceResponse.invoiceNumber}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['invoiceCounter']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{invoiceResponse.invoiceCounter}
				</Col>
			</Row>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<Row>
				<Col flex="auto">
					<QR
						verificationUrl={invoiceResponse.verificationUrl}
						className={`${styles.qr} qr`}
						style={{}}
						deferred={deferred}
					/>
					<br />
					<br />
				</Col>
			</Row>
			{includeSignature && (
				<>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['signature']}:</strong>
						</Col>
					</Row>
					<br />
					<br />
					<hr />
				</>
			)}

			{isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span>{locale['endInvoice']}</span>
				</h3>
			)}
			{!isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span> {locale['notFiscalReceipt']}</span>
				</h3>
			)}

			{lastAdvanceSale && (
				<>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['lastAdvance']}:</strong>
						</Col>
					</Row>
					<Row>
						<Col flex="auto" className={styles.alignLeft}>
							{lastAdvanceSale.invoiceNumber}{' '}
							{formatDate(lastAdvanceSale.sdcTime, true)}
						</Col>
					</Row>
					<hr />
				</>
			)}

			{advanceItems && advanceItems.length > 0 && (
				<div>
					<p>
						<strong>{locale['advanceItems']}</strong>
					</p>

					<table className={`${styles.items} items`}>
						<thead>
							<tr>
								<th className={`${styles.tdLeft} tdLeft`}>
									{locale['itemName']}
								</th>
								{!unknownAmountAdvance && (
									<th width={100} className={`${styles.tdRight} tdRight`}>
										{locale['price']}
									</th>
								)}
								<th width={50} className={`${styles.tdRight} tdRight`}>
									{locale['quantity']}
								</th>
								{!unknownAmountAdvance && (
									<th width={100} className={`${styles.tdRight} tdRight`}>
										{locale['itemTotal']}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{advanceItems.map((item, index) => (
								<Fragment key={index}>
									<tr>
										<td className={`${styles.tdLeft} tdLeft`} colSpan={4}>
											{`${item.product.name}${
												item.variant?.variantName
													? ` ${item.variant?.variantName}`
													: ''
											}`}{' '}
											(
											{`${(item.labels || item.product.taxRateLabels).join(
												', '
											)})${(() => {
												const showUnit =
													item.product.saleUnit?.label && item.variant
														? item.variant.saleUnit?.isPieceUnitOfMeasure
														: item.product?.saleUnit?.isPieceUnitOfMeasure;
												if (showUnit) {
													return item.variant
														? ` / ${item.variant?.saleUnit?.label}`
														: ` / ${item.product?.saleUnit?.label}` || '';
												}
												return '';
											})()}`}
										</td>
									</tr>
									<tr>
										<td></td>
										{!unknownAmountAdvance && (
											<td className={`${styles.tdRight} tdRight`}>
												{numberFormat(item.finalPrice, false, 2, true)}
											</td>
										)}
										<td className={`${styles.tdRight} tdRight`}>
											{numberFormat(item.quantity, false, 3, false)}
										</td>
										{!unknownAmountAdvance && (
											<td className={`${styles.tdRight} tdRight`}>
												{numberFormat(
													item.finalPrice * item.quantity,
													false,
													2,
													true
												)}
											</td>
										)}
									</tr>
								</Fragment>
							))}
						</tbody>
					</table>
					<hr />
				</div>
			)}

			{currentStore?.printBankAccounts &&
				bankAccounts.length &&
				invoiceRequest.transactionType === TransactionType.SALE &&
				invoiceRequest.payment.find(
					(payment) => payment.paymentType === PaymentType.WIRE_TRANSFER
				) && (
					<p className={`${styles.small} ${styles.alignLeft} small`}>
						{locale['paymentInstructions']}:<br />
						<br />
						<strong>
							{bankAccounts.length > 1
								? locale['bankAccounts']
								: locale['bankAccount']}
							:
						</strong>
						<br />
						{bankAccounts.map((bankAccount, index) => (
							<span key={index}>
								{banks[bankAccount.bankId]}
								<br />
								{bankAccount.formattedNumber}
								<br />
							</span>
						))}
						<br />
						<strong>{locale['model']}:</strong> 97
						<br />
						<strong>{locale['referenceNo']}:</strong>{' '}
						{Mod97_10.checksum(normalize(invoiceResponse.invoiceNumber))}-
						{invoiceResponse.invoiceNumber}
						<br />
						<hr />
					</p>
				)}

			{totalDiscount > 0 && currentStore?.printDiscounts && (
				<>
					<p className={`${styles.small} ${styles.alignLeft} small`}>
						{locale['discounts']}:<br />
						<br />
						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['itemName']}</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								<strong>{locale['discount']}</strong>
							</Col>
						</Row>
						{invoiceRequest.items
							.filter((item) => item.discount > 0)
							.map((item) => (
								<Row>
									<Col className={styles.alignLeft}>{item.name}</Col>
									<Col flex="auto" className={styles.alignRight}>
										{numberFormat(
											item.discount * item.quantity,
											false,
											2,
											true
										)}
									</Col>
								</Row>
							))}
					</p>
					<hr />
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['itemTotal']}</strong>
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							<strong>{numberFormat(totalDiscount, false, 2, true)}</strong>
						</Col>
					</Row>
					<hr />
				</>
			)}

			{additionalText && (
				<p className={`${styles.small} ${styles.alignLeft} small`}>
					{additionalText}
				</p>
			)}

			{devices.thermalPrinters[0].configuration.additionalText !== '' && (
				<>
					<small>
						{devices.thermalPrinters[0].configuration.additionalText}
					</small>
				</>
			)}
			<br />
		</div>
	);
}
