import { Typography } from 'antd';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { Receipt } from '../../../../../../stores/Receipt.mobx';
import styles from './ReferentDocumentLink.module.less';

interface Props {
	record: Receipt;
}

export function ReferentDocumentLink({ record }: Props) {
	const [, setViewQueryParam] = useQueryParam(
		`view/receipts/list`,
		StringParam
	);

	if (!record.connectedReceipts) {
		return record.referentDocumentNumber;
	}

	const ref = record.connectedReceipts.find(
		(item) => item.invoiceNumber === record.referentDocumentNumber
	);

	if (!ref) {
		return record.referentDocumentNumber;
	}

	return (
		<Typography.Link
			className={styles.columnLink}
			onClick={() => {
				return setViewQueryParam(ref.id);
			}}
		>
			{record.referentDocumentNumber}
		</Typography.Link>
	);
}
