import React from 'react';
import { useHistory, useNavigate } from 'react-router';

export const GlobalHistory = {
	navigate: null,
};

export function GlobalNavigator() {
	const navigate = useNavigate();

	return (
		<div
			ref={() => {
				GlobalHistory.navigate = navigate;
			}}
		></div>
	);
}
