import { PlusOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Empty, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';

import { usePermissions } from '../../../hooks/permissions';
import stores from '../../../stores/index.mobx';
import { Partner } from '../../../stores/Partner.mobx';
import { useDrawer } from '../../Page';

interface Props extends Omit<React.ComponentProps<typeof Select>, 'value'> {
	onChange?: (value: any, option: any) => void;
	value?: Partner[] | void;
}

const PartnerSelect = function PartnerSelect({
	onChange,
	value,
	...props
}: Props) {
	const [open, setOpen] = useState(false);
	const [, openCreatePartnerDrawer, , , , EditPartnerModal] = useDrawer(
		'create/settings/partners',
		(event, entity: any) => {
			if (event === 'entity-create') {
				onChange(entity.id, {
					key: entity.id,
					label: entity.name,
					value: entity.id,
				});
			}
		}
	);

	const openCreatePartner = useCallback(() => {
		setOpen(false);
		openCreatePartnerDrawer();
	}, [openCreatePartnerDrawer]);

	const onDropdownVisibleChange = useCallback((visible) => {
		setOpen(visible);
	}, []);

	const hasPartnerCreatePermisssion = usePermissions(
		'settings',
		'partners',
		'create'
	);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/handshake.png" />}
						imageStyle={{ height: 64 }}
						description="Nema partnera"
					>
						<Button
							type="link"
							onClick={() => openCreatePartner()}
							icon={<PlusOutlined />}
						>
							Dodaj novog partnera
						</Button>
					</Empty>
				)}
			>
				<Select
					open={open}
					onDropdownVisibleChange={onDropdownVisibleChange}
					showSearch
					filterOption={(input, option) =>
						option.props.children.toLowerCase().includes(input.toLowerCase())
					}
					loading={stores.partners.isFetching}
					onChange={onChange}
					dropdownRender={
						hasPartnerCreatePermisssion && stores.partners.available.length > 0
							? (menu) => (
									<>
										{menu}
										<Button
											type="link"
											onClick={() => openCreatePartner()}
											icon={<PlusOutlined />}
										>
											Dodaj novog partnera
										</Button>
									</>
							  )
							: null
					}
					value={value}
					{...props}
				>
					{stores.partners.available.map((partner) => (
						<Select.Option key={partner.id} value={partner.id}>
							{partner.name}
						</Select.Option>
					))}
				</Select>
				<EditPartnerModal />
			</ConfigProvider>
		</>
	);
};

export default observer(PartnerSelect);
