import { Tabs, Divider, Spin, Grid } from 'antd';
import { observer } from 'mobx-react-lite';
import { useImperativeHandle, useMemo } from 'react';
import { useWindowSize } from 'react-use';

import Key from '../../../components/Key';
import { PAYMENT_TYPE_TEXT } from '../../../constants/journal';
import stores from '../../../stores/index.mobx';
// import EnterKeyModal from './Components/EnterKeyModal';
import SelectStore from './Components/SelectStore';
import styles from './POS.module.less';
import Sale from './sale';

function POS(_, ref) {
	const {
		products: { isFetching },
		stores: { currentStore, currentStoreId },
		localSales: { active, setActive, available, createSale, removeSale, byId },
	} = stores;
	const paymentMethods = useMemo(() => {
		if (!currentStoreId || currentStore.isResolving) {
			return [];
		}

		return currentStore.paymentMethodsOrder.filter((pm) => pm >= 0);
	}, [currentStore, currentStoreId]);

	const { height } = useWindowSize();

	const screens = Grid.useBreakpoint();

	useImperativeHandle(ref, () => ({
		renderHeader: () => screens.xs && <div />,
	}));

	if (Object.keys(screens).length === 0) {
		return (
			<Spin wrapperClassName={styles.container}>
				<div className={styles.container}></div>
			</Spin>
		);
	}

	return (
		<div className={styles.container}>
			<Spin spinning={isFetching}>
				<Tabs
					className={styles.tabs}
					activeKey={`${active}`}
					tabPosition="top"
					type="editable-card"
					onChange={(key) => {
						setActive(key);
					}}
					tabBarGutter={0}
					animated={{ inkBar: false, tabPane: false }}
					onEdit={(targetKey, action) => {
						if (action === 'remove') {
							removeSale(byId[targetKey]);
						}

						if (action === 'add') {
							createSale();
						}
					}}
				>
					{available.map((sale) => (
						<Tabs.TabPane
							style={{ padding: '24px 24px 0' }}
							key={sale.id}
							tab={`Prodaja ${sale.id}`}
							closable
						>
							<Sale
								height={height}
								sale={sale}
								loading={isFetching}
								active={sale.id === active}
							/>
						</Tabs.TabPane>
					))}
				</Tabs>

				{/* TODO: implement shortcuts */}
				{screens.md && (
					<div className={styles.keys}>
						<Key text="Unos šifre">0-9</Key>
						<Divider type="vertical" />
						<Key text="Potvrdi">enter</Key>
						<Divider type="vertical" />
						<Key text="Odabir artikla">↑/↓</Key>
						<Divider type="vertical" />
						<Key text="Storno">⌫</Key>
						<Divider type="vertical" />
						<Key text="Promena količine">+</Key>
						<Divider type="vertical" />
						<Key text="">⌘ command / ⌃ control</Key>
						<Key text="Pretraga">f</Key>
						{currentStoreId && (
							<>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[0]]}>F10 / F16</Key>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[1]]}>F11 / F17</Key>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[2]]}>F12 / F18</Key>
							</>
						)}
					</div>
				)}
			</Spin>

			{!currentStoreId && <SelectStore />}
		</div>
	);
}

export default observer(POS, { forwardRef: true });
