import { notification } from 'antd';

import stores from '../../stores/index.mobx';

function getThermalPrinter() {
	return stores.devices.thermalPrinters[0];
}

export function print(data) {
	const device = getThermalPrinter();
	if (!device) {
		notification.error({
			message: 'Termalni štampač nije podešen',
		});

		throw new Error('Thermal printer not configured');
	}

	if (window.electron) {
		window.electron.thermalPrinter.print(data);
	}
}
