import { Form, Input, Modal } from 'antd';
import { useCallback, useState } from 'react';

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: (string) => void;
}

export default function EmailModal({
	visible,
	onCancel = () => null,
	onOk = () => null,
}: Props) {
	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const submit = useCallback(async () => {
		setIsSubmitting(true);
		try {
			const values = await form.validateFields();
			onOk(values.email);
		} catch (e) {
			//
		} finally {
			setIsSubmitting(false);
		}
	}, []);

	return (
		<Modal
			centered
			visible={visible}
			width={600}
			title="Slanje fiskalnog računa na E-Mail"
			onCancel={onCancel}
			onOk={submit}
		>
			<Form form={form} onFinish={submit}>
				<Form.Item
					label="E-Mail adresa"
					name="email"
					rules={[
						{
							required: true,
							message: 'Obavezan je unos E-Mail adrese',
						},
						{
							type: 'email',
							message: 'E-Mail adresa nije ispravna',
						},
					]}
				>
					<Input />
				</Form.Item>
				<input type="submit" style={{ display: 'none' }}></input>
			</Form>
		</Modal>
	);
}
