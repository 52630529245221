import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Table } from 'antd';
import { useCallback, useState } from 'react';

import { PartnerLocation } from '../../../../../stores/Partner.mobx';
import LocationEditDrawer from './Components/LocationEditDrawer';
import styles from './LocationsInput.module.less';

type Props = {
	value?: PartnerLocation[];
	onChange?: (value: PartnerLocation[]) => void;
};

function LocationsInput(props: Props) {
	const { value = [], onChange = () => null } = props;
	const [
		[editLocationVisible, editLocationEntity, editLocationIndex],
		setEditLocation,
	] = useState([false, null, null]);

	const save = useCallback(
		(location, index) => {
			const newLocations = [...(value || [])];
			if (index !== null) {
				newLocations[index] = {
					...newLocations[index],
					...location,
				};
			} else {
				newLocations.push(location);
			}
			onChange(newLocations);
			setEditLocation([false, null, null]);
		},
		[value, setEditLocation]
	);

	function handleDeleteLocation(index) {
		const newLocations = (value || []).slice();
		newLocations.splice(index, 1);

		onChange(newLocations);
	}

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src="/images/icons/place.png" alt="Izdvojene lokacije" />}
					imageStyle={{
						height: 64,
					}}
					description="Nema izdvojenih lokacija"
				>
					<Button
						type="link"
						onClick={() => {
							setEditLocation([true, null, null]);
						}}
						icon={<PlusOutlined />}
					>
						Dodaj izdvojenu lokaciju
					</Button>
				</Empty>
				<LocationEditDrawer
					visible={editLocationVisible}
					entity={editLocationEntity}
					save={save}
					close={() => setEditLocation([false, null, null])}
					locations={value || []}
					index={editLocationIndex}
				/>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={value || []}
					footer={() => (
						<Button
							onClick={() => setEditLocation([true, null, null])}
							icon={<PlusOutlined />}
						>
							Dodaj izdvojenu lokaciju
						</Button>
					)}
					scroll={{ x: 640 }}
				>
					<Table.Column
						title="Naziv"
						dataIndex="name"
						key="name"
						width={200}
						ellipsis
					/>
					<Table.Column
						title="Adresa"
						dataIndex="address"
						key="address"
						ellipsis
						render={(text, record: PartnerLocation) =>
							[
								...(record.address ? [record.address] : []),
								...(record.city ? [record.city] : []),
								...(record.country ? [record.country] : []),
							].join(', ')
						}
					/>
					<Table.Column
						title=""
						width={66}
						fixed="right"
						render={(text, record, index) => (
							<Button.Group>
								<Button
									size="small"
									type="link"
									icon={<EditOutlined />}
									onClick={() => {
										setEditLocation([true, record, index]);
									}}
								/>
								<Button
									size="small"
									type="link"
									icon={<DeleteOutlined />}
									danger
									onClick={() => handleDeleteLocation(index)}
								/>
							</Button.Group>
						)}
					/>
				</Table>
			</div>
			<LocationEditDrawer
				visible={editLocationVisible}
				entity={editLocationEntity}
				save={save}
				close={() => setEditLocation([false, null, null])}
				locations={value || []}
				index={editLocationIndex}
			/>
		</>
	);
}

export default LocationsInput;
