import { useLatest } from 'ahooks';
import { Button, Input, List, message, Modal, Tag } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';

import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner';
import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';
import styles from './POS.module.less';
interface Props {
	visible: boolean;
	value: string;
	onConfirm: (sku: number, multiplier: number) => void;
	onCancel: () => void;
	addByEan: (ean: string, quantity: number) => void;
}

function SkuEntryModal({
	visible,
	value,
	onConfirm,
	onCancel,
	addByEan,
}: Props) {
	const [skuPopoverValue, setSkuPopoverValue] = useState('');
	const [currentSku, setCurrentSku] = useState(null);
	const [currentMultiplier, setCurrentMultiplier] = useState(null);
	const [defaultQuantity, setDefaultQuantity] = useState(true);

	const {
		devices: { scales },
		products: { bySku, getById },
	} = stores;

	useEffect(() => {
		if (visible) {
			setSkuPopoverValue(value);
		}
	}, [visible, value]);

	useEffect(() => {
		if (visible) {
			if (skuPopoverValue.includes('x') || skuPopoverValue.includes('*')) {
				const parts = skuPopoverValue.split(/[x*]/);
				const [quantity, sku] = parts;
				const quantityMultiplier = round(
					parseFloat(quantity.replace(/,/g, '.')),
					3
				);
				setCurrentSku(parseInt(sku));
				setCurrentMultiplier(quantityMultiplier);
				setDefaultQuantity(false);
			} else {
				setCurrentSku(parseInt(skuPopoverValue));
				setCurrentMultiplier(1);
				setDefaultQuantity(true);
			}
		}
	}, [visible, skuPopoverValue]);

	const product = useMemo(() => {
		return bySku[currentSku];
	}, [bySku, currentSku]);

	useInterval(async () => {
		if (
			visible &&
			product &&
			product.quantityFromScale &&
			scales.length > 0 &&
			defaultQuantity
		) {
			try {
				setCurrentMultiplier(await window.electron.scale.getWeight());
			} catch (e) {
				setCurrentMultiplier(null);
			}
		}
	}, 500);

	const parentProduct = useMemo(() => {
		if (!product) {
			return null;
		}

		return product.parentId ? getById(product.parentId) : null;
	}, [product, getById]);

	const latestVisible = useLatest(visible);
	const latestCurrentMultiplier = useLatest(currentMultiplier);

	useBarcodeScanner((ean) => {
		if (latestVisible.current) {
			addByEan(ean, latestCurrentMultiplier.current);
			onCancel();
		}
	});

	const submit = useCallback(() => {
		const value = skuPopoverValue;
		if (value === '') {
			return;
		}

		if (currentMultiplier === 0) {
			message.error(
				'Neuspešno očitavanje težine sa vage. Proverite da li je vaga ispravno povezana i konfigurisana.'
			);
			return;
		}

		if (currentMultiplier <= 0) {
			message.error('Količina ne može biti manja od 0,001');
			return;
		}
		if (currentMultiplier > 99999999999999) {
			message.error('Količina ne može biti veća od 99.999.999.999.999,00');
			return;
		}

		if (!product) {
			message.error(`Proizvod sa šifrom ${currentSku} ne postoji`);
			return;
		}

		onConfirm(product, currentMultiplier);
	}, [currentMultiplier, skuPopoverValue, product]);

	return (
		<Modal
			centered
			visible={visible}
			width={900}
			title="Unesite šifru"
			footer={null}
			onCancel={onCancel}
			destroyOnClose
		>
			<Input.Group compact>
				<Input
					style={{ width: 'calc(100% - 100px)' }}
					ref={(skuInput) => {
						if (skuInput) {
							setTimeout(() => skuInput.focus());
						}
					}}
					onChange={(event) => {
						const { value } = event.target;
						setSkuPopoverValue(value);
					}}
					value={skuPopoverValue}
					onPressEnter={submit}
				/>

				<Button type="primary" onClick={submit} style={{ width: 100 }}>
					Potvrdi
				</Button>
			</Input.Group>
			{/* Not variant */}
			{product && !product.parentId && (
				<List>
					<List.Item>
						<List.Item.Meta
							avatar={
								product.coverImage ? (
									<img
										className={styles.largeImage}
										src={product.coverImage.urls['64x64']}
										alt={product.name}
									/>
								) : (
									<div></div>
								)
							}
							title={product.name}
							description={product.subtitle}
						></List.Item.Meta>
						{currentMultiplier === null && <div>---</div>}
						{currentMultiplier === 1 && (
							<div>{numberFormat(product.price, true)}</div>
						)}
						{currentMultiplier !== 1 && currentMultiplier !== null && (
							<div>
								{numberFormat(currentMultiplier, false, 3, false)} x{' '}
								{numberFormat(product.price, true)} ={' '}
								{numberFormat(currentMultiplier * product.price, true)}
							</div>
						)}
					</List.Item>
				</List>
			)}
			{product && product.parentId && (
				<List>
					<List.Item>
						<List.Item.Meta
							avatar={
								parentProduct.coverImage ? (
									<img
										className={styles.largeImage}
										src={parentProduct.coverImage.urls['64x64']}
										alt={parentProduct.name}
									/>
								) : (
									<div></div>
								)
							}
							title={
								<>
									{parentProduct.name}&nbsp;&nbsp;
									<Tag color="magenta">{product.variantName}</Tag>
								</>
							}
							description={parentProduct.subtitle}
						></List.Item.Meta>
						{currentMultiplier === null && <div>---</div>}
						{currentMultiplier === 1 && (
							<div>{numberFormat(product.price, true)}</div>
						)}
						{currentMultiplier !== 1 && currentMultiplier !== null && (
							<div>
								{numberFormat(currentMultiplier, false, 3, false)} x{' '}
								{numberFormat(product.price, true)} ={' '}
								{numberFormat(currentMultiplier * product.price, true)}
							</div>
						)}
					</List.Item>
				</List>
			)}
		</Modal>
	);
}

export default observer(SkuEntryModal);
