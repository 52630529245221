import { Select } from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';

interface Props {
	multiple?: boolean;
}

function PaymentMeansSelect({ multiple = true, ...props }: Props) {
	const {
		company: { bankAccounts },
		application: { banks },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option?.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			showArrow
			maxTagCount="responsive"
			dropdownMatchSelectWidth={false}
			optionLabelProp="label"
			mode={multiple ? 'multiple' : undefined}
			{...props}
		>
			{sortBy(bankAccounts, 'primary').map((bankAccount) => (
				<Select.Option
					key={bankAccount.number}
					value={`30:${bankAccount.number}`}
					label={<>Uplatom na račun {bankAccount.formattedNumber}</>}
				>
					Uplatom na račun {bankAccount.formattedNumber}{' '}
					<small>({banks[Number(bankAccount.number.substring(0, 3))]})</small>
				</Select.Option>
			))}
			<Select.Option key="10" value="10" label="U gotovini">
				U gotovini
			</Select.Option>
		</Select>
	);
}

export default observer(PaymentMeansSelect);
