import Bugsnag from '@bugsnag/browser';
import { action, computed, flow, observable } from 'mobx';

import { v2Client, defaultClient } from '../store/client';
import { CreateStore } from './Crud.mobx';
import { hydrate, init, setCurrentCompanyId } from './index.mobx';
import { Role } from './Role.mobx';
import { ReferenceTransformer } from './transformers/Reference';

const { Store, Entity } = CreateStore({
	name: 'user',
	paginated: false,
	clientVersion: 'v1',
	persistFields: ['all', 'token', 'authenticatedUserId'],
	persistDelay: 0,
});

class User extends Entity {
	@observable label?: string;
	@observable firstName?: string;
	@observable lastName?: string;
	@observable email?: string;
	@observable roleId?: string;
	@ReferenceTransformer('role', 'roleId')
	role?: Role;
	@observable companyId?: string;

	get isDeletable() {
		return this.getParent().authenticatedUser?.id !== this.id;
	}

	@computed
	get initials() {
		return `${this.firstName?.[0]}${this.lastName?.[0]}`.toUpperCase();
	}

	@computed
	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	@computed
	get isAdmin() {
		return this.role?.isAdmin;
	}

	hasPermission(
		module: string,
		submodule: string,
		action: string,
		entityId?: string
	) {
		if (entityId && this.id === entityId) return true;
		return this.isAdmin || this.role?.hasPermission(module, submodule, action);
	}

	constructor(data, parent, ignoreLastUpdated = false) {
		super(parent, ignoreLastUpdated);
		this.init(data);
	}
}

class Users extends Store<User> {
	@observable
	token?: string;
	@observable
	authenticatedUserId?: string;
	@ReferenceTransformer('user', 'authenticatedUserId')
	authenticatedUser?: User;

	constructor() {
		super(User);
	}

	@flow.bound
	*login(email: string, password: string) {
		this.isFetching = true;
		try {
			const { data } = yield this.getClient().post('/users/authenticate', {
				email,
				password,
			});

			if (data) {
				this.setToken(data.token);
				const { data: userData } = yield this.getClient().get('/users/me');
				this.setAuthenticatedUserId(userData.id);
				const user = new User(userData, this, true);
				this.all.push(user);
				this.hydrate(userData.companyId, this).then(function () {
					setCurrentCompanyId(userData.companyId);
					// instantiate();
					hydrate();
				});
				Bugsnag.setUser(user.id, email, user.fullName);
				Bugsnag.addMetadata('companyId', userData.companyId);
			}

			this.isFetching = false;
		} catch (e) {
			this.isFetching = false;
			throw e;
		}
	}

	@flow.bound
	*checkUser() {
		this.isFetching = true;
		try {
			const { data } = yield this.getClient().get('/users/me');
			if (data === null) {
				this.logout();
			}
			this.isFetching = false;
		} catch (e) {
			this.isFetching = false;
			if (e.response?.status === 401) {
				this.logout();
			}
		}
	}

	@action.bound
	setToken(token) {
		defaultClient.defaults.headers.common['x-access-token'] = token;
		v2Client.defaults.headers.common['x-access-token'] = token;
		this.token = token;
	}

	@action.bound
	setAuthenticatedUserId(authenticatedUserId: string) {
		this.authenticatedUserId = authenticatedUserId;
	}

	@action.bound
	logout() {
		delete defaultClient.defaults.headers.common['x-access-token'];
		delete v2Client.defaults.headers.common['x-access-token'];
		this.authenticatedUserId = undefined;
		this.token = undefined;
		setCurrentCompanyId(undefined);
		// init();
		Bugsnag.clearMetadata('companyId');
		Bugsnag.setUser(undefined, undefined, undefined);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Users, User };
