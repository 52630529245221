import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useState } from 'react';

import { usePermissions } from '../../../../../../hooks/permissions';
import numberFormat from '../../../../../../lib/numberFormat';
import CassaScontoEditModal from '../CassaScontoEditModal';

type CassaSconto = {
	dueDate?: string;
	amount?: string;
	id?: string;
};

interface Props {
	value?: Array<CassaSconto>;
}

function CassaScontoView({ value = [] }: Props) {
	const canEditPermission = usePermissions('documents', 'invoices', 'edit');
	const [editVisible, setEditVisible] = useState(false);

	return (
		<>
			{value?.length > 0 && (
				<Table
					size="small"
					bordered
					style={{ width: '100%' }}
					pagination={false}
					columns={[
						{
							title: 'Valuta plaćanja',
							key: 'dueDate',
							dataIndex: 'dueDate',
							render: (text) => moment(text).format('LL'),
							sortOrder: 'ascend',
							sorter: (a, b) => moment(a.dueDate).diff(moment(b.dueDate)),
						},
						{
							title: 'Iznos',
							key: 'amount',
							dataIndex: 'amount',
							render: (text) => numberFormat(text, true),
						},
					]}
					dataSource={value}
					footer={() =>
						canEditPermission && (
							<Button
								icon={<EditOutlined />}
								onClick={() => setEditVisible(true)}
							>
								Izmeni iznose za plaćanje u roku
							</Button>
						)
					}
				/>
			)}
			{!value?.length && (
				<>
					<span>Nema podataka</span>
					{canEditPermission && (
						<>
							.{' '}
							<Button type="link" onClick={() => setEditVisible(true)}>
								Dodaj iznose za plaćanje u roku
							</Button>
						</>
					)}
				</>
			)}
			<CassaScontoEditModal
				visible={editVisible}
				close={() => setEditVisible(false)}
			/>
		</>
	);
}

export default observer(CassaScontoView);
