import { observer } from 'mobx-react-lite';

import { usePermissions } from '../../hooks/permissions';

interface HasPermissionProps {
	module: string;
	submodule: string;
	action: string;
	children: any;
}

function HasPermission({
	module,
	submodule,
	action,
	children,
}: HasPermissionProps) {
	const hasPermission = usePermissions(module, submodule, action);

	return hasPermission && children;
}

export default observer(HasPermission);
