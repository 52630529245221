import { Alert, Input } from 'antd';
import { v4 as uuid } from 'uuid';

import { CreatePage } from '../../../../../components/Page';
import { APPLICATION_NAME } from '../../../../../constants/application';
import SecretInput from './Components/SecretInput';

export default CreatePage({
	module: 'settings',
	submodule: 'apikeys',
	model: 'apiKey',
	page: {
		shouldFetch: true,
		table: {
			columns: [
				{
					title: 'API ključ',
					dataIndex: 'key',
					key: 'key',
					shouldLink: true,
				},
				{
					title: 'Poslednja upotreba',
					dataIndex: 'latUsedAt',
					key: 'latUsedAt',
					render: (date) => (date ? date.fromNow() : '-'),
				},
			],
		},
		createButtonText: 'Dodaj API ključ',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj API ključ?',
	},
	view: {
		useEdit: true,
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje API ključa',
			existing: (entity) => `Izmena API ključa`,
		},
		shouldFetch: true,
		beforeSave: (entity) => {
			if (entity.secretPlain === null) {
				delete entity.secretPlain;
			}
			return entity;
		},
		fields: (entity) => [
			{
				key: 'all',
				fields: [
					{
						key: 'warning',
						component: (
							<Alert
								type="warning"
								message={`Pažnja: API ključevi omogućavaju neograničen pristup celom Vašem
								preduzeću na ${APPLICATION_NAME} aplikaciji. Budite obazrivi na koji način čuvate i kome dajete API ključeve.`}
							></Alert>
						),
					},
					{
						key: 'key',
						label: 'API ključ',
						initialValue: !entity
							? `${process.env.NODE_ENV}.${uuid()}`
							: undefined,
						component: <Input readOnly />,
						span: 24,
					},
					entity
						? {
								// TODO: extract to component to be able to control readonly state
								key: 'secretPlain',
								label: 'API tajna',
								component: <SecretInput />,
								extra:
									'Pažnja: API tajnu nije moguće prikazati. Ukoliko ste izgubili API tajnu, možete je ponovo generisati.',
						  }
						: {
								key: 'secretPlain',
								label: 'API tajna',
								initialValue: uuid(),
								component: <Input readOnly />,
								extra:
									'Pažnja: API tajnu nećete biti u mogućnosti da vidite nakon što zatvorite ovaj prozor. Ukoliko je izgubite, moraćete da je ponovo izgenerišete',
						  },
				],
			},
		],
	},
});
