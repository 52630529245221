import {
	CheckCircleFilled,
	DisconnectOutlined,
	LoadingOutlined,
	StopFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';
import styles from './SocketStatus.module.less';
function SocketStatus() {
	const { socket } = stores;

	return (
		<div className={`${styles.wrapper} connection-status-wrapper`}>
			{(() => {
				switch (socket.status) {
					case 'connected':
						return (
							<Tooltip placement="right" title="Povezan">
								<div className={styles.connected}>
									<CheckCircleFilled />{' '}
									<span className="status-text">Povezan</span>
								</div>
							</Tooltip>
						);
					case 'connecting':
						return (
							<Tooltip placement="right" title="Povezivanje...">
								<div>
									<LoadingOutlined spin />{' '}
									<span className="status-text">Povezivanje...</span>
								</div>
							</Tooltip>
						);
					case 'disconnected':
						return (
							<Tooltip placement="right" title="Nije povezan">
								<div className={styles.disconnected}>
									<DisconnectOutlined />{' '}
									<span className="status-text">Nije povezan</span>
								</div>
							</Tooltip>
						);
					case 'error':
						return (
							<Tooltip placement="right" title="Greška pri povezivanju">
								<div className={styles.error}>
									<StopFilled />{' '}
									<span className="status-text">Greška pri povezivanju</span>
								</div>
							</Tooltip>
						);
				}
			})()}
		</div>
	);
}

export default observer(SocketStatus);
