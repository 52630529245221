import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import {
	TransactionType,
	TRANSACTION_TYPE_MAP,
	PAYMENT_TYPE_MAP,
	PAYMENT_TYPE_TEXT,
	PAYMENT_TYPE_FROM_STRING,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import styles from '../../List.module.less';

function TaxList({ record }) {
	if (!record) {
		return null;
	}
	return (
		<Table
			className={styles.table}
			dataSource={[...record.taxItems]}
			pagination={false}
			size="small"
			bordered
			scroll={{ x: 370 }}
			summary={() => (
				<>
					<Table.Summary.Row>
						<Table.Summary.Cell index={0} colSpan={3} align="right">
							<strong>Ukupan iznos poreza :</strong>
						</Table.Summary.Cell>
						<Table.Summary.Cell index={1} align="right">
							{numberFormat(record?.taxTotal, true, 2, true)}
						</Table.Summary.Cell>
					</Table.Summary.Row>
				</>
			)}
		>
			<Table.Column key="label" dataIndex="label" title="Oznaka" width={80} />
			<Table.Column key="categoryName" dataIndex="categoryName" title="Ime" />
			<Table.Column
				key="rate"
				dataIndex="rate"
				title="Stopa"
				render={(text) => numberFormat(text, false, 2, true) + '%'}
				width={80}
			/>
			<Table.Column
				key="amount"
				dataIndex="amount"
				title="Porez"
				render={(text) => numberFormat(text, true, 2, true)}
				width={150}
				align="right"
			/>
		</Table>
	);
}

export default observer(TaxList);
