import {
	DeleteOutlined,
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';

import { v2Client } from '../../store/client';

type Props = {
	record: any;
	recordProperty: string;
	resource: string;
	onChange?: any;
	value?: any;
};

const SingleImageUpload = ({
	record,
	recordProperty,
	resource,
	onChange,
}: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState(
		record?.[recordProperty]?.urls?.['256x256']
	);

	useEffect(() => {
		setPreviewUrl(record?.[recordProperty]?.urls?.['256x256']);
	}, [record]);

	const handleChange = ({ file }) => {
		if (file.status === 'uploading') {
			setPreviewUrl(null);
			onChange({ status: 'uploading' });
			setIsLoading(true);
		}
		if (file.status === 'done') {
			onChange({
				id: file.response.id,
			});
			setIsLoading(false);
			setPreviewUrl(file.response.urls['256x256']);
		}
		if (file.status === 'error') {
			// message.error('There was an error while uploading');
			setPreviewUrl(null);
			setIsLoading(false);
		}
	};

	const uploadButton = useMemo(
		() => (
			<div>
				{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		),
		[isLoading]
	);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			<Upload
				disabled={isLoading}
				name={'file'}
				listType="picture-card"
				maxCount={1}
				showUploadList={false}
				accept={'.jpg, .jpeg, .png'}
				onChange={handleChange}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}`}
			>
				{previewUrl ? (
					<img src={previewUrl} alt="Pregled" style={{ width: '100%' }} />
				) : (
					uploadButton
				)}
			</Upload>
			{previewUrl && (
				<Button
					disabled={isLoading}
					onClick={() => {
						setPreviewUrl(null);
						onChange(undefined);
					}}
					icon={isLoading ? <LoadingOutlined /> : <DeleteOutlined />}
				>
					{isLoading ? 'Zamenjujem...' : 'Ukloni trenutnu sliku'}
				</Button>
			)}
		</div>
	);
};

export default observer(SingleImageUpload);
