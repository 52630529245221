import { computed, observable } from 'mobx';
import type { Moment } from 'moment';

import { CreateStore } from './Crud.mobx';
import { MomentTransformer } from './transformers/Moment';

const { Store, Entity } = CreateStore({
	name: 'apiKey',
	paginated: false,
	// persistFields: ['all'],
});

class ApiKey extends Entity {
	@observable key?: string;
	@observable secretPlain?: string;
	@MomentTransformer
	lastUsedAt?: Moment;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any): void {
		delete data.secretPlain;
		super.replace(data);
	}
}

class ApiKeys extends Store<ApiKey> {
	constructor() {
		super(ApiKey);
	}
}

export { ApiKeys, ApiKey };
