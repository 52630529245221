import { List, Modal } from 'antd';

import numberFormat from '../../../../../lib/numberFormat';
import { Product } from '../../../../../stores/Product.mobx';
import styles from '../../POS.module.less';
interface Props {
	product: Product;
	visible: boolean;
	onSelect: (product: Product) => void;
	onCancel: () => void;
}
function VariantPicker({ product, visible, onCancel, onSelect }: Props) {
	return (
		<Modal
			centered
			width={500}
			visible={visible}
			onCancel={onCancel}
			footer={null}
			title="Izaberite varijantu"
		>
			<List>
				{product &&
					product.variants.map((variant) => (
						<List.Item onClick={() => onSelect(variant)}>
							<List.Item.Meta
								avatar={
									product.coverImage ? (
										<img
											className={styles.image}
											src={product.coverImage.urls['64x64']}
											alt={product.name}
										/>
									) : (
										<div></div>
									)
								}
								title={variant.variantName}
							></List.Item.Meta>
							<div>
								{numberFormat(
									variant.price,
									true,
									2,
									true,
									variant.currencyId || undefined
								)}
							</div>
						</List.Item>
					))}
			</List>
		</Modal>
	);
}

export default VariantPicker;
