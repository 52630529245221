import { UploadOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { v2Client } from '../../store/client';
import styles from './AttachmentsEdit.module.less';

interface Props {
	onChange?: (files: any) => void;
	value?: any;
	resource?: string;
}

function AttachmentsEdit({
	onChange,
	value,
	resource = 'invoiceAttachment',
}: Props) {
	const uploadRef = useRef(null);

	const [fileList, setFileList] = useState([]);

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		onChange(newFileList);
	};

	useEffect(() => {
		if (value) {
			setFileList(
				value.map((file) =>
					file.uid
						? file
						: {
								uid: file.id,
								name: file.originalFilename || file.filename,
								status: 'done',
								// url: file.urls.original,
								response: file,
						  }
				)
			);
		}
	}, [value]);

	return (
		<div className={styles.container}>
			<Upload
				ref={uploadRef}
				// key={uuid()}
				listType="text"
				className={`${fileList.length === 0 ? styles.empty : ''}`}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}/true`}
				multiple
				onChange={handleChange}
				fileList={fileList}
				accept="application/pdf"
				maxCount={2}
			>
				<Upload.Dragger
					showUploadList={false}
					headers={{
						'x-access-token':
							v2Client.defaults.headers.common['x-access-token'],
					}}
					action={`${v2Client.defaults.baseURL}/files/${resource}/true`}
					multiple
					onChange={handleChange}
					className={`${styles.uploadButton}`}
					openFileDialogOnClick={false}
					accept="application/pdf"
					maxCount={2}
				>
					{fileList.length === 0 && (
						<Empty
							image="/images/icons/attachment.png"
							imageStyle={{
								height: 64,
							}}
							description={
								<>
									Nema priloga, prevucite priloge ili
									<br />
									kliknite ovde da dodate prilog.
								</>
							}
						></Empty>
					)}
					{fileList.length > 0 && (
						<Button
							className={styles.uploadButtonInner}
							icon={<UploadOutlined />}
							disabled={fileList.length === 2}
						>
							Otpremi
						</Button>
					)}
				</Upload.Dragger>
			</Upload>
		</div>
	);
}

export default observer(AttachmentsEdit);
