import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Tooltip } from 'antd';

import { CreatePage } from '../../../../components/Page';

export default CreatePage({
	module: 'settings',
	submodule: 'units',
	model: 'unit',
	view: {
		useEdit: true,
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje jedinice mere',
			existing: (entity) => `Izmena jedinice mere ${entity?.label || ''}`,
		},
		shouldFetch: false,
		fields: [
			{
				key: 'row1',
				fields: [
					{
						key: 'label',
						label: 'Oznaka',
						rules: [{ required: true, message: 'Oznaka je obavezna' }],
						component: <Input />,
						span: 24,
					},
				],
			},
			{
				key: 'row2',
				fields: [
					{
						key: 'isPieceUnitOfMeasure',
						label: '',
						component: (
							<Checkbox>
								Komadna jedinica mere&nbsp;
								<Tooltip
									title={
										<>
											Za komadne jedinice mere se neće računati cena po jedinici
											mere, i jedinica mere se neće prikazivati na fiskalnom
											računu.
										</>
									}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</Checkbox>
						),
						valuePropName: 'checked',
						span: 12,
						initialValue: false,
					},
				],
			},
		],
	},
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Oznaka',
					dataIndex: 'label',
					key: 'label',
					shouldLink: true,
				},
				{
					title: 'Komadna jedinica mere',
					dataIndex: 'isPieceUnitOfMeasure',
					filters: [
						{
							text: 'Da',
							value: '1',
						},
						{
							text: 'Ne',
							value: '0',
						},
					],
					defaultFilteredValue: ['0', '1'],
					filterMultiple: true,
					key: 'calculatePricePerUnit',
					onFilter: (value, record) =>
						(record.calculatePricePerUnit && value === '1') ||
						(!record.calculatePricePerUnit && value === '0'),
					render: (value) => (value ? 'Da' : 'Ne'),
				},
			],
		},
		createButtonText: 'Dodaj jedinicu mere',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovu jedinicu mere?',
	},
});
