import { Col, Row } from 'antd';
import moment from 'moment';

import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';
import { PeriodicReport } from '../../../stores/Report.mobx';
import styles from './A4Periodic.module.less';
import locale from './locale/sr-Cyrl-RS.json';

interface Props {
	storeId: string;
	from: moment.Moment;
	to: moment.Moment;
	invoiceTypes: ('normal' | 'advance')[];
	data: PeriodicReport;
	cashiers: string[] | void;
	getProducts: boolean | void;
	getDaily: boolean | void;
	getByPaymentMethod: boolean | void;
	splitProductsByPaymentMethod: boolean | void;
}

export default function A4Periodic({
	from,
	to,
	invoiceTypes,
	data,
	cashiers,
	getProducts,
	getDaily,
	getByPaymentMethod,
	splitProductsByPaymentMethod,
}: Props) {
	const {
		company: { tin, name },
		users: { getById: getUserById },
		taxRates,
		stores: { currentStore: store },
	} = stores;

	function formatDate(date: string | moment.Moment, dateOnly = false): string {
		if (dateOnly) {
			return moment(date).format('DD.MM.YYYY');
		}

		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}

	return (
		<div className={styles.page}>
			<h2 className={`${styles.title}`}>{locale['periodicReport']}</h2>
			<Row gutter={16}>
				<Col span={24}>
					<strong>{locale['tin']}:</strong> {tin}
					<br />
					<strong>{locale['company']}:</strong> {name}
					<br />
					<strong>{locale['store']}:</strong> {store.name}
					<br />
					<br />
					<strong>{locale['period']}:</strong> {formatDate(from, true)} -{' '}
					{formatDate(to, true)}
					<br />
					<strong>{locale['invoiceTypes']}:</strong>{' '}
					{invoiceTypes.map((type) => locale[type]).join(', ')}
					<br />
					{cashiers && cashiers.length > 0 && (
						<>
							<strong>{locale['cashiers']}:</strong>{' '}
							{cashiers.map((id) => getUserById(id).fullName).join(', ')}
							<br />
						</>
					)}
					{(!cashiers || cashiers.length === 0) && (
						<>
							<strong>{locale['cashiers']}:</strong> {locale['all']}
							<br />
						</>
					)}
				</Col>
			</Row>

			<h3>{locale['typesReport']}</h3>
			{data.byType.map((type) => (
				<>
					<h4>{locale[type.type]}</h4>

					<table className={styles.items}>
						<thead>
							<tr>
								<th width={100} className={styles.tdLeft}>
									{locale['label']}
								</th>
								<th className={styles.tdRight}>{locale['receipts']}</th>
								<th className={styles.tdRight}>{locale['baseVolume']}</th>
								<th className={styles.tdRight}>{locale['tax']}</th>
								<th width={200} className={styles.tdRight}>
									{locale['volumeWithTax']}
								</th>
							</tr>
						</thead>
						<tbody>
							{type.byLabel.map((item, index) => (
								<tr key={index}>
									<td>
										{item.label} ({taxRates.byLabel(item.label).name} -{' '}
										{numberFormat(
											taxRates.byLabel(item.label).rate,
											false,
											2,
											true
										)}
										%)
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.count, false, 0, false)}
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.amount - item.tax, false, 2, true)}
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.tax, false, 2, true)}
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.amount, false, 2, true)}
									</td>
								</tr>
							))}
							<tr key="total">
								<td>{locale['total']}</td>
								<td className={styles.tdRight}>
									{numberFormat(type.count, false, 0, false)}
								</td>
								<td className={styles.tdRight}>
									{numberFormat(type.amount - type.tax, false, 2, true)}
								</td>
								<td className={styles.tdRight}>
									{numberFormat(type.tax, false, 2, true)}
								</td>
								<td className={styles.tdRight}>
									{numberFormat(type.amount, false, 2, true)}
								</td>
							</tr>
						</tbody>
					</table>
				</>
			))}

			<h4>{locale['total']}</h4>

			<table className={styles.items}>
				<thead>
					<tr>
						<th width={100} className={styles.tdLeft}>
							{locale['label']}
						</th>
						<th className={styles.tdRight}>{locale['receipts']}</th>
						<th className={styles.tdRight}>{locale['baseVolume']}</th>
						<th className={styles.tdRight}>{locale['tax']}</th>
						<th width={200} className={styles.tdRight}>
							{locale['volumeWithTax']}
						</th>
					</tr>
				</thead>
				<tbody>
					{data.byLabel.map((item, index) => (
						<tr key={index}>
							<td>
								{item.label} ({taxRates.byLabel(item.label).name} -{' '}
								{numberFormat(
									taxRates.byLabel(item.label).rate,
									false,
									2,
									true
								)}
								%)
							</td>
							<td className={styles.tdRight}>
								{numberFormat(item.count, false, 0, false)}
							</td>
							<td className={styles.tdRight}>
								{numberFormat(item.amount - item.tax, false, 2, true)}
							</td>
							<td className={styles.tdRight}>
								{numberFormat(item.tax, false, 2, true)}
							</td>
							<td className={styles.tdRight}>
								{numberFormat(item.amount, false, 2, true)}
							</td>
						</tr>
					))}
					<tr key="total">
						<td>{locale['total']}</td>
						<td className={styles.tdRight}>
							{numberFormat(data.total.count, false, 0, false)}
						</td>
						<td className={styles.tdRight}>
							{numberFormat(data.total.amount - data.total.tax, false, 2, true)}
						</td>
						<td className={styles.tdRight}>
							{numberFormat(data.total.tax, false, 2, true)}
						</td>
						<td className={styles.tdRight}>
							{numberFormat(data.total.amount, false, 2, true)}
						</td>
					</tr>
				</tbody>
			</table>

			{getDaily && (
				<>
					<h3>{locale['dailyReport']}</h3>
					<table className={styles.items}>
						<thead>
							<tr>
								<th width={100} className={styles.tdLeft}>
									{locale['date']}
								</th>
								<th width={150} className={styles.tdLeft}>
									{locale['label']}
								</th>
								<th className={styles.tdRight}>{locale['receipts']}</th>
								<th className={styles.tdRight}>{locale['baseVolume']}</th>
								<th className={styles.tdRight}>{locale['tax']}</th>
								<th width={200} className={styles.tdRight}>
									{locale['volumeWithTax']}
								</th>
							</tr>
						</thead>
						<tbody>
							{data.byDay.map((day) => (
								<>
									{day.byLabel.map((item, index) => (
										<tr key={index}>
											{index === 0 && (
												<td rowSpan={day.byLabel.length + 1}>
													{formatDate(day.day, true)}
												</td>
											)}
											<td>
												{item.label} ({taxRates.byLabel(item.label).name} -{' '}
												{numberFormat(
													taxRates.byLabel(item.label).rate,
													false,
													2,
													true
												)}
												%)
											</td>
											<td className={styles.tdRight}>
												{numberFormat(item.count, false, 0, false)}
											</td>
											<td className={styles.tdRight}>
												{numberFormat(item.amount - item.tax, false, 2, true)}
											</td>
											<td className={styles.tdRight}>
												{numberFormat(item.tax, false, 2, true)}
											</td>
											<td className={styles.tdRight}>
												{numberFormat(item.amount, false, 2, true)}
											</td>
										</tr>
									))}
									<tr key="total">
										<td>{locale['total']}</td>
										<td className={styles.tdRight}>
											{numberFormat(day.total.count, false, 0, false)}
										</td>
										<td className={styles.tdRight}>
											{numberFormat(
												day.total.amount - day.total.tax,
												false,
												2,
												true
											)}
										</td>
										<td className={styles.tdRight}>
											{numberFormat(day.total.tax, false, 2, true)}
										</td>
										<td className={styles.tdRight}>
											{numberFormat(day.total.amount, false, 2, true)}
										</td>
									</tr>
								</>
							))}
						</tbody>
					</table>
					<br />
				</>
			)}

			{getProducts && (
				<>
					<h3>{locale['products']}</h3>
					<table className={styles.items}>
						<thead>
							<tr>
								<th width={100} className={styles.tdLeft}>
									{locale['sku']}
								</th>
								<th className={styles.tdLeft}>{locale['name']}</th>

								<th width={120} className={styles.tdLeft}>
									{locale['unit']}
								</th>
								{splitProductsByPaymentMethod && (
									<th width={200} className={styles.tdLeft}>
										{locale['paymentMethod']}
									</th>
								)}
								<th className={styles.tdRight}>{locale['quantity']}</th>
								<th className={styles.tdRight}>{locale['unitPrice']}</th>
								<th className={styles.tdRight}>{locale['total']}</th>
							</tr>
						</thead>
						<tbody>
							{data.products.map((item, index) => (
								<tr key={index}>
									<td>{item.sku}</td>
									<td>{item.name}</td>
									<td>{item.unit}</td>
									{splitProductsByPaymentMethod && (
										<td width={200} className={styles.tdLeft}>
											{locale[item.paymentType]}
										</td>
									)}
									<td className={styles.tdRight}>
										{numberFormat(item.quantity, false, 3, false)}
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.unitPrice, false, 2, true)}
									</td>
									<td className={styles.tdRight}>
										{numberFormat(item.amount, false, 2, true)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<br />
				</>
			)}

			{getByPaymentMethod && (
				<>
					<h3>{locale['byPeymentMethod']}</h3>
					<table className={styles.items}>
						<thead>
							<tr>
								<th width={200} className={styles.tdLeft}>
									{locale['paymentMethod']}
								</th>
								<th className={styles.tdRight}>{locale['total']}</th>
							</tr>
						</thead>
						<tbody>
							{data.paymentMethods.map((item, index) => (
								<tr key={index}>
									<td>{locale[item.paymentType]}</td>
									<td className={styles.tdRight}>
										{numberFormat(item.amount, false, 2, true)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<br />
				</>
			)}

			<p>
				<strong>{locale['reportGenerated']}:</strong> {formatDate(moment())}
			</p>
			{/* <h2 className={`${styles.title}`}>{locale['periodicReportEnd']}</h2> */}
		</div>
	);
}
