import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

export type Permissions = Record<
	string,
	Record<string, Record<string, boolean>>
>;

const { Store, Entity } = CreateStore({
	name: 'role',
	paginated: false,
	persistFields: ['all'],
});

class Role extends Entity {
	@observable name?: string;
	@observable active?: boolean = true;
	@observable isAdmin?: boolean = false;
	@observable permissions?: Permissions;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isEditable() {
		return !this.isAdmin;
	}

	get isDeletable() {
		return !this.isAdmin;
	}

	hasPermission(module: string, submodule: string, action: string) {
		return this.permissions?.[module]?.[submodule]?.[action] ?? false;
	}
}

class Roles extends Store<Role> {
	constructor() {
		super(Role);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Roles, Role };
