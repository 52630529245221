import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import stores from '../../stores/index.mobx';

function WarehouseSelect(props) {
	const {
		warehouses: { isFetching, list },
	} = stores;

	const data = useMemo(() => [...list], [list, list.length, isFetching]);
	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			dropdownMatchSelectWidth={false}
			{...props}
		>
			{(data || []).map((store) => (
				<Select.Option key={store.id} value={store.id}>
					{store.name}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(WarehouseSelect);
