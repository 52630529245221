import keyBy from 'lodash/keyBy';
import { observable, computed } from 'mobx';
import moment from 'moment';
import type { Moment } from 'moment';

import { CreateStore } from './Crud.mobx';
import { Currency } from './Currency.mobx';
import { MomentTransformer } from './transformers/Moment';
import { ReferenceTransformer } from './transformers/Reference';

const { Store, Entity } = CreateStore({
	name: 'exchangeRate',
	paginated: false,
	persistFields: ['all'],
});

class ExchangeRate extends Entity {
	@observable currencyId?: string;
	@observable rate?: number;

	@ReferenceTransformer('currency', 'currencyId')
	currency?: Currency;

	@MomentTransformer
	date: Moment;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable(): boolean {
		return false;
	}

	get isEditable(): boolean {
		return false;
	}
}

class ExchangeRates extends Store<ExchangeRate> {
	constructor() {
		super(ExchangeRate);
	}

	@computed
	get lastDate() {
		return this.available?.[0]?.date;
	}
	@computed
	get groupedByDate() {
		return this.available.reduce((acc, item) => {
			const date = item.date.format('YYYY-MM-DD');
			if (!acc[date]) {
				acc[date] = {};
			}
			acc[date][item.currencyId] = item;

			return acc;
		}, {});
	}
	getByDate(date) {
		const dateString = date.format('YYYY-MM-DD');
		const byDate = this.groupedByDate[dateString];
		if (!byDate) {
			// get available previos date from groupedByDate
			// get dates from keys of groupedByDate sorted by date
			const dates = Object.keys(this.groupedByDate).sort().reverse();
			const prevDate = dates.find((d) => d < dateString);
			const prevByDate = prevDate && this.groupedByDate[prevDate];
			if (prevByDate) {
				return [prevByDate, moment(prevDate)];
			}
		} else {
			return [byDate, date];
		}
	}

	@computed
	get lastRates() {
		return keyBy(
			this.available.filter((item) => item.date.isSame(this.lastDate)),
			'currencyId'
		);
	}

	@computed
	get active() {
		return this.available.filter((item) => item.date.isSame(this.lastDate));
	}

	get isCreatable() {
		return false;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { ExchangeRates, ExchangeRate };
