import { Button, Modal, Result, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { POS_TERMINAL_ERROR_CODE } from '../../constants/posTerminal';
import styles from './PosTerminalModal.module.less';
interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: () => void;
	amount: number;
}

function PosTerminalModal({
	visible,
	onCancel = () => null,
	onOk = () => null,
	amount,
}: Props) {
	const [[errorVisible, errorMessage], setError] = useState([false, '']);

	const createTransaction = useCallback(async () => {
		try {
			await window.electron.posTerminal.transaction(amount);
			onOk();
		} catch (e) {
			if (e.error?.message?.code === POS_TERMINAL_ERROR_CODE.CANCELED) {
				setError([true, 'Transakcija je poništena na POS terminalu.']);
			} else if (
				e.error?.message?.code !== POS_TERMINAL_ERROR_CODE.CANCELED_BY_ECR
			) {
				setError([true, 'Proverite razlog na izveštaju sa POS terminala.']);
			}
			// onCancel();
		}
	}, [amount]);

	useEffect(() => {
		if (visible) {
			createTransaction();
		}
	}, [visible, amount]);

	return (
		<Modal
			centered
			visible={visible}
			width={600}
			footer={null}
			closable={false}
			// zIndex={1600}
		>
			{!errorVisible && (
				<Result
					title="Plaćanje karticom"
					subTitle="Čekanje uplate putem POS terminala"
					icon={
						<img
							className={styles.icon}
							src="/images/icons/contactless.png"
							alt="Kartica"
						/>
					}
					extra={
						<Space>
							<Button
								danger
								onClick={() => {
									window.electron.posTerminal.cancel();
									onCancel();
								}}
							>
								Odustani
							</Button>
							<Button
								onClick={() => {
									window.electron.posTerminal.cancel();
									onOk();
								}}
							>
								Ručno potvrdi uplatu
							</Button>
						</Space>
					}
				/>
			)}
			{errorVisible && (
				<Result
					title="Transakcija nije uspela"
					subTitle={errorMessage}
					icon={
						<img
							className={styles.icon}
							src="/images/icons/contactless.png"
							alt="Kartica"
						/>
					}
					extra={
						<Space>
							<Button
								onClick={() => {
									setError([false, '']);
									onCancel();
								}}
								danger
							>
								Odustani
							</Button>

							<Button
								onClick={() => {
									window.electron.posTerminal.cancel();
									onOk();
								}}
							>
								Ručno potvrdi uplatu
							</Button>
							<Button
								type="primary"
								onClick={() => {
									setError([false, '']);
									createTransaction();
								}}
							>
								Pokušaj ponovo
							</Button>
						</Space>
					}
				/>
			)}
		</Modal>
	);
}

export default PosTerminalModal;
