import {
	CheckCircleFilled,
	InfoCircleFilled,
	MinusCircleFilled,
} from '@ant-design/icons';
import {
	Input,
	Button,
	Row,
	Col,
	Form,
	Steps,
	Checkbox,
	Table,
	Tooltip,
	Spin,
	Card,
	Grid,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';

import DisablePasswordAutocomplete from '../../components/DisablePasswordAutocomplete';
import Logo from '../../components/Logo';
import { APPLICATION_NAME } from '../../constants/application';
import {
	ERROR_CONFLICT_DUPLICATE_EMAIL,
	ERROR_CONFLICT_DUPLICATE_TIN,
	ERROR_NOT_FOUND_TIN,
} from '../../constants/errors';
import validatePIB from '../../lib/pibValidator';
import stores from '../../stores/index.mobx';
import styles from './Register.module.less';

const { Password } = Input;

const { Item: FormItem } = Form;
const { Step } = Steps;

function Available() {
	return <CheckCircleFilled className={styles.available} />;
}
function Unavailable() {
	return <MinusCircleFilled className={styles.unavailable} />;
}

const STEPS = {
	PACKAGE: '0',
	PAYMENT_TYPE: '1',
	COMPANY: '2',
	FINISH: '3',
};

function Register() {
	useTitle(`${APPLICATION_NAME} - Registracija`);
	const [form] = Form.useForm();
	const {
		step = process.env.REACT_APP_APP_NAME === 'ibuddy'
			? STEPS.PAYMENT_TYPE
			: STEPS.PACKAGE,
		package: selectedPackage = process.env.REACT_APP_APP_NAME === 'ibududy'
			? 'ibuddy'
			: undefined,
		paymentTypes = 'all',
	}: {
		step: string;
		package: string;
		paymentTypes?: 'all' | 'reduced';
	} = useParams();
	const {
		company: { isFetching, create, isCreating },
	} = stores;

	const screens = Grid.useBreakpoint();

	const [manualEntry, setManualEntry] = useState(false);
	const [tinNotFound, setTinNotFound] = useState(false);

	const navigate = useNavigate();

	const selectPackage = useCallback((pkg) => {
		navigate(`/register/${STEPS.PAYMENT_TYPE}/${pkg}`);
	}, []);
	const selectPaymentTypes = useCallback(
		(pt) => {
			navigate(
				`/register/${STEPS.COMPANY}/${
					process.env.REACT_APP_APP_NAME === 'ibuddy'
						? 'ibuddy'
						: selectedPackage
				}/${pt}`
			);
		},
		[selectedPackage]
	);

	const handleCompanyRegistration = useCallback(() => {
		setTinNotFound(false);
		form.validateFields().then(async (values) => {
			try {
				await create({
					...values,
					selectedPackage,
					availablePaymentMethods: paymentTypes,
				});
				navigate(`/register/${STEPS.FINISH}`);
				if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
					gtag('event', 'conversion', {
						send_to: 'AW-10893059914/HuS-CLbwsbgDEMrOm8oo',
					});
				}
			} catch (error) {
				switch (error.response?.data?.errorCode) {
					case ERROR_CONFLICT_DUPLICATE_TIN:
						form.setFields([
							{
								name: 'tin',
								errors: ['PIB je već u upotrebi'],
							},
						]);
						break;
					case ERROR_NOT_FOUND_TIN:
						setTinNotFound(true);
						break;
					case ERROR_CONFLICT_DUPLICATE_EMAIL:
						form.setFields([
							{
								name: 'adminEmail',
								errors: ['E-mail je već u upotrebi'],
							},
						]);
						break;
				}
			}
		});
	}, [selectedPackage, paymentTypes]);

	const handleSubmit = useCallback(() => {
		return handleCompanyRegistration();
	}, [step, handleCompanyRegistration]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.top}>
					<Logo />
				</div>
				<Form
					onFinish={handleSubmit}
					layout="vertical"
					className={styles.form}
					form={form}
					name="register"
					validateTrigger="onSubmit"
					autoComplete="false"
				>
					<Spin spinning={isFetching || isCreating}>
						<h2 className={styles.panelTitle}>
							Registracija na {APPLICATION_NAME}
						</h2>
						{screens.md && (
							<FormItem>
								<Steps
									size={screens.md ? 'small' : 'default'}
									progressDot
									current={
										parseInt(step, 10) -
										(process.env.REACT_APP_APP_NAME === 'ibuddy' ? 1 : 0)
									}
								>
									{process.env.REACT_APP_APP_NAME !== 'ibuddy' && (
										<Step title="Paket" />
									)}
									<Step title="Vrsta prometa" />
									<Step title="Podaci preduzeća" />
									<Step title="Kraj" />
								</Steps>
							</FormItem>
						)}
						{step === STEPS.PACKAGE && (
							<>
								<FormItem className={styles.panelTitle}>
									Odaberite paket u zavisnosti od vaših potreba.
									<br /> Svaki od paketa uključuje probni period od 14 dana.
								</FormItem>
								<FormItem>
									<Table
										size={screens.md ? 'large' : 'small'}
										pagination={false}
										dataSource={[
											{
												feature: (
													<>
														Osnovne funkcije&nbsp;
														<Tooltip
															title={
																<>
																	Proizvodi, evidentiranje prodaje, pregled
																	izdatih računa, štampa...
																</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: <Available />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: <>Podrška za V-PFR</>,
												lite: <Available />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: <>L-PFR</>,
												lite: '400 RSD mesečno',
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: <>Korisnici</>,
												lite: <Unavailable />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: (
													<>
														Varijante&nbsp;
														<Tooltip
															title={
																<>Grupisanje proizvoda u srodne varijante.</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: <Unavailable />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: (
													<>
														Dokumentacija&nbsp;
														<Tooltip
															title={
																<>Fakture, knjižna odobrenja i zaduženja</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: <Unavailable />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: (
													<>
														Aplikacije&nbsp;
														<Tooltip
															title={
																<>
																	Aplikacije koje proširuju funcionalnost
																	platforme
																</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: <Unavailable />,
												pos: <Available />,
												posplus: <Available />,
												erp: <Available />,
											},
											{
												feature: <>Praćenje zaliha robe</>,
												lite: <Unavailable />,
												pos: <Unavailable />,
												posplus: <Available />,
												erp: <Available />,
											},

											{
												feature: (
													<>
														Napredna dokumentacija&nbsp;
														<Tooltip
															title={
																<>Fakture, kalkulacije, nivelacije, otpis...</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: <Unavailable />,
												pos: <Unavailable />,
												posplus: <Unavailable />,
												erp: <Available />,
											},
											{
												feature: <>Napredni izveštaji</>,
												lite: <Unavailable />,
												pos: <Unavailable />,
												posplus: <Unavailable />,
												erp: <Available />,
											},
											{
												feature: (
													<>
														Cena&nbsp;
														<Tooltip
															title={
																<>
																	Obračunava se po bezbednosnom elementu koji je
																	u upotrebi
																</>
															}
														>
															<InfoCircleFilled className={styles.info} />
														</Tooltip>
													</>
												),
												lite: '50 RSD po fiskalnom računu',
												pos: '1800 RSD mesečno',
												posplus: '2400 RSD mesečno',
												erp: '3600 RSD mesečno',
											},
											{
												feature: '',
												lite: (
													<Button
														type="primary"
														size={screens.md ? 'large' : 'small'}
														onClick={() => selectPackage('lite')}
													>
														Odaberi
													</Button>
												),
												pos: (
													<Button
														type="primary"
														size={screens.md ? 'large' : 'small'}
														onClick={() => selectPackage('pos')}
													>
														Odaberi
													</Button>
												),
												posplus: (
													<Button
														type="primary"
														size={screens.md ? 'large' : 'small'}
														disabled
													>
														Uskoro
													</Button>
												),
												erp: (
													<Button
														type="primary"
														size={screens.md ? 'large' : 'small'}
														disabled
													>
														Uskoro
													</Button>
												),
											},
										]}
									>
										<Table.Column dataIndex="feature" align="right" />
										<Table.Column
											dataIndex="lite"
											title="Lite"
											align="center"
											width={screens.md ? 80 : 60}
										/>
										<Table.Column
											dataIndex="pos"
											title="POS"
											align="center"
											width={screens.md ? 80 : 60}
										/>
										<Table.Column
											dataIndex="posplus"
											title="POS Plus"
											align="center"
											width={screens.md ? 80 : 60}
										/>
										<Table.Column
											dataIndex="erp"
											title="ERP"
											align="center"
											width={screens.md ? 80 : 60}
										/>
									</Table>
								</FormItem>
								<FormItem>
									<Link to="/">
										<Button size="large" block>
											Nazad
										</Button>
									</Link>
								</FormItem>
							</>
						)}
						{step === STEPS.PAYMENT_TYPE && (
							<>
								<FormItem className={styles.panelTitle}>
									Odaberite vrstu prometa koja se evidentira na ovoj instalaciji
									ESIR-a. <br />
									Nećete biti u mogućnosti da naknadno sami promenite vrstu
									prometa.
								</FormItem>
								<FormItem>
									<Row gutter={[16, 16]}>
										<Col span={screens.md ? 12 : 24}>
											<Card
												className={styles.typeCard}
												type="inner"
												actions={[
													<Button
														type="primary"
														onClick={() => {
															selectPaymentTypes('reduced');
														}}
													>
														Odaberi
													</Button>,
												]}
											>
												<div className={styles.centered}>
													<FormItem className={styles.panelTitle}>
														<strong>
															Hrana i piće za konzumaciju na licu mesta
														</strong>
													</FormItem>

													<img
														src="/images/icons/burger.png"
														className={styles.typeIcon}
													/>
												</div>
												<FormItem>
													Preko ovog fiskalnog uređaja se evidentira promet
													usluge predaje hrane i pića za konzumaciju na licu
													mesta u smislu propisa kojim se uređuje porez na
													dodatu vrednost i promet hrane i pića koji se vrši u
													objektu pekare, bez obzira na to da li se preko ovog
													fiskalnog uređaja evidentira i drugi promet na malo.
													<br />
													<br />
													Dozvoljeni načini plaćanja za ovu vrstu prometa:
													<br />
													<br />
													<ol>
														<li>
															<strong>"gotovina"</strong> - ako se plaćanje vrši
															gotovim novcem (novčanicama i kovanim novcem),
															instant transferom odobrenja na prodajnom mestu,
															platnom karticom i čekom
														</li>
														<li>
															<strong>"prenos na račun"</strong> - ako se
															plaćanje vrši nalogom za uplatu ili nalogom za
															prenos
														</li>
														<li>
															<strong>"vaučer"</strong> - ako se plaćanje vrši
															vaučerom, novčanim bonovima, internim karticama
															ili sličnim sredstvom plaćanja
														</li>
														<li>
															<strong>"drugo bezgotovinsko plaćanje"</strong> -
															ako se plaćanje vrši bezgotovinski drugim
															sredstvom plaćanja koje se smatra dozvoljenim u
															Republici Srbiji, u skladu sa zakonom.
														</li>
													</ol>
												</FormItem>
											</Card>
										</Col>
										<Col span={screens.md ? 12 : 24}>
											<Card
												className={styles.typeCard}
												type="inner"
												actions={[
													<Button
														type="primary"
														onClick={() => {
															selectPaymentTypes('all');
														}}
													>
														Odaberi
													</Button>,
												]}
											>
												<div className={styles.centered}>
													<FormItem className={styles.panelTitle}>
														<strong>Sve ostale vrste prometa</strong>
													</FormItem>

													<img
														src="/images/icons/bag.png"
														className={styles.typeIcon}
													/>
												</div>
												<FormItem>
													Preko ovog fiskalnog uređaja se evidentira promet svih
													ostalih vrsta.
													<br />
													<br />
													Dozvoljeni su svi načini plaćanja za ovu vrstu
													prometa, odnosno:
													<br />
													<br />
													<ol>
														<li>
															<strong>"gotovina"</strong> - ako se plaćanje vrši
															gotovim novcem (novčanicama i kovanim novcem)
														</li>
														<li>
															<strong>"instant plaćanje"</strong> - ako se
															plaćanje vrši instant transferom odobrenja na
															prodajnom mestu
														</li>
														<li>
															<strong>"platna kartica"</strong> - ako se
															plaćanje vrši platnom karticom
														</li>
														<li>
															<strong>"ček"</strong> - ako se plaćanje vrši
															čekom
														</li>
														<li>
															<strong>"prenos na račun"</strong> - ako se
															plaćanje vrši nalogom za uplatu ili nalogom za
															prenos
														</li>
														<li>
															<strong>"vaučer"</strong> - ako se plaćanje vrši
															vaučerom, novčanim bonovima, internim karticama
															ili sličnim sredstvom plaćanja
														</li>
														<li>
															<strong>"drugo bezgotovinsko plaćanje"</strong> -
															ako se plaćanje vrši bezgotovinski drugim
															sredstvom plaćanja koje se smatra dozvoljenim u
															Republici Srbiji, u skladu sa zakonom.
														</li>
													</ol>
												</FormItem>
											</Card>
										</Col>
									</Row>
								</FormItem>
							</>
						)}
						{step === STEPS.COMPANY && (
							<>
								<DisablePasswordAutocomplete />
								<FormItem>
									Unesite osnovne podatke o preduzeću. Na osnovu Vašeg poreskog
									identifikacionog broja (PIB) će se preuzeti podaci o
									preduzeću, a pored toga je potrebno da unesete i podatke
									administratora.
								</FormItem>
								<div className="ant-descriptions-header">
									<div className="ant-descriptions-title">
										Informacije o preduzeću
									</div>
								</div>
								<FormItem
									rules={
										!manualEntry
											? [
													{ required: true, message: 'Unesite PIB' },
													{
														type: 'string',
														validator: async (rule, value) => {
															return validatePIB(value)
																? Promise.resolve()
																: Promise.reject();
														},
														message: (
															<>
																PIB koji ste uneli nije ispravan. Kliknite{' '}
																<strong>
																	<a
																		href="#"
																		onClick={() => {
																			setManualEntry(true);
																			setTinNotFound(false);
																		}}
																	>
																		ovde
																	</a>
																</strong>{' '}
																za ručni unos podataka.
															</>
														),
													},
											  ]
											: [{ required: true, message: 'Unesite PIB' }]
									}
									name="tin"
									label="PIB"
									validateStatus={tinNotFound ? 'error' : undefined}
									help={
										tinNotFound ? (
											<>
												PIB koji ste uneli nije pronađen u bazi podataka Narodne
												Banke Srbije. Kliknite{' '}
												<strong>
													<a
														href="#"
														onClick={() => {
															setManualEntry(true);
															setTinNotFound(false);
														}}
													>
														ovde
													</a>
												</strong>{' '}
												za ručni unos podataka.
											</>
										) : undefined
									}
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="companyRegistrationNumber"
									label="Matični broj"
									extra="Ukoliko delatnost obavljate kao fizičko lice, i identifikujete se JMBG-om, unesite ga u polje predviđeno za PIB, a matični broj ostavite prazan."
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="name"
									label="Naziv preduzeća"
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="address"
									label="Adresa preduzeća"
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="zip"
									label="Poštanski broj"
								>
									<Input size="large" />
								</FormItem>
								<FormItem hidden={!manualEntry} name="city" label="Grad">
									<Input size="large" />
								</FormItem>

								<FormItem
									name="vat"
									valuePropName="checked"
									hidden={!manualEntry}
								>
									<Checkbox>U sistemu PDV-a</Checkbox>
								</FormItem>
								<div className="ant-descriptions-header">
									<div className="ant-descriptions-title">
										Informacije o administratoru
									</div>
								</div>
								<Row gutter={8}>
									<Col span={12}>
										<FormItem
											rules={[{ required: true, message: 'Unesite ime' }]}
											name="adminFirstName"
											label="Ime"
										>
											<Input size="large" />
										</FormItem>
									</Col>
									<Col span={12}>
										<FormItem
											rules={[
												{
													required: true,
													message: 'Unesite prezime',
												},
											]}
											name="adminLastName"
											label="Prezime"
										>
											<Input size="large" />
										</FormItem>
									</Col>
								</Row>
								<FormItem
									rules={[
										{
											required: true,
											message: 'Unesite e-mail adresu',
										},
									]}
									name="adminEmail"
									label="E-mail adresa"
								>
									<Input
										size="large"
										autoComplete="false"
										role="presentation"
									/>
								</FormItem>
								<FormItem
									name="adminPassword"
									rules={[
										{ required: true, message: 'Unesite lozinku' },
										{
											type: 'string',
											min: 8,
											message: 'Lozinka mora imati najmanje 8 karaktera',
										},
									]}
									label="Lozinka"
								>
									<Password
										size="large"
										type="password"
										autoComplete="false"
										role="presentation"
									/>
								</FormItem>
								<FormItem
									name="passwordRepeat"
									rules={[
										{ required: true, message: 'Unesite lozinku' },
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													getFieldValue('adminPassword') === value
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error('Lozinke se ne poklapaju')
												);
											},
										}),
									]}
									label="Ponovite lozinku"
								>
									<Password size="large" type="password" />
								</FormItem>
								<FormItem>
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										block
										disabled={isFetching || isCreating}
										loading={isFetching || isCreating}
									>
										Registrujte preduzeće
									</Button>
								</FormItem>
							</>
						)}
						{step === STEPS.FINISH && (
							<>
								<FormItem className={styles.centered}>
									<h1 className={styles.panelTitle}>Čestitamo!</h1>
									<img
										className={styles.successIcon}
										src="/images/icons/happy.svg"
										alt="Uspešna registracija"
									/>
									<p>
										Uspešno ste registrovali svoje preduzeće na{' '}
										{APPLICATION_NAME}
									</p>
								</FormItem>
								<FormItem>
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										block
										onClick={() => (window.location.href = '/app/home')}
									>
										Počnite sa korišćenjem
									</Button>
								</FormItem>
							</>
						)}
					</Spin>
				</Form>
				<div className={styles.copyright}>
					Copyright © 2023 DVSoftware. Sva prava zadržana.
				</div>
			</div>
		</div>
	);
}

export default observer(Register);
