import { makeObservable, observable, computed } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'category',
	paginated: false,
	persistFields: ['all'],
});

class Category extends Entity {
	@observable name?: string;
	@observable categoryId?: string;
	@observable description?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@computed
	get children() {
		const children = this.getParent().available.filter(
			(category) => category.categoryId === this.id
		);
		return children.length ? children : null;
	}

	@computed
	get value() {
		return this.id;
	}

	@computed
	get title() {
		return this.name;
	}

	@computed
	get text() {
		return this.name;
	}
}

class Categories extends Store<Category> {
	constructor() {
		super(Category);
	}

	@computed({ keepAlive: true })
	get tree() {
		return this.available.filter((category) => category.categoryId === null);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Categories, Category };
