import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import stores from '../../stores/index.mobx';

interface Props {
	multiple?: boolean;
	onlyPreferred?: boolean;
	onChange?: (value: string | string[]) => void;
}

function CurrencySelect({
	multiple = false,
	onlyPreferred = false,
	...props
}: Props) {
	const {
		company: { preferredCurrencies },
		currencies: { isFetching, available },
	} = stores;

	const data = useMemo(() => {
		if (isFetching) {
			return [];
		}
		if (onlyPreferred) {
			return available.filter(({ id }) =>
				[...preferredCurrencies, 'RSD'].includes(id)
			);
		}

		return available;
	}, [isFetching, available, onlyPreferred]);

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			dropdownMatchSelectWidth={false}
			mode={multiple ? 'multiple' : undefined}
			{...props}
		>
			{data.map((currency) => (
				<Select.Option key={currency.id} value={currency.id}>
					{currency.id} - {currency.name}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(CurrencySelect);
