export const POS_TERMINAL_ERROR_CODE = {
	OK: '00',
	FAILED: '01',
	SETTLE_REQUIRED: '02',
	TRANSACTION_NOT_ALLOWED: '03',
	UNSUCCESSFUL: '04',
	INVALID_CARD: '05',
	INVALID_ENTRY: '06',
	ERROR: '07',
	UNSUPPORTED_CARD: '08',
	CANCELED: '09',
	NO_PAPER: '10',
	REMOVE_CARD: '11',
	CARD_EXPIRED: '12',
	DECLINED: '13',
	MAN_PAN_NOT_ALLOWED: '14',
	NOT_LOGGED_ON: '15',
	CHIP_NOT_SUPPORTED: '16',
	TRANSACTION_NOT_FOUND: '17',
	CANCELED_BY_ECR: '18',
	INIT_FAILED: '19',
	SETTLEMENT_FAILED: '20',
	LOGON_FAILED: '21',
};
