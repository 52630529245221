import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'saleChannel',
	paginated: false,
	persistFields: ['all'],
});

class SaleChannel extends Entity {
	@observable name?: string;
	@observable applicationId?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable(): boolean {
		return false;
	}

	get isEditable(): boolean {
		return false;
	}
}

class SaleChannels extends Store<SaleChannel> {
	constructor() {
		super(SaleChannel);
	}

	get isCreatable() {
		return false;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { SaleChannels, SaleChannel };
