import { CloudDownloadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Empty, Spin, Badge } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import '../../Drawers/Application';

import { useDrawer } from '../../../../../../components/Page';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Marketplace.module.less';

function Featured() {
	const { marketplaceCategories } = stores;
	const { id } = useParams() as any;

	const [, open, , , , MarketplaceApplicationDrawer] = useDrawer(
		'marketplace-application'
	);

	useEffect(() => {
		marketplaceCategories.getById(id)?.applications.fetchAll();
	}, [id]);

	const category = useMemo(() => marketplaceCategories.getById(id), [id]);

	return (
		<div className={styles.content}>
			<Spin spinning={category?.applications?.isFetching}>
				{!category?.applications?.isFetching &&
					category?.applications?.available.length === 0 && (
						<Empty
							image={<img src={`/images/icons/sad.png`} alt="" />}
							description="Nema aplikacija u ovoj kategoriji, molimo proverite kasnije"
						/>
					)}
				<Row gutter={[8, 8]}>
					{category?.applications?.available?.map((application) => (
						<Col flex="none">
							<Badge.Ribbon
								text="Instalirana"
								color="green"
								style={{ display: application?.isInstalled ? 'block' : 'none' }}
							>
								<Card
									className={styles.card}
									hoverable
									cover={
										application?.logoFile ? (
											<img src={application.logoFile.urls['256x256']} />
										) : (
											<img src="/images/icons/interface.png" />
										)
									}
									onClick={() => open(application.id)}
								>
									<Card.Meta
										title={application.name}
										description={
											<>
												<CloudDownloadOutlined />{' '}
												{numberFormat(
													application?.installationCount,
													false,
													0,
													false
												)}
												{/* Besplatno */}
												{/* <Rate disabled defaultValue={2} /> */}
											</>
										}
									/>
								</Card>
							</Badge.Ribbon>
						</Col>
					))}
				</Row>
				<MarketplaceApplicationDrawer />
			</Spin>
		</div>
	);
}

export default observer(Featured);
