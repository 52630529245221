import camelCase from 'lodash/camelCase';

import { ApiKeys } from './ApiKey.mobx';
import { Application } from './Application.mobx';
import { Categories } from './Category.mobx';
import { Company } from './Company.mobx';
import { Currencies } from './Currency.mobx';
import { DailyReceipts } from './DailyReceipts.mobx';
import { Devices } from './Device.mobx';
import { Discounts } from './Discount.mobx';
import { ExchangeRates } from './ExchangeRate.mobx';
import { Invoices } from './Invoice.mobx';
import { Kpos } from './Kpo.mobx';
import { LocalSales } from './LocalSale.mobx';
import { MarketplaceApplications } from './MarketplaceApplication.mobx';
import { MarketplaceCategories } from './MarketplaceCategory.mobx';
import { migrate } from './migrate';
import { MyMarketplaceApplications } from './MyMarketplaceApplication.mobx';
import { Partners } from './Partner.mobx';
import { Products } from './Product.mobx';
import { Receipts } from './Receipt.mobx';
import { Report } from './Report.mobx';
import { Roles } from './Role.mobx';
import { SaleChannels } from './SaleChannel.mobx';
import { SDC } from './SDC.mobx';
import { Session } from './Session.mobx';
import { Socket } from './Socket.mobx';
import { Stores } from './Store.mobx';
import { TaxRates } from './TaxRate.mobx';
import { Units } from './Unit.mobx';
import { Users } from './User.mobx';
import { Warehouses } from './Warehouse.mobx';
import { Webhooks } from './Webhook.mobx';

const AllStores = {
	ApiKeys,
	Application,
	Categories,
	Company,
	Currencies,
	DailyReceipts,
	Devices,
	Discounts,
	Partners,
	ExchangeRates,
	LocalSales,
	Products,
	Receipts,
	Report,
	Roles,
	SDC,
	Socket,
	Stores,
	TaxRates,
	Units,
	Users,
	Warehouses,
	Webhooks,
	Invoices,
	MarketplaceCategories,
	MarketplaceApplications,
	MyMarketplaceApplications,
	Kpos,
	SaleChannels,
};

const instances = {};

const session = new Session();

export async function hydrate() {
	instances['application'].setIsInitialized(false);
	if (session.currentCompanyId) {
		for (const store in instances) {
			if (instances[store].hydrate) {
				await instances[store].hydrate(
					session.currentCompanyId,
					instances[store]
				);
			}
		}
		if (instances['users'].token) {
			instances['users'].setToken(instances['users'].token);
			instances['users'].setAuthenticatedUserId(
				instances['users'].authenticatedUserId
			);
			await instances['users'].checkUser();

			afterAuth(true, session.currentCompanyId);
		} else {
			afterAuth(false, undefined);
		}
	}
	const converted = await migrate();
	if (!converted) {
		instances['application'].setIsInitialized(true);
	}
}

export function instantiate() {
	for (const store in AllStores) {
		instances[camelCase(store)] = new AllStores[store]();
	}
}

export function setCurrentCompanyId(companyId: string) {
	session.setCurrentCompanyId(companyId);
}

export function afterAuth(authenticated: boolean, companyId: string) {
	for (const store in instances) {
		if (instances[store].afterAuth) {
			instances[store].afterAuth(authenticated, companyId);
		}
	}
}

export async function init() {
	instantiate();
	await session.init();
	hydrate();
}

init();

export default instances as any;
