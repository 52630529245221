import {
	Alert,
	Button,
	Form,
	Input,
	message,
	Modal,
	notification,
	Progress,
	Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import { useDrawer } from '../../../../../../../../components/Page';
import config from '../../../../../../../../config.json';
import { v2Client } from '../../../../../../../../store/client';
import stores from '../../../../../../../../stores/index.mobx';
function ApplicationSettingsEfaktura(props, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [currentApiKey, setCurrentApiKey] = useState('');

	const {
		users: { authenticatedUser },
		invoices: { isImporting, setImporting },
	} = stores;

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`)
				.then((response) => {
					form.setFieldsValue({
						apiKey: response.data.credentials.apiKey,
					});
					setCurrentApiKey(response.data.credentials.apiKey);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id]);

	const [form] = Form.useForm();
	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	const startImport = useCallback(() => {
		v2Client.patch(`/marketplace-applications/${id}/import-invoices`);
		notification.info({
			key: 'eFakturaSync',
			message: 'Uvoz e-faktura',
			description: (
				<span>
					Uvoz postojećih e-faktura je u toku
					<Progress percent={0} status="active" />
				</span>
			),
			duration: 0,
		});
		setImporting(true);
	}, [id]);

	const handleSubmit = async () => {
		const values = await form.validateFields();

		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {},
					credentials: values,
				}
			);

			if (response.data.hasInvoices && currentApiKey !== values.apiKey) {
				Modal.confirm({
					title: 'Podešavanja su sačuvana',
					content:
						'Podešavanja su uspešno sačuvana, da li želite da uvezete postojeće e-fakture?',
					okText: 'Uvezi',
					cancelText: 'Ne',
					onOk: async () => {
						startImport();
						close();
					},
					onCancel: () => {
						close();
					},
				});
			} else {
				close();
			}

			setCurrentApiKey(values.apiKey);
		} catch (error) {
			message.error({
				content: 'Došlo je do greške prilikom čuvanja podešavanja',
			});
		}
		setLoading(false);
	};
	return (
		<Spin spinning={loading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Kredencijali</div>
				</div>
				<Form.Item
					name="apiKey"
					label="API ključ"
					rules={[
						{
							required: true,
							message: 'Obavezan je unos API ključa',
						},
					]}
				>
					<Input />
				</Form.Item>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">
						Parametri za konfigurisanje
					</div>
				</div>
				<Form.Item
					label="Adresa za primanje notifikacija"
					name="notificationUrl"
					initialValue={`${config.apiV2}/marketplace-applications/${id}/webhooks/${authenticatedUser.companyId}`}
				>
					<Input.TextArea readOnly onClick={(event) => event.target.select()} />
				</Form.Item>
				<Form.Item>
					<Alert
						// message="Napomena"
						description="Zbog načina na koji funkcionišu notifikacije na sistemu e-faktura, nove e-fakture i izmene postojećih, ćete početi da dobijate tek od narednog dana (od ponoći). U međuvremenu, možete koristiti uvoz postojećih e-faktura."
						type="warning"
					/>
				</Form.Item>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Akcije</div>
				</div>
				<Form.Item label="Uvoz postojećih e-faktura" name="import">
					<Button
						type="primary"
						onClick={startImport}
						disabled={
							isImporting || !currentApiKey || currentApiKey.length === 0
						}
						loading={isImporting}
					>
						Uvezi e-fakture
					</Button>
				</Form.Item>
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Spin>
	);
}

export default observer(ApplicationSettingsEfaktura, { forwardRef: true });
