import sortBy from 'lodash/sortBy';
import { action, computed, observable } from 'mobx';

import { v2Client, defaultClient } from '../store/client';
import { CreateStore } from './Crud.mobx';
import { ReferenceTransformer } from './transformers/Reference';

interface PrintMethod {
	thermal: boolean;
	a4: boolean;
	email: boolean;
}

const { Store: BaseStore, Entity } = CreateStore({
	name: 'store',
	paginated: false,
	persistFields: ['all', 'currentStoreId'],
});

class Store extends Entity {
	@observable name?: string;
	@observable address?: string;
	@observable city?: string;
	@observable zip?: string;
	@observable phone?: string;
	@observable language: 'sr-Cyrl-RS' | 'sr-Latn-RS' | 'en-US' = 'sr-Cyrl-RS';
	@observable printMethod?: PrintMethod = {
		thermal: true,
		a4: true,
		email: true,
	};
	@observable paymentMethodsOrder?: number[] = [1, 3, 2, -1, 4, 5, 6, 0];
	@observable productPickerType?: 'list' | 'grid' = 'list';
	@observable printBankAccounts?: boolean = false;
	@observable printDiscounts?: boolean = true;
	@observable isSoundEnabled?: boolean = true;
	@observable gln?: string;
	@observable defaultUnknownAmountAdvance?: boolean = false;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable() {
		// TODO: check if store management feature is available
		return true;
	}

	@computed
	get fullAddress() {
		return `${this.address}, ${this.zip} ${this.city}`
			.replace(/  /g, ' ')
			.replace(/\n/g, ', ');
	}
}

class Stores extends BaseStore<Store> {
	@observable currentStoreId?: string;

	@ReferenceTransformer('store', 'currentStoreId')
	currentStore?: Store;

	constructor() {
		super(Store);
	}

	get isCreatable() {
		// TODO: check if store management feature is available
		return true;
	}

	replaceAll(data: any[]): void {
		super.replaceAll(data);
		if (this.currentStoreId) {
			this.setCurrentStore(this.currentStoreId);
		} else {
			const sorted = sortBy(data, 'createdAt');
			if (sorted.length) {
				this.setCurrentStore(sorted[0].id);
			}
		}
	}

	@action.bound
	setCurrentStore(storeId: string) {
		this.currentStoreId = storeId;
		defaultClient.defaults.headers.common['store-id'] = storeId;
		v2Client.defaults.headers.common['store-id'] = storeId;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchAll();
		}
	}
}

export { Stores, Store };
