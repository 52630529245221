import { Input, Select } from 'antd';

import CategorySelect from '../../../../../../components/CategorySelect';
import ProductSelect from '../../../../../../components/ProductSelect';
import { TableInput } from '../../../../../../components/TableInput';
import { DiscountRule } from '../../../../../../stores/Discount.mobx';

type Props = {
	value?: any[];
	onChange?: (any) => void;
};

export function RulesInput({ value = [], ...props }: Props) {
	return (
		<TableInput
			addButtonText="Dodaj pravilo"
			emptyText="Nema pravila"
			iconPath="/images/icons/branch.png"
			columns={[
				{
					title: 'Tip',
					key: 'type',
					dataIndex: 'type',
					editable: true,
					width: 180,
					component: (
						<Select style={{ width: '100%' }}>
							<Select.Option value=""> </Select.Option>
							<Select.Option value="categories">Kategorije</Select.Option>
							<Select.Option value="products">Artikli</Select.Option>
							{/* <Select.Option value="receipt-total">
								Ukupan iznos računa
							</Select.Option> */}
							<Select.Option value="all">Sve</Select.Option>
						</Select>
					),
					rules: [
						{
							required: true,
							message: 'Tip je obavezan',
						},
					],
				},
				{
					title: 'Vrednost',
					key: 'value',
					dataIndex: 'value',
					editable: true,
					component: (record: DiscountRule, index, handleEdit) => {
						if (record.type === 'all') {
							return <>Popust će biti primenjen na sve artikle</>;
						}

						if (record.type === 'receipt-total') {
							return <Input />;
						}

						if (record.type === 'categories') {
							return <CategorySelect multiple />;
						}

						if (record.type === 'products') {
							return <ProductSelect />;
						}

						return <></>;
					},
				},
			]}
			value={value}
			{...props}
		/>
	);
}
