import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	notification,
	Row,
	Spin,
	Select,
} from 'antd';
import gs1 from 'gs1-key';
import { useCallback, useState } from 'react';

import BankAccountsInput from '../../../../../components/BankAccountsInput';
import { Title } from '../../../../../components/Title';
import { BUDGET_USER_TYPE } from '../../../../../constants/documentInvoice';
import { v2Client } from '../../../../../store/client';
import ContactsInput from '../ContactsInput';
import LocationsInput from '../LocationsInput';
import { NameInput } from '../NameInput';

interface Props {
	form?: FormInstance;
}

export function PartnerForm({ form }: Props) {
	const [loading, setLoading] = useState(false);
	const tin = Form.useWatch('tin', form);
	const mb = Form.useWatch('companyRegistrationNumber', form);
	const name = Form.useWatch('name', form);

	const address = Form.useWatch('address', form);
	const city = Form.useWatch('city', form);
	const country = Form.useWatch('country', form);

	const loadCompanyInfo = useCallback(async () => {
		if (tin || mb) {
			setLoading(true);
		}

		let data;

		try {
			if (tin) {
				const { data: responseData } = await v2Client.get(
					`/partners/info/by-tin/${tin}`
				);
				data = responseData;
			} else if (mb) {
				const { data: responseData } = await v2Client.get(
					`/partners/info/by-mb/${mb}`
				);
				data = responseData;
			}
		} catch (error) {
			notification.error({
				message: 'Greška',
				description:
					'Neuspešno preuzimanje informacija o partneru sa javno dostupnih servisa. Pokušajte ponovo ili ručno unesite podatke.',
			});
		}
		if (data) {
			if (typeof data.vat === 'undefined') {
				notification.warning({
					message: 'Upozorenje',
					description:
						'Neuspelo preuzimanje informacije o PDV statusu obveznika.',
				});
			}
			form.setFieldsValue({
				name: data.name,
				address: data.address,
				city: data.place,
				country: 'Republika Srbija',
				companyRegistrationNumber: data.mb,
				tin: data.pib,
				bankAccounts: data.bank.map((bank, index) => ({
					bankId: bank.account.split('-')[0],
					number: bank.account.replace(/-/g, ''),
					primary: index === 0,
				})),
				vat: data.vat,
				zip: data.zip,
				budgetUserType: data.budgetUserType,
			});
		}

		setLoading(false);
	}, [tin, mb, name]);

	return (
		<Spin spinning={loading}>
			<Title>Osnovne informacije</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item
						label="Naziv"
						name="name"
						rules={[{ required: true, message: 'Naziv je obavezan' }]}
						extra="Unosom dela naziva, PIB-a ili matičnog broja, biće vam ponuđeni podaci o partneru sa javno dostupnih servisa. Ukoliko partner ne može biti pronađen, podaci moraju biti ručno uneti."
					>
						<NameInput form={form} setLoading={setLoading} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label="PIB" name="tin">
						<Input onBlur={loadCompanyInfo} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label="Matični broj" name="companyRegistrationNumber">
						<Input onBlur={loadCompanyInfo} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label="JBKJS" name="jbkjs">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label="Šifra partnera" name="code">
						<Input />
					</Form.Item>
				</Col>
				{form.getFieldValue('jbkjs') && (
					<Col span={24}>
						<Form.Item
							name="budgetUserType"
							label="Tip korisnika javnih sredstava"
						>
							<Select>
								{Object.entries(BUDGET_USER_TYPE).map(([key, value]) => (
									<Select.Option key={key} value={key}>
										{key} - {value}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				)}
				<Col span={24}>
					<Form.Item name="vat" valuePropName="checked">
						<Checkbox>U sistemu PDV-a</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item
						label={
							<>
								Adresa&nbsp;&nbsp;
								{address && city && (
									<Button
										type="link"
										size="small"
										href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
											`${address || ''} ${city || ''} ${country || ''}`
										)}`}
										target="_blank"
									>
										Prikaži na mapi
									</Button>
								)}
							</>
						}
						name="address"
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label="Poštanski broj" name="zip">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label="Grad" name="city">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label="Država" name="country">
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col xs={24} sm={12}>
					<Form.Item
						label="E-Mail"
						name="email"
						rules={[{ type: 'email', message: 'E-Mail nije ispravan' }]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item label="Web sajt" name="website">
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Title>Kontakti</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="phones">
						<ContactsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>Tekući računi</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="bankAccounts">
						<BankAccountsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>Identifikacija</Title>
			<Row gutter={8}>
				<Col xs={24} sm={8}>
					<Form.Item label="Šifra objekta sedišta" name="internalCode">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item
						label="Interni broj za rutiranje sedišta"
						name="internalRoutingNumber"
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item
						label="GLN sedišta"
						name="gln"
						rules={[
							{
								validator: (_, value) => {
									if (!value || value.length === 0) {
										return Promise.resolve();
									}

									if (!gs1.validate(value)) {
										return Promise.reject();
									}
									return Promise.resolve();
								},
								message: 'GLN nije ispravan',
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Title>Izdvojene lokcije</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="locations">
						<LocationsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>Ostalo</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item label="Napomena" name="notes">
						<Input.TextArea />
					</Form.Item>
				</Col>
			</Row>
		</Spin>
	);
}
