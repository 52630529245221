import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Table, Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useCallback } from 'react';

import stores from '../../stores/index.mobx';
import { BankAccount } from '../../stores/shared/BankAccount';
import styles from './BankAccountsInput.module.less';
import BankAccountEditDrawer from './Components/BankAccountEditDrawer';

// type BankAccount = {
// 	bankId?: string;
// 	number?: string;
// 	id: string;
// 	part1?: string;
// 	part2?: string;
// 	part3?: string;
// 	primary: boolean;
// 	valid: boolean;
// };

type Props = {
	value?: BankAccount[];
	onChange?: (any) => void;
	showInvoiceSwitch?: boolean;
};

function BankAccountsInput(props: Props, ref) {
	const { application } = stores;
	const [
		[editBankAccountVisible, editBankAccountEntity, editBankAccountIndex],
		setEditBankAccount,
	] = useState([false, null, null]);

	const { value = [], onChange = () => null } = props;
	const { banks } = application;

	const handleDeleteBankAccount = useCallback(
		(index) => {
			const newBankAccounts = value.slice();
			newBankAccounts.splice(index, 1);

			onChange(newBankAccounts);
		},
		[value]
	);

	const save = useCallback(
		(account) => {
			const newAccounts = [
				...(value || []).map(
					(v) =>
						new BankAccount({
							...v,
							primary: account.primary ? false : v.primary,
						})
				),
			];
			if (editBankAccountIndex !== null) {
				newAccounts[editBankAccountIndex] = new BankAccount({
					...newAccounts[editBankAccountIndex],
					...account,
				});
			} else {
				newAccounts.push(account);
			}
			onChange(newAccounts);
			setEditBankAccount([false, null, null]);
		},
		[editBankAccountIndex, value]
	);

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src="/images/icons/bank.png" alt="Tekući računi" />}
					imageStyle={{
						height: 64,
					}}
					description="Nema tekućih računa"
				>
					<Button
						type="link"
						onClick={() => {
							setEditBankAccount([true, null, null]);
						}}
						icon={<PlusOutlined />}
					>
						Dodaj tekući račun
					</Button>
				</Empty>
				<BankAccountEditDrawer
					visible={editBankAccountVisible}
					entity={editBankAccountEntity}
					save={save}
					close={() => setEditBankAccount([false, null, null])}
					showInvoiceSwitch={props.showInvoiceSwitch}
				/>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={value || []}
					footer={() => (
						<Button
							onClick={() => setEditBankAccount([true, null, null])}
							icon={<PlusOutlined />}
						>
							Dodaj tekući račun
						</Button>
					)}
				>
					<Table.Column
						title="Primaran"
						dataIndex="primary"
						key="primary"
						render={(value) => <Checkbox checked={value} readOnly />}
						width={90}
					/>
					<Table.Column
						title="Banka"
						dataIndex="bankId"
						key="bankId"
						ellipsis
						render={(value) => banks[Number(value)]}
					/>
					<Table.Column
						title="Broj računa"
						dataIndex="number"
						key="number"
						width={200}
						ellipsis
						render={(value) => {
							const bankId = value.substring(0, 3);
							const accountNumber = value.substring(3, value.length - 2);
							const controlNumber = value.substring(value.length - 2);
							return `${bankId}-${accountNumber}-${controlNumber}`;
						}}
					/>

					<Table.Column
						title=""
						width={66}
						fixed="right"
						render={(text, record, index) => (
							<Button.Group>
								<Button
									size="small"
									type="link"
									icon={<EditOutlined />}
									onClick={() => {
										setEditBankAccount([true, record, index]);
									}}
								/>
								<Button
									size="small"
									type="link"
									icon={<DeleteOutlined />}
									danger
									onClick={() => handleDeleteBankAccount(index)}
								/>
							</Button.Group>
						)}
					/>
				</Table>
			</div>
			<BankAccountEditDrawer
				visible={editBankAccountVisible}
				entity={editBankAccountEntity}
				save={save}
				close={() => setEditBankAccount([false, null, null])}
				showInvoiceSwitch={props.showInvoiceSwitch}
			/>
		</>
	);
}

export default observer(BankAccountsInput, { forwardRef: true });
