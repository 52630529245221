import { Button, Card, Col, Modal, Result, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { useDrawer } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';
import styles from '../POS.module.less';
function SelectStore() {
	const {
		stores: { available, setCurrentStore },
	} = stores;

	const [, openCreate] = useDrawer('create');

	return (
		<Modal
			centered
			visible={true}
			title={available.length > 0 ? 'Odaberite prodajno mesto' : null}
			footer={null}
			width={700}
			closable={false}
		>
			{!available.length && (
				<Result
					icon={<img src="/images/icons/shops.png" className={styles.icon} />}
					title="Nemate ni jedno prodajno mesto"
					subTitle="Morate imati bar jedno prodajno mesto da biste mogli da izdajete račune"
					extra={
						<Button
							type="primary"
							onClick={() => {
								openCreate('store');
							}}
						>
							Dodaj prodajno mesto
						</Button>
					}
				/>
			)}
			{available.length > 0 && (
				<Row gutter={16}>
					{available.map((store) => (
						<Col span={8}>
							<Card
								style={{ height: '100%' }}
								type="inner"
								title={store.name}
								hoverable
								onClick={() => {
									setCurrentStore(store.id);
								}}
							>
								{store.address}
							</Card>
						</Col>
					))}
				</Row>
			)}
		</Modal>
	);
}

export default observer(SelectStore);
