import { message } from 'antd';
import { action, computed, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { CreateStore } from './Crud.mobx';

export type SerialDevice = {
	type: 'serial';
	name?: string;
	productId?: string;
	vendorId?: string;
	path?: string;
	manufacturer?: string;
	baudRate?: number;
};

export type PrinterDevice = {
	type: 'printer';
	name?: string;
	protocol?: 'escpos' | 'peripage';
};

export type NetworkDevice = {
	type: 'network';
	host?: string;
	port?: number;
};

export type ScaleDeviceConfiguration = {
	type: 'scale';
	protocol?: 'elicom-continuous';
};

export type ThermalPrinterDeviceConfiguration = {
	type: 'thermal_printer';
	imageMode?: 'image' | 'raster';
	printLogo?: boolean;
	printWidth?: '58' | '80';
	resolution?: number;
	cut?: boolean;
	kick?: boolean;
	delayClose?: number;
	delayConsecutive?: number;
	additionalText?: string;
	fontType?: 'fixed' | 'variable';
	font?:
		| 'Roboto Mono'
		| 'Source Code Pro'
		| 'Ubuntu Mono'
		| 'IBM Plex Mono'
		| 'PT Mono'
		| 'Cousine'
		| 'Fira Mono'
		| 'Anonymous Pro'
		| 'Overpass Mono'
		| 'JetBrains Mono'
		| 'Noto Sans Mono';
	variableFont?:
		| 'Roboto'
		| 'Roboto Condensed'
		| 'Roboto Slab'
		| 'Open Sans'
		| 'Montserrat'
		| 'Source Sans Pro'
		| 'Noto Sans'
		| 'Ubuntu'
		| 'IBM Plex Sans'
		| 'IBM Plex Serif'
		| 'PT Sans'
		| 'PT Serif';
};

const { Store, Entity } = CreateStore({
	name: 'device',
	paginated: false,
	persistFields: ['all'],
	clientVersion: 'local',
	local: true,
});

class Device extends Entity {
	@observable name?: string;
	@observable type?:
		| 'barcode_scanner'
		| 'thermal_printer'
		| 'printer'
		| 'scanner'
		| 'rfid_reader'
		| 'nfc_reader'
		| 'pos_terminal'
		| 'scale';
	@observable connectionType?:
		| 'usb'
		| 'bluetooth'
		| 'serial'
		| 'network'
		| 'hid'
		| 'printer';
	@observable device?: SerialDevice | PrinterDevice | NetworkDevice;
	@observable configuration?:
		| ScaleDeviceConfiguration
		| ThermalPrinterDeviceConfiguration
		| void;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isEditable() {
		return false;
	}

	@action.bound
	saveConfiguration(configuration) {
		this.configuration = configuration;
	}
}

class Devices extends Store<Device> {
	@observable serialDevices: SerialDevice[] = [];
	@observable printerDevices: PrinterDevice[] = [];

	constructor() {
		super(Device);
	}

	@computed
	get thermalPrinters() {
		return this.available.filter((d) => d.type === 'thermal_printer');
	}

	@computed
	get posTerminals() {
		return this.available.filter((d) => d.type === 'pos_terminal');
	}

	@computed
	get barcodeScanners() {
		return this.available.filter((d) => d.type === 'barcode_scanner');
	}

	@computed
	get scales() {
		return this.available.filter((d) => d.type === 'scale');
	}

	*create(device) {
		const newDevice = {
			...device,
			id: uuid(),
			device: {
				...JSON.parse(device.device),
				type: device.connectionType,
			},
			...(device.type === 'scale'
				? { configuration: { type: 'scale', protocol: 'elicom-continuous' } }
				: {}),
			...(device.type === 'thermal_printer'
				? {
						configuration: {
							type: 'thermal_printer',
							imageMode: 'image',
							printLogo: true,
							printWidth: '58',
							resolution: 203,
							cut: true,
							kick: true,
							delayClose: 5,
							delayConsecutive: 2,
							additionalText:
								process.env.REACT_APP_APP_NAME === 'ibuddy'
									? 'Ovaj račun je izdat pomoću\nI Buddy POS aplikacije\nhttps://ibuddy.rs'
									: 'Ovaj račun je izdat pomoću\nStoreBuddy POS aplikacije\nhttps://storebuddy.rs',
							fontType: 'fixed',
							font: 'Roboto Mono',
							variableFont: 'Montserrat',
						},
				  }
				: {}),
		};
		if (device.type === 'thermal_printer') {
			window['electron'].registerThermalPrinter(newDevice);
		}

		if (device.type === 'pos_terminal') {
			window['electron'].registerPosTerminal(newDevice);
		}
		if (device.type === 'barcode_scanner') {
			window['electron'].registerBarcodeScanner(newDevice);
		}
		if (device.type === 'scale') {
			window['electron'].registerScale(newDevice);
		}

		return super.create(newDevice);
	}

	@action.bound
	addDevice(type: 'serial' | 'printer', device: any) {
		if (type === 'serial') {
			if (!this.serialDevices.find((d) => d.path === device.path)) {
				this.serialDevices.push({
					type: 'serial',
					...device,
					name: `${device.path} ${
						device.manufacturer ? `(${device.manufacturer})` : ''
					}`,
				});
			}
		} else if (type === 'printer') {
			if (!this.printerDevices.find((d) => d.name === device.name)) {
				this.printerDevices.push({
					type: 'printer',
					...device,
				});
			}
		}
	}

	@action.bound
	removeDevice(type: 'serial' | 'printer', device: any) {
		if (type === 'serial') {
			this.serialDevices = this.serialDevices.filter(
				(d) => d.path !== device.path
			);
		} else if (type === 'printer') {
			this.printerDevices = this.printerDevices.filter(
				(d) => d.name !== device.name
			);
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (window['electron']) {
				window['electron'].receive('serial-port-added', (port) => {
					// if (port.vendorId && port.productId) {
					this.addDevice('serial', port);
					// }
				});
				window['electron'].receive('serial-port-removed', (path) => {
					this.removeDevice('serial', path);
				});

				window['electron'].receive('printer-added', (printer) => {
					this.addDevice('printer', printer);
				});
				window['electron'].receive('printer-removed', (name) => {
					this.removeDevice('printer', name);
				});

				window['electron'].receive('register-pos-terminal-error', () => {
					message.error(
						'Greška prilikom povezivanja na POS terminal. Proverite da li je uređaj povezan, uključen i ispravno konfigurisan.'
					);
				});
				window['electron'].receive('register-thermal-printer-error', () => {
					message.error(
						'Greška prilikom povezivanja na termalni štampač. Proverite da li je uređaj povezan, uključen i ispravno konfigurisan.'
					);
				});
				window['electron'].receive(
					'register-barcode-scanner-error',
					(error) => {
						message.error(
							'Greška prilikom povezivanja na bar kod skener. Proverite da li je uređaj povezan, uključen i ispravno konfigurisan.'
						);
						console.log(error);
					}
				);
				window['electron'].receive('register-scale-error', (error) => {
					message.error(
						'Greška prilikom povezivanja na vagu. Proverite da li je uređaj povezan, uključen i ispravno konfigurisan.'
					);
					console.log(error);
				});

				window['electron'].enumerateSerialDevices();
				window['electron'].enumeratePrinterDevices();

				const thermalPrinter = this.thermalPrinters[0];
				if (thermalPrinter) {
					window['electron'].registerThermalPrinter(thermalPrinter.toPlain());
				}

				const posTerminal = this.list.find((d) => d.type === 'pos_terminal');
				if (posTerminal) {
					window['electron'].registerPosTerminal(posTerminal.toPlain());
				}

				const barcodeScanner = this.barcodeScanners[0];
				if (barcodeScanner) {
					window['electron'].registerBarcodeScanner(barcodeScanner.toPlain());
				}
				const scale = this.scales[0];
				if (scale) {
					window['electron'].registerScale(scale.toPlain());
				}
			}
		}
	}
}

export { Devices, Device };
