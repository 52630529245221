import { Select, Row, Col, Input } from 'antd';
import { useState, useEffect } from 'react';

interface Props {
	value?: string;
	onChange?: (string) => void;
	readOnly?: boolean;
	disabled?: boolean;
}

const costCenterTypes = [
	{
		value: 10,
		label: 'Broj ZPPPDV',
	},
	{
		value: 11,
		label: 'Broj obrasca operatera za refakciju',
	},
	{
		value: 20,
		label: 'Broj SNPDV',
	},
	{
		value: 21,
		label: 'Broj LNPDV',
	},
	{
		value: 30,
		label: 'Broj PPO-PDV',
	},
	{
		value: 31,
		label: 'Broj ZPPO-PDV',
	},
	{
		value: 32,
		label: 'Broj MPPO-PDV',
	},
	{
		value: 33,
		label: 'Broj IPPO-PDV',
	},
	{
		value: 40,
		label: 'Broj jednonamenskog vaučera',
	},
];

function BuyerCostCenterInput({
	value,
	onChange,
	readOnly = false,
	disabled = false,
}: Props) {
	const initialValue = value ? value.split(':') : ['', ''];
	const [type, setType] = useState(initialValue[0]);
	const [id, setId] = useState(initialValue[1]);

	useEffect(() => {
		const [t, i] = value ? value.split(':') : ['', ''];

		setType(t);
		setId(i);
	}, [value]);

	useEffect(() => {
		const newValue = type ? `${type}:${id}` : ':';
		if (newValue === value) {
			return;
		}
		if (newValue === ':') {
			onChange(null);
		} else {
			onChange(newValue);
		}
	}, [type, id]);

	return (
		<Row gutter={2}>
			<Col span={10}>
				<Select
					dropdownMatchSelectWidth={false}
					value={type ? Number(type) : undefined}
					onChange={(value) => {
						setType(value);
					}}
					disabled={readOnly || disabled}
				>
					<Select.Option value="">&nbsp;</Select.Option>
					{costCenterTypes.map((option) => (
						<Select.Option value={option.value} key={option.value}>
							{option.label}
						</Select.Option>
					))}
				</Select>
			</Col>
			<Col span={14}>
				<Input
					style={{ width: '100%' }}
					value={id}
					onChange={(event) => {
						setId(event.target.value);
					}}
					disabled={readOnly || disabled}
				/>
			</Col>
		</Row>
	);
}
export default BuyerCostCenterInput;
