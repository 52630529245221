import { Input, Select } from 'antd';
import React from 'react';

import Editor from '../../../../../../components/Editor';
import { CreatePage } from '../../../../../../components/Page';
import PhotosEdit from '../../../../../../components/PhotosEdit';
import SingleImageUpload from '../../../../../../components/SingleImageUpload';

export default CreatePage({
	module: 'settings',
	submodule: 'my-applications',
	model: 'myMarketplaceApplications',
	view: {
		useEdit: true,
	},
	edit: {
		width: 800,
		title: {
			new: 'Dodavanje aplikacije',
			existing: (entity) => `Izmena aplikacije ${entity?.name || ''}`,
		},
		beforeSave: (values) => {
			return {
				...values,
				// dimensions: values.dimensions === 'xx' ? null : values.dimensions,
				images: values.images
					? values.images.map((image) => image.response?.id || image?.id)
					: [],
				logoFileId: values.logoFileId?.id,
			};
		},
		fields: (record) => [
			{
				key: 'basic',
				label: 'Osnovne informacije',
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						rules: [{ required: true, message: 'Naziv je obavezan' }],
						component: <Input />,
						span: 10,
					},
					{
						key: 'packageId',
						label: 'ID paketa',
						rules: [
							{ required: true, message: 'ID paketa je obavezan' },
							{
								type: 'string',
								pattern: /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/,
								message: (
									<>
										ID paketa mora biti u{' '}
										<a
											href="https://en.wikipedia.org/wiki/Reverse_domain_name_notation#:~:text=Reverse%20domain%20name%20notation%20(or,components%20reversed%20for%20grouping%20purposes."
											target="_blank"
										>
											reverse domain name
										</a>{' '}
										notaciji
									</>
								),
							},
						],
						component: <Input />,
						span: 10,
					},
					{
						key: 'version',
						label: 'Verzija',
						rules: [
							{ required: true, message: 'Verzija je obavezna' },
							{
								type: 'string',
								pattern: /^\d+\.\d+\.\d+$/,
								message: 'Verzija mora biti u formatu x.y.z',
							},
						],
						component: <Input />,
						span: 4,
					},
					{
						key: 'description',
						label: 'Opis',
						rules: [{ required: true, message: 'Opis je obavezan' }],
						component: <Editor />,
						span: 24,
					},

					{
						key: 'scopes',
						label: 'Opseg dozvola pristupa',
						rules: [{ required: true, message: 'Opseg je obavezan' }],
						component: (
							<Select mode="tags">
								<Select.Option value="company">Preduzeće</Select.Option>
								<Select.Option value="users">Korisnici</Select.Option>
								<Select.Option value="categories">Kategorije</Select.Option>
								<Select.Option value="products">Artikli</Select.Option>
								<Select.Option value="receipts">Fiskalni računi</Select.Option>
								<Select.Option value="invoices">Fakture</Select.Option>
							</Select>
						),
						span: 24,
					},
					{
						key: 'applicationUrl',
						label: 'URL aplikacije',
						rules: [
							{ required: true, message: 'URL aplikacije je obavezan' },
							{
								type: 'url',
								message: 'URL aplikacije mora biti validan',
							},
						],
						component: <Input />,
						span: 24,
					},
					{
						key: 'websiteUrl',
						label: 'Web sajt',
						rules: [
							{
								type: 'url',
								message: 'URL aplikacije mora biti validan',
							},
						],
						component: <Input />,
						span: 24,
					},
					{
						key: 'supportEmail',
						label: 'Email za podršku',
						rules: [
							{
								type: 'email',
								message: 'Email mora biti validan',
							},
						],
						component: <Input />,
						span: 24,
					},
				],
			},
			{
				key: 'images',
				label: 'Slike',
				fields: [
					{
						key: 'logoFileId',
						label: 'Logo',
						component: (
							<SingleImageUpload
								resource="applicationLogo"
								record={record}
								recordProperty="logoFile"
							/>
						),
						span: 24,
						rerenderOnChange: true,
					},
					{
						key: 'images',
						label: 'Slike',
						component: <PhotosEdit resource="applicationImage" />,
						span: 24,
						rerenderOnChange: true,
					},
				],
			},
			{
				key: 'legal',
				label: 'Pravne informacije',
				fields: [
					{
						key: 'privacyPolicyUrl',
						label: 'URL politike privatnosti',
						rules: [
							{
								type: 'url',
								message: 'URL politike privatnosti mora biti validan',
							},
						],
						component: <Input />,
						span: 24,
					},
					{
						key: 'termsOfServiceUrl',
						label: 'URL uslova korišćenja',
						rules: [
							{
								type: 'url',
								message: 'URL uslova korišćenja mora biti validan',
							},
						],
						component: <Input />,
						span: 24,
					},
				],
			},
		],
	},
	page: {
		shouldFetch: true,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					shouldLink: false,
				},
			],
		},
	},
});
