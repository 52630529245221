import { Row, Col } from 'antd';

interface Props {
	value?: {
		name: string;
		address: string;
		companyRegistrationNumber: string;
		tin: string;
	};
}

function PartnerView({ value }: Props) {
	return (
		<Row gutter={2} style={{ width: '100%' }}>
			<Col span={24}>{value?.name}</Col>
		</Row>
	);
}

export default PartnerView;
