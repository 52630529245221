export class BankAccount {
	bankId?: string;
	number?: string;
	primary?: boolean;
	invoice?: boolean;

	constructor(data: BankAccount) {
		Object.assign(this, data);
	}

	get formattedNumber(): string {
		// get number in format xxx-xxxxxxxxxxx-xx
		const number = this.number;
		if (!number) {
			return '';
		}

		return number.replace(/^(\d{3})(\d.*)(\d{2})$/, '$1-$2-$3');
	}
}
