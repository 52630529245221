import { DatePicker, Input, Table } from 'antd';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';

import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import CustomCreateButton from './Components/CustomCreateButton';
export default CreatePage({
	module: 'documents',
	submodule: 'kpo',
	model: 'kpo',
	view: {
		useEdit: true,
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje stavke u KPO knjigu',
			existing: (entity) => `Izmena stavke KPO knjige`,
		},
		shouldFetch: false,
		fields: (record, form, setFields) => {
			return [
				{
					key: 'info',
					fields: [
						{
							hidden: true,
							key: 'type',
							initialValue: 'custom',
							component: <Input />,
						},
						{
							hidden: true,
							key: 'currencyId',
							initialValue: 'RSD',
							component: <Input />,
						},
						{
							label: 'Datum',
							key: 'documentDate',
							component: <DatePicker format={'L'} style={{ width: '100%' }} />,
							rules: [
								{
									required: true,
									message: 'Datum dokumenta je obavezan',
								},
							],
							span: 24,
						},
						{
							label: 'Opis',
							key: 'description',
							component: <Input.TextArea rows={4} />,
							rules: [
								{
									required: true,
									message: 'Opis je obavezan',
								},
							],
							span: 24,
						},
						{
							label: 'Prihod od prodaje proizvoda',
							key: 'productAmount',
							component: (
								<Input
									type="number"
									min={0}
									style={{ width: '100%' }}
									suffix="RSD"
								/>
							),
							span: 12,
						},
						{
							label: 'Prihod od izvršenih usluga',
							key: 'serviceAmount',
							component: (
								<Input
									type="number"
									min={0}
									style={{ width: '100%' }}
									suffix="RSD"
								/>
							),
							span: 12,
						},
					],
				},
			];
		},
	},
	page: {
		empty: {
			text: 'Nema stavki u KPO knjizi',
		},
		shouldFetch: true,
		table: {
			props: {
				scroll: { x: 1200 },
				summary: () => (
					<Observer>
						{() => (
							<Table.Summary fixed>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0} colSpan={3} align="right">
										<strong>Ukupno</strong>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={1}>
										{numberFormat(stores.kpos.productAmount, true)}
									</Table.Summary.Cell>
									<Table.Summary.Cell index={2}>
										{numberFormat(stores.kpos.serviceAmount, true)}
									</Table.Summary.Cell>
									<Table.Summary.Cell index={3}>
										{numberFormat(stores.kpos.totalAmount, true)}
									</Table.Summary.Cell>
									<Table.Summary.Cell index={4}></Table.Summary.Cell>
								</Table.Summary.Row>
							</Table.Summary>
						)}
					</Observer>
				),
			},
			columns: () => {
				return [
					{
						title: 'Redni broj',
						key: 'ordinalNumber',
						width: 100,
						render: (text, record, index) => (
							<Observer>
								{() => index + 1 + (stores.kpos.pagination.offset || 0)}
							</Observer>
						),
					},
					{
						title: 'Datum',
						dataIndex: 'documentDate',
						key: 'documentDate',
						width: 120,
						shouldLink: false,
						render: (value) => value.format('L'),
					},
					{
						title: 'Opis',
						dataIndex: 'description',
						key: 'description',
						ellipsis: true,
						render(text?, record?, index?) {
							if (record.type === 'receipts') {
								return `Fiskalni računi za dan ${record.documentDate.format(
									'L'
								)}`;
							}
							if (record.type === 'invoice') {
								return `Faktura ${record.documentNumber} za ${record.partnerName}`;
							}
							if (record.type === 'creditNote') {
								return `Knjižno odobrenje ${record.documentNumber} za ${record.partnerName}`;
							}
							if (record.type === 'debitNote') {
								return `Knjižno zaduženje ${record.documentNumber} za ${record.partnerName}`;
							}
							if (record.type === 'advanceInvoice') {
								return `Avansni račun ${record.documentNumber} za ${record.partnerName}`;
							}

							return text;
						},
					},
					{
						title: 'Prihod od prodaje proizvoda',
						dataIndex: 'productAmount',
						key: 'productAmount',
						ellipsis: true,
						render: (value) => numberFormat(value, true),
					},
					{
						title: 'Prihod od izvršenih usluga',
						dataIndex: 'serviceAmount',
						key: 'serviceAmount',
						ellipsis: true,
						render: (value) => numberFormat(value, true),
					},
					{
						title: 'Svega prihodi od delatnosti',
						dataIndex: 'amount',
						key: 'amount',
						ellipsis: true,
						render: (value) => numberFormat(value, true),
					},
				];
			},
		},
		createButtonText: 'Dodaj stavku',
		additionalQueryParams: {
			date: moment().format('YYYY-MM-DD'),
		},
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovu stavku?',
		customCreateButton: (createHandler) => (
			<CustomCreateButton createButtonHandler={createHandler} />
		),
	},
});
