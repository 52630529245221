import {
	CheckOutlined,
	DeleteOutlined,
	EditOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { useLatest } from 'ahooks';
import {
	Button,
	notification,
	ConfigProvider,
	Empty,
	Tag,
	Space,
	Alert,
	Tooltip,
	Typography,
	Row,
	Col,
	Grid,
	List,
	Skeleton,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import numeral from 'numeral';
import React, {
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';
import ShowMoreText from 'react-show-more-text';
import SplitPane, { Pane } from 'react-split-pane';
import { useKey, useMeasure, useWindowSize, useAudio } from 'react-use';

import { useDrawer } from '../../../components/Page';
import PaymentModal from '../../../components/PaymentModal/PaymentModal';
import { APPLICATION_NAME } from '../../../constants/application';
import { DrawerContext } from '../../../context/DrawerContext';
import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner';
import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';
import CategoryPicker from './Components/CategoryPicker';
import EnterQuantityModal from './Components/EnterQuantityModal';
import VariantPicker from './Components/VariantPicker';
import EditModal from './EditModal';
import styles from './POS.module.less';
import ProductNotFound from './ProductNotFound';
import SkuEntryModal from './SkuEntryModal';

const SOUNDS = {
	ERROR: '/sounds/error.mp3',
	BEEP: '/sounds/beep.mp3',
	DELETE: '/sounds/trash.mp3',
};

type Props = {
	height: number;
	sale: any;
	active: boolean;
	loading: boolean;
};

notification.config({
	placement: 'bottomRight',
});

function Sale({ height, sale, loading, active }: Props) {
	const [warningRef, { height: warningHeight }] = useMeasure();
	const [totalRef, { width: totalWidth }] = useMeasure();
	const { width } = useWindowSize();
	const {
		products: { byEan },
		application: {
			posSettings: { paneWidth },
			setPaneWidth,
		},
		localSales: { removeSale },
		exchangeRates: { lastDate },
		devices: { scales },
		sdc: { currentESDCError },
		stores: { currentStore },
	} = stores;

	const [audioBeep, , { play: playBeepOriginal, seek: seekBeep }] = useAudio({
		src: SOUNDS.BEEP,
		autoPlay: false,
	});
	const [audioError, , { play: playErrorOriginal, seek: seekError }] = useAudio(
		{
			src: SOUNDS.ERROR,
			autoPlay: false,
		}
	);
	const [audioDelete, , { play: playDeleteOriginal, seek: seekDelete }] =
		useAudio({
			src: SOUNDS.DELETE,
			autoPlay: false,
		});

	const playBeep = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekBeep(0);
		playBeepOriginal();
	}, [playBeepOriginal, currentStore]);

	const playError = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekError(0);
		playErrorOriginal();
	}, [playErrorOriginal, currentStore]);

	const playDelete = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekDelete(0);
		playDeleteOriginal();
	}, [playDeleteOriginal, currentStore]);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editSaleItem, setEditSaleItem] = useState(null);

	const [variantPickerVisible, setVariantPickerVisible] = useState(false);
	const [variantPickerProduct, setVariantPickerProduct] = useState(null);

	const [notFoundModalVisible, setNotFoundModalVisible] = useState(false);
	const [notFoundModalText, setNotFoundModalText] = useState(null);

	const [enterQuantityModalVisible, setEnterQuantityModalVisible] =
		useState(false);
	const [enterQuantitySaleItem, setEnterQuantitySaleItem] = useState(null);

	const [skuPopoverVisible, setSkuPopoverVisible] = useState(false);
	const [skuPopoverValue, setSkuPopoverValue] = useState('');

	const [paymentModalVisible, setPaymentModalVisible] = useState(false);
	const [prefillPayment, setPrefillPayment] = useState([0, 0, 0, 0, 0, 0, 0]);

	const suppressBarcode = useRef(false);

	const addByEan = useCallback(
		(ean, quantity = 1) => {
			const product = byEan[ean];
			if (!product) {
				setNotFoundModalText(
					<>
						Proizvod sa bar kodom <strong>{ean}</strong> nije pronađen. <br />
						Pokušajte drugi metod unosa
					</>
				);
				playError();
				return setNotFoundModalVisible(true);
			}
			sale.addItem(product, quantity);
			playBeep();
		},
		[sale, byEan]
	);

	const getScaleWeight = useCallback(
		async (product) => {
			if (scales.length > 0 && product.quantityFromScale) {
				try {
					return window.electron.scale.getWeight();
				} catch (e) {
					return null;
				}
			}

			return 1;
		},
		[scales]
	);

	const selectProduct = useCallback(
		async (product) => {
			if (product.variants.length) {
				setVariantPickerProduct(product);
				setVariantPickerVisible(true);
			} else {
				sale.addItem(product, await getScaleWeight(product));
				playBeep();
			}
		},
		[sale]
	);

	const [, openSearchDrawer, , isSearchVisible, , ProductSearchDrawer] =
		useDrawer('product-search', async (event, product: any) => {
			if (active) {
				const p = product.variant ? product.variant : product.product;
				if (scales.length > 0 && p.quantityFromScale) {
					const weight = await getScaleWeight(p);
					sale.addItem(p, weight);
					playBeep();
				} else {
					setEnterQuantitySaleItem(p);
					setEnterQuantityModalVisible(true);
				}
			}
		});
	const { isDrawerOpen } = useContext(DrawerContext);

	const shouldReactToKeyWithoutSkuEntry = useMemo(
		() =>
			active &&
			!isDrawerOpen &&
			!variantPickerVisible &&
			!notFoundModalVisible &&
			!enterQuantityModalVisible &&
			!editModalVisible &&
			!paymentModalVisible &&
			!isSearchVisible &&
			!currentESDCError,
		[
			active,
			isDrawerOpen,
			variantPickerVisible,
			notFoundModalVisible,
			enterQuantityModalVisible,
			editModalVisible,
			isSearchVisible,
			paymentModalVisible,
			currentESDCError,
		]
	);
	const shouldReactToKey = useMemo(
		() => shouldReactToKeyWithoutSkuEntry && !skuPopoverVisible,
		[shouldReactToKeyWithoutSkuEntry, skuPopoverVisible]
	);

	const latestShouldReactToKey = useLatest(shouldReactToKey);

	useBarcodeScanner((ean) => {
		if (latestShouldReactToKey.current && !suppressBarcode.current) {
			addByEan(ean);
		}
	});

	const characters = useRef('');
	const charactersTimeout = useRef(null);

	useKey(
		(e) => {
			if (
				e.key === '.' ||
				e.key === ',' ||
				e.key === '*' ||
				e.key === 'x' ||
				isFinite(parseInt(e.key, 10))
			) {
				return true;
			}

			return false;
		},
		(e) => {
			if (!shouldReactToKeyWithoutSkuEntry) {
				return;
			}
			characters.current += e.key;
			setSkuPopoverValue(characters.current);

			if (!shouldReactToKey) {
				return;
			}

			clearTimeout(charactersTimeout.current);

			charactersTimeout.current = setTimeout(() => {
				if (characters.current.length >= 8) {
					setSkuPopoverVisible(false);
					setSkuPopoverValue('');
					addByEan(characters.current);
					characters.current = '';
				} else {
					setSkuPopoverVisible(true);
				}
			}, 100);
		},
		{
			event: 'keydown',
		},
		[shouldReactToKey, shouldReactToKeyWithoutSkuEntry, addByEan]
	);

	useKey(
		'ArrowUp',
		() => {
			if (!shouldReactToKey) {
				return;
			}
			sale.selectPreviousItem();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		'ArrowDown',
		() => {
			if (!shouldReactToKey) {
				return;
			}
			sale.selectNextItem();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		(e) => {
			return e.key === 'f3' || ((e.ctrlKey || e.metaKey) && e.key === 'f');
		},
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			openSearchDrawer();
			e.preventDefault();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		'Backspace',
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			sale.removeItem(sale.activeProductId);
			playDelete();
			e.preventDefault();
			return false;
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);

	useKey(
		'Delete',
		() => {
			if (!shouldReactToKey) {
				return;
			}
			playDelete();
			sale.removeItem(sale.activeProductId);
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);

	useKey(
		'+',
		() => {
			if (!shouldReactToKey || characters.current.length > 0) {
				return;
			}
			setEditSaleItem(sale.activeSaleItem);
			setEditModalVisible(true);
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);
	useKey(
		'F10',
		(event) => {
			if (!shouldReactToKey) {
				return;
			}

			setPrefillPayment([sale.total, 0, 0, 0, 0, 0, 0]);
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F16',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment([sale.total, 0, 0, 0, 0, 0, 0]);
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F11',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment([0, sale.total, 0, 0, 0, 0, 0]);
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F17',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment([0, sale.total, 0, 0, 0, 0, 0]);
			setPaymentModalVisible(true);

			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F12',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment([0, 0, sale.total, 0, 0, 0, 0]);
			setPaymentModalVisible(true);
			event.preventDefault();
			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F18',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment([0, 0, sale.total, 0, 0, 0, 0]);
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);

	const screens = Grid.useBreakpoint();

	const titleBarHeight =
		window.electron &&
		(!window.electron.platform || window.electron.platform === 'darwin') &&
		(screens.sm || screens.xs) &&
		!screens.lg
			? 28
			: 0;

	return (
		<div
			style={{
				height:
					height -
					95 +
					(!screens.md ? 38 : 0) -
					(!screens.sm ? 47 : 0) -
					titleBarHeight,
			}}
		>
			{audioBeep}
			{audioDelete}
			{audioError}
			<div className={styles.wrap}>
				<Row className={styles.subTotal} gutter={8} ref={totalRef}>
					{!screens.xs && (
						<Col flex="none" className={styles.subtotalText}>
							Iznos
							<br />
							računa
						</Col>
					)}
					<Col flex="none">
						<h2>
							<strong>{numeral(sale.total).format('0,0.00')}</strong>
						</h2>
					</Col>
					<Col flex="auto" className={styles.buttons}>
						<Button
							onClick={() => {
								// createInvoice();
								setPaymentModalVisible(true);
							}}
							icon={<CheckOutlined />}
							type="primary"
							disabled={sale.itemsAsArray.length === 0}
						>
							Plaćanje
						</Button>
					</Col>
				</Row>
			</div>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/bar-code.png" alt="Bar Kod" />}
						description="Nema proizvoda. Koristite bar kod čitač, pretragu ili unesite šifru."
					/>
				)}
			>
				{screens.sm && (
					<SplitPane
						split="vertical"
						defaultSize={paneWidth < 230 ? 230 : paneWidth}
						minSize={230}
						style={{ height: 'auto' }}
						onChange={setPaneWidth}
					>
						<Pane>
							<CategoryPicker
								openSearchDrawer={openSearchDrawer}
								height={height - 139 + (!screens.md ? 39 : 0) - titleBarHeight}
								width={paneWidth < 230 ? 230 : paneWidth}
								selectProduct={selectProduct}
							/>
						</Pane>
						<div
							style={{
								width: totalWidth + 40 - (paneWidth < 230 ? 230 : paneWidth),
								height: height - 139 + (!screens.md ? 38 : 0) - titleBarHeight,
							}}
						>
							{sale.hasForeignCurrency &&
								!lastDate.isSame(moment().startOf('day')) && (
									<div ref={warningRef}>
										<Alert
											className={styles.rateWarning}
											type="warning"
											message={
												<ShowMoreText
													lines={1}
													more="Više"
													less="Manje"
													expanded={false}
													width={
														totalWidth +
														18 -
														(paneWidth < 230 ? 230 : paneWidth)
													}
												>
													Postoje artikli čija je cena u stranoj valuti, ali
													kursna lista za današnji dan nije dostupna. Narodna
													Banka Srbije kursnu listu objavljuje radnim danima u
													8:00, a {APPLICATION_NAME} kursnu listu preuzima
													najkasnije 5 minuta od njenog objavljivanja.
													Primenjena je kursna lista dana{' '}
													{lastDate.format('LL')}
												</ShowMoreText>
											}
										/>
									</div>
								)}
							<div
								className={styles.scrollableList}
								style={{
									height:
										height -
										136 +
										(!screens.md ? 36 : 0) -
										(sale.hasForeignCurrency ? warningHeight || 0 : 0) -
										titleBarHeight,
								}}
							>
								<List
									className={styles.list}
									size="small"
									dataSource={loading ? [] : [...sale.itemsAsArray]}
									rowKey="id"
									renderItem={(item) => (
										<List.Item
											key={item.key}
											actions={[
												<Space>
													<Button
														size="small"
														type="link"
														icon={<EditOutlined />}
														onClick={() => {
															(document.activeElement as HTMLElement).blur();

															setEditSaleItem(item);
															setEditModalVisible(true);
														}}
													></Button>
													<Button
														danger
														type="link"
														size="small"
														icon={<DeleteOutlined />}
														onClick={() => {
															sale.removeItem(item.key);
															playDelete();
														}}
													></Button>
												</Space>,
											]}
										>
											{item.variant?.isResolving ||
											item.product?.isResolving ? (
												<Skeleton />
											) : (
												<>
													{item.key === sale.activeProductId && (
														<div className={styles.selectedProduct}></div>
													)}
													<List.Item.Meta
														avatar={
															item.product?.coverImage ? (
																<>
																	<img
																		src={item.product.coverImage.urls['64x64']}
																		alt="Slika"
																		className={styles.listImage}
																	/>
																</>
															) : (
																<div className={styles.listImage} />
															)
														}
														title={
															<>
																{item.product?.name}&nbsp;&nbsp;
																{item.variant ? (
																	<Tag color="magenta">
																		{item.variant.variantName}
																	</Tag>
																) : (
																	''
																)}
															</>
														}
														description={
															<div className={styles.listDescription}>
																<Row gutter={8}>
																	<Col span={8}>
																		#
																		{item.variant
																			? item.variant.sku
																			: item.product.sku}
																	</Col>
																	<Col span={screens.lg ? 8 : 16}>
																		{item.discount ? (
																			<>
																				{item.finalPrice === 0 && (
																					<>
																						<Tooltip title="Artikal nema unetu cenu">
																							<Typography.Text type="danger">
																								<WarningOutlined />
																							</Typography.Text>
																						</Tooltip>
																						&nbsp;
																					</>
																				)}
																				<span className={styles.strikethrough}>
																					{numberFormat(
																						item.quantity,
																						false,
																						3,
																						false
																					)}
																					&nbsp;x&nbsp;
																					{`${numeral(
																						item.priceWithoutDiscount
																					).format('0,0.00')}`}
																					&nbsp;RSD
																				</span>
																				<br />
																				{numberFormat(
																					item.quantity,
																					false,
																					3,
																					false
																				)}
																				&nbsp;x&nbsp;
																				{`${numeral(item.finalPrice).format(
																					'0,0.00'
																				)}`}
																				&nbsp;RSD&nbsp;(-
																				{item.discount}%)
																			</>
																		) : (
																			<>
																				{item.finalPrice === 0 && (
																					<>
																						<Tooltip title="Artikal nema unetu cenu">
																							<Typography.Text type="danger">
																								<WarningOutlined />
																							</Typography.Text>
																						</Tooltip>
																						&nbsp;
																					</>
																				)}
																				{numberFormat(
																					item.quantity,
																					false,
																					3,
																					false
																				)}
																				&nbsp;x&nbsp;
																				{`${numeral(item.finalPrice).format(
																					'0,0.00'
																				)} RSD`}
																			</>
																		)}
																	</Col>
																	{screens.lg && (
																		<Col span={8}>
																			{item.discount ? (
																				<>
																					<span
																						className={styles.strikethrough}
																					>
																						{`${numeral(
																							item.priceWithoutDiscount *
																								item.quantity
																						).format('0,0.00')}`}
																						&nbsp;RSD
																					</span>
																					<br />
																					{`${numeral(
																						item.finalPrice * item.quantity
																					).format('0,0.00')}`}
																					&nbsp;RSD&nbsp;(-{item.discount}%)
																				</>
																			) : (
																				`${numeral(
																					item.finalPrice * item.quantity
																				).format('0,0.00')} RSD`
																			)}
																		</Col>
																	)}
																</Row>
															</div>
														}
													/>
												</>
											)}
										</List.Item>
									)}
								/>
							</div>
						</div>
					</SplitPane>
				)}
				{!screens.sm && (
					<div
						className={styles.horizontalWrapper}
						style={{
							height: height - 140 - titleBarHeight,
						}}
					>
						<SplitPane
							split="horizontal"
							defaultSize={paneWidth}
							minSize={260}
							onChange={setPaneWidth}
							primary="second"
						>
							<div style={{ width }}>
								{sale.hasForeignCurrency &&
									!lastDate.isSame(moment().startOf('day')) && (
										<div ref={warningRef}>
											<Alert
												className={styles.rateWarning}
												type="warning"
												message={
													<ShowMoreText
														lines={1}
														more="Više"
														less="Manje"
														expanded={false}
														width={totalWidth + 18}
													>
														Postoje artikli čija je cena u stranoj valuti, ali
														kursna lista za današnji dan nije dostupna. Narodna
														Banka Srbije kursnu listu objavljuje radnim danima u
														8:00, a {APPLICATION_NAME} kursnu listu preuzima
														najkasnije 5 minuta od njenog objavljivanja.
														Primenjena je kursna lista dana{' '}
														{lastDate.format('LL')}
													</ShowMoreText>
												}
											/>
										</div>
									)}
								<div
									className={styles.scrollableList}
									style={{
										height:
											height -
											140 -
											paneWidth -
											(warningHeight || 0) -
											titleBarHeight,
									}}
								>
									<List
										className={styles.list}
										size="small"
										dataSource={loading ? [] : [...sale.itemsAsArray]}
										rowKey="id"
										renderItem={(item) => (
											<List.Item
												key={item.key}
												actions={[
													<Space>
														<Button
															size="small"
															type="link"
															icon={<EditOutlined />}
															onClick={() => {
																(document.activeElement as HTMLElement).blur();

																setEditSaleItem(item);
																setEditModalVisible(true);
															}}
														></Button>
														<Button
															danger
															type="link"
															size="small"
															icon={<DeleteOutlined />}
															onClick={() => {
																sale.removeItem(item.key);
																playDelete();
															}}
														></Button>
													</Space>,
												]}
											>
												<List.Item.Meta
													avatar={
														item.product.coverImage ? (
															<>
																<img
																	src={item.product.coverImage.urls['64x64']}
																	alt="Slika"
																	className={styles.listImage}
																/>
															</>
														) : (
															<div className={styles.listImage} />
														)
													}
													title={
														<>
															{item.product.name}&nbsp;&nbsp;
															{item.variant ? (
																<Tag color="magenta">
																	{item.variant.variantName}
																</Tag>
															) : (
																''
															)}
														</>
													}
													description={
														<div className={styles.listDescription}>
															<Row gutter={8}>
																<Col span={8}>
																	#
																	{item.variant
																		? item.variant.sku
																		: item.product.sku}
																</Col>
																<Col span={16}>
																	{item.discount ? (
																		<>
																			{item.finalPrice === 0 && (
																				<>
																					<Tooltip title="Artikal nema unetu cenu">
																						<Typography.Text type="danger">
																							<WarningOutlined />
																						</Typography.Text>
																					</Tooltip>
																					&nbsp;
																				</>
																			)}
																			<span className={styles.strikethrough}>
																				{numberFormat(
																					item.quantity,
																					false,
																					3,
																					false
																				)}
																				&nbsp;x&nbsp;
																				{`${numeral(
																					item.priceWithoutDiscount
																				).format('0,0.00')}`}
																				&nbsp;RSD
																			</span>{' '}
																			{numberFormat(
																				item.quantity,
																				false,
																				3,
																				false
																			)}
																			&nbsp;x&nbsp;
																			{`${numeral(item.finalPrice).format(
																				'0,0.00'
																			)}`}
																			&nbsp;RSD&nbsp;(-
																			{item.discount}%)
																		</>
																	) : (
																		<>
																			{item.finalPrice === 0 && (
																				<>
																					<Tooltip title="Artikal nema unetu cenu">
																						<Typography.Text type="danger">
																							<WarningOutlined />
																						</Typography.Text>
																					</Tooltip>
																					&nbsp;
																				</>
																			)}
																			{numberFormat(
																				item.quantity,
																				false,
																				3,
																				false
																			)}
																			&nbsp;x&nbsp;
																			{`${numeral(item.finalPrice).format(
																				'0,0.00'
																			)} RSD`}
																		</>
																	)}
																</Col>
															</Row>
														</div>
													}
												/>
											</List.Item>
										)}
									/>
								</div>
							</div>
							<Pane>
								<CategoryPicker
									openSearchDrawer={openSearchDrawer}
									height={paneWidth}
									width={width}
									selectProduct={selectProduct}
								/>
							</Pane>
						</SplitPane>
					</div>
				)}
			</ConfigProvider>
			{paymentModalVisible && (
				<PaymentModal
					visible={paymentModalVisible}
					amount={sale.total}
					items={sale.itemsAsArray}
					prefillPayment={prefillPayment as [any, any, any, any, any, any, any]}
					hasForeignCurrency={sale.hasForeignCurrency}
					setDate={sale.setDate}
					setTaxFree={sale.setTaxFree}
					closePayment={(remove = false) => {
						setPaymentModalVisible(false);
						sale.setDate(null);
						sale.setTaxFree(false);
						if (remove) {
							removeSale(sale);
						}
					}}
				/>
			)}
			<ProductNotFound
				visible={notFoundModalVisible}
				text={notFoundModalText}
				onCancel={() => {
					setNotFoundModalVisible(false);
				}}
				onOk={() => {
					setNotFoundModalVisible(false);
				}}
			/>
			<SkuEntryModal
				visible={skuPopoverVisible}
				onCancel={() => {
					suppressBarcode.current = true;
					setSkuPopoverVisible(false);
					setTimeout(() => {
						suppressBarcode.current = false;
					});
					characters.current = '';
				}}
				value={skuPopoverValue}
				onConfirm={function (sku: number, multiplier: number): void {
					sale.addItem(sku, multiplier);
					playBeep();
					setTimeout(() => {
						suppressBarcode.current = false;
					});

					characters.current = '';
					setSkuPopoverVisible(false);
				}}
				addByEan={addByEan}
			></SkuEntryModal>
			<EditModal
				visible={editModalVisible}
				saleItem={editSaleItem}
				sale={sale}
				closeEdit={() => {
					setEditModalVisible(false);

					setEditSaleItem(null);
				}}
			></EditModal>
			<EnterQuantityModal
				item={enterQuantitySaleItem}
				visible={enterQuantityModalVisible}
				onClose={() => {
					setEnterQuantityModalVisible(false);
				}}
				onSubmit={(item, quantity: number) => {
					sale.addItem(item, quantity);
					playBeep();
				}}
			/>
			<VariantPicker
				visible={variantPickerVisible}
				product={variantPickerProduct}
				onCancel={() => {
					setVariantPickerVisible(false);
				}}
				onSelect={async (variant) => {
					setVariantPickerVisible(false);
					sale.addItem(variant, await getScaleWeight(variant));
					playBeep();
				}}
			/>
			<ProductSearchDrawer />
		</div>
	);
}

export default observer(Sale);
