export const DEVICE_TYPE = {
	BARCODE_SCANNER: 'barcode_scanner',
	THERMAL_PRINTER: 'thermal_printer',
	// PRINTER: 'printer',
	// SCANNER: 'scanner',
	// RFID_READER: 'rfid_reader',
	// NFC_READER: 'nfc_reader',
	SCALE: 'scale',
	POS_TERMINAL: 'pos_terminal',
};
export const DEVICE_TYPE_NAME = {
	barcode_scanner: 'Bar kod skener',
	thermal_printer: 'Termalni štampač',
	printer: 'Štampač',
	scanner: 'Skener',
	scale: 'Vaga',
	rfid_reader: 'RFID čitač',
	nfc_reader: 'NFC čitač',
	pos_terminal: 'POS terminal',
};

export const DEVICE_CONNECTION_TYPE = {
	USB: 'usb',
	BLUETOOTH: 'bluetooth',
	NETWORK: 'network',
	SERIAL: 'serial',
	HID: 'hid',
	KEYBOARD_EMULATION: 'keyboard_emulation',
	PRINTER: 'printer',
};
export const DEVICE_CONNECTION_TYPE_NAME = {
	usb: 'USB',
	serial: 'Serijski',
	bluetooth: 'Bluetooth',
	network: 'Mreža',
	// serial: 'USB/Serijski',
	hid: 'HID',
	keyboard_emulation: 'Emulacija tastature',
	printer: 'Štampač',
};

export const HID_DEVICE_USAGE = {
	barcode_scanner: {
		usagePage: 0x8c,
	},
	rfid_reader: {
		usagePage: 0x20,
		usage: 0x92,
	},
	nfc_reader: {
		usagePage: 0x20,
		usage: 0x93,
	},
};

export const DEVICE_TYPE_CONNECTION_TYPE = {
	thermal_printer: [
		// DEVICE_CONNECTION_TYPE.BLUETOOTH,
		DEVICE_CONNECTION_TYPE.SERIAL,
		DEVICE_CONNECTION_TYPE.NETWORK,
		DEVICE_CONNECTION_TYPE.PRINTER,
	],
	pos_terminal: [
		DEVICE_CONNECTION_TYPE.NETWORK,
		//  DEVICE_CONNECTION_TYPE.SERIAL
	],
	barcode_scanner: [
		// DEVICE_CONNECTION_TYPE.BLUETOOTH,
		DEVICE_CONNECTION_TYPE.SERIAL,
		// DEVICE_CONNECTION_TYPE.USB,
		// DEVICE_CONNECTION_TYPE.HID,
		// DEVICE_CONNECTION_TYPE.KEYBOARD_EMULATION,
	],
	scale: [DEVICE_CONNECTION_TYPE.SERIAL],
};
