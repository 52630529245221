import { Badge } from 'antd';
import moment from 'moment';

import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';

export default CreatePage({
	module: 'settings',
	submodule: 'taxRates',
	model: 'taxRate',
	page: {
		shouldFetch: false,
		table: {
			showActions: false,
			props: {
				scroll: { x: 520 },
			},
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Oznaka',
					dataIndex: 'label',
					key: 'label',
					width: 70,
				},
				{
					title: 'Stopa',
					dataIndex: 'rate',
					key: 'rate',
					width: 70,
					render: (text) => `${numberFormat(text, false)}%`,
				},
				{
					title: 'Važi od',
					dataIndex: 'validFrom',
					key: 'falidFrom',
					width: 150,
					render: (text) => moment(text).format('LL'),
					sorter: (a, b) => a.validFrom?.valueOf() - b.validFrom?.valueOf(),
					defaultSortOrder: 'descend',
				},
				{
					title: 'Aktivna',
					dataIndex: 'isActive',
					key: 'isActive',
					width: 120,
					filters: [
						{
							text: <Badge color="green" text="Aktivna" />,
							value: '1',
						},
						{
							text: <Badge color="red" text="Neaktivna" />,
							value: '0',
						},
					],
					defaultFilteredValue: ['1'],
					filterMultiple: true,
					onFilter: (value, record) =>
						(record.isActive && value === '1') ||
						(!record.isActive && value === '0'),
					render: (text) =>
						text ? (
							<Badge color="green" text="Aktivna" />
						) : (
							<Badge color="red" text="Neaktivna" />
						),
				},
			],
		},
	},
});
