import {
	DeleteOutlined,
	FileImageOutlined,
	FileOutlined,
	FilePdfOutlined,
	FileTextOutlined,
	SearchOutlined,
	SendOutlined,
} from '@ant-design/icons';
import {
	Badge,
	Button,
	Checkbox,
	DatePicker,
	Dropdown,
	Form,
	Grid,
	Input,
	Menu,
	Modal,
	notification,
	Select,
	Space,
	Tooltip,
} from 'antd';
import { sortBy } from 'lodash';
import round from 'lodash/round';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { Fragment } from 'react';

import AttachmentsEdit from '../../../../components/AttachmentsEdit';
import CurrencySelect from '../../../../components/CurrencySelect';
import InvoiceItemsView from '../../../../components/InvoiceCommon/InvoiceItemsView';
import PartnerView from '../../../../components/InvoiceCommon/PartnerView';
import InvoiceSummaryView from '../../../../components/InvoiceSummaryView';
import { CreatePage, useDrawer } from '../../../../components/Page';
import pageStyles from '../../../../components/Page/Page.module.less';
import PaymentMeansSelect from '../../../../components/PaymentMeansSelect';
import PartnerSelect from '../../../../components/Selects/PartnerSelect';
import config from '../../../../config.json';
import {
	DOCUMENT_CREDIT_DEBIT_NOTE_TYPE_MAP,
	DOCUMENT_TAX_INVOICE_PERIOD,
	DOCUMENT_TAX_INVOICE_PERIOD_MAP,
	DOCUMENT_TYPE,
	DOCUMENT_TYPE_MAP,
	EFAKTURA_ERROR_CODE,
	INVOICE_STATUS,
	INVOICE_STATUS_MAP,
} from '../../../../constants/documentInvoice';
import {
	ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR,
	ERROR_BAD_REQUEST_PARTNER_MISSING_CODE,
	ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL,
} from '../../../../constants/errors';
import { usePermissions } from '../../../../hooks/permissions';
import numberFormat from '../../../../lib/numberFormat';
import { v2Client } from '../../../../store/client';
import stores from '../../../../stores/index.mobx';
import {
	AdvancePayment,
	Invoice,
	TaxBreakdown,
} from '../../../../stores/Invoice.mobx';
import { Partner } from '../../../../stores/Partner.mobx';
import AdvanceInvoicesInput from './Components/AdvanceInvoicesInput';
import CancelModal from './Components/CancelModal';
import CreditDebitNoteReferencesInput from './Components/CreditDebitNoteReferencesInput';
import InvoiceItemsInput from './Components/InvoiceItemsInput';
import PartnerLocationSelect from './Components/PartnerLocationSelect';
import PayButton from './Components/PayButton';
import ReferenceInput from './Components/ReferenceInput';
import ReferenceView from './Components/ReferenceView';
import { TaxExemptionInput } from './Components/TaxExemptionInput';

interface Props {
	record: Invoice;
	close: () => void;
}

const InvoiceButtons = observer(({ record, close }: Props) => {
	const [emailModalVisible, setEmailModalVisible] = useState(false);
	const [emailModalMode, setEmailModalMode] = useState(null);
	const [cancelModalVisible, setCancelModalVisible] = useState(false);
	const [cancelModalData, setCancelModalData] = useState(null);
	const [overrideSending, setOverrideSending] = useState(false);
	const [form] = Form.useForm();

	const emailModal = (
		<Modal
			title="Unesite e-mail adresu"
			open={emailModalVisible}
			confirmLoading={record?.isSending}
			onOk={form.submit}
			onCancel={() => {
				setEmailModalVisible(false);
			}}
			destroyOnClose
		>
			<Form
				preserve={false}
				form={form}
				layout="vertical"
				onFinish={async (values) => {
					if (emailModalMode === 'send') {
						await record.send(values.email, values.storeEmail);
						notification.success({
							message: 'Dokument je uspešno poslat',
						});
					} else if (emailModalMode === 'cancel') {
						await record.cancel({
							...cancelModalData,
							email: values.email,
							storeEmail: values.storeEmail,
						});
						notification.success({
							message: 'Dokument je uspešno storniran',
						});
					}
					setEmailModalVisible(false);

					close();
				}}
			>
				<p>
					Za kupca <strong>{record?.partner?.name}</strong>, ne postoji sačuvana
					e-mail adresa. Molimo vas da unesete e-mail adresu na koju želite da
					pošaljete dokument.
				</p>
				<Form.Item
					name="email"
					label="E-Mail adresa"
					rules={[
						{
							required: true,
							message: 'E-Mail adresa je obavezna',
						},
						{
							type: 'email',
							message: 'E-Mail adresa nije validna',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					initialValue={true}
					valuePropName="checked"
					name="storeEmail"
				>
					<Checkbox>Sačuvaj e-mail adresu za kupca</Checkbox>
				</Form.Item>
			</Form>
		</Modal>
	);

	const send = useCallback(async () => {
		try {
			setOverrideSending(true);

			await record.send();
			notification.success({
				message: 'Dokument je uspešno poslat',
			});
			close();
		} catch (e) {
			if (
				e?.response?.data?.errorCode === ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
			) {
				setEmailModalMode('send');
				setEmailModalVisible(true);
			}
			if (
				e?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
			) {
				notification.error({
					message: 'Greška prilikom slanja e-fakture',
					description:
						EFAKTURA_ERROR_CODE[e?.response?.data?.variables?.ErrorCode] ||
						e?.response?.data?.variables?.Message ||
						'Nepoznata greška',
					duration: 0,
				});
			}
		} finally {
			setOverrideSending(false);
		}
	}, [close]);

	const canEditPermission = usePermissions(
		'documents',
		'outgoing-invoices',
		'edit'
	);

	const screens = Grid.useBreakpoint();

	const menuItems = useMemo(() => {
		if (!record) {
			return [];
		}
		const items = [];

		if (record.file) {
			items.push({
				type: 'item',
				label: 'Preuzmi PDF',
				key: 'pdf',
				icon: <FilePdfOutlined />,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.file.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			});
		}

		if (record.xmlFile) {
			items.push({
				type: 'item',
				label: 'Preuzmi XML',
				key: 'xml',
				icon: <FileTextOutlined />,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.xmlFile.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			});
		}

		if ((record.attachments?.length || 0) > 0) {
			items.push({
				label: 'Prilozi',
				key: 'attachments',
				type: 'divider',
				onClick: null,
				disabled: null,
				icon: null,
			});

			record.attachments.forEach((attachment) => {
				let attachmentIcon = <FileOutlined />;
				if (attachment.mimetype === 'application/xml') {
					attachmentIcon = <FileTextOutlined />;
				} else if (attachment.mimetype === 'application/pdf') {
					attachmentIcon = <FilePdfOutlined />;
				} else if (attachment.mimetype.startsWith('image/')) {
					attachmentIcon = <FileImageOutlined />;
				}

				items.push({
					type: 'item',
					label: `Preuzmi prilog ${
						attachment.originalFilename || attachment.filename
					}`,
					key: attachment.id,
					icon: attachmentIcon,
					disabled: false,
					onClick: () => {
						window.open(
							`${config.apiV2}/files/${attachment.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
						);
					},
				});
			});
		}

		if (record.status === INVOICE_STATUS.DRAFT) {
			if (items.length > 0) {
				items.push({
					type: 'divider',
					label: null,
					key: 'divider',
					icon: null,
					disabled: null,
					showAsButton: true,
					onClick: null,
				});
			}
			items.push({
				type: 'item',
				label: `Pošalji`,
				key: 'send',
				icon: <SendOutlined />,
				disabled: overrideSending,
				showAsButton: true,
				onClick: async () => {
					if (
						record?.status === INVOICE_STATUS.SENT ||
						record?.status === INVOICE_STATUS.SENDING
					) {
						return Modal.confirm({
							title: 'Dokument je već poslat',
							content: 'Da li ste sigurni da želite da ga pošaljete ponovo?',
							onOk: async () => {
								await send();
							},
						});
					}
					await send();
				},
			});
		}
		if (
			[
				INVOICE_STATUS.APPROVED,
				INVOICE_STATUS.REJECTED,
				INVOICE_STATUS.SENT,
			].includes(record.status)
		) {
			if (items.length > 0) {
				items.push({
					type: 'divider',
					label: null,
					key: 'divider',
					icon: null,
					disabled: null,
					showAsButton: true,
					onClick: null,
				});
			}
			items.push({
				type: 'item',
				label: `Storniraj`,
				danger: true,
				key: 'cancel',
				icon: <DeleteOutlined />,
				disabled: overrideSending,
				showAsButton: true,
				onClick: () => {
					setCancelModalVisible(true);
				},
			});
		}
		return items;
	}, [record, overrideSending, send]);

	const menu = useMemo(() => {
		if (menuItems.length === 0) {
			return null;
		}

		const [firstMenuItem, ...otherMenuItems] = menuItems;

		if (otherMenuItems.length > 0 && otherMenuItems[0].type === 'divider') {
			otherMenuItems.shift();
		}

		const subMenuItems =
			screens.xs && !screens.sm
				? otherMenuItems
				: otherMenuItems.filter((item) => !item.showAsButton);

		const buttons = screens.sm
			? otherMenuItems.filter(
					(item) => item.showAsButton && item.type !== 'divider'
			  )
			: [];

		return (
			<>
				<Space className={pageStyles.leftButton}>
					{subMenuItems.filter((item) => item.type !== 'divider').length ===
					0 ? (
						<Button onClick={firstMenuItem.onClick} icon={firstMenuItem.icon}>
							{firstMenuItem.label}
						</Button>
					) : (
						<Dropdown.Button
							overlay={
								<Menu
									onClick={({ key }) => {
										otherMenuItems.find((item) => item.key === key)?.onClick();
									}}
									items={subMenuItems}
								/>
							}
							onClick={firstMenuItem.onClick}
						>
							{firstMenuItem.icon} {firstMenuItem.label}
						</Dropdown.Button>
					)}
					{buttons.map((item) => {
						return (
							<Button
								key={item.key}
								danger={item.danger}
								icon={item.icon}
								onClick={item.onClick}
								disabled={item.disabled}
							>
								{item.label}
							</Button>
						);
					})}
				</Space>
				{emailModal}
				<CancelModal
					visible={cancelModalVisible}
					close={() => {
						setCancelModalVisible(false);
					}}
					onOk={async (values) => {
						try {
							await record.cancel(values);
							notification.success({
								message: 'Dokument je uspešno storniran',
							});
						} catch (e) {
							if (
								e?.response?.data?.errorCode ===
								ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
							) {
								setEmailModalMode('cancel');
								setCancelModalData(values);

								setEmailModalVisible(true);
							}
							if (
								e?.response?.data?.errorCode ===
								ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
							) {
								notification.error({
									message: 'Greška prilikom storniranja e-fakture',
									description:
										EFAKTURA_ERROR_CODE[
											e?.response?.data?.variables?.ErrorCode
										] ||
										e?.response?.data?.variables?.Message ||
										'Nepoznata greška',
									duration: 0,
								});
							}
						}
					}}
					record={record}
				/>
			</>
		);
	}, [menuItems, emailModal, screens]);

	if (!record) {
		return null;
	}

	return (
		<>
			{menu}

			<Button key="close" type="primary" onClick={close}>
				Zatvori
			</Button>

			{emailModal}
		</>
	);
});

export default CreatePage({
	module: 'documents',
	submodule: 'outgoing-invoices',
	model: 'invoice',
	view: {
		width: 1200,
		shouldFetch: true,
		footer: (single, edit, close) => (
			<InvoiceButtons record={single} close={close} />
		),
		title: (entity) => `Pregled dokumenta ${entity?.number || ''}`,
		// fields: <ViewInvoice />,
		fields: (record) => [
			{
				key: 'info',
				label: 'Informacije',
				column: 12,
				fields: [
					{
						key: 'documentType',
						label: 'Tip dokumenta',
						span: 3,
						render: (text) => (text ? DOCUMENT_TYPE_MAP[text] : 'Nepoznat'),
					},
					{
						key: 'partner',
						label: 'Kupac',
						span: 9,
						component: <PartnerView />,
					},
					{
						key: 'date',
						label: 'Datum prometa',
						span: 3,
						render: (text) => moment(text).format('LL'),
					},
					{
						key: 'dueDate',
						label: 'Datum valute',
						span: 3,
						render: (text) => (text ? moment(text).format('LL') : ''),
					},
					{
						key: 'number',
						label: 'Broj dokumenta',
						span: 3,
					},
					{
						key: 'reference',
						label: 'Poziv na broj',
						span: 3,
						component: <ReferenceView />,
					},
				],
			},
			{
				key: 'payment',
				label: 'Plaćanje',
				column: 12,
				fields: [
					{
						key: 'payableAmount',
						label: 'Iznos',
						span: 3,
						render: (text) => numberFormat(text, true),
					},
					// {
					// 	key: 'amountWithCassaSconto',
					// 	label: 'Iznos za plaćanje u roku',
					// 	span: 3,
					// 	render: (text) => numberFormat(text, true),
					// },
					{
						key: 'paidAmount',
						label: 'Plaćeno',
						span: 3,
						render: (text) => numberFormat(text, true),
					},
					{
						label: 'Preostalo',
						span: 3,
						render: (text, record) =>
							numberFormat(
								record.amountWithCassaSconto - record.paidAmount,
								true
							),
					},
					// {
					// 	key: 'cassaSconto',
					// 	label: 'Iznosi za plaćanje u roku',
					// 	span: 12,
					// 	component: <CassaScontoView />,
					// },
				],
			},
			...(record.invoiceItems?.length > 0
				? [
						{
							key: 'items',
							label: 'Stavke fakture',
							column: 12,

							fields: [
								{
									key: 'invoiceItems',
									span: 12,
									component: <InvoiceItemsView />,
								},
								{
									key: 'taxBreakdown',
									span: 12,
									component: <InvoiceSummaryView record={record} />,
								},
							],
						},
				  ]
				: []),
		],
	},
	edit: {
		controllerComponent: forwardRef((props, ref) => {
			const [, openEditPartnerDrawer, , , , EditPartnerDrawer] = useDrawer(
				'edit/settings/partners'
			);

			useImperativeHandle(ref, () => ({
				openEditPartnerDrawer,
			}));

			return <EditPartnerDrawer />;
		}),
		width: 1600,
		buttons: ({
			entity,
			save,
			close,
			isCreating,
			isLoading,
			disabled,
			canEditPermission,
		}) => {
			const [overrideSending, setOverrideSending] = useState(false);
			const [emailModalVisible, setEmailModalVisible] = useState(false);
			const [currentSavedEntity, setCurrentSavedEntity] = useState(null);

			const [form] = Form.useForm();

			const send = useCallback(async () => {
				try {
					setOverrideSending(true);
					let savedEntity = entity;
					if (
						!entity?.status ||
						entity?.status === INVOICE_STATUS.DRAFT ||
						entity?.status === INVOICE_STATUS.MISTAKE
					) {
						savedEntity = await save(false);
					}
					setCurrentSavedEntity(savedEntity);
					await savedEntity.send();
					setCurrentSavedEntity(null);
					notification.success({
						message: 'Dokument je uspešno poslat',
					});
					close();
				} catch (e) {
					if (
						e?.response?.data?.errorCode ===
						ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
					) {
						setEmailModalVisible(true);
					}
					if (
						e?.response?.data?.errorCode ===
						ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
					) {
						notification.error({
							message: 'Greška prilikom slanja e-fakture',
							description:
								EFAKTURA_ERROR_CODE[e?.response?.data?.variables?.ErrorCode] ||
								e?.response?.data?.variables?.Message ||
								'Nepoznata greška',
							duration: 0,
						});
					}
				} finally {
					setOverrideSending(false);
				}
			}, [save, close]);
			return (
				<>
					<Space>
						<Button
							key="cancel"
							onClick={close}
							disabled={
								isLoading ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isSending
							}
						>
							Zatvori
						</Button>
						<Button
							disabled={
								isLoading ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isSending ||
								!canEditPermission ||
								entity?.status === INVOICE_STATUS.CANCELLED ||
								entity?.status === INVOICE_STATUS.STORNO
							}
							loading={overrideSending || entity?.isSending}
							onClick={async () => {
								if (
									entity?.status === INVOICE_STATUS.SENT ||
									entity?.status === INVOICE_STATUS.SENDING
								) {
									return Modal.confirm({
										title: 'Dokument je već poslat',
										content:
											'Da li ste sigurni da želite da ga pošaljete ponovo?',
										onOk: async () => {
											await send();
										},
									});
								}

								await send();
							}}
						>
							Pošalji
						</Button>
						<Button
							key="save"
							type="primary"
							loading={(isCreating || entity?.isUpdating) && !overrideSending}
							onClick={save}
							disabled={
								isLoading ||
								disabled ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isSending ||
								!canEditPermission
							}
						>
							Sačuvaj nacrt
						</Button>
						<Modal
							title="Unesite e-mail adresu"
							open={emailModalVisible}
							confirmLoading={currentSavedEntity?.isSending}
							onOk={form.submit}
							onCancel={() => {
								setEmailModalVisible(false);
								setCurrentSavedEntity(null);
							}}
							destroyOnClose
						>
							<Form
								preserve={false}
								form={form}
								layout="vertical"
								onFinish={async (values) => {
									await currentSavedEntity.send(
										values.email,
										values.storeEmail
									);
									setEmailModalVisible(false);
									notification.success({
										message: 'Dokument je uspešno poslat',
									});
									close();
								}}
							>
								<p>
									Za kupca <strong>{entity?.partner?.name}</strong>, ne postoji
									sačuvana e-mail adresa. Molimo vas da unesete e-mail adresu na
									koju želite da pošaljete dokument.
								</p>
								<Form.Item
									name="email"
									label="E-Mail adresa"
									rules={[
										{
											required: true,
											message: 'E-Mail adresa je obavezna',
										},
										{
											type: 'email',
											message: 'E-Mail adresa nije validna',
										},
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									initialValue={true}
									valuePropName="checked"
									name="storeEmail"
								>
									<Checkbox>Sačuvaj e-mail adresu za kupca</Checkbox>
								</Form.Item>
							</Form>
						</Modal>
					</Space>
				</>
			);
		},
		title: {
			new: 'Dodavanje izlaznog dokumenta',
			existing: (entity) => `Izmena izlaznog dokumenta ${entity?.number || ''}`,
		},
		shouldFetch: true,
		beforeSetFormFields: (entity) => {
			entity.creditDebitNotePeriod = (entity.creditDebitNotePeriod || []).map(
				(date) => moment(date)
			);
			return entity;
		},
		onError(error, entity, form, controller) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_PARTNER_MISSING_CODE
			) {
				Modal.confirm({
					title: 'Greška prilikom kreiranja dokumenta',
					content:
						'Format broja dokumenta sadrži šifru partnera, ali odabrani kupac nema postavljenu šifru. Da li želite da izmenite kupca i postavite šifru?',
					okText: 'Izmeni odabranog kupca',
					onOk: () => {
						(controller.current as any).openEditPartnerDrawer(
							form.getFieldValue('partnerId')
						);
					},
				});
			}
		},
		beforeSave: (entity) => {
			entity.storeId = stores.stores.currentStoreId;
			entity.invoiceItems = [
				...entity.invoiceItems.map((item) => ({
					...item,
					allowances: item.discountPercentage
						? [
								{
									amount: round(
										item.unitPrice *
											item.quantity *
											(item.discountPercentage / 100),
										2
									),
									baseAmount: round(item.unitPrice * item.quantity, 2),
									currencyId: item.currencyId,
									percentage: item.discountPercentage,
									reason: 'Popust',
									indicator: 'discount',
									taxCategoryCode: item.taxCode,
								},
						  ]
						: [],
				})),
			];

			return {
				...entity,
				attachments: entity.attachments
					? entity.attachments.map(
							(attachment) => attachment.response?.id || attachment?.id
					  )
					: [],
			};
		},
		disabled: (entity) => {
			if (
				entity?.direction === 'outgoing' &&
				entity?.status !== INVOICE_STATUS.DRAFT
			) {
				return true;
			}
			return entity?.externalId;
		},
		disabledReason: (entity) => {
			if (
				entity?.direction === 'outgoing' &&
				entity?.status !== INVOICE_STATUS.DRAFT
			) {
				return 'Dokument je već poslat i nije ga moguće menjati';
			}
			return 'Dokument je uvezen iz drugog sistema i nije ga moguće menjati';
		},
		fields: (record, form, setFields) => {
			const amount = round(
				form
					.getFieldValue('invoiceItems')
					?.reduce(
						(acc, item) =>
							acc +
							(item.quantity * item.unitPrice - item.discountAmount) +
							(item.quantity * item.unitPrice - item.discountAmount) *
								((item.taxPercentage || 0) / 100),
						0
					),
				2
			);

			const taxBreakdown = Object.values(
				form.getFieldValue('invoiceItems')?.reduce((acc, item) => {
					acc[item.taxCode] = acc[item.taxCode] || {
						taxRate: item.taxPercentage,
						taxCategory: item.taxCode,
						taxableAmount: 0,
						taxAmount: 0,
						currencyId: form.getFieldValue('currencyId'),
					};

					acc[item.taxCode].taxableAmount = round(
						acc[item.taxCode].taxableAmount +
							item.quantity * item.unitPrice -
							item.discountAmount,
						2
					);

					acc[item.taxCode].taxAmount = round(
						acc[item.taxCode].taxAmount +
							(item.quantity * item.unitPrice - item.discountAmount) *
								((item.taxPercentage || 0) / 100),
						2
					);

					return acc;
				}, {}) || {}
			) as TaxBreakdown[];

			const advancePayments = Object.values(
				form.getFieldValue('advanceInvoices')?.reduce((acc, item) => {
					['S10', 'S20', 'AE', 'O', 'E', 'R', 'Z', 'SS', 'OE', 'N'].forEach(
						(taxCode) => {
							if (!item[`amount${taxCode}`]) {
								return;
							}

							acc[taxCode] = acc[taxCode] || {
								taxRate: ['S10', 'S20'].includes(taxCode)
									? Number(taxCode.replace('S', ''))
									: 0,
								taxCategory: ['S10', 'S20'].includes(taxCode) ? 'S' : taxCode,
								taxableAmount: 0,
								taxAmount: 0,
								currencyId: form.getFieldValue('currencyId'),
							};

							acc[taxCode].taxableAmount = round(
								acc[taxCode].taxableAmount + item[`amount${taxCode}`],
								2
							);

							acc[taxCode].taxAmount = round(
								acc[taxCode].taxAmount +
									(['S10', 'S20'].includes(taxCode)
										? item[`vat${taxCode}`]
										: 0),
								2
							);
						}
					);

					return acc;
				}, {}) || {}
			) as AdvancePayment[];

			const totalAdvancePayments = round(
				advancePayments.reduce(
					(acc, item) => acc + item.taxableAmount + item.taxAmount,
					0
				),
				2
			);

			const payableAmount = round(amount - totalAdvancePayments, 2);

			return [
				{
					key: 'row1',
					label: 'Osnovne informacije',
					fields: [
						{
							key: 'direction',
							hidden: true,
							initialValue: 'outgoing',
							component: <Input />,
						},
						{
							key: 'documentType',
							label: 'Tip dokumenta',
							initialValue: 'invoice',
							rules: [
								{
									required: true,
									message: 'Tip dokumenta je obavezan',
								},
							],
							component: (
								<Select
									onChange={(value) => {
										if (value === DOCUMENT_TYPE.ADVANCE_INVOICE) {
											form.setFieldsValue({
												taxInvoicePeriod:
													DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE,
											});
										}
									}}
								>
									{Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => (
										<Select.Option value={key}>{value}</Select.Option>
									))}
								</Select>
							),
							xs: 24,
							sm: 6,
							rerenderOnChange: true,
						},
						{
							key: 'partnerId',
							label: 'Kupac',
							rules: [
								{
									required: true,
									message: 'Kupac je obavezan',
								},
							],
							component: <PartnerSelect />,
							rerenderOnChange: true,
							xs: 24,
							sm: 10,
						},
						{
							key: 'partnerLocationId',
							label: 'Lokacija',

							component: (
								<PartnerLocationSelect
									partnerId={form.getFieldValue('partnerId')}
								/>
							),
							xs: 24,
							sm: 8,
						},

						{
							key: 'number',
							label: 'Broj dokumenta',
							xs: 24,
							sm: 12,
							rules:
								stores.company.invoiceNumbering === 'custom'
									? [
											{
												required: true,
												message: 'Broj dokumenta je obavezan',
											},
									  ]
									: [],
							component: (
								<Input
									disabled={stores.company.invoiceNumbering !== 'custom'}
									placeholder={
										stores.company.invoiceNumbering !== 'custom'
											? 'Broj će biti automatski generisan'
											: ''
									}
								/>
							),
						},
						{
							key: 'reference',
							label: 'Poziv na broj',
							xs: 24,
							sm: 12,
							rules: [],
							component: (
								<ReferenceInput
									disabled={stores.company.invoiceNumbering !== 'custom'}
									placeholder={
										stores.company.invoiceNumbering !== 'custom'
											? 'Poziv na broj će biti automatski generisan'
											: ''
									}
								/>
							),
						},
					],
				},
				...([DOCUMENT_TYPE.CREDIT_NOTE, DOCUMENT_TYPE.DEBIT_NOTE].includes(
					form.getFieldValue('documentType')
				)
					? [
							{
								key: 'row2',
								fields: [
									{
										key: 'creditDebitNoteType',
										label:
											form.getFieldValue('documentType') ===
											DOCUMENT_TYPE.CREDIT_NOTE
												? 'Knjižno odobrenje se odnosi na'
												: 'Knjižno zaduženje se odnosi na',
										xs: 24,
										sm: 10,
										md: 8,
										initialValue: 'singleInvoice',
										rerenderOnChange: true,
										component: (
											<Select dropdownMatchSelectWidth={false}>
												{Object.entries(
													DOCUMENT_CREDIT_DEBIT_NOTE_TYPE_MAP
												).map(([key, value]) => (
													<Select.Option value={key}>{value}</Select.Option>
												))}
											</Select>
										),
									},
									...(form.getFieldValue('creditDebitNoteType') ===
									'singleInvoice'
										? [
												{
													key: 'creditDebitNoteReferences',
													label: 'Izvorne fakture',
													component: (
														<CreditDebitNoteReferencesInput
															partnerId={form.getFieldValue('partnerId')}
														/>
													),
													rules: [
														{
															required: true,
															message: 'Izvorne fakture su obavezne',
														},
													],
													span: 24,
												},
										  ]
										: [
												{
													key: 'creditDebitNotePeriod',
													label: 'Period',
													xs: 24,
													sm: 14,
													md: 8,
													component: (
														<DatePicker.RangePicker
															style={{ width: '100%' }}
															format="L"
														/>
													),
													rules: [
														{
															required: true,
															message: 'Period je obavezan',
														},
													],
												},
										  ]),
								],
							},
					  ]
					: []),
				{
					key: 'contract',
					fields: [
						{
							key: 'contractNumber',
							label: 'Broj ugovora',
							xs: 24,
							sm: 24,
							md: 8,
							rules:
								stores.partners.getById(form.getFieldValue('partnerId'))
									?.jbkjs !== ''
									? [
											{
												required: true,
												message: 'Broj ugovora je obavezan',
											},
									  ]
									: undefined,
							component: <Input />,
						},
						{
							key: 'orderNumber',
							label: 'Broj narudžbenice/fakture/ponude',
							xs: 24,
							sm: 24,
							md: 8,
							component: <Input />,
						},
						{
							key: 'lotNumber',
							label: 'Broj okvirnog sporazuma',
							xs: 24,
							sm: 24,
							md: 8,
							component: <Input />,
						},
					],
				},
				...([DOCUMENT_TYPE.INVOICE].includes(form.getFieldValue('documentType'))
					? [
							{
								key: 'row3',
								fields: [
									{
										key: 'date',
										label: 'Datum prometa',
										initialValue: moment(),
										xs: 24,
										sm: 8,
										rules: [
											{
												required: true,
												message: 'Datum prometa fakture je obavezan',
											},
										],
										component: (
											<DatePicker format="L" style={{ width: '100%' }} />
										),
									},

									{
										key: 'dueDate',
										label: 'Datum dospeća',
										xs: 24,
										sm: 8,
										initialValue: moment().add(
											stores.company.defaultDueDate,
											'days'
										),
										rules:
											form.getFieldValue('taxInvoicePeriod') ===
											DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE
												? [
														{
															required: true,
															message: 'Datum dospeća je obavezan',
														},
												  ]
												: [],
										component: (
											<DatePicker style={{ width: '100%' }} format="L" />
										),
									},
									{
										key: 'taxInvoicePeriod',
										label: 'Datum obračuna PDV-a',
										xs: 24,
										sm: 8,
										initialValue: DOCUMENT_TAX_INVOICE_PERIOD.ISSUE_DATE,
										rerenderOnChange: true,
										component: (
											<Select dropdownMatchSelectWidth={false}>
												{Object.entries(DOCUMENT_TAX_INVOICE_PERIOD_MAP).map(
													([key, value]) => (
														<Select.Option value={key}>{value}</Select.Option>
													)
												)}
											</Select>
										),
									},
								],
							},
					  ]
					: []),
				...([DOCUMENT_TYPE.ADVANCE_INVOICE].includes(
					form.getFieldValue('documentType')
				)
					? [
							{
								key: 'row3',
								fields: [
									{
										key: 'dueDate',
										label: 'Datum plaćanja',
										xs: 24,
										sm: 8,
										rules: [
											{
												required: true,
												message: 'Datum plaćanja je obavezan',
											},
										],
										component: (
											<DatePicker style={{ width: '100%' }} format="L" />
										),
									},
									{
										key: 'taxInvoicePeriod',
										label: 'Datum obračuna PDV-a',
										xs: 24,
										sm: 8,
										initialValue: DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE,
										rerenderOnChange: true,
										component: (
											<Select dropdownMatchSelectWidth={false} disabled>
												<Select.Option
													value={DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE}
												>
													{
														DOCUMENT_TAX_INVOICE_PERIOD_MAP[
															DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE
														]
													}
												</Select.Option>
											</Select>
										),
									},
								],
							},
					  ]
					: []),

				{
					key: 'row4',
					label: 'Plaćanje',
					fields: [
						{
							key: 'currencyId',
							label: 'Valuta',
							component: (
								<CurrencySelect
									onChange={(value) => {
										form.setFieldsValue({
											exchangeRate:
												round(
													stores.exchangeRates.lastRates?.[value as string]
														?.rate,
													6
												) || '',
										});
									}}
								/>
							),
							rerenderOnChange: true,
							initialValue: 'RSD',
							hidden: true,
							xs: 24,
							sm: 5,
							// TODO: if currency is not RSD, add exchange rate field, which is automatically populated with the latest exchange rate
						},
						// ...(form.getFieldValue('currencyId') !== 'RSD'
						// 	? [
						// 			{
						// 				key: 'exchangeRate',
						// 				label: 'Kurs',
						// 				component: (
						// 					<Input
						// 						prefix={`1 ${form.getFieldValue('currencyId')} = `}
						// 						suffix="RSD"
						// 						style={{ textAlign: 'right' }}
						// 					/>
						// 				),
						// 				xs: 24,
						// 				sm: 8,
						// 				md: 7,
						// 			},
						// 	  ]
						// 	: []),
						{
							key: 'paymentMeans',
							label: 'Načini plaćanja',
							component: <PaymentMeansSelect />,
							// xs: 24,
							initialValue: sortBy(stores.company?.bankAccounts, (account) =>
								account.primary ? 0 : 1
							)
								.filter((account) => account.invoice)
								.map((account) => `30:${account.number}`),
							// sm: form.getFieldValue('currencyId') !== 'RSD' ? 11 : 19,
							// md: form.getFieldValue('currencyId') !== 'RSD' ? 12 : 19,
							span: 24,
							rules: [
								{
									required: true,
									message: 'Način plaćanja je obavezan',
								},
							],
						},
						// {
						// 	key: 'type',
						// 	label: 'Tip',
						// 	span: 12,
						// 	component: (
						// 		<Select
						// 			mode="multiple"
						// 			style={{ width: '100%' }}
						// 			placeholder="Izaberite tipove"
						// 		>
						// 			<Select.Option value="goods">Ulaz robe</Select.Option>
						// 			<Select.Option value="assets">Osnovna sredstva</Select.Option>
						// 			<Select.Option value="supplies">Potrošna roba</Select.Option>
						// 			<Select.Option value="services">Usluge</Select.Option>
						// 		</Select>
						// 	),
						// },
					],
				},

				// ...([
				// 	DOCUMENT_TYPE.INVOICE,
				// 	DOCUMENT_TYPE.ADVANCE_INVOICE,
				// 	DOCUMENT_TYPE.DEBIT_NOTE,
				// ].includes(form.getFieldValue('documentType'))
				// 	? [
				// 			{
				// 				key: 'row5',
				// 				fields: [
				// 					{
				// 						key: 'cassaSconto',
				// 						label: 'Iznosi za plaćanje u roku',
				// 						rules: [],
				// 						component: <CassaScontoInput />,
				// 						span: 24,
				// 						initialValue: [],
				// 					},
				// 				],
				// 			},
				// 	  ]
				// 	: []),
				{
					key: 'row6',
					label: 'Stavke dokumenta',
					fields: [
						{
							key: 'invoiceItems',
							component: (
								<InvoiceItemsInput
									currencyId={form.getFieldValue('currencyId')}
									exchangeRate={form.getFieldValue('exchangeRate')}
									documentType={form.getFieldValue('documentType')}
								/>
							),
							span: 24,
							rerenderOnChange: true,
						},
					],
				},
				...((form.getFieldValue('invoiceItems') || []).find((item) => {
					return (
						item.taxCode !== null && item.taxCode !== 'S' && item.taxCode !== ''
					);
				})
					? [
							{
								key: 'row7',
								label: 'Osnov za oslobađanje/izuzeće od PDV-a',
								fields: [
									{
										key: 'taxExemption',
										component: (
											<TaxExemptionInput
												categories={uniq(
													form
														.getFieldValue('invoiceItems')
														.map((item) => item.taxCode)
												)}
											/>
										),
										span: 24,
									},
								],
							},
					  ]
					: []),
				...([DOCUMENT_TYPE.INVOICE].includes(form.getFieldValue('documentType'))
					? [
							{
								key: 'advances',
								label: 'Avansni računi',
								fields: [
									{
										key: 'advanceInvoices',
										component: (
											<AdvanceInvoicesInput
												partnerId={form.getFieldValue('partnerId')}
											/>
										),
										span: 24,
										rerenderOnChange: true,
									},
								],
							},
					  ]
					: []),
				{
					key: 'summary',
					fields: [
						{
							key: 'invoiceSummary',
							component: (
								<>
									<InvoiceSummaryView
										record={{
											currencyId: form.getFieldValue('currencyId'),
											taxCurrencyId: form.getFieldValue('currencyId'),
											payableAmount,
											amount,
											taxBreakdown,
											advancePayments,
										}}
									/>
								</>
							),
							span: 24,
							rerenderOnChange: true,
						},
					],
				},
				{
					key: 'other',
					label: 'Napomena',
					fields: [
						{
							key: 'note',
							component: <Input.TextArea rows={4} />,
							span: 24,
						},
					],
				},
				{
					key: 'attachments',
					label: 'Prilozi',
					fields: [
						{
							key: 'attachments',
							component: <AttachmentsEdit />,
							span: 24,
						},
					],
				},
			];
		},
	},
	page: {
		additionalQueryParams: {
			direction: 'outgoing',
		},
		shouldFetch: true,
		table: {
			props: {
				scroll: { x: 1900 },
			},
			showDisabledActions: true,
			actions: [(record) => <PayButton record={record} />],
			columns: () => [
				{
					title: 'Broj dokumenta',
					dataIndex: 'number',
					key: 'number',
					width: 220,
					shouldLink: true,
					ellipsis: true,
					filterKey: 'number',
					render: (text, record) => (
						<>
							{text}
							{record.externalId && (
								<img
									src="/images/icons/efaktura-logo.png"
									style={{ float: 'right', marginTop: '4px' }}
									title="e-Faktura"
								/>
							)}
						</>
					),
					filterIcon: (filtered) => (
						<SearchOutlined
							style={{ color: filtered ? '#7E89C8' : undefined }}
						/>
					),
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<Input
									placeholder="Broj dokumenta"
									value={selectedKeys[0]}
									onPressEnter={() => confirm({ closeDropdown: true })}
									onChange={(event) => setSelectedKeys([event.target.value])}
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Tip dokumenta',
					dataIndex: 'documentType',
					key: 'documentType',
					ellipsis: true,
					width: 150,
					render: (text) => {
						return DOCUMENT_TYPE_MAP[text] || 'Nepoznat';
					},
					filters: Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => ({
						text: value,
						value: key,
					})),
					filterMultiple: true,
					filterKey: 'documentType',
				},
				// {
				// 	title: 'Poziv na broj',
				// 	key: 'reference',
				// 	ellipsis: true,
				// 	render: (record) =>
				// 		record.reference &&
				// 		`${record.reference.model ? `(${record.reference.model})` : ''} ${
				// 			record.reference.number
				// 		}`,
				// },
				{
					title: 'Status',
					dataIndex: 'status',
					key: 'status',
					filterKey: 'status',
					width: 120,
					filters: Object.entries(INVOICE_STATUS_MAP).map(([key, value]) => ({
						text: value,
						value: key,
					})),
					filterMultiple: true,
					render: (text) => {
						return INVOICE_STATUS_MAP[text] || 'Nepoznato';
					},
				},
				{
					title: 'Kupac',
					dataIndex: ['partner', 'name'],
					key: 'partnerId',
					filterKey: 'partnerId',
					filters: () =>
						stores.partners.list?.map((partner: Partner) => ({
							text: partner.name,
							value: partner.id,
						})),
					filterMultiple: true,
					ellipsis: true,
					filterSearch: true,
				},
				{
					title: 'Iznos',
					width: 160,
					key: 'amountWithCassaSconto',
					dataIndex: 'amountWithCassaSconto',
					render: (text) => numberFormat(text, true),
				},
				{
					title: 'Preostalo',
					width: 160,
					key: 'remainingAmount',
					dataIndex: 'remainingAmount',
					render: (text, record) => numberFormat(record.remainingAmount, true),
				},
				{
					title: 'Datum prometa',
					width: 170,
					key: 'date',
					dataIndex: 'date',
					render: (text) => (text ? moment(text).format('LL') : ''),
					filterKey: 'date',
					sorter: true,
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Datum izdavanja',
					width: 170,
					key: 'issueDate',
					dataIndex: 'issueDate',
					render: (text) => (text ? moment(text).format('LL') : '-'),
					filterKey: 'issueDate',
					sorter: true,
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Datum plaćanja',
					width: 170,
					key: 'dueDate',
					dataIndex: 'dueDateWithCassaSconto',
					render: (text, record) => {
						if (!text) {
							return '';
						}
						const badgeColor =
							record.cassaSconto && record.cassaSconto.length
								? 'yellow'
								: 'green';
						return (
							<Tooltip
								title={
									record.cassaSconto && record.cassaSconto.length
										? [
												...record.cassaSconto,
												{
													dueDate: record.dueDate,
													amount: record.payableAmount,
												},
										  ].map((cassa) => (
												<Fragment key={cassa.dueDate}>
													{moment(cassa.dueDate).format('LL')}:{' '}
													{numberFormat(cassa.amount, true)}
													<br />
												</Fragment>
										  ))
										: undefined
								}
							>
								<Badge
									color={
										moment().endOf('day').isAfter(moment(text).endOf('day'))
											? 'red'
											: badgeColor
									}
									text={moment(text).format('LL')}
								/>
							</Tooltip>
						);
					},
					sorter: true,
					filterKey: 'dueDate',
					filterDropdown: ({
						setSelectedKeys,
						selectedKeys,
						confirm,
						clearFilters,
					}) => (
						<div>
							<div style={{ padding: '8px' }}>
								<DatePicker.RangePicker
									value={[
										selectedKeys[0]
											? moment(selectedKeys[0]).startOf('day')
											: undefined,
										selectedKeys[1]
											? moment(selectedKeys[1]).endOf('day')
											: undefined,
									]}
									onChange={(values) =>
										setSelectedKeys([
											moment(values[0]).startOf('day').toISOString(),
											moment(values[1]).endOf('day').toISOString(),
										])
									}
									format="L"
								/>
							</div>
							<div className="ant-table-filter-dropdown-btns">
								<Button
									onClick={() => clearFilters()}
									size="small"
									style={{ width: 90 }}
								>
									Poništi
								</Button>
								<Button
									type="primary"
									onClick={() => confirm({ closeDropdown: true })}
									size="small"
									style={{ width: 90 }}
								>
									Potvrdi
								</Button>
							</div>
						</div>
					),
				},
				{
					title: 'Plaćeno',
					width: 160,
					key: 'paid',
					filters: [
						{
							text: <Badge color="green" text="Plaćeno" />,
							value: 'yes',
						},
						{
							text: <Badge color="orange" text="Delimično plaćeno" />,
							value: 'partial',
						},
						{
							text: <Badge color="red" text="Nije plaćeno" />,
							value: 'no',
						},
					],
					defaultFilteredValue: ['no', 'partial'],
					filterKey: 'paid',
					filterMultiple: true,
					render: (record) => {
						if (!record.paid && record.partiallyPaid) {
							return <Badge color="orange" text="Delimično plaćeno" />;
						}

						return record.paid ? (
							<Badge color="green" text="Plaćeno" />
						) : (
							<Badge color="red" text="Nije plaćeno" />
						);
					},
				},
				// {
				// 	title: 'Tipovi',
				// 	key: 'type',
				// 	filterKey: 'type',
				// 	width: 200,
				// 	filters: [
				// 		{
				// 			text: 'Ulaz robe',
				// 			value: 'goods',
				// 		},
				// 		{
				// 			text: 'Osnovna sredstva',
				// 			value: 'assets',
				// 		},
				// 		{
				// 			text: 'Potrošna roba',
				// 			value: 'supplies',
				// 		},
				// 		{
				// 			text: 'Usluge',
				// 			value: 'services',
				// 		},
				// 		{
				// 			text: 'Ostalo',
				// 			value: 'other',
				// 		},
				// 	],
				// 	defaultFilteredValue: [],
				// 	filterMultiple: true,
				// 	render: (record) => {
				// 		const labelsMap = {
				// 			goods: 'Ulaz robe',
				// 			supplies: 'Potrošna roba',
				// 			services: 'Usluge',
				// 			other: 'Ostalo',
				// 		};
				// 		return ((record.type || []).length === 0 ? ['other'] : record.type)
				// 			.map((type) => labelsMap[type])
				// 			.join(', ');
				// 	},
				// },
			],
		},
		createButtonText: 'Dodaj izlazni dokument',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj dokument?',
	},
});
