import { SettingOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';

import { useDrawer } from '../../../../components/Page';
import { CreatePage } from '../../../../components/Page';
import {
	DEVICE_CONNECTION_TYPE,
	DEVICE_TYPE_NAME,
} from '../../../../constants/device';
import EditForm from './Components/EditForm';
import styles from './Devices.module.less';
import './Drawers/Configure';

function DeviceActions({ record }) {
	const [, openConfigureDrawer, , , , ConfigureDrawer] =
		useDrawer('configure-device');

	return (
		// <HasPermission module="documents" submodule="calculations" action="edit">
		record.type === 'thermal_printer' && (
			<>
				<Button
					key="configureButton"
					size="small"
					type="link"
					icon={<SettingOutlined />}
					onClick={() => {
						openConfigureDrawer(record.id);
					}}
				/>
				<ConfigureDrawer />
			</>
		)
		// </HasPermission>
	);
}

export default CreatePage({
	module: 'settings',
	submodule: 'devices',
	model: 'device',
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje uređaja',
			existing: (entity) =>
				`Izmena uređaja ${DEVICE_TYPE_NAME[entity.type] || ''}`,
		},
		shouldFetch: false,
		beforeSave: (entity) => {
			if (entity.connectionType === DEVICE_CONNECTION_TYPE.NETWORK) {
				return {
					...entity,
					name: `${entity.host}:${entity.port}`,
					device: JSON.stringify({
						port: parseInt(entity.port, 10),
						host: entity.host,
					}),
				};
			}
			const device = JSON.parse(entity.device);
			return {
				...entity,
				name: device.name,
				device: JSON.stringify({
					...device,
					protocol: entity.protocol,
				}),
			};
		},
		fields: <EditForm />,
	},
	page: {
		shouldFetch: false,
		table: {
			actions: [(record) => <DeviceActions record={record} />],
			columns: [
				{
					title: '',
					width: 46,
					render(record) {
						return (
							<img
								className={styles.icon}
								src={`/images/icons/devices/${record.type}.png`}
								alt={record.type}
							/>
						);
					},
				},
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Tip',
					dataIndex: 'type',
					key: 'type',
					render(text: string | number) {
						return DEVICE_TYPE_NAME[text];
					},
				},
			],
		},
		createButtonText: 'Dodaj uređaj',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj uređaj?',
	},
});
