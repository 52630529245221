import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { MARKETPLACE_APPLICATION_SCOPE } from '../../../../../../constants/marketplace';
import { MarketplaceApplication } from '../../../../../../stores/MarketplaceApplication.mobx';

interface Props {
	application: MarketplaceApplication;
	visible?: boolean;
	close: () => void;
	openSuccessModal: () => void;
}

const InstallConfirmation = ({
	application,
	visible,
	close,
	openSuccessModal,
}: Props) => {
	return (
		<Modal
			visible={visible}
			title={`Instaliranje aplikacije ${application?.name}`}
			okText="Instaliraj"
			cancelText="Odustani"
			onOk={() => {
				application?.install();
				close();
				openSuccessModal();
			}}
			onCancel={close}
		>
			<p>
				Da li ste sigurni da želite da instalirate aplikaciju{' '}
				{application?.name}?<br />
				Aplikacija će imati pristup sledećim podacima:
				<br />
				<br />
				<ul>
					{application?.scopes?.map((scope) => (
						<li>{MARKETPLACE_APPLICATION_SCOPE[scope]}</li>
					))}
				</ul>
			</p>
		</Modal>
	);
};

export default observer(InstallConfirmation);
