import { Input } from 'antd';

import { CreatePage } from '../../../../../components/Page';

export default CreatePage({
	module: 'settings',
	submodule: 'warehouses',
	model: 'warehouse',
	page: {
		shouldFetch: false,
		table: {
			columns: [
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Adresa',
					dataIndex: 'address',
					key: 'address',
					render: (text) => (text || '').replace(/\n/g, ', '),
				},
				{
					title: 'Telefon',
					dataIndex: 'phone',
					key: 'phone',
				},
			],
		},
		createButtonText: 'Dodaj magacin',
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj magacin?',
	},
	edit: {
		width: 500,
		title: {
			new: 'Dodavanje magacina',
			existing: (entity) => `Izmena magacina ${entity.name || ''}`,
		},
		shouldFetch: false,
		fields: [
			{
				key: 'basic',
				label: 'Osnovni podaci',
				fields: [
					{
						key: 'name',
						label: 'Naziv',
						rules: [
							{
								required: true,
								message: 'Naziv magacina je obavezan',
							},
						],
						component: <Input />,
					},
					{
						key: 'address',
						label: 'Adresa',
						component: <Input.TextArea />,
					},
					{
						key: 'phone',
						label: 'Telefon',
						component: <Input />,
					},
				],
			},
		],
	},
});
