import ProLayout from '@ant-design/pro-layout/es';
import { Affix, Card, Carousel, Col, Menu, Rate, Row, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import stores from '../../../../stores/index.mobx';
import styles from './Marketplace.module.less';
import Category from './Pages/Category';
import Featured from './Pages/Featured';

function RouteRender() {
	return <Category />;
}

function Marketplace() {
	const {
		marketplaceCategories: { isFetching, available },
	} = stores;

	const routes = useMemo(() => {
		const defaultRoutes = [
			// {
			// 	path: '/app/settings/applications/marketplace/featured',
			// 	name: 'Preporučeno',
			// 	exact: true,
			// 	icon: (
			// 		<img
			// 			src="/images/icons/marketplace/star.png"
			// 			alt="Preporučeno"
			// 			className="anticon"
			// 		/>
			// 	),
			// 	component: Featured,
			// },
		];
		if (isFetching) {
			return defaultRoutes;
		}

		return [
			...defaultRoutes,
			...available.map(({ id, name, icon }) => ({
				name,
				path: `/app/settings/applications/marketplace/category/${id}`,
				routePath: `/app/settings/applications/marketplace/category/:id`,
				exact: true,
				headerTitle: `Podešavanja / Aplikacije / Prodavnica / ${name}`,
				icon: (
					<img
						src={`/images/icons/marketplace/${icon}.png`}
						alt={name}
						className="anticon"
					/>
				),
				component: Category,
			})),
		];
	}, [isFetching, available]);

	return (
		<Spin spinning={isFetching}>
			<ProLayout
				menuHeaderRender={() => null}
				siderWidth={200}
				menuItemRender={(item, defaultDom) => (
					<Link to={item.path}>{defaultDom}</Link>
				)}
				menuDataRender={() => routes}
				footerRender={() => null}
				navTheme="light"
				fixedHeader
				fixSiderbar
				collapsedButtonRender={() => null}
				disableContentMargin
				headerRender={false}
			>
				<Routes>
					<Route path="/" element={<RouteRender />} />
				</Routes>
			</ProLayout>
		</Spin>
	);

	// <Spin spinning={false}>

	// </Spin>
}

export default {
	Page: observer(Marketplace),
	drawers: [],
	modals: [],
};
