import './crypto';
import 'reflect-metadata';
import { notification } from 'antd';
import isObject from 'lodash/isObject';
import mixin from 'lodash/mixin';
import transform from 'lodash/transform';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

declare global {
	interface Window {
		electron: any;
	}
}

function deepMap(obj, iterator, context) {
	return transform(obj, (result, val, key) => {
		result[key] = isObject(val)
			? /* && !_.isDate(val) */ deepMap(val, iterator, context)
			: iterator.call(context, val, key, obj);
	});
}

mixin({
	deepMap,
});

// Wrap the rendering in a function:
const root = createRoot(document.getElementById('root'));
root.render(<App />);
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {
					notification.info({
						message: 'Nova verzija je dostupna',
						description: 'Kliknite ovde da ažurirate aplikaciju',
						key: 'update',
						duration: 0,
						onClick: () => {
							window.location.reload();
						},
					});
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});
