import Fuse from 'fuse.js';
import localforage from 'localforage';
import kebabCase from 'lodash/kebabCase';
import keyBy from 'lodash/keyBy';
import {
	action,
	computed,
	extendObservable,
	flow,
	makeObservable,
	observable,
	runInAction,
	toJS,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { computedFn } from 'mobx-utils';
import moment from 'moment';
import { Moment } from 'moment';
import pluralize from 'pluralize';
import qs from 'qs';

import { v2Client, defaultClient } from '../store/client';
import { MomentTransformer } from './transformers/Moment';

const clients = {
	v1: defaultClient,
	v2: v2Client,
};

export interface Pagination {
	supported?: boolean;
	count?: number;
	offset?: number;
	limit?: number;
	filters?: any;
}

interface StoreOptions {
	name: string;
	type?: 'collection' | 'entity';
	clientVersion?: 'v1' | 'v2' | 'local';
	local?: boolean;
	paginated?: boolean;
	searchFields?: string[];
	persistFields?: string[];
	persistDelay?: number;
}

const TransformFields = Symbol('TransformFields');
const ArrayTypes = Symbol('ArrayTypes');
const Parent = Symbol('Parent');

export function CreateStore({
	name,
	type = 'collection',
	local = false,
	clientVersion = 'v2',
	paginated = false,
	searchFields,
	persistFields = [],
	persistDelay = 2000,
}: StoreOptions) {
	const client = !local && clients[clientVersion];
	const resourceName = kebabCase(pluralize(name));

	class Base {
		@observable.deep public _original = {};
		@observable lastUpdated?: string | void = null;

		@action.bound
		setLastUpdated(date: string) {
			if (!this.lastUpdated || date > this.lastUpdated) {
				this.lastUpdated = date;
			}
		}

		static setArrayType(key: string, type: any) {
			if (!(this as any)[ArrayTypes]) {
				(this as any)[ArrayTypes] = {};
			}
			this[ArrayTypes][key] = type;
		}

		static getArrayType(key: string) {
			return (this as any)[ArrayTypes]?.[key];
		}

		static setTransformField(key: string, fn: (value: any) => any) {
			if (!(this as any)[TransformFields]) {
				(this as any)[TransformFields] = {};
			}
			this[TransformFields][key] = fn;
		}

		getTransformed(key) {
			return (this.constructor as any)[TransformFields][key].call(
				this,
				this._original[key]
			);
		}

		setTransformed(key, value) {
			this._original[key] = value;
		}

		makeTransformable() {
			if (!(this.constructor as any)[TransformFields]) {
				return;
			}
			for (const key in (this.constructor as any)[TransformFields]) {
				delete this[key];

				extendObservable(
					this,
					{
						get [key]() {
							return this.getTransformed(key);
						},
						set [key](value) {
							this.setTransformed(key, value);
						},
					},
					{
						[key]: computed,
					}
				);
			}
		}

		getClient() {
			return client;
		}
	}

	class Persistable extends Base {
		@action.bound
		hydrate(companyId: string, target: any) {
			if (!target) {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				target = this;
			}

			if (persistFields.length) {
				target.isFetching = true;
				const storage = localforage.createInstance({
					name: 'storeBuddy',
					version: 1.0,
					storeName: `data_v2_${companyId}`,
				});
				const getItem = async (key: string): Promise<any> => {
					const value: any = await storage.getItem(key);
					if (!value) {
						return null;
					}

					if (type === 'collection') {
						for (
							let i = 0, length = (value.all || []).length;
							i < length;
							i++
						) {
							if (!value.all[i].ignoreLastUpdated) {
								this.setLastUpdated(value.all[i]._original.updatedAt);
							}
							value.all[i].isFetching = false;
							value.all[i].isUpdating = false;

							value.all[i] = new target._EntityType(value.all[i], this);
						}
					}

					return value;
				};
				return makePersistable(
					target || this,
					{
						name: `${resourceName}.store`,
						properties:
							persistFields[0] === '*'
								? (Object.keys(target) as (keyof this)[])
								: (persistFields as (keyof this)[]),
						storage: {
							setItem: storage.setItem,
							getItem,
							removeItem: storage.removeItem,
						},
						stringify: false,
						debugMode: process.env.NODE_ENV === 'development',
					},
					{ delay: persistDelay, fireImmediately: true }
				).then(() => {
					if (type === 'collection') {
						runInAction(() => {
							target.isFetching = false;
						});
						target.reindex();
					}
				});
			}
		}
	}

	class Entity extends Persistable {
		ignoreLastUpdated = false;

		@observable id: string;
		@observable isFetching = false;
		@observable isUpdating = false;
		@observable isDestroying = false;
		@MomentTransformer
		createdAt?: Moment | void;
		@MomentTransformer
		updatedAt?: Moment | void;
		// @MomentTransformer - i don't care
		@observable
		deletedAt?: string | void;

		constructor(parent?: Store<any>, ignoreLastUpdated = false) {
			super();
			this.ignoreLastUpdated = ignoreLastUpdated;
			Object.defineProperty(this, Parent, {
				configurable: true,
				enumerable: false,
				value: parent,
			});
		}

		init(data = {}) {
			this.makeTransformable();
			makeObservable(this);
			this.replace(data);
		}

		toPlain(removeTransformed = false) {
			const js = toJS(this);
			const original = js._original;
			delete js[Parent];
			delete js._original;
			delete js.ignoreLastUpdated;
			if (removeTransformed) {
				Object.keys((this.constructor as any)[TransformFields]).forEach(
					(key: string) => delete js[key]
				);
			}
			return { ...js, ...original };
		}

		// Methods

		getParent() {
			return this[Parent];
		}

		replace(data: any) {
			for (const key in data) {
				if (Object.hasOwnProperty.call(this, key)) {
					const t = Reflect.getMetadata('design:type', this, key);
					if (t === Array) {
						let at = (this.constructor as any).getArrayType(key);
						if (
							typeof at === 'function' &&
							Object.getOwnPropertyDescriptor(at, 'prototype')?.writable ===
								true
						) {
							at = at();
						}
						if (at && data[key]) {
							this[key] = data[key].map((d: any) => new at(d, this));
							continue;
						}
					}
					this[key] = data[key];
				}
			}
		}

		// Computeds
		@computed
		get isEditable() {
			return true;
		}

		@computed
		get isDeletable() {
			return true;
		}

		// Flows
		@flow.bound
		*update(patchData: any) {
			const parent = this.getParent();
			const existing = parent?.getById(this.id);
			if (existing) {
				runInAction(() => {
					existing.isUpdating = true;
				});
			}

			this.isUpdating = true;

			try {
				if (!local) {
					const { data } = yield client({
						method: 'PATCH',
						url: `/${resourceName}/${this.id}`,
						data: patchData,
					});
					parent?.setLastUpdated?.(data.updatedAt);

					if (existing) {
						runInAction(() => {
							existing.replace(data);
						});
					}
					this.replace(data);
				} else {
					this.replace(patchData);
				}
				if (existing) {
					runInAction(() => {
						existing.isUpdating = false;
					});
				}

				this.isUpdating = false;

				parent?.reindex();
				return this;
			} catch (error) {
				if (existing) {
					runInAction(() => {
						existing.isUpdating = false;
					});
				}

				this.isUpdating = false;

				throw error;
			}
		}

		@flow.bound
		*destroy() {
			this.isDestroying = true;
			try {
				this.deletedAt = moment().toISOString();
				if (!local) {
					yield client({
						method: 'DELETE',
						url: `/${resourceName}/${this.id}`,
					});

					this.getParent().setLastUpdated(this.deletedAt);
				}
				this.getParent().reindex();
				this.isDestroying = false;
			} catch (error) {
				this.isDestroying = false;
				throw error;
			}
		}

		@action.bound
		triggerUpdate() {
			this.isFetching = true;
			this.isFetching = false;
		}
	}

	// if (type === 'entity') {
	// 	return { Entity, Store: undefined as Store<any> };
	// }

	class Store<T extends Entity> extends Persistable {
		private _EntityType?: new (data: any, store?: Store<any>) => T;
		private _resourcePrefix = '';
		private searchDb: any;

		@observable.shallow all: T[] = [];
		@observable.ref single?: T;
		@observable pagination: Pagination = {
			supported: paginated,
		};
		@observable isFetching = false;
		@observable isCreating = false;

		constructor(
			entityType?: new (data: any, store?: Store<any>) => T,
			options?: { resourcePrefix?: string }
		) {
			super();
			this._EntityType = entityType;
			this._resourcePrefix = options?.resourcePrefix || '';

			if (searchFields) {
				this.searchDb = new Fuse([], {
					shouldSort: true,
					threshold: 0.2,
					minMatchCharLength: 1,
					keys: searchFields,
					findAllMatches: false,
					ignoreLocation: true,
					ignoreFieldNorm: true,
					includeMatches: true,
				});
			}

			setTimeout(() => {
				this.init();
			});
		}

		init() {
			this.makeTransformable();
			makeObservable(this);
		}

		@action.bound
		reindex() {
			if (!searchFields) {
				return;
			}
			this.searchDb.setCollection(this.available);
		}

		@action.bound
		search(query: string) {
			if (!searchFields || !this.searchDb) {
				return [];
			}

			return this.searchDb.search(query);
		}

		// Methods
		getById = computedFn((id: string) => {
			return this.byId[id];
		});

		// Computeds
		@computed
		get isCreatable() {
			return true;
		}

		@computed
		get deleted() {
			return this.all.filter((item) => item.deletedAt);
		}

		@computed
		get available() {
			return this.all.filter((item) => !item.deletedAt);
		}

		@computed
		get byId() {
			return keyBy(this.all, 'id');
		}

		@computed
		get list() {
			return this.available;
		}

		// Flows
		@flow.bound
		*fetchAll(limit = 10, offset = 0, filters = {}, sorters = undefined) {
			this.isFetching = true;
			try {
				if (!local) {
					const { data, headers }: any = yield client({
						method: 'GET',
						url: `/${this._resourcePrefix}${resourceName}?${qs.stringify({
							...filters,
							sort: sorters || undefined,
						})}`,
						headers: paginated
							? {
									'pagination-limit': limit,
									'pagination-offset': offset,
									'with-deleted': 'true',
							  }
							: {},
					});

					if (paginated) {
						this.pagination = {
							...this.pagination,
							limit,
							offset,
							count: parseInt(headers['pagination-count'], 10),
							supported: true,
							filters,
						};
					}

					this.replaceAll(data);
					this.reindex();
				}
				this.isFetching = false;
			} catch (error) {
				this.isFetching = false;
				throw error;
			}
		}

		replaceAll(data: any[]) {
			this.all = this.all.slice(0, data.length);
			const currentLength = this.all.length;
			for (let i = 0, length = data.length; i < length; i++) {
				if (currentLength < i + 1) {
					this.all.push(new this._EntityType(data[i], this));
				} else {
					this.all[i].replace(data[i]);
				}

				this.setLastUpdated(data[i].updatedAt);
			}
		}

		@flow.bound
		*fetchSingle(id: string) {
			if (this.byId[id]) {
				this.single = new this._EntityType(this.byId[id].toPlain(), this);
				this.single.isFetching = true;
			} else {
				this.single = new this._EntityType({ id, isFetching: true }, this);
			}

			try {
				if (!local) {
					const { data } = yield client({
						method: 'GET',
						url: `/${this._resourcePrefix}${resourceName}/${id}`,
					});
					this.single = new this._EntityType(data, this);
					return this.single;
				}
			} catch (error) {
				this.single = undefined;
				throw error;
			}
		}

		@flow.bound
		*create(postData: T) {
			this.isCreating = true;
			try {
				let entity;
				if (!local) {
					const { data } = yield client({
						method: 'POST',
						url: `/${this._resourcePrefix}${resourceName}`,
						data: postData,
					});
					entity = new this._EntityType(data, this);
					this.setLastUpdated(data.updatedAt);
					// TODO: handle pagination
				} else {
					entity = new this._EntityType(postData, this);
				}
				this.all.push(entity);
				this.isCreating = false;
				this.reindex();
				return entity;
			} catch (error) {
				this.isCreating = false;
				throw error;
			}
		}

		@flow.bound
		*getOrFetchSingle(id: string) {
			if (this.byId[id]) {
				this.single = new this._EntityType(this.byId[id].toPlain(), this);
			} else {
				yield this.fetchSingle(id);
			}
		}

		// Actions
		@action.bound
		triggerUpdate() {
			this.isFetching = true;
			this.isFetching = false;
		}

		@action.bound
		unloadSingle() {
			this.single = undefined;
		}

		@action.bound
		receiveFromSocket(data: any[]) {
			data.forEach((singleItem) => {
				const existing = this.getById(singleItem.id);
				this.setLastUpdated(singleItem.updatedAt);
				if (existing) {
					existing.replace(singleItem);
				} else {
					this.all.push(new this._EntityType(singleItem, this)); // TODO: adjust to play nice with pagination
				}

				if (this.single && this.single.id === singleItem.id) {
					this.single.replace(singleItem);
				}
			});
			if (data.length) {
				this.triggerUpdate();
			}
		}
	}

	return { Store, Entity };
}
