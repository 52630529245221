import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import {
	TreeSelect,
	Button,
	ConfigProvider,
	Empty,
	Form,
	notification,
	Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { v2Client } from '../../../../../../../../../store/client';
import stores from '../../../../../../../../../stores/index.mobx';
import { MarketplaceApplication } from '../../../../../../../../../stores/MarketplaceApplication.mobx';

interface Props extends Omit<React.ComponentProps<typeof TreeSelect>, 'value'> {
	treeCheckable?: boolean;
	value?: string | void;
	showCreateButton?: boolean;
}

function convertData(data: any[]) {
	return data.map((item: any) => {
		return {
			...item,
			name: item.name?.[0]?.value,
			child_categories: convertData(item.child_categories),
		};
	});
}

function CategorySelect({ onChange, value, ...rest }: Props) {
	const { saleChannels } = stores;

	const parentForm = Form.useFormInstance();
	const storeId = Form.useWatch('storeId', parentForm);

	const [isFetching, setIsFetching] = useState(false);
	const [installedApplication, setInstalledApplication] =
		useState<MarketplaceApplication | null>(null);

	console.log('parentForm', installedApplication);
	useEffect(() => {
		const id =
			saleChannels.byId[parentForm.getFieldValue('saleChannelId')]
				?.applicationId;

		if (storeId && id) {
			setIsFetching(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setInstalledApplication(response.data);
				})
				.finally(() => {
					setIsFetching(false);
				});
		}
	}, [storeId, parentForm, saleChannels.byId]);

	const reloadCategories = useCallback(() => {
		setIsFetching(true);
		v2Client
			.get(
				`/marketplace-applications/${installedApplication?.id}/functions/get-items`
			)
			.then((response) =>
				v2Client.get(
					`/marketplace-applications/installed/${installedApplication?.id}`,
					{
						headers: {
							'store-id': storeId,
						},
					}
				)
			)
			.then((response) => {
				setInstalledApplication(response.data);
			})
			.catch((error) => {
				console.log(error);
				notification.error({
					message: 'Greška',
					description: 'Došlo je do greške prilikom učitavanja kategorija',
				});
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [installedApplication]);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/subfolder.png" />}
						imageStyle={{ height: 64 }}
						description="Nema kategorija"
					>
						<Button
							type="link"
							onClick={() => reloadCategories()}
							icon={<ReloadOutlined />}
						>
							Osveži kategorije
						</Button>
					</Empty>
				)}
			>
				<TreeSelect
					loading={isFetching}
					treeLine
					fieldNames={{
						label: 'name',
						value: 'id',
						children: 'child_categories',
					}}
					dropdownMatchSelectWidth={false}
					treeData={convertData(
						installedApplication?.settings?.categories || []
					)}
					dropdownRender={(menu) => (
						<Spin spinning={isFetching}>
							{menu}
							<Button
								type="link"
								onClick={() => reloadCategories()}
								icon={<ReloadOutlined />}
							>
								Osveži kategorije
							</Button>
						</Spin>
					)}
					onChange={onChange}
					value={value}
					{...rest}
				/>
			</ConfigProvider>
		</>
	);
}

export default observer(CategorySelect);
