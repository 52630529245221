import { Checkbox, Spin, Switch, Tooltip } from 'antd';
import { Observer } from 'mobx-react-lite';

import { CreatePage } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';
import styles from '../Products/Products.module.less';
export default CreatePage({
	module: 'catalog',
	submodule: 'products',
	model: 'product',
	page: {
		shouldFetch: false,
		empty: {
			text: 'Nema artikala',
			image: 'availability',
		},
		searchable: true,
		searchPlaceholder: 'Pretraži artikle',
		searchFilter: (values) => {
			return values.filter((value) => {
				return value.saleChannels.length > 0;
			});
		},
		customCreateButton: () => <></>,
		table: {
			listProp: 'variantsWithSaleChannels',
			showActions: false,
			columns: () => {
				return [
					{
						title: '',
						dataIndex: 'coverImage',
						key: 'coverImage',
						width: 50,
						render: (image) => {
							return image ? (
								<>
									<img
										src={image.urls['64x64']}
										alt="Slika"
										className={styles.image}
									/>
								</>
							) : null;
						},
						shouldLink: true,
					},
					{
						title: 'Naziv',
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: 'Svi',
						key: 'all',
						width: 100,
						align: 'center',
						render: (value, record) => (
							<Observer>
								{() => (
									<Checkbox
										onChange={(e) => {
											record.saleChannels
												.filter(
													(sc) => sc.storeId === stores.stores.currentStoreId
												)
												.map((sc) => sc.setAvailability(e.target.checked));
										}}
										checked={record.saleChannels
											.filter(
												(sc) => sc.storeId === stores.stores.currentStoreId
											)
											.every((sc) => sc.enabled)}
										indeterminate={
											record.saleChannels
												.filter(
													(sc) => sc.storeId === stores.stores.currentStoreId
												)
												.some((sc) => sc.enabled) &&
											!record.saleChannels
												.filter(
													(sc) => sc.storeId === stores.stores.currentStoreId
												)
												.every((sc) => sc.enabled)
										}
									/>
								)}
							</Observer>
						),
					},
					...stores.saleChannels.available.map((saleChannel) => {
						return {
							title: saleChannel.name,
							key: saleChannel.id,
							width: 100,
							align: 'center',
							render: (value, record) => (
								<Observer>
									{() => (
										<Tooltip
											title={(() => {
												if (
													record.saleChannels.find(
														(sc) =>
															sc.saleChannelId === saleChannel.id &&
															sc.storeId === stores.stores.currentStoreId &&
															!sc.externalId
													)
												) {
													return 'Artikal nije potpuno povezan sa ovim kanalom prodaje';
												}

												if (
													!record.saleChannels.find(
														(sc) =>
															sc.saleChannelId === saleChannel.id &&
															sc.storeId === stores.stores.currentStoreId
													)
												) {
													return 'Artikal nije dostupan na ovom kanalu prodaje';
												}

												return undefined;
											})()}
										>
											<Checkbox
												onChange={(e) => {
													record.saleChannels
														.find(
															(sc) =>
																sc.saleChannelId === saleChannel.id &&
																sc.storeId === stores.stores.currentStoreId
														)
														?.setAvailability(e.target.checked);
												}}
												disabled={
													!record.saleChannels.find(
														(sc) =>
															sc.saleChannelId === saleChannel.id &&
															sc.storeId === stores.stores.currentStoreId &&
															sc.externalId
													)
												}
												checked={
													record.saleChannels.find(
														(sc) =>
															sc.saleChannelId === saleChannel.id &&
															sc.storeId === stores.stores.currentStoreId
													)?.enabled
												}
											/>
										</Tooltip>
									)}
								</Observer>
							),
						};
					}),
					// {
					// 	title: 'Prodajna cena',
					// 	dataIndex: 'price',
					// 	key: 'price',
					// 	render: (value, record) => {
					// 		if (record.variants.length === 0) {
					// 			return numberFormat(
					// 				value || 0,
					// 				true,
					// 				2,
					// 				true,
					// 				record.currencyId || 'RSD'
					// 			);
					// 		}
					// 		if (record.variants.length === 1) {
					// 			return numberFormat(
					// 				record.variants[0].price,
					// 				true,
					// 				2,
					// 				true,
					// 				record.currencyId || 'RSD'
					// 			);
					// 		}

					// 		return `${numberFormat(
					// 			record.minPricedVariant.price,
					// 			(record.minPricedVariant.currencyId || 'RSD') !==
					// 				(record.maxPricedVariant.currencyId || 'RSD'),
					// 			2,
					// 			true,
					// 			record.minPricedVariant.currencyId || 'RSD'
					// 		)} - ${numberFormat(
					// 			record.maxPricedVariant.price,
					// 			true,
					// 			2,
					// 			true,
					// 			record.maxPricedVariant.currencyId || 'RSD'
					// 		)}`;
					// 	},
					// 	responsive: ['md'],
					// },
				];
			},
		},
	},
});
