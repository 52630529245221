import { ArrowDownOutlined, MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import update from 'immutability-helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
	PaymentType,
	PAYMENT_TYPE_TEXT,
} from '../../../../../../../constants/journal';
import stores from '../../../../../../../stores/index.mobx';
import styles from './PaymentMethodsOrder.module.less';

const type = 'DraggableBodyRow';

interface Props {
	value?: number[];
	onChange?: (any) => void;
}

const DraggableBodyRow = ({
	index,
	moveRow,
	className,
	style,
	...restProps
}) => {
	const ref = useRef();
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const { index: dragIndex } = monitor.getItem() || {};
			return {
				isOver: monitor.isOver(),
				dropClassName: ` ${
					dragIndex < index
						? styles['drop-over-downward']
						: styles['drop-over-upward']
				}`,
			};
		},
		drop: (item) => {
			moveRow(item.index, index);
		},
	});
	const [, drag] = useDrag({
		type,
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drop(drag(ref));

	return (
		<tr
			ref={ref}
			className={`${className} ${isOver ? dropClassName : ''}`}
			style={{ cursor: 'move', ...style }}
			{...restProps}
		/>
	);
};

export default function PaymentMethodsOrder({
	value = [],
	onChange = () => null,
}: Props) {
	const {
		company: { availablePaymentMethods },
	} = stores;

	const [paymentMethods, setPaymentMethods] = useState(value);
	useEffect(() => {
		setPaymentMethods(value);
	}, [value]);

	const components = {
		body: {
			row: DraggableBodyRow,
		},
	};

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = paymentMethods[dragIndex];
			const newPaymentMethods = update(paymentMethods, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRow],
				],
			});
			setPaymentMethods(newPaymentMethods);

			onChange(newPaymentMethods);
		},
		[paymentMethods]
	);

	const completePaymentMethods = useMemo(() => {
		return paymentMethods
			.filter((paymentMethod) => {
				return availablePaymentMethods === 'all'
					? true
					: [
							PaymentType.CASH,
							PaymentType.WIRE_TRANSFER,
							PaymentType.VOUCHER,
							PaymentType.OTHER,
							-1,
					  ].includes(paymentMethod);
			})
			.map((paymentMethod, index) => ({
				value: paymentMethod,
				title:
					paymentMethod === -1 ? (
						<>
							<ArrowDownOutlined /> Sakriveni načini plaćanja
						</>
					) : (
						PAYMENT_TYPE_TEXT[paymentMethod]
					),
			}));
	}, [paymentMethods, availablePaymentMethods]);

	return (
		<DndProvider backend={HTML5Backend}>
			<Table
				pagination={false}
				bordered
				size="small"
				rowKey="id"
				showHeader={false}
				dataSource={completePaymentMethods}
				components={components}
				onRow={(record, index) => ({
					index,
					moveRow,
				})}
			>
				<Table.Column
					width={42}
					render={() => (
						<MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
					)}
				/>
				<Table.Column key="title" dataIndex="title" />
			</Table>
		</DndProvider>
	);
}
