import round from 'lodash/round';
import { flow, observable } from 'mobx';
import type { Moment } from 'moment';

import { CreateStore } from './Crud.mobx';
import { MomentTransformer } from './transformers/Moment';

const { Store: CrudStore, Entity } = CreateStore({
	name: 'kpo',
	paginated: true,
	persistFields: [],
});

export type KpoType =
	| 'invoice'
	| 'creditNote'
	| 'debitNote'
	| 'advanceInvoice'
	| 'receipts'
	| 'custom'
	| 'import';

class Kpo extends Entity {
	@observable type?: KpoType;
	@observable description?: string;
	@observable partnerName?: string;
	@observable documentNumber?: string;
	@observable productAmount?: number;
	@observable serviceAmount?: number;
	@observable amount?: number;

	@MomentTransformer
	documentDate?: Moment;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isEditable() {
		return this.type === 'custom' || this.type === 'import';
	}

	get isDeletable() {
		return this.type === 'custom' || this.type === 'import';
	}
}

class Kpos extends CrudStore<Kpo> {
	@observable totalAmount?: number;
	@observable serviceAmount?: number;
	@observable productAmount?: number;

	constructor() {
		super(Kpo);
	}

	get isCreatable() {
		return true;
	}

	@flow.bound
	*backfill(startDate) {
		this.isFetching = true;
		try {
			yield this.getClient().post(`/kpos/backfill`, {
				startDate,
			});
			this.isFetching = false;
		} catch (error) {
			console.error(error);
			this.isFetching = false;
			throw error;
		}
	}

	*fetchAll(
		limit = 10,
		offset = 0,
		filters = {
			date: string,
		},
		sorters = {}
	): Generator<any, void, any> {
		this.totalAmount = 0;
		this.serviceAmount = 0;
		this.productAmount = 0;

		try {
			this.isFetching = true;
			const {
				data: { data, totals },
				headers,
			} = yield this.getClient()({
				method: 'GET',
				url: `/kpos/${filters.date}`,
				headers: {
					'pagination-limit': limit,
					'pagination-offset': offset,
				},
			});
			this.replaceAll(data);
			this.serviceAmount = totals.serviceAmount;
			this.productAmount = totals.productAmount;
			this.totalAmount = round(totals.serviceAmount + totals.productAmount, 2);

			this.pagination = {
				...this.pagination,
				limit,
				offset,
				count: parseInt(headers['pagination-count'], 10),
				supported: true,
			};
			this.isFetching = false;
		} catch (error) {
			console.error(error);
			this.isFetching = false;
			throw error;
		}
	}
}

export { Kpos, Kpo };
