import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	message,
	notification,
} from 'antd';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import DisablePasswordAutocomplete from '../../../../components/DisablePasswordAutocomplete';
import { STATUS_CODES, STATUS_PER_MRC } from '../../../../constants/esdc';
import stores from '../../../../stores/index.mobx';
import styles from './SDC.module.less';

function SDC() {
	const [form] = Form.useForm();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const {
		sdc,
		company: { package: currentPackage },
	} = stores;

	const { browsePfx, saveConfiguration, isSaving } = sdc;

	const [currentValues, setCurrentValues] = useState({
		url: null,
	});

	useEffect(() => {
		form.setFieldsValue({
			sdc,
		});
	}, [sdc, sdc.pac]);

	async function onSubmit() {
		try {
			const values = await form.validateFields();
			if (values.url) {
				try {
					if (window.electron) {
						await window.electron.esdcRequest({
							url: `${values.url}/api/v3/status`,
							method: 'get',
						});
					} else {
						await axios.get(`${values.url}/api/v3/status`);
					}
				} catch (e) {
					form.setFields([
						{
							name: 'url',
							value: values.url,
							errors: ['L-PFR nije dostupan na ovoj adresi'],
						},
					]);
					return;
				}
			}

			await saveConfiguration(values);
			notification.success({
				message: 'Podešavanja su upešno sačuvana',
			});
			setIsFormTouched(false);
		} catch (error) {
			console.error(error);
			notification.error({
				message: 'Došlo je do greške prilikom čuvanja podešavanja',
			});
		}
	}

	function onValuesChange(changedValues, allValues) {
		setIsFormTouched(true);
		setCurrentValues({ ...currentValues, ...allValues });
	}

	async function onBrowseClick() {
		try {
			const path = await browsePfx();
			form.setFieldsValue({
				pfx: path,
			});
		} catch (e) {
			message.error(e.message);
		}
	}

	return (
		<div className={styles.wrapper}>
			<Form
				layout="vertical"
				className={styles.form}
				initialValues={sdc}
				onFinish={onSubmit}
				form={form}
				onValuesChange={onValuesChange}
			>
				<DisablePasswordAutocomplete />

				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">L-PFR</div>
				</div>
				<>
					<Form.Item>
						L-PFR je obavezan u poslovnom prostoru ukoliko delatnost nije online
						trgovina. <br />
						{process.env.REACT_APP_APP_NAME !== 'ibuddy' && (
							<>
								{currentPackage === 'lite' ? (
									<>
										Kao korisnik Lite paketa, možete koristiti StoreBuddy L-PFR
										po ceni od 400 RSD mesečno.
										<br />
									</>
								) : (
									<>
										Kao korisnik StoreBuddy POS aplikacije, možete potpuno
										besplatno koristiti StoreBuddy L-PFR.
										<br />
									</>
								)}
								Preuzmite StoreBuddy L-PFR{' '}
								<a
									href="https://dvsoftware.rs/proizvodi/storebuddy-lpfr/"
									target="_blank"
								>
									ovde
								</a>
								.
							</>
						)}
					</Form.Item>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="url"
								label="Adresa do L-PFR-a"
								rules={[
									// {
									// 	required: true,
									// 	message: 'Morate uneti adresu do L-PFR-a',
									// },
									{
										type: 'url',
										message: 'Morate uneti ispravnu adresu',
									},
								]}
								extra={
									sdc.url && (
										<span className={styles.status}>
											{!sdc.esdcStatus?.available && (
												<>
													<img src="/images/icons/esdc/small/decline.png" />
													Nije dostupan&nbsp;&nbsp;
												</>
											)}
											{sdc.esdcStatus?.available && (
												<>
													<img src="/images/icons/esdc/small/accept.png" />
													Dostupan&nbsp;&nbsp;
													{(sdc.esdcStatus?.gsc || []).map((statusCode) => {
														const status = STATUS_CODES[`${statusCode}`];
														if (!status) {
															return '';
														}
														return (
															<>
																<img
																	src={`/images/icons/esdc/small/${status[1]}.png`}
																/>{' '}
																{status[0]}&nbsp;&nbsp;
															</>
														);
													})}
													{(sdc.esdcStatus?.mssc || []).map((statusCode) => {
														const MRC =
															sdc.esdcStatus?.deviceSerialNumber.substring(
																0,
																7
															);
														const status =
															STATUS_PER_MRC[MRC]?.[`${statusCode}`];
														if (!status) {
															return '';
														}
														return (
															<>
																<img
																	src={`/images/icons/esdc/small/${status[1]}.png`}
																/>{' '}
																{status[0]}&nbsp;&nbsp;
															</>
														);
													})}
												</>
											)}
										</span>
									)
								}
							>
								<Input type="text" />
							</Form.Item>
						</Col>
					</Row>
				</>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">V-PFR</div>
				</div>
				{!window.electron && (
					<Form.Item>
						Kako biste koristili V-PFR iz internet pregledača, potrebno je da
						instalirate sertifikat koji ste dobili od Poreske uprave i da
						unesete PAK.
					</Form.Item>
				)}
				<Form.Item name="useVsdc" valuePropName="checked">
					<Checkbox>Koristi V-PFR kada je dostupna internet veza</Checkbox>
				</Form.Item>
				{window.electron && (
					<Row gutter={16}>
						<Col span={18}>
							<Form.Item label="PFX Fajl" name="pfx">
								<Input type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label=" ">
								<Button block onClick={onBrowseClick}>
									Pronađi
								</Button>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={16}>
					{window.electron && (
						<>
							<Col span={8}>
								<Form.Item label="JID" name="uid">
									<Input type="text" disabled />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Lozinka" name="password">
									<Input type="password" />
								</Form.Item>
							</Col>
						</>
					)}
					<Col span={8}>
						<Form.Item label="PAK" name="pac">
							<Input type="text" />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						disabled={!isFormTouched}
						loading={isSaving}
					>
						Sačuvaj
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}

export default observer(SDC);
