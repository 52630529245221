import { Mod11_10 } from '@konfirm/iso7064';

export default function validate(pib: string) {
	if (pib.length !== 9) {
		return false;
	}

	if (pib.match(/^[0-9]+$/) === null) {
		return false;
	}

	return Mod11_10.validate(pib);
}
