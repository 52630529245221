/* eslint-disable import/order */
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef } from 'react';
import './style.less';
// import JoditEditor from 'jodit-react';
// import { Jodit } from 'jodit';
import sr from '../../lib/suneditor/sr.json';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
// (Jodit as any).lang.sr = sr;

interface Props {
	onChange?: (value: string) => void;
	value?: string;
	disabled?: boolean;
}

const Editor = function Editor({ onChange, value }: Props) {
	const editor = useRef(null);
	const disabled = useContext(DisabledContext);

	useEffect(() => {
		if (editor.current) {
			editor.current.setContents(value);
		}
	}, [value]);

	return (
		<SunEditor
			onBlur={(event, editorContents) => onChange(editorContents)}
			defaultValue={value}
			setOptions={{
				buttonList: buttonList.complex,
				height: '200px',
			}}
			getSunEditorInstance={(instance) => (editor.current = instance)}
			lang={sr}
			setDefaultStyle="font-family: Montserrat; font-size: 14px;"
			disable={disabled}
		/>
	);
};

export default observer(Editor);
