import { InfoCircleOutlined } from '@ant-design/icons';
import {
	Button,
	Checkbox,
	Col,
	Drawer,
	Form,
	Input,
	InputNumber,
	Row,
	Space,
	Tooltip,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';

import CurrencySelect from '../../../../../../../../components/CurrencySelect';
import { Title } from '../../../../../../../../components/Title';
import UnitSelect from '../../../../../../../../components/UnitSelect';
import stores from '../../../../../../../../stores/index.mobx';
import { Product } from '../../../../../../../../stores/Product.mobx';
import SaleChannelsInput from '../../../SaleChannelsInput';

interface Props {
	visible?: boolean;
	entity?: Product;
	close: () => void;
	productType: string;
	save: (entity: Product) => void;
}

export default function VariantEditDrawer({
	visible,
	entity,
	close,
	productType,
	save,
}: Props) {
	const [formData, setFormData] = useState({ ...(entity || {}) });
	const [form] = Form.useForm();

	const parentForm = Form.useFormInstance();

	useEffect(() => {
		if (entity) {
			setFormData({ ...entity });
		} else {
			form.resetFields();
			setFormData({});
		}
	}, [entity]);

	useEffect(() => {
		form.setFieldsValue(formData);
	}, [formData, form]);

	const onFieldsChange = useCallback((changedFields, allFields) => {
		setFormData((prev) => ({
			...prev,
			...allFields.reduce((acc, field) => {
				acc[field.name[0]] = field.value;
				return acc;
			}, {}),
		}));
	}, []);

	return (
		<Drawer
			width={842}
			visible={visible}
			title={
				entity
					? `Izmena varijante ${entity?.variantName}`
					: 'Dodavanje varijante'
			}
			onClose={close}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						Poništi
					</Button>
					<Button key="save" type="primary" onClick={form.submit}>
						{entity ? 'Izmeni' : 'Dodaj'}
					</Button>
				</Space>
			}
		>
			<Form
				form={form}
				onFinish={save}
				layout="vertical"
				onFieldsChange={onFieldsChange}
			>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Osnovne informacije</div>
				</div>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item
							label="Naziv varijante"
							name="variantName"
							rules={[
								{ required: true, message: 'Naziv varijante je obavezan' },
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				{stores.saleChannels.all.length > 0 && (
					<>
						<Title>Dodatni kanali prodaje</Title>
						<Row gutter={8}>
							<Col span={24}>
								<Form.Item name="saleChannels">
									<SaleChannelsInput isVariant productForm={parentForm} />
								</Form.Item>
							</Col>
						</Row>
					</>
				)}
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Inventar</div>
				</div>
				<Row gutter={8}>
					<Col xs={5} sm={3} md={4}>
						<Form.Item
							name="sku"
							rules={[
								{
									type: 'number',
									validator: async (rule, value) => {
										if (value > 9999999) {
											throw new Error('Maksimalna šifra je 9999999');
										}
									},
									message: 'Maksimalna šifra je 9999999',
								},
							]}
							label={
								<>
									Šifra&nbsp;
									<Tooltip
										title={
											<>
												Šifru možete koristiti da otkucate artikal ukoliko on
												nema bar kod
												<br />
												<br />
												Ukoliko ovo polje ostavite prazno, artiklu će se
												dodeliti prva slobodna šifra.
											</>
										}
									>
										<InfoCircleOutlined />
									</Tooltip>
								</>
							}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					{productType === 'product' && (
						<>
							<Col xs={8} sm={5} md={4}>
								<Form.Item name="manufacturerSku" label="Šifra proizvođača">
									<Input />
								</Form.Item>
							</Col>
							<Col xs={11} sm={5}>
								<Form.Item
									name="ean"
									label="Bar kod (GTIN)"
									rules={[
										{
											type: 'string',
											validator: async (rule, value) => {
												if (
													typeof value === 'undefined' ||
													value === null ||
													value === ''
												) {
													return;
												}
												if (value.length < 8 || value.length > 14) {
													throw new Error(
														'GTIN ne može biti kraći od 8 i duži od 14 karaktera'
													);
												}
											},
										},
									]}
								>
									<Input type="number" />
								</Form.Item>
							</Col>
						</>
					)}
					<Col xs={12} sm={6}>
						<Form.Item
							name="currencyId"
							label="Prodajna cena"
							style={{ display: 'none' }}
						>
							<CurrencySelect onlyPreferred />
						</Form.Item>
						<Form.Item name="price" label="Prodajna cena">
							<InputNumber
								addonAfter={
									stores.company.allowCurrencyConversion ? (
										<CurrencySelect
											style={{ width: 75 }}
											onlyPreferred
											value={formData.currencyId || 'RSD'}
											optionLabelProp="value"
											onChange={(value) => {
												setFormData({ ...formData, currencyId: value });
											}}
										/>
									) : (
										'RSD'
									)
								}
								step={0.01}
							/>
						</Form.Item>
					</Col>
				</Row>
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Mere</div>
				</div>
				{productType === 'product' && (
					<Row gutter={8}>
						<Col xs={10} sm={6}>
							<Form.Item name="baseUnitId" style={{ display: 'none' }}>
								<UnitSelect />
							</Form.Item>
							<Form.Item
								name="quantityPerBaseUnit"
								label={
									<>
										Količina u JM&nbsp;
										<Tooltip
											title={
												<>
													Jedinica mere u kojoj je iskazana količina proizvoda
													na ambalaži, na primer: ukoliko je u pitanju čokolada
													koja ima 100g, onda je jedinica mere kilogram, a
													količina je 0.1
												</>
											}
										>
											<InfoCircleOutlined />
										</Tooltip>
									</>
								}
							>
								<Input
									type="number"
									addonAfter={
										<>
											<UnitSelect
												value={formData.baseUnitId}
												onChange={(value) => {
													setFormData({ ...formData, baseUnitId: value });
												}}
												placeholder="JM"
											/>
										</>
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={8} sm={4}>
							<Form.Item name="saleUnitId" label="Prodajna JM">
								<UnitSelect />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="quantityFromScale"
								label=""
								valuePropName="checked"
							>
								<Checkbox>
									Koristi težinu sa vage&nbsp;
									<Tooltip
										title={
											<>
												Ukoliko je vaga konfigurisana, prilikom dodavanja
												proizvoda na račun, kao količina će se koristiti težina
												sa vage
											</>
										}
									>
										<InfoCircleOutlined />
									</Tooltip>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
				)}
				<div className="ant-descriptions-header">
					<div className="ant-descriptions-title">Račun</div>
				</div>
				<input type="submit" style={{ display: 'none' }} />
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item name="multiplePerReceipt" valuePropName="checked">
							<Checkbox>Dozvoli više puta na jednom računu</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}
